// src/Table.js
import { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'  // new
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';

import {
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    MenuIcon,
    UsersIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
    Logout,
    GiftIcon,
    UserGroup,
    ExclamationIcon,
    CogIcon,
    EyeOffIcon,
    EyeIcon,
    InformationCircleIcon,
    TrashIcon,

  } from '@heroicons/react/outline'
import { Button, PageButton } from './shared/Button'
import axios from 'axios'
import ReactTooltip from 'react-tooltip';


const { parseCookies, destroyCookie, setCookie } = require('nookies');



function Table({ columns, data, pagSize }) {
    const [allUsers2, viewUsers2] = useState([{}])
    const cookies = parseCookies()
    const [loading, setLoading] = useState(false)




  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state, preGlobalFilteredRows, setGlobalFilter} =
    useTable({
      columns,
      data,
      initialState: { pageSize: pagSize }

    },
    useGlobalFilter,
    usePagination
    );

    //Recupero i dati dal db
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/merchant/fetch`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers2(users.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  // Render the UI for your table
  return (
    <>
          <div className="flex gap-x-2">

    <GlobalFilter
      preGlobalFilteredRows={preGlobalFilteredRows}
      globalFilter={state.globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
          </div>

    <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle  min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b mx-4 border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} style={{borderSpacing: '0px',
            tableLayout: 'fixed',
            marginLeft:'auto',
            marginRight:'auto'
          }} className="divide-y divide-gray-200 table-fixed w-full">
                <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
                <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps()}
                        >{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row, i) => {  // new
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-1 py-1 break-all"
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
    </table>
    </div>
          </div>
        </div>
      </div>
     {/* new */}
     <div className="py-3 flex mt-3 items-center justify-between mr-4 mb-3">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Precedente</Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>Successiva</Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mx-4">
          <div className="flex  justify-between gap-x-4	">
            <p className="text-sm text-gray-600">
              Pagina {state.pageIndex + 1} di {pageOptions.length}</p>

                    <select
                className="mt-1 block w-full ml-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={(e) => {
                setPageSize(Number(e.target.value));
                }}
                >
              {[3, 5, 10, 20, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                Vedi {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Prima</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Precedente</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => nextPage()}
                disabled={!canNextPage
                }>
                <span className="sr-only">Successiva</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Ultima</span>
                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </>

  );
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
    return (
        <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-600 text-sm ml-3">Cerca: </span>
        <input
          type="text"
          class="my-6 block w-full h-8  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} risultati`}

        />
      </label>
    )
  }

export default Table;
