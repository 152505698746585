/* Variabili di Navigazione per Menu   */
import {
    CalendarIcon,
    HomeIcon,
    GiftIcon,
    UserIcon,
    OfficeBuildingIcon,
    ChartSquareBarIcon,
    BadgeCheckIcon,
    ShoppingBagIcon
  } from '@heroicons/react/outline'

import { BsPeople } from 'react-icons/bs';
import { GoPackage } from 'react-icons/go'
import { TbFileInvoice } from 'react-icons/tb'

export default function Menu(attivo){

//Dichiaro Voci del menu
const navigation = [
    { name: 'Riepilogo', href: '/', icon: HomeIcon},
    { name: 'Clienti', href: '/clienti', icon: BsPeople },
    { name: 'Fornitori', href: '/fornitori', icon: GoPackage },
    { name: 'Preventivi', href: '/preventivi', icon: TbFileInvoice },
    { name: 'Container', href: '/container', icon: OfficeBuildingIcon },
    { name: 'Noleggio scala', href: '/noleggio-scala', icon: ChartSquareBarIcon },
    { name: 'Permessi', href: '/permessi', icon: BadgeCheckIcon },
    { name: 'Listino', href: '/listino', icon: CalendarIcon},
    { name: 'Magazzino', href: '/magazzino', icon: ShoppingBagIcon },
    { name: 'Utenti', href: '/utenti', icon: UserIcon},
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

//Creo la costante con le voci

const arrayDiNavigazione = []

const map = navigation.map((item,index) => {

       if (attivo.attivo === item.name) {

       let schedaAttiva = true;

       //Controllo quale sheet è attiva
       const menu = {
        name: item.name,
        href: item.href,
        icon: item.icon,
        current: schedaAttiva }
        arrayDiNavigazione.push(menu)


       } else {

        let schedaAttiva = false;
        //Controllo quale sheet è attiva
        const menu = {
        name: item.name,
        href: item.href,
        icon: item.icon,
        current: schedaAttiva }
        arrayDiNavigazione.push(menu)

       }

      })

  return (
    <>
    {arrayDiNavigazione.map((item) => (
    <a
        key={item.name}
        href={item.href}
        className={classNames(
          item.current ? 'bg-blue-900 text-white' : 'text-white hover:bg-blue-700 hover:text-white',
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
        )}
      >
        <item.icon
          className={classNames(
            item.current2 ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
            'mr-3 flex-shrink-0 h-6 w-6'
          )}
          aria-hidden="true"
        />
        {item.name}
      </a>
    ))}
    </>

  )
}
