import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  SearchCircleIcon,
  SpeakerphoneIcon,
  MapIcon,
  ViewGridAddIcon,
  CogIcon,
  CameraIcon,
  UploadIcon,
  CheckIcon,
  CheckCircleIcon,
  TrashIcon,
  HeartIcon,
  checkCircleIcon,
  InformationCircleIconOut,
  RefreshIcon,
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon, PlusIcon, } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'

import getCroppedImg1 from './cropImage1'

//Importo Demoni per le tabelle
import { useTable } from "react-table";
import Table from "./Table1";


//Importo Menu
import Menu from "./navigation_events";


const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function DasboardMerchant() {
  //Setto gli state






  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsers, viewUsers] = useState([{}])
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const [immagineCliccata, setImmagineCliccata] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const indirzzi_email_loggato = cookies.email_events;
  const indirzzi_email_account = cookies.email;
  const [activeTab, setActiveTab] = useState('Profilo')
  const [isAdmin, setisAdmin] = useState(false);
  const [showsetAllProfile, setAllProfile] = useState([{}]);
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [show5, setShow5] = useState(false)
  const [allSommaEventi, setallSommaEventi] = useState([{}])
  const [allSommaPartecipanti, setallSommaPartecipanti] = useState([{}])









  //Controllo se la pagina è controllata da un Amministrstore oppure solamnete dall'Esercente
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      try {
        if(mounted){

        if (indirzzi_email_loggato === indirzzi_email_account) {

           //Sono Esercente
           setisAdmin(false)

          //Controllo Se sono uno degli amministratori
          if (indirzzi_email_loggato === 'info@finestrapp.it' || indirzzi_email_loggato === 'info@tribusadv.com') {
          //Sono Amministrazione ma nel mio account
          setisAdmin(true)
          } else {
           //Sono Esercente
           setisAdmin(false)
          }


        } else {

          //Sono Amministratore
          setisAdmin(true)

        }
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Numero Eventi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchNumeroEventi`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setallSommaEventi(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Numero Eventi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/events/fetchNumeroPartecipanti`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setallSommaPartecipanti(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {

      return "Chiuso";

    } else {

      return string;


    }
  }


  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero i dati dal db
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/events/fetchMessaggiEsercente/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  const richiamaDati = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/events/fetchMessaggiEsercente/${indirzzi_email_loggato}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        viewUsers(users.data)
        setLoading(true)

    } catch (e) {
      console.log(e)
    }
  }


  //Setto nascosto esercente
  const settoMessagioLetto = async (id_messaggio) => {


    //Verifico se il messaggio è già stato letto

    const users = await axios.get(`${window.$apiEndpoint}/events/fetchLettoEsercenteID/${id_messaggio}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        const controllo = users.data[0].letto;


        if (controllo === 1) {

        console.log("Sono qua")

        //Già usato stampo errore
        setShow5(true);
        setTimeout(() => {setShow5(false)},4000)

        } else {
          console.log("Sono qua 2")

        //Non usato procedo a settare

        try {


          const add = await axios.post(`${window.$apiEndpoint}/events/settaMessaggio`, {
            settaMessaggio: id_messaggio,
        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          //Ruchiamo dati
          setTimeout(() => {richiamaDati()},1000)
          setShow3(true);
          setTimeout(() => {setShow3(false)},4000)
        } catch (err) {
          setShow2(true)
          setTimeout(() => {setShow2(false)},4000)
        }

        }


    } catch (e) {
      console.log(e)
    }


  }


  const columns = useMemo(
    () => [
      {
        Header: "Provenienza",
        accessor: "titolo_offerta",
        Cell: cell => (
          <td className="px-6 py-4 break-all text-sm text-gray-500"><p className="break-all"style={{width: '8.5rem'}} >{cell.row.values.titolo_offerta}</p></td>

        )
      },
      {
        Header: "messaggio",
        accessor: "messaggio",
        Cell: cell => (
          <td className="px-6 py-4 break-all text-sm font-medium text-gray-900 ml-4"><p className="break-all" style={{width: '22rem'}} >{cell.row.values.messaggio}</p></td>

        )
      },
      {
        Header: "",
        accessor: "data_insert1",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right"></td>

        )
      },
      {
        Header: "Data messaggio",
        accessor: "data_insert",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{cell.row.values.data_insert}</td>

        )
      },

      {
        Header: "Stato Messaggio",
        accessor: "letto",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

      { cell.row.values.letto > 0 ? (
      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
            Letto
          </span> ) :

      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200">
            Non letto
      </span> }

      </td>

        )
      },
      {
        Header: "azioni",
        accessor: "azioni",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <ReactTooltip />
        <a onClick={() => settoMessagioLetto(cell.row.values.azioni)} className="text-green-600 hover:text-green-900">
        <button
        type="button"
        className="inline-flex items-center  ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >

        <CheckIcon data-tip="Leggi Messaggio" className="h-4 w-4" aria-hidden="true" />
      </button>
      </a>

      </td>
        )
      },


    ],
    []
  );


  const data = useMemo(() => allUsers, []);


  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.finestrapp.it/logo_bianco.svg"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                <Menu attivo={"Riepilogo & Messaggi"} />
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo_events"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.finestrapp.it/logo_bianco.svg"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <Menu attivo={"Riepilogo & Messaggi"} />
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo_events"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">



        {/* Header se admin è loggato come merchant*/}

        { isAdmin ? <div className="bg-green-600">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-green-800">
                              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white truncate">
                              <span className="md:hidden">Attenzione! Stai impersonificando un utente eventi! Indirizzo Email dell'utente: {indirzzi_email_loggato} </span>
                              <span className="hidden md:inline">Attenzione! Stai impersonificando un utente eventi! Indirizzo Email dell'utente: <strong>{indirzzi_email_loggato} </strong> </span>
                            </p>
                          </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <a
                            href="/eventi_admin"
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50"
                          >
                            Ritorna al Pannello Amministratore
                          </a>
                        </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">

                          </div>
                        </div>
                      </div>
          </div> : null }
          <div className="py-6">

            <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">
              {/* Replace with your content

               #Contenuto Merchant Dashboard

               */}

                 {/* Replace with your content */}
                 <div className="mt-8">
                     <h2 className="text-lg leading-6 font-medium text-gray-900 pt-5">Riepilogo & Messaggi</h2>
                     <div className="mt-2 p-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2 pb-5">

               <div  className="bg-white overflow-hidden shadow rounded-lg">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <CalendarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm font-medium text-gray-500 truncate">Eventi</dt>
                        <dd>
                        {allSommaEventi[0].n_eventi > 0 ? (
                          <div className="text-lg font-medium text-gray-900">{allSommaEventi[0].n_eventi}</div>) : <div className="text-lg font-medium text-gray-900">0</div> }                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-5 py-3">
                  <div className="text-sm">
                    <a href="create_event" className="font-medium text-cyan-700 hover:text-cyan-900">
                      Vedi Tutti
                    </a>
                  </div>
                </div>
              </div>

              <div  className="bg-white overflow-hidden shadow rounded-lg ">
               <div className="p-5">
                 <div className="flex items-center">
                   <div className="flex-shrink-0">
                     <UserGroupIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                   </div>
                   <div className="ml-5 w-0 flex-1">
                     <dl>
                       <dt className="text-sm font-medium text-gray-500 truncate">Totale Partecipanti (Eventi Attivi)</dt>
                       <dd>
                       {allSommaPartecipanti[0].n_partecipanti > 0 ? (
                         <div className="text-lg font-medium text-gray-900">{allSommaPartecipanti[0].n_partecipanti}</div>) : <div className="text-lg font-medium text-gray-900">0</div> }
                       </dd>
                     </dl>
                   </div>
                 </div>
               </div>
               <div className="bg-gray-50 px-5 py-3">
                 <div className="text-sm">
                   <a href="create_event" className="font-medium text-cyan-700 hover:text-cyan-900">
                     Vedi Tutti
                   </a>
                 </div>
               </div>
             </div>

                </div>
                  </div>


                      <div className="max-w-10xl mx-auto px-2 sm:px-4 lg:px-4 py-10" >
                      <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">


                    {/* Tabella Esercenti */}

                    <Table columns={columns} data={allUsers} pagSize={3} />


                              </div>
                            </div>
                          </div>
                        </div>

                       </div>




               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show5}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">Informazione!</p>
                  <p className="mt-1 text-sm text-gray-500">Attenzione questo messaggio risulta già letto!</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setShow5(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show2}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">Informazione!</p>
                  <p className="mt-1 text-sm text-gray-500">Impossibile processare la richiesta</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setShow2(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>





    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show3}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">Informazione!</p>
                  <p className="mt-1 text-sm text-gray-500">Messaggio letto con successo!</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setShow3(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>





















              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
