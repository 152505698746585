import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Select, { components } from 'react-select';
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  CogIcon,
  EyeOffIcon,
  EyeIcon,
  InformationCircleIcon,
  TrashIcon,
  SpeakerphoneIcon,
  CheckIcon,

} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { func } from 'prop-types'

//Importo Demoni per le tabelle
import { useTable } from "react-table";
import Table from "./Table";

//Importo Menu
import Menu from "./navigation";

//Importo Notifiche di successo
import SuccNotify from "./successNotify";

//Importo Notifiche di insucesso
import ErrNotify from "./errorNotify";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




const { parseCookies, destroyCookie, setCookie } = require('nookies');



export default function IntroAdmin() {
  //Setto gli state
  const [allUsers, viewUsers] = useState([{}])
  const [allEsercenti, setAllEsercenti] = useState([{}])
  const [allIntro, setAllIntro] = useState([{}])
  const [allIntro1, setAllIntro1] = useState([{}])
  const [allIntro2, setAllIntro2] = useState([{}])

  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [showEmailMerchant, setEmailMerchant] = useState('')
  const [showsetAllProfile, setAllProfile] = useState([{}]);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [showOpenEsercente, setOpenEsercente] = useState(false);
  const [showOpenIntro, setOpenIntro] = useState(false);
  const [showOpenIntro1, setOpenIntro1] = useState(false);
  const [showOpenIntro2, setOpenIntro2] = useState(false);

  const [allEventi, setAllEventi] = useState([{}])






  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;


  //Quando Seleziono

  const handleChange = selectedOption => {

    //Desetto tutte le scelte precedenti
    setSelectedOption(selectedOption.value);
    setSelectedOptionLabel(selectedOption.label);

  };



  //Chiamata post per inserimento prodotto
  const onSubmit = data => {



    //Variabili
    let date_now = new Date();
    let dataOggi_now = date_now.toLocaleString('it-IT').slice(0,-10)
    const TitoloIntro = data.titolo_intro;
    const Desc = data.testo_intro.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '').replace(/\'/g,"''");

    const TitoloIntro1 = data.titolo_intro_1;
    const Desc1 = data.testo_intro_1.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '').replace(/\'/g,"''");

    const TitoloIntro2 = data.titolo_intro_2;
    const Desc2 = data.testo_intro_2.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '').replace(/\'/g,"''");

    //Eseguo Update


    const addM = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/updateIntro`, {
          titolo_intro: TitoloIntro,
          titolo_intro_1: TitoloIntro1,
          titolo_intro_2: TitoloIntro2,
          testo_intro: Desc,
          testo_intro_1: Desc1,
          testo_intro_2: Desc2,

      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
      setSucc(true);
      setTimeout(() => {setSucc(false)},6000)
      } catch (err) {
        setErr(true)
        setTimeout(() => {setErr(false)},4000)
      }
    }
    addM()




}

  const data = useMemo(() => allUsers, []);



  const cancelButtonRef = useRef(null)
  let history = useHistory()


  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }


  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  const CALLLoginAsMerchant = (email) => {

    //Imposto email del merchant come amministratore
    setCookie(null, 'email_merchant', email , {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    })

    //Eseguo  Redirect su pagina merchant

    history.push('/dashboard_merchant')

  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Lista Utenti per select
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllIntro`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllIntro(info.data[0])


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Utenti per select
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllIntro`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllIntro(info.data[0])


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Utenti per select
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllIntro`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllIntro1(info.data[1])


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Lista Utenti per select
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllIntro`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllIntro2(info.data[2])


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.finestrapp.it/logo_bianco.svg"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                <Menu attivo={"Gestisci Intro"} />
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />

                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.finestrapp.it/logo_bianco.svg"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <Menu attivo={"Gestisci Intro"} />
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />

                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {/* Replace with your content */}

              <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Gestisci schermate Intro
              </h2>
              <p className="max-w-6xl mx-auto mt-1 px-4 text-sm leading-6 font-small text-gray-400 sm:px-6 lg:px-8">Utilizza i seguenti campi per modificare le schermate di intro sulla App</p>

              <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8 mt-3" >
              <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">



            <div className="bg-white ">

            {/* Corpo pagina */}

            <form onSubmit={handleSubmit(onSubmit)} className="p-8">
            <div className="grid grid-cols-12 gap-4">
             <div className="col-span-12 sm:col-span-12">
             <span className="text-xs my-3 font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                   Schermata Intro 1
                 </span>
                <label htmlFor="titolo_intro" className="block text-sm font-medium text-gray-700 mb-1">
                Titolo Intro
                </label>
                <input
                  type="text"
                  name="titolo_intro"
                  id="titolo_intro"
                  defaultValue={allIntro.titolo}
                  {...register("titolo_intro", { required: true })}
                  className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              {errors.titolo_intro && <p>Il campo è obbligatorio, se intendete mantenere lo stesso valore non modificato ricliccate il tasto Modifica Intro</p>}
              <small className="text-xs text-gray-500 mt-2" >Titolo schermata </small>

              </div>

              <div className="col-span-12 sm:col-span-12">
                <label htmlFor="testo_intro" className="block text-sm font-medium text-gray-700 mb-1">
                Testo Intro
                </label>
                <textarea
                id="testo_intro"
                name="testo_intro"
                rows={2}
                defaultValue={allIntro.testo}
                {...register("testo_intro", { required: true })}
                className="shadow-sm focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"


              />
              {errors.testo_intro && <p>Il campo è obbligatorio, se intendete mantenere lo stesso valore non modificato ricliccate il tasto Modifica Intro</p>}

                <small className="text-md text-gray-500 mt-2" >Testo schermata</small>
                <br/>

              </div>

              <div className="col-span-12 sm:col-span-12">
              <span className="text-xs my-3 font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                    Schermata Intro 2
                  </span>
                 <label htmlFor="titolo_intro_1" className="block text-sm font-medium text-gray-700 mb-1">
                 Titolo Intro
                 </label>
                 <input
                   type="text"
                   name="titolo_intro_1"
                   id="titolo_intro_1"
                   defaultValue={allIntro1.titolo}
                   {...register("titolo_intro_1",  { required: true })}

                   className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                 />
               {errors.titolo_intro_1 && <p>Il campo è obbligatorio, se intendete mantenere lo stesso valore non modificato ricliccate il tasto Modifica Intro</p>}
               <small className="text-xs text-gray-500 mt-2" >Titolo schermata </small>

               </div>

               <div className="col-span-12 sm:col-span-12">
                 <label htmlFor="testo_intro_1" className="block text-sm font-medium text-gray-700 mb-1">
                 Testo Intro
                 </label>
                 <textarea
                 id="testo_intro_1"
                 name="testo_intro_1"
                 rows={2}
                 defaultValue={allIntro1.testo}
                 {...register("testo_intro_1",  { required: true })}
                 className="shadow-sm focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"


               />
               {errors.testo_intro_1 && <p>Il campo è obbligatorio, se intendete mantenere lo stesso valore non modificato ricliccate il tasto Modifica Intro</p>}

                 <small className="text-md text-gray-500 mt-2" >Testo schermata</small>
                 <br/>

               </div>

               <div className="col-span-12 sm:col-span-12">
               <span className="text-xs my-3 font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                     Schermata Intro 3
                   </span>
                  <label htmlFor="titolo_intro_2" className="block text-sm font-medium text-gray-700 mb-1">
                  Titolo Intro
                  </label>
                  <input
                    type="text"
                    name="titolo_intro_2"
                    id="titolo_intro_2"
                    defaultValue={allIntro2.titolo}
                    {...register("titolo_intro_2", { required: true } )}

                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                {errors.titolo_intro_2 && <p>Il campo è obbligatorio, se intendete mantenere lo stesso valore non modificato ricliccate il tasto Modifica Intro</p>}
                <small className="text-xs text-gray-500 mt-2" >Titolo schermata </small>

                </div>

                <div className="col-span-12 sm:col-span-12">
                  <label htmlFor="testo_intro_2" className="block text-sm font-medium text-gray-700 mb-1">
                  Testo Intro
                  </label>
                  <textarea
                  id="testo_intro_2"
                  name="testo_intro_2"
                  rows={2}
                  defaultValue={allIntro2.testo}
                  {...register("testo_intro_2", { required: true } )}
                  className="shadow-sm focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"


                />
                {errors.testo_intro_2 && <p>Il campo è obbligatorio, se intendete mantenere lo stesso valore non modificato ricliccate il tasto Modifica Intro</p>}

                  <small className="text-md text-gray-500 mt-2" >Testo schermata</small>
                  <br/>

                </div>











            </div>



            <div className="flex justify-end mt-5">
            <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
            Modifica Intro
            </button>
            </div>
            </form>
            </div>



                      </div>
                    </div>
                  </div>
                </div>

               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    {/* Notifche di successo */}

    { showSucc ? <SuccNotify titolo={"Congratulazioni"}  testo={"Intro modificati con successo!"} /> : null }

   {/* Notifche di insucesso */}

   { showErr ? <ErrNotify titolo={"Attenzione"}  testo={"Errore nella modifica. Ricontrolla!"} /> : null }

    <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Informazione!</p>
                    <p className="mt-1 text-sm text-gray-500">Modifica inviata con successo!</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
