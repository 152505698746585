import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  SearchCircleIcon,
  SpeakerphoneIcon,
  MapIcon,
  ViewGridAddIcon,
  CogIcon,
  CameraIcon,
  UploadIcon,
  CheckIcon,
  CheckCircleIcon,
  TrashIcon,
  HeartIcon,
  checkCircleIcon,
  InformationCircleIconOut,
  RefreshIcon,
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon, PlusIcon, } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'

import getCroppedImg1 from './cropImage1'


//Importo Menu
import Menu from "./navigation_merchant";

//Importo Notifiche di insucesso
import ErrNotify from "./errorNotify";


//Importo demone per il contet text rich
import { HtmlEditor, Toolbar, Editor } from '@aeaton/react-prosemirror'
import { plugins, schema, toolbar } from '@aeaton/react-prosemirror-config-default'

const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function DasboardMerchant() {
  //Setto gli state
  const [imageCover, setImageCover] = useState([{}])
  const [imageProfilo, setImageProfilo] = useState([{}])
  const [imageNegozio, setimageNegozio] = useState([{}])
  const [Allinfo, setAllInfo] = useState([{}]);
  const [AllTags, setTags] = useState([{}]);
  const [AllAntipasti, setAntipasti] = useState([{}]);
  const [AllPrimi, setPrimi] = useState([{}]);
  const [AllSecondi, setSecondi] = useState([{}]);
  const [AllFormaggi, setFormaggi] = useState([{}]);
  const [AllDessert, setDessert] = useState([{}]);
  const [AllBevande, setBevande] = useState([{}]);
  const [AllPiatti, setPiatti] = useState([{}]);

  const [AllOrari, setOrari] = useState([{}]);
  const [AllProdotti, setProdotti] = useState([{}]);
  const [AllCoordinate, setCoordinate] = useState([{}]);
  const [showPercentuale, setshowPercentuale] = useState(0)
  const [AllCategorie, setAllCategorie] = useState([{}]);






  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const [immagineCliccata, setImmagineCliccata] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [openModalDet, setopenModalDet] = useState(false)
  const [openModalUploadProfile, setOpenModalUploadProfile] = useState(false)
  const [openModalUploadNegozio, setOpenModalUploadNegozio] = useState(false)
  const [openModalUpdateInfo, setOpenModalUpdateInfo] = useState(false)
  const [openModalUpdateTags, setOpenModalUpdateTags] = useState(false)
  const [openModalUpdateMenu, setOpenModalUpdateMenu] = useState(false)
  const [openModalUpdateOrari, setOpenModalUpdateOrari] = useState(false)
  const [openModalCropProfile, setOpenModalCropProfile] = useState(false)
  const [imageProfile, setimageProfile] = useState('')
  const [imageProfile1, setimageProfile1] = useState('')

  const [imageProfileUrl, setimageProfileUrl] = useState(null)
  const [imageProfileUrl1, setimageProfileUrl1] = useState(null)

  const [styleHover, setStyleHover] = useState({display: 'none'});
  const [styleHoverImg, setStyleHoverImg] = useState({display: 'block'});
  const [predefinita, setPredefinita] = useState(true);
  const [showsetAllProfile, setAllProfile] = useState([{}]);




  const [openModalCropPredefinita, setOpenModalCropPredefinita] = useState(false)







  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2 , handleSubmit: handleSubmit2 , formState: { errors: errors2 } } = useForm();
  const { register: register3 , handleSubmit: handleSubmit3 , formState: { errors: errors3 } } = useForm();
  const { register: register4 , handleSubmit: handleSubmit4 , formState: { errors: errors4 } } = useForm();


  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadStatus2, setUploadStatus2] = useState('');
  const [uploadStatus3, setUploadStatus3] = useState('');

  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTabProfilo, setcurrentTabProfilo] = useState(true);
  const [currentTabPOfferte, setcurrentTabOfferte] = useState(false);
  const [currentTabOrari, setcurrentTabOrari] = useState(false);
  const [activeTab, setActiveTab] = useState('Profilo')
  const [isAdmin, setisAdmin] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [showSucc1, setSucc1] = useState(false);
  const [showErr1, setErr1] = useState(false);
  const [showSucc2, setSucc2] = useState(false);
  const [showErr2, setErr2] = useState(false);
  const [showSucc10, setSucc10] = useState(false);
  const [showErr10, setErr10] = useState(false);
  const [showErr4, setErr4] = useState(false);
  const [showSucc4, setSucc4] = useState(false);
  const [showPreSucc, setPreSucc] = useState('');
  const [showPreErr, setPreErr] = useState('');
  const [show, setShow] = useState(false)
  const [showDataTip, setshowDataTip] = useState('')
  const [showErr100, setErr100] = useState(false);



  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)


  const [crop1, setCrop1] = useState({ x: 0, y: 0 })
  const [rotation1, setRotation1] = useState(0)
  const [zoom1, setZoom1] = useState(1)
  const [croppedAreaPixels1, setCroppedAreaPixels1] = useState(null)
  const [croppedImage1, setCroppedImage1] = useState(null)
  const [showsetImmagineNegozioCrop, setImmagineNegozioCrop] = useState('')
  const [numeroCellulareDef, setNumeroCellulareDef] = useState('NULL')



  const ImgProfile = window.$fileserverEndpointCrop + imageProfile;

  const ImgProfile1 = window.$fileserverEndpointCrop + showsetImmagineNegozioCrop;


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onCropComplete1 = useCallback((croppedArea1, croppedAreaPixels1) => {
    setCroppedAreaPixels1(croppedAreaPixels1)
  }, [])

  const showCroppedImage = useCallback(async () => {

    try {
      const croppedImage = await getCroppedImg({
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
        ImgProfile,
        croppedAreaPixels,
        rotation
      });
      setCroppedImage(croppedImage)
      setimageProfileUrl(croppedImage)
      //Il resize è andato a buon fine procedo al update del campo url_image_profile nel database

      const addBase64 = async () => {
        try {

          const add = await axios.post(`${window.$apiEndpoint}/merchant/updatebase64`, {
            email: indirzzi_email_loggato,
            base64: croppedImage,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          setSucc10(true);
          setTimeout(() => {setSucc10(false)},5000)
        } catch (err) {
          setErr10(true)
          setTimeout(() => {setErr10(false)},5000)
        }
      }
      addBase64()

    } catch (error) {
      console.error(error)
    }
  }, [croppedAreaPixels, rotation])

  const showCroppedImage1 = useCallback(async () => {

    try {
      const croppedImage1 = await getCroppedImg1({
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
        ImgProfile1,
        croppedAreaPixels1,
        rotation1
      });

      setCroppedImage1(croppedImage1)
      setimageProfileUrl1(croppedImage1)
      //Il resize è andato a buon fine procedo al update del campo url_image_profile nel database

      const addBase64 = async () => {
        try {

          const add = await axios.post(`${window.$apiEndpoint}/merchant/updatebase64Negozio`, {
            email: indirzzi_email_loggato,
            base64: croppedImage1,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          setSucc10(true);
          setTimeout(() => {setSucc10(false)},5000)
          setTimeout(() => {setOpenModalCropPredefinita(false)},5000)

        } catch (err) {
          setErr10(true)
          setTimeout(() => {setErr10(false)},5000)
        }
      }
      addBase64()

    } catch (error) {
      console.error("Sono in questo errore " + error)
    }
  }, [croppedAreaPixels1, rotation1])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  const onClose1 = useCallback(() => {
    setCroppedImage1(null)
  }, [])


  const initialValue = '<p></p>'
  const [valueText, setValueText] = useState(initialValue)

  //Controllo se la pagina è controllata da un Amministrstore oppure solamnete dall'Esercente
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      try {
        if(mounted){

        if (indirzzi_email_loggato === indirzzi_email_account) {

           //Sono Esercente
           setisAdmin(false)

          //Controllo Se sono uno degli amministratori
          if (indirzzi_email_loggato === 'info@finestrapp.it' || indirzzi_email_loggato === 'info@tribusadv.com') {
          //Sono Amministrazione ma nel mio account
          setisAdmin(true)
          } else {
           //Sono Esercente
           setisAdmin(false)
          }


        } else {

          //Sono Amministratore
          setisAdmin(true)

        }
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])





  const tabs = [
    { name: 'Profilo'},
    { name: 'Foto'},
    { name: 'Orari di Apertura'},
    { name: 'Prodotti'},
    { name: 'Menu'},
  ]


 const changeTab = (index) => {
  setTabIndex(index)
   if(index === 0) {
     setActiveTab('Profilo')
   } else if(index === 1) {
     setActiveTab('Foto')
   } else if(index === 2) {
    setActiveTab('Orari di Apertura')
   } else if(index === 3) {
    setActiveTab('Prodotti')
   } else if(index === 4) {
    setActiveTab('Menu')
   }
  }



  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }

  const CallUpload = () => {
    setOpenModalUpload(true);
  }

  const CallUpdateInfo = () => {
    setOpenModalUpdateInfo(true);
  }

  const CallUploadProfile = () => {
    setOpenModalUploadProfile(true);
  }

  const CallUploadNegozio = () => {
    setOpenModalUploadNegozio(true);
  }

  const CallUpdateTags = () => {
    setOpenModalUpdateTags(true);
  }

  const CallUpdateOrari = () => {
    setOpenModalUpdateOrari(true);
  }

  const CallUpdateMenu = () => {
    setOpenModalUpdateMenu(true);
  }

  const CallUpdateDet = (nome_immagine) => {
  //Apro il modale per cancellare o rendere predefinita
  setopenModalDet(true);
  setImmagineCliccata(nome_immagine);
  }

  //Recupero Controllo completamento del profilo
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {

          //Controllo se impostare o la notifoca

          if (controllo.data.controllo == 1) {

            setTimeout(() => {
              setShow(false)
              setLoading(true)
              }, 1000);
            //Non faccio niente

          } else {

            setTimeout(() => {
              setShow(true)
              setLoading(true)
              }, 3000);

          }

      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [show])


  //Recupero Controllo per immagine predefinito
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchPredefinita/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {

          //Controllo se impostare o no il banner

          if (controllo.data[0].controllo >= 1) {

            setPredefinita(false)
            setLoading(true)

          } else {

            setPredefinita(true)
            setLoading(true)

          }

      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [predefinita])

  //Recupero immagine cover
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const imageCoverS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageCover/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setTimeout(() => {
          setImageCover(imageCoverS.data)
          setLoading(true)
          }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [imageCover])

  //Recupero immagine cover
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const imageCoverS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageCover/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setImageCover(imageCoverS.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero categorie merchant
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const categorie = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllCategorie`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllCategorie(categorie.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero immagine Profilo
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const imageProfiloS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageProfilo/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setTimeout(() => {
            setImageProfilo(imageProfiloS.data)
            setimageProfile(imageProfiloS.data[0].url_imagine_profilo)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [imageProfilo])


  //Recupero immagine Negozio
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const imageNegozioS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageNegozio/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setTimeout(() => {
          setimageNegozio(imageNegozioS.data)
          setLoading(true)
          }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [imageNegozio])

  //Recupero immagine Negozio
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const imageNegozioS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageNegozio/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setimageNegozio(imageNegozioS.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero immagine Profilo
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const imageProfiloS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageProfilo/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
            setImageProfilo(imageProfiloS.data)
            setimageProfile(imageProfiloS.data[0].url_imagine_profilo)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Informazioni Profilo Esercente & Cerco indirizzo su google maps
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchMerchant/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllInfo(info.data)
          setLoading(true)

              //Converto indirizzo per Google Maps
        let indirizzo_google = `${info.data[0].indirizzo}`.toString()
        let indirizzo_google_2 = indirizzo_google.replace(/\s/g, "+");


          const fetch = async () => {
            const Coordinate = await axios.get(`https://maps.google.com/maps/api/geocode/json?key=${process.env.REACT_APP_API_KEY_GOOGLE}&address=${indirizzo_google_2},${info.data[0].civico}+${info.data[0].cap}+${info.data[0].citta}+${info.data[0].provincia}`, {
            });
            try {
              if(mounted){
                setCoordinate(Coordinate.data.results[0].geometry.location)
                setLoading(true)


              }
            } catch (e) {
              console.log(e)
            }
          }
          fetch();

          var bufferBase64 = new Buffer(info.data[0].base_64_image_profile_cropped.data, 'binary');
          setimageProfileUrl(`${bufferBase64}`)






        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Informazioni Profilo Esercente & Cerco indirizzo su google maps
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/merchant/fetchMerchant/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setLoading(true)

          setTimeout(() => {
            setAllInfo(info.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [Allinfo])

  //Recupero Tags
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Tag = await axios.get(`${window.$apiEndpoint}/merchant/fetchTagsOnly/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setTags(Tag.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Tags
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Tag = await axios.get(`${window.$apiEndpoint}/merchant/fetchTagsOnly/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setTags(Tag.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllTags])


  //Recupero Antipasti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Antipasti = await axios.get(`${window.$apiEndpoint}/merchant/fetchAntipasti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAntipasti(Antipasti.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Antipasti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Antipasti = await axios.get(`${window.$apiEndpoint}/merchant/fetchAntipasti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setAntipasti(Antipasti.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllAntipasti])


  //Recupero Primi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Primi = await axios.get(`${window.$apiEndpoint}/merchant/fetchPrimi/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setPrimi(Primi.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Primi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Primi = await axios.get(`${window.$apiEndpoint}/merchant/fetchPrimi/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setPrimi(Primi.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllPrimi])


  //Recupero Secondi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Secondi = await axios.get(`${window.$apiEndpoint}/merchant/fetchSecondi/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setSecondi(Secondi.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Secondi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Secondi = await axios.get(`${window.$apiEndpoint}/merchant/fetchSecondi/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){


          setTimeout(() => {
            setSecondi(Secondi.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllSecondi])


  //Recupero Formaggi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Formaggi = await axios.get(`${window.$apiEndpoint}/merchant/fetchFormaggi/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setFormaggi(Formaggi.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Formaggi
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Formaggi = await axios.get(`${window.$apiEndpoint}/merchant/fetchFormaggi/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setFormaggi(Formaggi.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllFormaggi])


  //Recupero Dessert
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Dessert = await axios.get(`${window.$apiEndpoint}/merchant/fetchDessert/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setDessert(Dessert.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Dessert
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Dessert = await axios.get(`${window.$apiEndpoint}/merchant/fetchDessert/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setDessert(Dessert.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllDessert])


  //Recupero Bevande
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Bevande = await axios.get(`${window.$apiEndpoint}/merchant/fetchBevande/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setBevande(Bevande.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Bevande
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Bevande = await axios.get(`${window.$apiEndpoint}/merchant/fetchBevande/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setBevande(Bevande.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllBevande])



//Recupero Percentuale di Completamento del Profilo per la progress Bar
useEffect(() => {
  let mounted = true
  const fetch = async () => {
    const percentuale2 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        setshowPercentuale(percentuale2.data[0].percentuale)

        //Controllo se la percentuale è 100% imposto i data tip diverso
        if (percentuale2.data[0].percentuale === 100) {
        //Uguale
        setshowDataTip('Complimenti hai completato le informazioni di base per essere pubblicato!')
        } else {
        //Non Uguale
        setshowDataTip('Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.')
        }

        setLoading(true)

      }
    } catch (e) {
      console.log(e)
    }
  }
  fetch();
  return () => {
    mounted = false
  }
}, [showPercentuale])


   //Recupero tutti i piatti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Piatti = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllPiatti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setPiatti(Piatti.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero tutti i piatti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Piatti = await axios.get(`${window.$apiEndpoint}/merchant/fetchAllPiatti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setPiatti(Piatti.data)
            setLoading(true)
            }, 1000);
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllPiatti])

   //Recupero Orari Merchant
   useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Orari = await axios.get(`${window.$apiEndpoint}/merchant/fetchOrari/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setOrari(Orari.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Orari Merchant
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Orari = await axios.get(`${window.$apiEndpoint}/merchant/fetchOrari/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){


          setTimeout(() => {
            setOrari(Orari.data)
            setLoading(true)
            }, 1000);

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllOrari])

  //Recupero Prodotti Merchant
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Prodotti = await axios.get(`${window.$apiEndpoint}/merchant/fetchProdotti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setProdotti(Prodotti.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Prodotti Merchant
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Prodotti = await axios.get(`${window.$apiEndpoint}/merchant/fetchProdotti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setProdotti(Prodotti.data)
             setLoading(true)
            }, 2000);


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllProdotti])


  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {

      return "Chiuso";

    } else {

      return string;


    }
  }

  //Caricamento Immagine
  const imageHandler = (event) => {

    const file = event.target.files[0];
    const formData = new FormData()
    formData.append('image', file)
    formData.append("email", indirzzi_email_loggato);

    fetch(`${window.$apiEndpoint}/merchant/upload`, {
        method: 'POST',
        body: formData,
        headers: {
              'x-access-token' : cookies.token,
              'Accept': 'multipart/form-data'
          },
      })
      .then(res => res.json())
      .then(res => {
        setUploadStatus(res.msg);



      })
      .catch(error => {
        console.log(error)
      })
  }

  //Caricamento Immagine
  const imageHandler2 = (event) => {

    const file = event.target.files[0];
    const formData = new FormData()
    formData.append('image', file)
    formData.append("email", indirzzi_email_loggato);

    fetch(`${window.$apiEndpoint}/merchant/uploadProfile`, {
        method: 'POST',
        body: formData,
        headers: {
              'x-access-token' : cookies.token,
              'Accept': 'multipart/form-data'
          },
      })
      .then(res => res.json())
      .then(res => {



        //Controllo se il messaggio di errore se no apro il resize

        if (res.msg === 'Tipo di file non supportato per il caricamento!') {
          setUploadStatus2(res.msg);
        } else if (res.msg === 'Il file caricato supera i 8MB di diemnsione!') {
          setUploadStatus2(res.msg);
        } else {

        //Apro il modale per il resize
        setTimeout(() => {
          setOpenModalUploadProfile(false)
          setOpenModalCropProfile(true)
          }, 1000);

        }

      })
      .catch(error => {
        console.log(error)
      })
  }

  //Caricamento Immagini Negozio
  const imageHandler3 = (event) => {

    const file = event.target.files[0];
    const formData = new FormData()
    formData.append('image', file)
    formData.append("email", indirzzi_email_loggato);

    fetch(`${window.$apiEndpoint}/merchant/uploadNegozio`, {
        method: 'POST',
        body: formData,
        headers: {
              'x-access-token' : cookies.token,
              'Accept': 'multipart/form-data'
          },
      })
      .then(res => res.json())
      .then(res => {

          //Returno Messaggio
          setUploadStatus3(res.msg);

          const fetch = async () => {
            const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
              headers: {
                "x-access-token": cookies.token
              }
            });
            try {

                //Controllo se impostare o la notifoca

                if (controllo.data.controllo == 1) {

                  setTimeout(() => {
                    setShow(false)
                    setLoading(true)
                    }, 1000);

                  //Non faccio niente

                } else {

                  setTimeout(() => {
                    setShow(true)
                    setLoading(true)
                    }, 3000);

                }

            } catch (e) {
              console.log(e)
            }
          }
          fetch();

          //Recupero Percentuale di Completamento del Profilo per la progress Bar

          const fetch1 = async () => {
            const percentuale1 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
              headers: {
                "x-access-token": cookies.token
              }
            });
            try {

              setTimeout(() => {
                setshowPercentuale(percentuale1.data[0].percentuale)
                //Controllo se la percentuale è 100% imposto i data tip diverso
                if (percentuale1.data[0].percentuale === 100) {
                  //Uguale
                  setshowDataTip('Complimenti hai completato le informazioni di base per essere pubblicato!')
                  } else {
                  //Non Uguale
                  setshowDataTip('Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.')
                  }
                setLoading(true)
                }, 3000);



            } catch (e) {
              console.log(e)
            }
          }
          fetch1();



      })
      .catch(error => {
        console.log(error)
      })
  }


   //Chiamata post per inserimento utente
   const onSubmit = data => {
    const fname = data.fname.toUpperCase().trim();
    const lname = data.lname.toUpperCase().trim();
    const payoff = data.payoff.toUpperCase().replace(/'/g, "\\'");
    const n_telefono = data.numTel;
    const n_cellulare = data.numCel;
    const indirizzo = data.indirizzo.toUpperCase();
    const civico = data.civico;
    const citta = data.citta.toUpperCase();
    const provincia = data.provincia.toUpperCase();
    const cap = data.cap;
    const ragioneSociale = data.ragioneSociale.toUpperCase().replace(/'/g, "\\'");
    const pIva = data.pIva.toUpperCase();
    const about = valueText.replace(/'/g, "\\'");
    const catMerciologica = data.catMerciologica;
    const indirizzoEmail = data.indirizzoEmail;
    const sito1 = data.sito1.toLowerCase();
    const sito2 = data.sito2.toLowerCase();
    const sito3 = data.sito3.toLowerCase();

    //Verifico se il numero di cellulare e vuoto
    if (n_cellulare === '' || n_cellulare === ' ') {
    //Vuoto
    const n_cellulare_def = 'NULL';

    const addM = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/updateProfile`, {
          fname: fname,
          lname: lname,
          payoff: payoff,
          n_telefono: n_telefono,
          n_cellulare: n_cellulare_def,
          indirizzo: indirizzo,
          civico: civico,
          provincia: provincia,
          cap: cap,
          citta: citta,
          ragioneSociale: ragioneSociale,
          pIva: pIva,
          email: indirizzoEmail,
          about: about,
          catMerciologica: catMerciologica,
          sito1: sito1,
          sito2: sito2,
          sito3: sito3,


      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc(true);
        setTimeout(() => {setSucc(false)},3000)
      } catch (err) {
        setErr(true)
        setTimeout(() => {setErr(false)},3000)
      }
    }
    addM()
  } else {
    //Non Vuoto
    const n_cellulare_def = n_cellulare;

    const addM = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/updateProfile`, {
          fname: fname,
          lname: lname,
          payoff: payoff,
          n_telefono: n_telefono,
          n_cellulare: n_cellulare_def,
          indirizzo: indirizzo,
          civico: civico,
          provincia: provincia,
          cap: cap,
          citta: citta,
          ragioneSociale: ragioneSociale,
          pIva: pIva,
          email: indirizzoEmail,
          about: about,
          catMerciologica: catMerciologica,
          sito1: sito1,
          sito2: sito2,
          sito3: sito3,


      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc(true);
        setTimeout(() => {setSucc(false)},3000)
      } catch (err) {
        setErr(true)
        setTimeout(() => {setErr(false)},3000)
      }
    }
    addM()



  }


    const fetch = async () => {
      const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {

          //Controllo se impostare o la notifoca

          if (controllo.data.controllo == 1) {

            setTimeout(() => {
              setShow(false)
              setLoading(true)
              }, 1000);
            //Non faccio niente

          } else {

            setTimeout(() => {
              setShow(true)
              setLoading(true)
              }, 3000);

          }

      } catch (e) {
        console.log(e)
      }
    }
    fetch();

    //Recupero Percentuale di Completamento del Profilo per la progress Bar

    const fetch1 = async () => {

      const percentuale1 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        setTimeout(() => {
          setshowPercentuale(percentuale1.data[0].percentuale)
          //Controllo se la percentuale è 100% imposto i data tip diverso
        if (percentuale1.data[0].percentuale === 100) {
          //Uguale
          setshowDataTip('Complimenti hai completato le informazioni di base per essere pubblicato!')
          } else {
          //Non Uguale
          setshowDataTip('Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.')
          }
          setLoading(true)
          }, 3000);


      } catch (e) {
        console.log(e)
      }
    }
    fetch1();
  }

  //Cancello Tags
  const cacellaTags = async (idTags, emailMerchant) => {

    try {


      const add = await axios.post(`${window.$apiEndpoint}/merchant/deleteTags`, {
        idTags: idTags,
        email: emailMerchant,
    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })
      setSucc1(true);
      setTimeout(() => {setSucc1(false)},3000)
      const fetch = async () => {
        const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {

            //Controllo se impostare o la notifoca

            if (controllo.data.controllo == 1) {

              setTimeout(() => {
                setShow(false)
                setLoading(true)
                }, 1000);

              //Non faccio niente

            } else {

              setTimeout(() => {
                setShow(true)
                setLoading(true)
                }, 3000);

            }

        } catch (e) {
          console.log(e)
        }
      }
      fetch();

      //Recupero Percentuale di Completamento del Profilo per la progress Bar

      const fetch1 = async () => {

        const percentuale1 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {
          setTimeout(() => {
            setshowPercentuale(percentuale1.data[0].percentuale)
            //Controllo se la percentuale è 100% imposto i data tip diverso
            if (percentuale1.data[0].percentuale === 100) {
              //Uguale
              setshowDataTip('Complimenti hai completato le informazioni di base per essere pubblicato!')
              } else {
              //Non Uguale
              setshowDataTip('Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.')
              }
            setLoading(true)
            }, 3000);


        } catch (e) {
          console.log(e)
        }
      }
      fetch1();
    } catch (err) {
      setErr1(true)
      setTimeout(() => {setErr1(false)},3000)
    }
  }


  //Cancello Piatti
  const cacellaPiatti = async (idPiatti, emailMerchant) => {

    try {


      const add = await axios.post(`${window.$apiEndpoint}/merchant/deletePiatti`, {
        idPiatti: idPiatti,
        email: emailMerchant,
    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })
      setSucc4(true);
      setTimeout(() => {setSucc4(false)},3000)
    } catch (err) {
      setErr4(true)
      setTimeout(() => {setErr4(false)},3000)
    }
  }



  //Chiamata post per inserimento Tags
  const onSubmitTags = data => {

    console.log("Sono qui nel primo")
    const tags = data.tags.toUpperCase().trim();
    const image_tags_list = data.image_tags_list;

    if (image_tags_list === "null") {
    // Immagine di profilo non ancora settata stampa errore

    console.log("Sono qui nell'Errore")
    //Stampo Errore
    setErr100(true);
    setTimeout(() => {
    setOpenModalUpdateTags(false)
    setErr100(false)

  },6000)

   } else {

    const addM = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/addTags`, {
          tags: tags,
          email: indirzzi_email_loggato,
          image_tags_list: image_tags_list,
      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc1(true);
        setTimeout(() => {setSucc1(false)},3000)
        reset();
        const fetch = async () => {
          const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
            headers: {
              "x-access-token": cookies.token
            }
          });
          try {

              //Controllo se impostare o la notifoca

              if (controllo.data.controllo == 1) {

                setTimeout(() => {
                  setShow(false)
                  setLoading(true)
                  }, 1000);

                //Non faccio niente

              } else {

                setTimeout(() => {
                  setShow(true)
                  setLoading(true)
                  }, 3000);

              }

          } catch (e) {
            console.log(e)
          }
        }
        fetch();

        //Recupero Percentuale di Completamento del Profilo per la progress Bar

          const fetch1 = async () => {

            const percentuale1 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
              headers: {
                "x-access-token": cookies.token
              }
            });
            try {
              setTimeout(() => {
                setshowPercentuale(percentuale1.data[0].percentuale)
                //Controllo se la percentuale è 100% imposto i data tip diverso
                if (percentuale1.data[0].percentuale === 100) {
                  //Uguale
                  setshowDataTip('Complimenti hai completato le informazioni di base per essere pubblicato!')
                  } else {
                  //Non Uguale
                  setshowDataTip('Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.')
                  }
                setLoading(true)
                }, 3000);

            } catch (e) {
              console.log(e)
            }
          }
          fetch1();
      } catch (err) {
        setErr1(true)
        setTimeout(() => {setErr1(false)},3000)
      }
    }
    addM()
  }//Fine If
  }


  //Chiamata post per inserimento Piatti
  const onSubmitMenu = data => {

    const  NomePiatto = data.piatto.toUpperCase().trim();
    const  prezzoPiatto = data.prezzoPiatto;
    const  catPiatto = data.catPiatto;

    const addM = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/addPiatto`, {
          NomePiatto: NomePiatto,
          prezzoPiatto: prezzoPiatto,
          catPiatto: catPiatto,
          email: indirzzi_email_loggato,
      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc4(true);
        setTimeout(() => {setSucc4(false)},3000)
        reset()
      } catch (err) {
        setErr4(true)
        setTimeout(() => {setErr4(false)},3000)
      }
    }
    addM()
  }


  //Chiamata post per inserimento Orari
  const onSubmitOrari = data => {

    console.log(data)

    //Dichiaro le variabili per la chiamata
    //Lunedì
    const lunedìAperturaMattina = data.Lunedìapertura_mattina;
    const lunedìChiusuraMattina = data.Lunedìchiusura_mattina;
    const lunedìAperturaPomeriggio = data.Lunedìapertura_pomeriggio;
    const lunedìChiusuraPomeriggio = data.Lunedìchiusura_pomeriggio;

    //Martedì
    const martedìAperturaMattina = data.Martedìapertura_mattina;
    const martedìChiusuraMattina = data.Martedìchiusura_mattina;
    const martedìAperturaPomeriggio = data.Martedìapertura_pomeriggio;
    const martedìChiusuraPomeriggio = data.Martedìchiusura_pomeriggio;

    //Mercoledì

    const mercoledìAperturaMattina = data.Mercoledìapertura_mattina;
    const mercoledìChiusuraMattina = data.Mercoledìchiusura_mattina;
    const mercoledìAperturaPomeriggio = data.Mercoledìapertura_pomeriggio;
    const mercoledìChiusuraPomeriggio = data.Mercoledìchiusura_pomeriggio;

    //Giovedì

    const giovedìAperturaMattina = data.Giovedìapertura_mattina;
    const giovedìChiusuraMattina = data.Giovedìchiusura_mattina;
    const giovedìAperturaPomeriggio = data.Giovedìapertura_pomeriggio;
    const giovedìChiusuraPomeriggio = data.Giovedìchiusura_pomeriggio;


    //Venerdì

    const venerdìAperturaMattina = data.Venerdìapertura_mattina;
    const venerdìChiusuraMattina = data.Venerdìchiusura_mattina;
    const venerdìAperturaPomeriggio = data.Venerdìapertura_pomeriggio;
    const venerdìChiusuraPomeriggio = data.Venerdìchiusura_pomeriggio;

    //Sabato

    const sabatoAperturaMattina = data.Sabatoapertura_mattina;
    const sabatoChiusuraMattina = data.Sabatochiusura_mattina;
    const sabatoAperturaPomeriggio = data.Sabatoapertura_pomeriggio;
    const sabatoChiusuraPomeriggio = data.Sabatochiusura_pomeriggio;

    //Domenica

    const domenicaAperturaMattina = data.Domenicaapertura_mattina;
    const domenicaChiusuraMattina = data.Domenicachiusura_mattina;
    const domenicaAperturaPomeriggio = data.Domenicaapertura_pomeriggio;
    const domenicaChiusuraPomeriggio = data.Domenicachiusura_pomeriggio;

    const addM = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/updateOrari`, {
          lunediAperturaMattina: lunedìAperturaMattina,
          lunediChiusuraMattina: lunedìChiusuraMattina,
          lunediAperturaPomeriggio: lunedìAperturaPomeriggio,
          lunediChiusuraPomeriggio: lunedìChiusuraPomeriggio,
          martediAperturaMattina: martedìAperturaMattina,
          martediChiusuraMattina: martedìChiusuraMattina,
          martediAperturaPomeriggio: martedìAperturaPomeriggio,
          martediChiusuraPomeriggio: martedìChiusuraPomeriggio,
          mercolediAperturaMattina: mercoledìAperturaMattina,
          mercolediChiusuraMattina: mercoledìChiusuraMattina,
          mercolediAperturaPomeriggio: mercoledìAperturaPomeriggio,
          mercolediChiusuraPomeriggio: mercoledìChiusuraPomeriggio,
          giovediAperturaMattina: giovedìAperturaMattina,
          giovediChiusuraMattina: giovedìChiusuraMattina,
          giovediAperturaPomeriggio: giovedìAperturaPomeriggio,
          giovediChiusuraPomeriggio: giovedìChiusuraPomeriggio,
          venerdiAperturaMattina: venerdìAperturaMattina,
          venerdiChiusuraMattina: venerdìChiusuraMattina,
          venerdiAperturaPomeriggio: venerdìAperturaPomeriggio,
          venerdiChiusuraPomeriggio: venerdìChiusuraPomeriggio,
          sabatoAperturaMattina: sabatoAperturaMattina,
          sabatoChiusuraMattina: sabatoChiusuraMattina,
          sabatoAperturaPomeriggio: sabatoAperturaPomeriggio,
          sabatoChiusuraPomeriggio: sabatoChiusuraPomeriggio,
          domenicaAperturaMattina: domenicaAperturaMattina,
          domenicaChiusuraMattina: domenicaChiusuraMattina,
          domenicaAperturaPomeriggio: domenicaAperturaPomeriggio,
          domenicaChiusuraPomeriggio: domenicaChiusuraPomeriggio,
          email: indirzzi_email_loggato,
      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc2(true);
        setTimeout(() => {setSucc2(false)},3000)
      } catch (err) {
        setErr2(true)
        setTimeout(() => {setErr2(false)},3000)
      }
    }
    addM()
  }

  //Setto immagine predefinita
  const impostaPredefinita = async (nome_immagine) => {

    setImmagineNegozioCrop(nome_immagine)
    setOpenModalCropPredefinita(true)

    try {


      const add = await axios.post(`${window.$apiEndpoint}/merchant/settaPredefinita`, {
        nome_immagine: nome_immagine,
        email: indirzzi_email_loggato,
    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })
      setPreSucc('Immagine settata con successo!');
      setTimeout(() => {setPreSucc('')},3000)
      const fetch = async () => {
        const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {

            //Controllo se impostare o la notifoca

            if (controllo.data.controllo == 1) {

              setTimeout(() => {
                setShow(false)
                setLoading(true)
                }, 1000);

              //Non faccio niente

            } else {

              setTimeout(() => {
                setShow(true)
                setLoading(true)
                }, 3000);

            }

        } catch (e) {
          console.log(e)
        }
      }
      fetch();

      //Recupero Percentuale di Completamento del Profilo per la progress Bar

      const fetch1 = async () => {

        const percentuale2 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {
          setTimeout(() => {
            setshowPercentuale(percentuale2.data[0].percentuale)
            setLoading(true)
            }, 3000);


        } catch (e) {
          console.log(e)
        }
      }
      fetch1();
    } catch (err) {
      setPreErr('Errore nel settare l\'immagine!')
      setTimeout(() => {setPreErr('')},3000)
    }
  }


  //Cancello immagine selezionata dalla gallerie delle Fotografie
  const cancellaImmagine = async (nome_immagine) => {

    try {

      const add = await axios.post(`${window.$apiEndpoint}/merchant/cancellaImmagine`, {
        nome_immagine: nome_immagine,
        email: indirzzi_email_loggato,
    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })
      setPreSucc('Immagine rimossa con successo!');
      setTimeout(() => {setPreSucc('')},3000)
      //Chiudo il modale
      setTimeout(() => {setopenModalDet(false)},2000)

      const fetch = async () => {
        const controllo = await axios.get(`${window.$apiEndpoint}/merchant/fetchControllo/${indirzzi_email_loggato}`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {

            //Controllo se impostare o la notifoca

            if (controllo.data.controllo == 1) {
              setTimeout(() => {
                setShow(false)
                setLoading(true)
                }, 1000);

              //Non faccio niente

            } else {

              setTimeout(() => {
                setShow(true)
                setLoading(true)
                }, 3000);

            }

        } catch (e) {
          console.log(e)
        }
      }
      fetch();

      //Recupero Percentuale di Completamento del Profilo per la progress Bar

      const fetch1 = async () => {

        const percentuale1 = await axios.get(`${window.$apiEndpoint}/merchant/fetchControlloPercentualeProgress/${indirzzi_email_loggato}`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {
          setTimeout(() => {
            setshowPercentuale(percentuale1.data[0].percentuale)
            //Controllo se la percentuale è 100% imposto i data tip diverso
        if (percentuale1.data[0].percentuale === 100) {
          //Uguale
          setshowDataTip('Complimenti hai completato le informazioni di base per essere pubblicato!')
          } else {
          //Non Uguale
          setshowDataTip('Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.')
          }
            setLoading(true)
            }, 3000);


        } catch (e) {
          console.log(e)
        }
      }
      fetch1();


    } catch (err) {
      setPreErr('Errore nell\'eliminazione dell\'immagine!')
      setTimeout(() => {setPreErr('')},3000)
    }
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Funzione che trosforma In mauscola dopo il punto
  function sentenceCase(input, lowercaseBefore) {
      input = ( input === undefined || input === null ) ? '' : input;
      if (lowercaseBefore) { input = input.toLowerCase(); }
      return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
          return separator + "\n \n" + char.toUpperCase();
      });
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.finestrapp.it/logo_bianco.svg"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                <Menu attivo={"Il tuo Profilo"} />
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.finestrapp.it/logo_bianco.svg"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <Menu attivo={"Il tuo Profilo"} />
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><span>Ruolo: {capitalize(cookies.role) + "  "}</span><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">



        {/* Header se admin è loggato come merchant*/}

        { isAdmin ? <div className="bg-green-600">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-green-800">
                              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white truncate">
                              <span className="md:hidden">Attenzione! Stai impersonificando un esercente! Indirizzo Email Esercente: {indirzzi_email_loggato} </span>
                              <span className="hidden md:inline">Attenzione! Stai impersonificando un esercente! Indirizzo Email Esercente: <strong>{indirzzi_email_loggato} </strong> </span>
                            </p>
                          </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <a
                            href="/merchant_list"
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50"
                          >
                            Ritorna al Pannello Amministratore
                          </a>
                        </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">

                          </div>
                        </div>
                      </div>
          </div> : null }
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">
              {/* Replace with your content

               #Contenuto Merchant Dashboard

               */}




               <article>

              {/* Profile header */}
              <div >
                <div className="pt-3">
                <ReactTooltip />
                <div className="absolute flex items-center ml-3 mt-5 p-2 w-11 bg-gray-600 	border-2	border-white space-x-1 rounded-3xl"><a onClick={() => CallUpload()} className="" data-tip="Cambia immagine copertina" href="#"><UploadIcon className="h-6 w-6 hover:text-white text-gray-400"/></a>


                </div>

                  <img className="h-32 mt-4 w-full shadow object-cover lg:h-48 rounded-t-lg" src={window.$fileserverEndpoint + imageCover[0].url_imagine_cover} alt="Immagine di Copertina" />

                </div>
                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex">
                      <img
                        className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                        src={imageProfileUrl ? imageProfileUrl : ImgProfile}
                        alt="Immagine di profilo"
                      />
                    <ReactTooltip />
                    <div className="flex-initial "><div className="-ml-8 p-2 bg-gray-600  border-2	border-white space-x-1 rounded-3xl"><a  onClick={() => CallUploadProfile()} data-tip="Cambia immagine profilo, Attenzione: Questa sarà l'immagine pubblicata come immagine profilo nell'APP Finestrapp" href="#"><CameraIcon className="h-6 w-6 hover:text-white text-gray-400"/></a></div></div>

                    </div>
                    <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                      <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                        <h1 className="text-2xl mt-3 font-bold text-gray-900 truncate">{capitalize(`${Allinfo[0].ragione_sociale}`.toString())}</h1>
                        <p className="text-sm	 text-gray-500 capitalize" >{capitalize(`${Allinfo[0].payoff}`.toString())}</p>
                      </div>
                      <div className="mt-8 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <a href={`mailto:${Allinfo[0].email_address}`}><button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        >
                          <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>Scrivici</span>
                        </button></a>
                        <a href={`tel:${Allinfo[0].numero_telefono}`}><button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        >
                          <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>Chiamaci</span>
                        </button></a>
                        <a target='_blank' href={`https://www.google.it/maps/place/${AllCoordinate.lat}+${AllCoordinate.lng}`}><button

                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        >
                          <LocationMarkerIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>Dove siamo</span>
                        </button></a>
                      </div>
                    </div>
                  </div>
                  <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl mt-3 font-bold text-gray-900 truncate">{capitalize(`${Allinfo[0].ragione_sociale}`.toString())}</h1>
                  <p className="text-sm	 text-gray-500 capitalize" >{Allinfo[0].payoff}</p>
                  </div>
                </div>
              </div>

              {/* Tabs */}


              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
                    <TabList className="-mb-px flex lg:space-x-8 md:space-x-6 border-b border-gray-200 sm:space-x-1" style={{marginBottom: '1px'}} aria-label="Tabs">
                      {tabs.map((tab, index) => (
                        <>
                        <Tab className={classNames(
                            (activeTab === tab.name)
                              ? 'border-pink-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                          )}
                          aria-current={tab.current ? 'page' : undefined}>

                          {tab.name}

                          </Tab>
                        </>
                      ))}
                      </TabList>
                      <TabPanel>
                      {/* Tab Info */}
                            <div className="mt-6 max-w-5xl mx-auto px-1">
                              {loading ?
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                {Allinfo.map((item) => (
                                  <>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Nome</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{capitalize(`${item.fname}`.toString())}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Cognome</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{capitalize(`${item.lname}`.toString())}</dd>
                                  </div>

                                  <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Indrizzo Email</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{item.email_address}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Numero di Telefono</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{item.numero_telefono}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Numero di Cellulare</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{item.numero_cellulare}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Indrizzo</dt>
                                    <dd className="mt-1 text-sm text-gray-900 capitalize">{capitalize(`${item.indirizzo}`.toString())}, {capitalize(`${item.civico}`.toString())}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Città</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{capitalize(`${item.citta}`.toString())}</dd>
                                  </div>


                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Provincia</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{capitalize(`${item.provincia}`.toString())}</dd>
                                  </div>


                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Cap</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{capitalize(`${item.cap}`.toString())}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Partita Iva</dt>
                                    <dd className="mt-1 text-sm text-gray-900 uppercase ">{item.partita_iva}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Categoria Merciologica</dt>
                                    <dd className="mt-1 text-sm text-purple-900 font-bold uppercase ">{capitalize(`${item.categoria}`.toString())}</dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Sito collegato #1</dt>
                                    <dd className="mt-1 text-sm text-gray-900  "><a className="mt-1 text-sm text-purple-900 font-bold		  " href={item.sito1} target="_blank">{item.sito1}</a></dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Sito collegato #2</dt>
                                    <dd className="mt-1 text-sm text-gray-900  "><a className="mt-1 text-sm text-purple-900 font-bold	  " href={item.sito2} target="_blank">{item.sito2}</a></dd>
                                  </div>

                                  <div  className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Sito collegato #3</dt>
                                    <dd className="mt-1 text-sm text-gray-900  "><a className="mt-1 text-sm text-purple-900 font-bold	  " href={item.sito3} target="_blank">{item.sito3}</a></dd>
                                  </div>



                                  </>

                                ))}

                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Descrizione Aziendale</dt>
                                  <dd
                                    className="mt-1 max-w-full text-justify	 text-sm text-gray-900 space-y-5 whitespace-pre-line"
                                    dangerouslySetInnerHTML={{__html: `${Allinfo[0].about}`}} />
                                </div>
                              </dl> : null}
                              <div className="flex flex-row-reverse my-2">
                              <a className="flex" onClick={() => CallUpdateInfo()} href="#">
                              <PencilAltIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Modifica Profilo</p>
                              </a>
                             </div>
                              </div>

                            {/* Tags list */}
                            <div className="mt-8 max-w-5xl mx-auto pb-12">
                              <h2 className="text-sm font-medium text-gray-500">Tags Azienda</h2>
                              <div className="mt-1">

                                  <div>
                                    <ul className="mt-2 leading-8 flex-auto	">
                                      <li className="inline">
                                      {AllTags.map((item) => (
                                        <>
                                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">
                                          <div className="absolute flex-shrink-0 flex items-center justify-center ">
                                            <span className="h-1.5 w-1.5 rounded-full bg-blue-500" aria-hidden="true"></span>
                                          </div>
                                          <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.tags}`.toString())}</div>
                                        </div>
                                        </>
                                        ))}
                                      </li>

                                    </ul>
                                  </div>
                                  <div className="flex flex-row-reverse my-2">
                                  <a className="flex" onClick={() => CallUpdateTags()} href="#">
                                  <PencilAltIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Modifica Tags</p>
                                  </a>
                                </div>

                              </div>

                              <div className="py-4">
                              <h2 className="text-sm font-medium text-gray-500">Completamento Profilo</h2>
                              <div className="flex mt-2 mb-2 items-center justify-between">
                                <div>
                                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                  Progresso
                                  </span>

                                </div>
                                <div>
                                <ReactTooltip />
                                <InformationCircleIcon data-tip={showDataTip} className="h-6 w-6 text-green-400" />
                                </div>

                                <div className="text-right">
                                  <span className="text-xs font-semibold inline-block text-green-600">
                                    {showPercentuale}%
                                  </span>
                                </div>
                              </div>
                              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                                <div style={{ width: showPercentuale + "%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-400"></div>
                              </div>
                            </div>
                            <div className="flex -mt-4 mb-2 items-center justify-between">
                                <div>
                                <ReactTooltip />
                                <a href="https://my.finestrapp.it/dashboard_merchant"><RefreshIcon data-tip="Clicca per aggiornare la percentuale" className="h-6 w-6 text-green-400" /></a>

                                </div>
                                </div>
                            </div>


                          </TabPanel>



                          <TabPanel>

                          {/* Foto negozio list */}

                          <div className="bg-white">
                          <div className="mx-auto py-5 px-0 max-w-7xl sm:px-6 sm:py-10 lg:px-2 lg:py-10">
                            <div className="space-y-10">
                            { predefinita ? <div className="rounded-md bg-blue-50 p-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                  </div>
                                  <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">Seleziona almeno un immagine come predefinita per essere visibile sullo Store</p>
                                  </div>
                                </div>
                              </div> : null}

                              <div className="rounded-md bg-blue-50 p-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                  </div>
                                  <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">Per cancellare o rendere predefinita cliccare sull'immagine</p>
                                  </div>
                                </div>
                              </div>
                              <div className="rounded-md bg-blue-50 p-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                  </div>
                                  <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700"><strong>Nota Bene:</strong> Tutte le immagini caricate nella seguente sezione saranno visibili nell'app sotto la sezione Fotografie nel profilo esercente, mentre l'immagine predefinita sara pubblicata sul sito <strong>finestrapp.it</strong></p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row my-2">
                              <a className="flex" onClick={() => CallUploadNegozio()} href="#">
                              <CameraIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Carica foto</p>
                              </a>
                             </div>
                               <ul className="space-y-1 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
                                {imageNegozio.map((item) => (
                                  <li key={item.id}>
                                    <div className="space-y-4">
                                    <div className="aspect-w-1 aspect-h-2">
                                    <a href="#" onClick={() => CallUpdateDet(item.url_image_negozio)}><img  className="object-cover h-55 shadow-lg rounded-lg" src={window.$fileserverEndpoint + item.url_image_negozio} alt="Immagine Negozio" /></a>
                                    </div>
                                    <div className="space-y-2">
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                    <p className="text-gray-500 text-sm"></p>
                                    </div>
                                    { item.predefinita > 0 ? (
                                    <ul className="flex space-x-5">
                                    <li>
                                    <a href="" className="text-gray-400 hover:text-gray-500">
                                    <CheckCircleIcon className="-ml-1 mr-2 h-5 w-5 text-green-400" aria-hidden="true" />
                                    </a>
                                    </li>
                                    <li>
                                    <ReactTooltip />
                                    <a href="" className="text-gray-400 hover:text-gray-500">
                                    <span data-tip="Questa immagine verrà pubblicata nel sito pubblico visibile a tutti" className="text-green-900 text-sm">Immagine predefinita per Store</span>
                                    </a>
                                    </li>
                                    </ul>) : null }
                                    </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>

                          </TabPanel>



                          <TabPanel>
                          {/* Tab Orari di Apertura */}
                            <div className="mt-6 max-w-5xl mx-auto px-1">
                              {loading ?
                              <dl className="grid grid-cols-3 gap-4">
                                {AllOrari.map((item) => (
                                  <>

                                  <div  className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Giorno</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{capitalize(`${item.giorno}`.toString())}</dd>
                                  </div>

                                  <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Mattina</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{checkNull(item.apertura_mattina)} - {checkNull(item.chiusura_mattina)}</dd>
                                  </div>

                                  <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Pomeriggio</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{checkNull(item.apertura_pomeriggio)} - {checkNull(item.chiusura_pomeriggio)}</dd>
                                  </div>




                                  </>

                                ))}

                                <div className="sm:col-span-2">
                                  <dd
                                    className="mt-1 max-w-full text-justify	 text-sm text-gray-900 space-y-5"
                                  />
                                </div>
                              </dl> : null}
                              <div className="flex flex-row-reverse my-2">
                              <a className="flex" onClick={() => CallUpdateOrari()} href="#">
                              <PencilAltIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Modifica Orari</p>
                              </a>
                             </div>
                              </div>

                            {/* Tags list */}
                            <div className="mt-4 max-w-5xl mx-auto pb-12">
                              <h2 className="text-sm font-bold text-gray-600">Informazioni su orari</h2>
                              <div className="mt-1">
                              <p className="text-sm font-regular text-gray-500">Gli orari possono cambiare nei periodi festivi</p>
                                  <div>

                                  </div>

                              </div>
                            </div>
                          </TabPanel>


                          <TabPanel>

                          {/* Vetrina Product list */}

                        <div className="bg-white ">
                          <div className="max-w-2xl mx-auto px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 ">
                            <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8 -mt-10">
                              {AllProdotti.map((product) => (
                                <a key={product.id} href="#" className="group">
                                  <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                                    <img
                                      src={window.$fileserverEndpointCrop + product.url_immagine_prodotto}
                                      alt={product.nome_prodotto}
                                      className="w-full h-full object-center object-cover group-hover:opacity-75"
                                    />
                                  </div>
                                  <h3 className="mt-4 text-sm text-gray-700">{product.nome_prodotto}</h3>
                                  <h5 className="mt-2 text-xs text-gray-500 text-left">{product.descrizione_prodotto}</h5>
                                  <p className="mt-1 text-lg font-medium text-gray-900">{product.prezzo} €</p>
                                </a>
                              ))}
                            </div>
                          </div>

                        </div>
                          </TabPanel>

                          <TabPanel>

                      {/* Tab Menu */}

                      <div className="bg-white">
                      <div className="mx-auto py-5 px-0 max-w-7xl sm:px-6 sm:py-10 lg:px-2 lg:py-10">
                        <div className="space-y-2">
                        <p className="flex text-sm font-light	 text-gray-600 space-y-5 hover:text-blue-900"> Se la tua categoria merciologica lo prevede puoi inserire un menu</p>
                        <p className="flex text-sm font-light	 text-gray-600 space-y-5 hover:text-blue-900"><br /></p>


                        <p className="flex text-sm font-light mt-3	 text-gray-700 space-y-5 hover:text-blue-900"> Antipasti</p>

                        <ul className="mt-0 leading-8 flex-auto	">
                        <li className="inline">
                        {AllAntipasti.map((item) => (
                         <>
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">

                        <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())} - <span className="ml-3.5 text-sm font-bold text-gray-600">{item.prezzo} €</span></div>


                        </div>
                        </>
                        ))}
                        </li>

                        </ul>

                        <p className="flex text-sm font-light	 text-gray-700 space-y-5 hover:text-blue-900"> Primi</p>

                        <ul className="mt-0 leading-8 flex-auto	">
                        <li className="inline">
                        {AllPrimi.map((item) => (
                         <>
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">

                        <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())} - <span className="ml-3.5 text-sm font-bold text-gray-600">{item.prezzo} €</span></div>


                        </div>
                        </>
                        ))}
                        </li>

                        </ul>

                        <p className="flex text-sm font-light	 text-gray-700 space-y-5 hover:text-blue-900"> Secondi</p>


                        <ul className="mt-0 leading-8 flex-auto	">
                        <li className="inline">
                        {AllSecondi.map((item) => (
                         <>
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">

                        <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())} - <span className="ml-3.5 text-sm font-bold text-gray-600">{item.prezzo} €</span></div>


                        </div>
                        </>
                        ))}
                        </li>

                        </ul>

                        <p className="flex text-sm font-light	text-gray-700 space-y-5 hover:text-blue-900"> Formaggi</p>


                        <ul className="mt-0 leading-8 flex-auto	">
                        <li className="inline">
                        {AllFormaggi.map((item) => (
                         <>
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">

                        <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())} - <span className="ml-3.5 text-sm font-bold text-gray-600">{item.prezzo} €</span></div>


                        </div>
                        </>
                        ))}
                        </li>

                        </ul>

                        <p className="flex text-sm font-light	 text-gray-700 space-y-5 hover:text-blue-900"> Dessert</p>


                        <ul className="mt-0 leading-8 flex-auto	">
                        <li className="inline">
                        {AllDessert.map((item) => (
                         <>
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">

                        <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())} - <span className="ml-3.5 text-sm font-bold text-gray-600">{item.prezzo} €</span></div>


                        </div>
                        </>
                        ))}
                        </li>

                        </ul>

                        <p className="flex text-sm font-light	text-gray-700  hover:text-blue-900"> Bevande</p>

                        <ul className="mt-0 flex-auto	">
                        <li className="inline">
                        {AllBevande.map((item) => (
                         <>
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 mr-3 bg-gray-200">

                        <div className="ml-3.5 text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())} - <span className="ml-3.5 text-sm font-bold text-gray-600">{item.prezzo} €</span></div>


                        </div>
                        </>
                        ))}
                        </li>

                        </ul>

                        <p className="flex text-sm font-light	 text-gray-600 space-y-5 hover:text-blue-900"><br /></p>


                        <div className="flex mt-3 flex-row my-2">
                          <a className="flex" onClick={() => CallUpdateMenu()} href="#">
                          <PlusIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Inserisci o Cancella Piatti</p>
                          </a>
                        </div>

                        </div>
                      </div>
                      </div>

                      </TabPanel>

                    </Tabs>
                  </div>
                </div>
              </div>



              </article>




               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    {/** Modale per conferma predefinita oppure cancella immagine*/}

    <Transition.Root show={openModalDet} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={openModalDet} onClose={setopenModalDet}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <p className="text-sm items-center justify-center text-gray-800">Che cosa vuoi fare?</p>
              <div>
                <div className="mt-0 text-center sm:mt-5">
                  <div className="mt-0">
                  <button
                    onClick={() => cancellaImmagine(immagineCliccata)}
                    type="button"
                    className="inline-flex items-center mx-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancellare
                  </button>

                  <button
                  onClick={() => impostaPredefinita(immagineCliccata)}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Settare predefinita
                  </button>

                  <div className="space-y-1 text-center">

                    <p className="text-sm text-green-600 mt-3" >{showPreSucc}</p>
                    <p className="text-sm text-red-600 mt-3" >{showPreErr}</p>

                  </div>

                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setopenModalDet(false)}
                >
                  Torna al tuo profilo
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/** Modale Upload immagine di copertina*/}

    <Transition.Root show={openModalUpload} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={openModalUpload} onClose={setOpenModalUpload}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UploadIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  </Dialog.Title>
                  <div className="mt-2">


                  <div className="space-y-1 text-center">
                    <div className=" text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500"
                      >
                        <span>Carica immagine</span>
                        <input id="file-upload" name="image" accept="image/*" multiple={false} type="file" onChange={imageHandler} className="sr-only" />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG fino a 2MB</p>
                    <p className="text-sm text-gray-600" >{uploadStatus}</p>
                  </div>

                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setOpenModalUpload(false)}
                >
                  Torna al tuo profilo
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/** Modale Upload immagine di profilo*/}

    <Transition.Root show={openModalUploadProfile} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={openModalUploadProfile} onClose={setOpenModalUploadProfile}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UploadIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  </Dialog.Title>
                  <div className="mt-2">


                  <div className="space-y-1 text-center">
                    <div className=" text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500"
                      >
                        <span>Carica immagine</span>
                        <input id="file-upload" name="image" accept="image/*" multiple={false} type="file" onChange={imageHandler2} className="sr-only" />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG fino a 8MB (preferibile 512x512px)</p>
                    <p className="text-sm text-gray-600" >{uploadStatus2}</p>

                    {/* Implementazione crop image */}

                  </div>
                  <div className="mt-2">
                  </div>

                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setOpenModalUploadProfile(false)}
                >
                  Torna al tuo profilo
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/* Modale di modifica informazioni azienda */}

    <Transition.Root show={openModalUpdateInfo} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalUpdateInfo}
        onClose={setOpenModalUpdateInfo}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">



                    {/* Corpo del modale */}

                    <div className="space-y-6">


      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Informazioni Profilo</h3>
            <p className="mt-1 text-sm text-gray-500">Use i seguenti campi per modificare le informazioni del tuo profilo.</p>

            { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel aggiornamento dei dati! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Dati profilo aggironati con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Nome
                  </label>
                  <input
                    type="text"
                    name="fname"
                    id="first-name"
                    defaultValue={capitalize(`${Allinfo[0].fname}`.toString())}
                    {...register("fname", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.fname && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Campagno
                  </label>
                  <input
                    type="text"
                    defaultValue={capitalize(`${Allinfo[0].lname}`.toString())}
                    {...register("lname", { required: true })}
                    id="last-name"
                    autoComplete="family-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.lname && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label htmlFor="indirizzoEmail" className="block text-sm font-medium text-gray-700">
                    Indirizzo Email
                  </label>
                  <input
                    type="email"
                    defaultValue={capitalize(`${Allinfo[0].email_address}`.toString())}
                    disabled={true}
                    name="indirizzoEmail"
                    id="indirizzoEmail"
                    autoComplete="email"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <input
                    type="hidden"
                    defaultValue={Allinfo[0].email_address}
                    {...register("indirizzoEmail", { required: true })}
                    name="indirizzoEmail"
                    id="indirizzoEmail"
                    autoComplete="email"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <small>L'indirzzo email è associato all'account di conseguenza non si può cambiare</small>
                  {errors.indirizzoEmail && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label htmlFor="payoff" className="block text-sm font-medium text-gray-700">
                    Payoff
                  </label>
                  <input
                    type="text"
                    name="payoff"
                    id="payoff"
                    defaultValue={capitalize(`${Allinfo[0].payoff}`.toString())}
                    {...register("payoff", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <small>Piccola descrizione aziedale</small>
                  {errors.payoff && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="numTel" className="block text-sm font-medium text-gray-700">
                    Numero di Telefono
                  </label>
                  <input
                    type="text"
                    name="numTel"
                    id="numTel"
                    defaultValue={capitalize(`${Allinfo[0].numero_telefono}`.toString())}
                    {...register("numTel", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.numTel && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="numCel" className="block text-sm font-medium text-gray-700">
                    Numero di Cellulare
                  </label>
                  <input
                    type="text"
                    defaultValue={capitalize(`${Allinfo[0].numero_cellulare}`.toString())}
                    {...register("numCel", { required: false })}
                    id="numCel"
                    autoComplete="family-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                <small>Numero Whatsapp, senza prefissi ne spazi</small>

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">

                  <label htmlFor="catMerciologica" className="block text-sm font-medium text-gray-700">
                    Categoria Merciologica
                  </label>

                  <select
                    id="catMerciologica"
                    name="catMerciologica"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    {...register("catMerciologica", { required: true })}

                  >

                  <option>{Allinfo[0].categoria}</option>
                  {AllCategorie.map((item,index) => {

                  return (<option>{item.categoria}</option>)

                  })}

                  </select>

                  {errors.catMerciologica && <p>Il campo è obbligatorio</p>}


                </div>


                <div className="col-span-6 lg:col-span-4">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                    Indirizzo
                  </label>
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    defaultValue={capitalize(`${Allinfo[0].indirizzo}`.toString())}
                    {...register("indirizzo", { required: true })}
                    autoComplete="street-address"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.indirizzo && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="civico" className="block text-sm font-medium text-gray-700">
                    Civico
                  </label>
                  <input
                    type="text"
                    name="civico"
                    defaultValue={capitalizeUpper(`${Allinfo[0].civico}`.toString())}
                    {...register("civico", { required: true })}
                    id="civico"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.civico && <p>Il campo è obbligatorio</p>}
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="citta" className="block text-sm font-medium text-gray-700">
                    Città
                  </label>
                  <input
                    type="text"
                    name="citta"
                    id="citta"
                    defaultValue={capitalize(`${Allinfo[0].citta}`.toString())}
                    {...register("citta", { required: true })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.citta && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="provincia" className="block text-sm font-medium text-gray-700">
                    Provincia
                  </label>
                  <input
                    type="text"
                    name="provincia"
                    id="provincia"
                    defaultValue={capitalize(`${Allinfo[0].provincia}`.toString())}
                    {...register("provincia", { required: true })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.provincia && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="cap" className="block text-sm font-medium text-gray-700">
                    Cap
                  </label>
                  <input
                    type="text"
                    name="cap"
                    id="cap"
                    autoComplete="cap"
                    defaultValue={capitalize(`${Allinfo[0].cap}`.toString())}
                    {...register("cap", { required: true })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.cap && <p>Il campo è obbligatorio</p>}
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="sito1" className="block text-sm font-medium text-gray-700">
                    Sito collegato #1
                  </label>
                  <input
                    type="text"
                    name="sito1"
                    id="sito1"
                    defaultValue={Allinfo[0].sito1}
                    {...register("sito1", { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.sito1 && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="sito2" className="block text-sm font-medium text-gray-700">
                  Sito collegato #2
                  </label>
                  <input
                    type="text"
                    name="sito2"
                    id="sito2"
                    defaultValue={Allinfo[0].sito2}
                    {...register("sito2", { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.sito2 && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="sito3" className="block text-sm font-medium text-gray-700">
                  Sito collegato #3
                  </label>
                  <input
                    type="text"
                    name="sito3"
                    id="sito3"
                    defaultValue={Allinfo[0].sito3}
                    {...register("sito3", { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.sito3 && <p>Il campo è obbligatorio</p>}

                </div>


                <div className="col-span-6 lg:col-span-4">
                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                    Ragione Sociale
                  </label>
                  <input
                    type="text"
                    name="ragioneSociale"
                    id="ragioneSociale"
                    defaultValue={capitalize(`${Allinfo[0].ragione_sociale}`.toString())}
                    {...register("ragioneSociale", { required: true })}
                    autoComplete="ragioneSociale"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.ragioneSociale && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="pIva" className="block text-sm font-medium text-gray-700">
                    Partita Iva
                  </label>
                  <input
                    type="text"
                    name="pIva"
                    defaultValue={capitalizeUpper(`${Allinfo[0].partita_iva}`.toString())}
                    {...register("pIva", { required: true })}
                    id="pIva"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.pIva && <p>Il campo è obbligatorio</p>}
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-6 mb-2 ">
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                    Descrizione Aziendale
                    </label>
                    <div className="mt-1 p-5 focus:ring-green-500 focus:border-green-500 block w-full shadow-lg sm:text-sm border-gray-900 rounded-md"
>
                    <HtmlEditor
                    schema={schema}
                    plugins={plugins}
                    value={`${Allinfo[0].about}`}
                    handleChange={setValueText}
                    debounce={300}

                  >
                    <Toolbar toolbar={toolbar} />
                    <Editor autoFocus />
                  </HtmlEditor>
                      {errors.about && <p>Il campo è obbligatorio</p>}
                    </div>
                 </div>


              </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Profilo
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalUpdateInfo(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>



    {/* Modale di modifica e aggiunta Tags */}

    <Transition.Root show={openModalUpdateTags} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalUpdateTags}
        onClose={setOpenModalUpdateTags}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">



                    {/* Corpo del modale */}

                    <div className="space-y-6">


      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Aggiungi o cancella Tags</h3>
            <p className="mt-1 text-sm text-gray-500">I tags sono quelle parole chiave che ti permettono di essere trovato all'interno della comunity finestrapp.</p>

            { showErr1 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel aggiornamento dei dati! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc1 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Tags aggironati con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit2(onSubmitTags)}>
            <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Nome del Tags
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {AllTags.map((item, personIdx) => (
                                <tr key={item.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{capitalize(`${item.tags}`.toString())}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">

                                    <a onClick={() => cacellaTags(item.id, item.email_merchant)} href="#" className="text-green-600 hover:text-green-900">
                                    Cancella
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                              </div>
                            </div>

                            <div className="grid grid-cols-6 gap-6 mt-5">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                    Aggiungi Tags
                  </label>
                  <input
                    type="text"
                    name="tags"
                    id="tags"
                    placeholder="nome del Tags"
                    {...register2("tags", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors2.tags && <p className="mt-1 text-sm text-gray-500">Il campo è obbligatorio</p>}

                  <input
                    type="hidden"
                    name="image_tags_list"
                    id="image_tags_list"
                    value={`${Allinfo[0].url_immagine_profilo_croppata}`}
                    {...register2("image_tags_list", { required: true })}
                  />

                </div>
                </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Aggiungi Tags
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalUpdateTags(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/* Modale di modifica e aggiunta Piatti Menu */}

    <Transition.Root show={openModalUpdateMenu} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalUpdateMenu}
        onClose={setOpenModalUpdateMenu}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">



                    {/* Corpo del modale */}

                    <div className="space-y-6">


      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Aggiungi o cancella Piatti del Menu</h3>

            { showErr4? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel aggiornamento dei dati! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc4 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Piatti aggironati con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit4(onSubmitMenu)}>
            <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Nome del Piatto
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Categoria
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Prezzo
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Azioni
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {AllPiatti.map((item, personIdx) => (
                                <tr key={item.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{capitalize(`${item.piatto}`.toString())}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{capitalize(`${item.categoria}`.toString())}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{capitalize(`${item.prezzo}`.toString())} €</td>


                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">

                                    <a onClick={() => cacellaPiatti(item.id, item.email_merchant)} href="#" className="text-green-600 hover:text-green-900">
                                    Cancella
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                              </div>
                            </div>

                            <div className="grid grid-cols-6 gap-6 mt-5">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="piatto" className="block text-sm font-medium text-gray-700">
                    Nome del Piatto
                  </label>
                  <input
                    type="text"
                    name="piatto"
                    id="piatto"
                    placeholder="nome del Piatto"
                    {...register4("piatto", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors4.piatto && <p className="mt-1 text-sm text-gray-500">Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="prezzoPiatto" className="block text-sm font-medium text-gray-700">
                    Costo Piatto (solo la cifra senza simbolo della valuta)
                  </label>
                  <input
                    type="text"
                    name="prezzoPiatto"
                    id="prezzoPiatto"
                    placeholder="costo del piatto"
                    {...register4("prezzoPiatto", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors4.prezzoPiatto && <p className="mt-1 text-sm text-gray-500">Il campo è obbligatorio</p>}

                </div>
                <div className="col-span-6 sm:col-span-3">
                <label htmlFor="catPiatto" className="block text-sm font-medium text-gray-700">
                    Categoria Piatto
                  </label>

                  <select
                    id="catPiatto"
                    name="catPiatto"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    {...register4("catPiatto", { required: true })}

                  >

                  <option  value="antipasti">ANTIPASTI</option>
                  <option  value="primi" >PRIMI</option>
                  <option  value="secondi" >SECONDI</option>
                  <option  value="formaggi" >FORMAGGI</option>
                  <option  value="dessert" >DESSERT</option>
                  <option  value="bevande" >BEVANDE</option>


                  </select>

                  {errors4.catPiatto && <p>Il campo è obbligatorio</p>}


                </div>
                </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Aggiungi Piatto
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalUpdateMenu(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/* Modale di modifica orari azienda */}

    <Transition.Root show={openModalUpdateOrari} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalUpdateOrari}
        onClose={setOpenModalUpdateOrari}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">



                    {/* Corpo del modale */}

                    <div className="space-y-6">


      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Informazioni Orari</h3>
            <p className="mt-1 text-sm text-gray-500">Use i seguenti campi per modificare gli orari di apertura, quando si vuole inserire chiuso semplicemnte basta non selezionare un orario (--:--).</p>

            { showErr2 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel aggiornamento dei dati! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc2 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Orari aggironati con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit3(onSubmitOrari)}>
              <div className="grid grid-cols-6 gap-6 ml-4">

            {AllOrari.map((item) => (
              <>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2 -mt-4">
              <h3 className="block text-sm font-medium text-gray-900">Giorno</h3>
              <label htmlFor={item.giorno} className="block text-md font-bold text-gray-700">
              {item.giorno}
              </label>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor={item.giorno} className="block text-sm font-medium text-gray-700">
                    Mattina
                  </label>
                  <input
                    type="time"
                    name={item.giorno}
                    id={item.giorno}
                    defaultValue={item.apertura_mattina}
                    {...register3(`${item.giorno}apertura_mattina`, { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block  max-w-minshadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <input
                    type="time"
                    name={item.giorno}
                    id={item.giorno}
                    defaultValue={item.chiusura_mattina}
                    {...register3(`${item.giorno}chiusura_mattina`, { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block  max-w-minshadow-sm sm:text-sm border-gray-300 rounded-md"
                  />

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="lunedi" className="block text-sm font-medium text-gray-700">
                    Pomeriggio
                  </label>
                  <input
                    type="time"
                    name={item.giorno}
                    id={item.giorno}
                    defaultValue={item.apertura_pomeriggio}
                    {...register3(`${item.giorno}apertura_pomeriggio`, { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block  max-w-minshadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <input
                    type="time"
                    name={item.giorno}
                    id={item.giorno}
                    defaultValue={item.chiusura_pomeriggio}
                    {...register3(`${item.giorno}chiusura_pomeriggio`, { required: false })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block  max-w-minshadow-sm sm:text-sm border-gray-300 rounded-md"
                  />

                </div>
                </>
                ))}

              </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Orari
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalUpdateOrari(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>



    {/* Modale per il crop dell' immagine profilo */}

    <Transition.Root show={openModalCropProfile} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalCropProfile}
        onClose={setOpenModalCropProfile}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">

                  <div style={{
                      height: '28vw',
                      position: 'relative',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: '26vw',
                    }} >
                  <Cropper
                        image={ImgProfile}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={2 / 2}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                    </div>

                    {/* Corpo del modale */}

                  <div className="space-y-6">

                  <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">


                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Modifica Immagine</h3>
                        <p className="mt-1 text-sm text-gray-500">Utilizza i seguenti tools per modificare l'immagine e settarla al meglio all'interno della griglia.</p>


                        { showErr10 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel ritaglio del immagine! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc10 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Immagine ritagliata e settata correttamente, si prega di chiudere la finestra!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
                      </div>


                      <div className="mt-5 md:mt-0 md:col-span-2">

                      <div className={styles.controls}>
                            <div className={styles.sliderContainer}>
                              <Typography
                                variant="overline"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Zoom
                              </Typography>
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                classes={{ root: styles.slider }}
                                onChange={(e, zoom) => setZoom(zoom)}
                              />
                            </div>
                            <div className={styles.sliderContainer}>
                              <Typography
                                variant="overline"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Rotazione
                              </Typography>
                              <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                classes={{ root: styles.slider }}
                                onChange={(e, rotation) => setRotation(rotation)}
                              />
                            </div>
                          </div>


                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="button"
                  onClick={showCroppedImage}
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Immagine
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalCropProfile(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
                </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/** Modale Upload immagine del Negozio */}

    <Transition.Root show={openModalUploadNegozio} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={openModalUploadNegozio} onClose={setOpenModalUploadNegozio}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UploadIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  </Dialog.Title>
                  <div className="mt-2">


                  <div className="space-y-1 text-center">
                    <div className=" text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500"
                      >
                        <span>Carica immagine</span>
                        <input id="file-upload" name="image" accept="image/*" multiple={false} type="file" onChange={imageHandler3} className="sr-only" />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG fino a 4MB</p>
                    <p className="text-sm text-gray-600" >{uploadStatus3}</p>

                    {/* Implementazione crop image */}

                  </div>
                  <div className="mt-2">
                  </div>

                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setOpenModalUploadNegozio(false)}
                >
                  Torna al tuo profilo
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


     {/* Modale per il crop dell' immagine predefinita da inserire nel frontEnd  */}

     <Transition.Root show={openModalCropPredefinita} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalCropPredefinita}
        onClose={setOpenModalCropPredefinita}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">

                  <div style={{
                      height: '28vw',
                      position: 'relative',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: '26vw',
                    }} >
                  <Cropper
                        image={ImgProfile1}
                        crop={crop1}
                        rotation={rotation1}
                        zoom={zoom1}
                        aspect={3 / 4}
                        onCropChange={setCrop1}
                        onRotationChange={setRotation1}
                        onCropComplete={onCropComplete1}
                        onZoomChange={setZoom1}
                    />
                    </div>

                    {/* Corpo del modale */}

                  <div className="space-y-6">

                  <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">


                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Modifica Immagine</h3>
                        <p className="mt-1 text-sm text-gray-500">Utilizza i seguenti tools per modificare l'immagine e settarla al meglio all'interno della griglia.</p>


                        { showErr10 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel ritaglio del immagine! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc10 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Immagine ritagliata e settata correttamente, si prega di chiudere la finestra!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
                      </div>


                      <div className="mt-5 md:mt-0 md:col-span-2">

                      <div className={styles.controls}>
                            <div className={styles.sliderContainer}>
                              <Typography
                                variant="overline"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Zoom
                              </Typography>
                              <Slider
                                value={zoom1}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                classes={{ root: styles.slider }}
                                onChange={(e, zoom1) => setZoom1(zoom1)}
                              />
                            </div>
                            <div className={styles.sliderContainer}>
                              <Typography
                                variant="overline"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Rotazione
                              </Typography>
                              <Slider
                                value={rotation1}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                classes={{ root: styles.slider }}
                                onChange={(e, rotation1) => setRotation1(rotation1)}
                              />
                            </div>
                          </div>


                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="button"
                  onClick={showCroppedImage1}
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Immagine
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalCropPredefinita(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
                </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Completa il tuo profilo!</p>
                    <p className="mt-1 text-sm text-gray-500">Completa la tua anagrafica (Cellulare, Categoria Merciologica, Sito Collegato, etc..), aggiungi dei tag, inoltre carica almeno una foto settandola come predefinita e imposta i tuoi orari di apertura.</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      { showErr100 ? <ErrNotify titolo={"Attenzione"}  testo={"Prima di inserire un Tag devi impostare l'immagine del profilo cliccando sul bottone della Fotocamera e Aggiornare la pagina!"} /> : null }

              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
