import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import Table from "./Table";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CirclePicker } from 'react-color'
import SidebarMenu from "./sidebar_menu"
import Select from "react-select";
import moment from "moment"

import {
  ArrowLeftIcon,
  TrashIcon,
  ArrowRightIcon,
  EyeIcon,
  CalendarIcon,
  CheckIcon,
  ChartBarIcon,
  PrinterIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  PencilIcon,
  MailIcon,
  BookmarkIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

//Importo Menu
import Menu from "./navigation";
import { propTypes } from 'react-bootstrap/esm/Image'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function ClientiOdl(props) {


  //Setto gli state
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState({});


useEffect(() => {
  const expiration = cookies.expiration
  if(new Date(expiration) < new Date()) {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')
  }
}, [setLogout])


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  let styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "white",
      backgroundColor: state.data.color,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
    })
  };

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    getClienti()
    getListino()
  }, [])



  //Gestione listino

  const [isListino, setListino] = useState(null)

  const getListino = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/user/fetch4Preventivo`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setUtenti(response.data)
    setLoading2(true)
  }


  const [isPreventivo, setPreventivo] = useState([])
  const [isUtenti, setUtenti] = useState([])
  const [isCliente, setCliente] = useState([])
  const [servizi, setServizi] = useState(null)
  const [legenda, setLegenda] = useState(null)
  const [scatole, setScatole] = useState(null)
  const [info, setInfo] = useState(null)
  const [squadra, setSquadra] = useState(null)
  const [stanze, setStanze] = useState([
    {
      value: 'waewauG8Tahs2yeich',
      label: 'INGRESSO'
    },
    {
      value: 'ice8mohdaeNgoo1moo',
      label: 'CUCINA'
    },
    {
      value: 'ahf0iem5iixahJ4aru',
      label: 'SALOTTO'
    },
    {
      value: 'dieHieM0yei9saithe',
      label: 'BAGNO'
    },
    {
      value: 'ohshaeh8lini4cheeH',
      label: 'CAMERA DA LETTO 1'
    },
    {
      value: 'eph5uePeelu5ulae0i',
      label: 'CAMERA DA LETTO 2'
    },
  ])
  const [isSaving, setSaving] = useState(false)
  const [serviceChooser, setServiceChooser] = useState(false)
  const [currentStep, setCurrentStep] = useState(null)


  const onClick = (id) => {
    setServiceChooser(prev => Boolean(!prev[id]) ? { ...prev, [id]: true } : { ...prev, [id]: false });
  }


  const generate_random = (length) => {
    var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}



  // ✅ Update one or more objects in a state array
  const updateColoreLegenda = (item, color) => {
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, color: color };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateDestinazioneLegenda = (item, dest) => {
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateStanza = (item, dest) => {
    setStanze(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePermessiLegenda = (item, dest) => {
    console.log(dest)
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, permessi: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateNomeServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_name: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePrezzoServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_prezzo: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateQtyServizio = (item, value) => {
    item.qty = value
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_qty: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateFromChangeServizio = (item, value) => {
    console.log(value)
    setSquadra(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, value: value.value };
        }
        return obj;
      }),
    );
    setSquadra(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: value.label };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateDestinazioneServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, legenda: value };
        }
        return obj;
      }),
    );
  };


  // ✅ Update one or more objects in a state array
  const updateStanzaServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, stanza: value };
        }
        return obj;
      }),
    );
  };



  const getClienti = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/clienti/fetchPreventivo`, {
      params: {
        id: props.match.params.id
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    setPreventivo(response.data.preventivo)
    setLegenda(response.data.preventivo.legenda)
    if (response.data.preventivo.stanze.length > 0) {
      setStanze(response.data.preventivo.stanze)
    }
    setCliente(response.data.cliente)
    setSquadra(response.data.preventivo.squadra)
    setServizi(response.data.preventivo.servizi)
    setScatole(response.data.preventivo.scatole)
    setInfo(response.data.preventivo.info)
    let steps = response.data.preventivo.steps
    if(steps) {
      let i = 1
      steps.map(step => {
        if(step.status === 'complete') {
          i = i + 1
        }
      })
      setCurrentStep(i)
       console.log(i)
    }
    setLoading(true)
  }

        //Chiamata post per inserimento utente
        const onSubmit = async (data) => {
          setSaving(true)
          const response = await axios.patch(`${window.$apiEndpoint}/clienti/edit-odl`, {
            cliente: isCliente,
            preventivo: data,
            id: props.match.params.id,
            squadra: squadra
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti()  
          setTimeout(() => {
            setSaving(false)
          }, 1000)
      }
        //Chiamata post per inserimento utente
        const assegnaODL = async (data) => {
          setSaving(true)
          const response = await axios.patch(`${window.$apiEndpoint}/clienti/assegna-odl`, {
            cliente: isCliente,
            preventivo: data,
            prev : isPreventivo,
            id: props.match.params.id,
            squadra: squadra
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti()  
          setTimeout(() => {
            setSaving(false)
            history.push(`/clienti/${isCliente._id}`)
          }, 1000)
      }


      const [stepLoader, setStepLoader] = useState(true)
        //Chiamata post per inserimento utente
        const completeStep = async (data) => {
          setStepLoader(false)
          const response = await axios.patch(`${window.$apiEndpoint}/clienti/complete-step`, {
            cliente: isCliente,
            id: props.match.params.id,
            step: data
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti() 
          setTimeout(() => {
            setStepLoader(true)
            if(parseInt(data) === 2) {
              history.push(`/clienti/${isCliente._id}`)
            }
          }, 1000) 
      }


    const { SearchBar } = Search;


    //Se lo state isCreated ricarico la pagina
    if (isCreated) {
        //Ricarica Pagina
        return window.location.reload();
    }




  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu attivo={"Clienti"} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

          {/* STEP 1 */}
          <div className="py-6">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
              {/* Replace with your content */}
             

            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className=" md:mt-0 md:col-span-2">
                    
                  {isPreventivo && isCliente && loading && loading2 ? <form onSubmit={handleSubmit(onSubmit)}>

                      <div className="grid grid-cols-2 gap-2 mb-4 ">

                      <div className="col-span-1 sm:col-span-1">
                          <label htmlFor="inizioLavoro" className="block text-sm font-medium text-gray-700">
                          Inizio lavori<strong><span style={{ color: 'red' }}>*</span></strong>
                        </label>
                        <input
                          type="date"
                            name="inizioLavoro"
                            id="inizioLavoro"
                            defaultValue={isPreventivo.inizioLavoro ? moment(isPreventivo.inizioLavoro).format("YYYY-MM-DD") : null}
                            {...register("inizioLavoro")}
                          className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />

                          {errors.inizioLavoro && <p>Il campo è obbligatorio</p>}
                      </div>
                      <div className="col-span-1 sm:col-span-1">
                          <label htmlFor="fineLavoro" className="block text-sm font-medium text-gray-700">
                          Fine lavori<strong><span style={{ color: 'red' }}>*</span></strong>
                        </label>
                        <input
                          type="date"
                            name="fineLavoro"
                            id="fineLavoro"
                            defaultValue={isPreventivo.fineLavoro ? moment(isPreventivo.fineLavoro).format("YYYY-MM-DD") : null}
                            {...register("fineLavoro")}
                          className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />

                          {errors.fineLavoro && <p>Il campo è obbligatorio</p>}
                      </div>
                      </div>
              
                    {/*SERVIZI*/}
                    <div>
                      <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                            Squadra <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setSquadra(old => [...old, {
                              value: "",
                              label: "",
                            }])}>Aggiungi</small>
                        </label>
                      </div>
                        {squadra ? <div className="mt-2">
                          {squadra.map((item, index) => {
                            return (
                              <div key={index} className="grid grid-cols-3 gap-3 mb-4 mt-4">
                                
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}>
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginRight: '2vw',
                                    paddingTop: '2vh'
                                  }}>
                                    <ReactTooltip />
                                    <MinusCircleIcon
                                      onClick={() => setSquadra(current =>
                                        current.filter(dest => {
                                          return dest.value !== item.value;
                                        }),
                                      ) }
                                      className="h-6 w-6 text-red-600 mr-4"
                                      aria-hidden="true"
                                      data-tip="Rimuovi"
                                    />
                                  </div>

                                  <div style={{width: '100vw'}}>
                                    <label htmlFor="ragioneSociale" className={`block text-sm font-medium text-gray-700 ${!serviceChooser[index] ? ' mb-1' : null}`}>
                                      Operatore
                                    </label>
                                    <Select
                                      options={isUtenti}
                                      defaultValue={{
                                        value: item.value,
                                        label: item.label
                                      }}
                                      onChange={e =>{
                                        console.log(e)
                                         updateFromChangeServizio(item, e)
                                        }}
                                      placeholder="Seleziona servizio"
                                      />
                                  </div>
                                  
                                </div>
                              </div>
                            )
                          })}
                        </div> : null}

                    </div>



                        {/* COSTI TOTALI */}

            

                      <div className="flex justify-between">
                        <div className="flex justify-start mt-5">
                          <button
                            type="button"
                            onClick={() => history.goBack()}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                          </button>
                        </div>
                        <div className="flex justify-end mt-5">
                          {/*<button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MailIcon className="mr-2 h-4 w-4" />Invia
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <PrinterIcon className="mr-2 h-4 w-4" />Stampa
                        </button>*/}
                          <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            {isSaving ? 'Salvo...' : 'Aggiorna'}
                          </button>
                          <button
                            type="button"
                            onClick={() => assegnaODL()}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Assegna
                          </button>
                        </div>
                      </div>

                      
                    </form> : null}
                    </div>




    
                  </div>
                </div>
              </div>

              
              </div>

              

              
          </main>
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

 
            </div>




  )
}
