import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  ShoppingBagIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/outline'
import { BsPeople } from 'react-icons/bs';
import { GoPackage } from 'react-icons/go';
import { TbFileInvoice } from 'react-icons/tb';

import ReactTooltip from 'react-tooltip';
import navigation from "./navigation";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "moment/locale/it"
import logo_main from "../assets/images/falco_white.png"
import SidebarMenu from "./sidebar_menu"


//Importo Menu
import Menu from "./navigation";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function DashboardOperatore() {
  const localizer = momentLocalizer(moment)
  const messages = { // new
    allDay: 'Tutto il giorno',
    previous: 'Prec.',
    next: 'Succ.',
    today: 'Oggi',
    month: 'Mese',
    week: 'Settimana',
    day: 'Giorno',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Ora',
    event: 'Evento',
  };
  //Setto gli state
  const [allUsers, viewUsers] = useState([{}])
  const [allEvents, viewEvents] = useState([{}])
  const [allSommaEsercenti, setallSommaEsercenti] = useState([{}])
  const [allSommaOfferte, setallSommaOfferte] = useState([{}])
  const [allSommaEventi, setallSommaEventi] = useState([{}])


  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const [showsetAllProfile, setAllProfile] = useState([{}]);


  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;

  const meetings = [
    {
      id: 1,
      date: 'January 10th, 2022',
      time: '5:00 PM',
      datetime: '2022-01-10T17:00',
      name: 'Leslie Alexander',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      location: 'Starbucks',
    },
    // More meetings...
  ]
  const days = [
    { date: '2021-12-27' },
    { date: '2021-12-28' },
    { date: '2021-12-29' },
    { date: '2021-12-30' },
    { date: '2021-12-31' },
    { date: '2022-01-01', isCurrentMonth: true },
    { date: '2022-01-02', isCurrentMonth: true },
    { date: '2022-01-03', isCurrentMonth: true },
    { date: '2022-01-04', isCurrentMonth: true },
    { date: '2022-01-05', isCurrentMonth: true },
    { date: '2022-01-06', isCurrentMonth: true },
    { date: '2022-01-07', isCurrentMonth: true },
    { date: '2022-01-08', isCurrentMonth: true },
    { date: '2022-01-09', isCurrentMonth: true },
    { date: '2022-01-10', isCurrentMonth: true },
    { date: '2022-01-11', isCurrentMonth: true },
    { date: '2022-01-12', isCurrentMonth: true, isToday: true },
    { date: '2022-01-13', isCurrentMonth: true },
    { date: '2022-01-14', isCurrentMonth: true },
    { date: '2022-01-15', isCurrentMonth: true },
    { date: '2022-01-16', isCurrentMonth: true },
    { date: '2022-01-17', isCurrentMonth: true },
    { date: '2022-01-18', isCurrentMonth: true },
    { date: '2022-01-19', isCurrentMonth: true },
    { date: '2022-01-20', isCurrentMonth: true },
    { date: '2022-01-21', isCurrentMonth: true },
    { date: '2022-01-22', isCurrentMonth: true, isSelected: true },
    { date: '2022-01-23', isCurrentMonth: true },
    { date: '2022-01-24', isCurrentMonth: true },
    { date: '2022-01-25', isCurrentMonth: true },
    { date: '2022-01-26', isCurrentMonth: true },
    { date: '2022-01-27', isCurrentMonth: true },
    { date: '2022-01-28', isCurrentMonth: true },
    { date: '2022-01-29', isCurrentMonth: true },
    { date: '2022-01-30', isCurrentMonth: true },
    { date: '2022-01-31', isCurrentMonth: true },
    { date: '2022-02-01' },
    { date: '2022-02-02' },
    { date: '2022-02-03' },
    { date: '2022-02-04' },
    { date: '2022-02-05' },
    { date: '2022-02-06' },
  ]

  let mesi = [
    {
      id: 0,
      nome: "Gennaio"
    },
    {
      id: 1,
      nome: "Febbraio"
    },
    {
      id: 2,
      nome: "Marzo"
    },
    {
      id: 3,
      nome: "Aprile"
    },
    {
      id: 4,
      nome: "Maggio"
    },
    {
      id: 5,
      nome: "Giugno"
    },
    {
      id: 6,
      nome: "Luglio"
    },
    {
      id: 7,
      nome: "Agosto"
    },
    {
      id: 8,
      nome: "Settembre"
    },
    {
      id: 9,
      nome: "Ottobre"
    },
    {
      id: 10,
      nome: "Novembre"
    },
    {
      id: 11,
      nome: "Dicembre"
    }
  ]


  useEffect(() => {

    let month = moment().month()
    let day = moment().date()
    console.log(day)
    var current = mesi.filter(obj => {
      return obj.id === month
    })
    setCurrentMonth(current[0].nome)


    const expiration = cookies.expiration

    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])

  useEffect(() => {
    getStatistics()
  }, [])


  const [odl, setOdl] = useState([])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }

  const [statistics, setStatistics] = useState(null)
  const [currentMonth, setCurrentMonth] = useState(null)

  var getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const getStatistics = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/user/profilo`, {
      params: {
        id: "6346fbc9d3ee4aed08a22e9b"
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    let array = []
    console.log(response.data)
    let interventi = response.data.odl ? response.data.odl : []
    interventi.map(item => {
      let date = getDaysArray(item.inizioLavoro, item.fineLavoro)
      date.map(d => {
        let obj = {
          data: moment(d).format("YYYY-MM-DD"),
          preventivoID: item.id,
          preventivoName: item.name
        }
        array.push(obj)
      })
    })
    array = array.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.data === value.data && t.preventivoID === value.preventivoID
      ))
    )
    setStatistics(array)
    setLoading(true)
  }


  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }



  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">

      <SidebarMenu attivo={"Operatore"}/>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {loading ? <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
              <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
                <div className="flex items-center text-gray-900">
                  <button
                    type="button"
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Prec.</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                      <div className="flex-auto font-semibold">{currentMonth}</div>
                  <button
                    type="button"
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Succ.</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                  <div>Lun</div>
                  <div>Mar</div>
                  <div>Mer</div>
                  <div>Gio</div>
                  <div>Ven</div>
                  <div>Sab</div>
                  <div>Dom</div>
                </div>
                <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                  {days.map((day, dayIdx) => (
                    <button
                      key={day.date}
                      type="button"
                      className={classNames(
                        'py-1.5 hover:bg-gray-100 focus:z-10',
                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                        (day.isSelected || day.isToday) && 'font-semibold',
                        day.isSelected && 'text-white',
                        !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                        !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                        day.isToday && !day.isSelected && 'text-indigo-600',
                        dayIdx === 0 && 'rounded-tl-lg',
                        dayIdx === 6 && 'rounded-tr-lg',
                        dayIdx === days.length - 7 && 'rounded-bl-lg',
                        dayIdx === days.length - 1 && 'rounded-br-lg'
                      )}
                    >
                      <time
                        dateTime={day.date}
                        className={classNames(
                          'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                          day.isSelected && day.isToday && 'bg-indigo-600',
                          day.isSelected && !day.isToday && 'bg-gray-900'
                        )}
                      >
                        {day.date.split('-').pop().replace(/^0/, '')}
                      </time>
                    </button>
                  ))}
                </div>
              </div>
              <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                {statistics.map((meeting) => (

                  <li key={meeting.id} className="relative border rouded-sm flex space-x-6 p-4 py-2 xl:static rounded-lg mt-1 mb-1">
                    <div className="flex-auto">
                      <Link to={`/operatore/odl/${meeting.preventivoID}`}>
                        <h5 className="pr-10 font-semibold text-gray-900 xl:pr-0">{meeting.preventivoName}. Effettuare in data {meeting.data}</h5>
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
         </div>
        </div>
        </main> : null}
      </div>
    </div>
  )
}
