import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import { Dialog, Transition, RadioGroup } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  SearchCircleIcon,
  SpeakerphoneIcon,
  MapIcon,
  ViewGridAddIcon,
  CogIcon,
  CameraIcon,
  UploadIcon,
  CheckIcon,
  CheckCircleIcon,
  TrashIcon,
  HeartIcon,
  checkCircleIcon,
  plusCircleIcon,

} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon, StarIcon } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'

//Importo Menu
import Menu from "./navigation_merchant";


const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})



const product = {
  name: 'Basic Tee 6-Pack ',
  price: '$192',
  rating: 3.5,
  reviewCount: 117,
  href: '#',
  imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg',
  imageAlt: 'Two each of gray, white, and black shirts arranged on table.',
  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
  ],
  sizes: [
    { name: 'XXS', inStock: true },
    { name: 'XS', inStock: true },
    { name: 'S', inStock: true },
    { name: 'M', inStock: true },
    { name: 'L', inStock: true },
    { name: 'XL', inStock: true },
    { name: 'XXL', inStock: true },
    { name: 'XXXL', inStock: false },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Prodotti() {
  //Setto gli state
  const [Allinfo, setAllInfo] = useState([{}]);
  const [AllProdotti, setProdotti] = useState([{}]);
  const [Articolo, setArticolo] = useState([{}]);
  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openModalDettaglio, setOpenModalDettaglio] = useState(false)
  const [openModalInserimento, setOpenModalInserimento] = useState(false)


  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [showsetAllProfile, setAllProfile] = useState([{}]);

  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;
  const [isAdmin, setisAdmin] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [showErr2, setErr2] = useState(false);

  const [showPreSucc, setPreSucc] = useState('');
  const [showPreErr, setPreErr] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [nomeImmagine, setnomeImmagine] = useState(false);
  const [nomeImmagine2, setnomeImmagine2] = useState('');




  

  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])

 
  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    
  }

  const CALLlogout = () => {
    setOpen(true);
  }

  const CallModaleDettaglio = (id_prodotto) => {
    //Recupero dal database i dati per l'articolo selezionato
    let mounted = true
    const cercaProdotto = async () => {
      const Prodotti = await axios.get(`${window.$apiEndpoint}/merchant/fetchProdottoSingolo/${indirzzi_email_loggato}/${id_prodotto}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setArticolo(Prodotti.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    cercaProdotto();
    //Apro il modale con il dettaglio dell'articolo selezionato
    setOpenModalDettaglio(true);
  }

   //Controllo se la pagina è controllata da un Amministrstore oppure solamnete dall'Esercente 
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      try {
        if(mounted){   

        if (indirzzi_email_loggato === indirzzi_email_account) {
          
           //Sono Esercente
           setisAdmin(false)
         
          //Controllo Se sono uno degli amministratori
          if (indirzzi_email_loggato === 'info@finestrapp.it' || indirzzi_email_loggato === 'info@tribusadv.com') {
          //Sono Amministrazione ma nel mio account 
          setisAdmin(true) 
          } else {
           //Sono Esercente
           setisAdmin(false)
          }

      
        } else {

          //Sono Amministratore 
          setisAdmin(true)
        
        } 
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Prodotti Merchant
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Prodotti = await axios.get(`${window.$apiEndpoint}/merchant/fetchProdotti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setProdotti(Prodotti.data)
          setLoading(true)

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

  //Recupero Prodotti Merchant
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const Prodotti = await axios.get(`${window.$apiEndpoint}/merchant/fetchProdotti/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){

          setTimeout(() => {
            setProdotti(Prodotti.data)
             setLoading(true)
            }, 2000);
          

        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [AllProdotti])

  
  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {
      
      return "Chiuso";

    } else {

      return string;

    
    }
  }

  //Cancello prodotto selezionato
  const cancellaArticolo = async (id_articolo) => {
  
    try {

      const add = await axios.post(`${window.$apiEndpoint}/merchant/cancellaArticolo`, {
        idArticolo: id_articolo,
        email: indirzzi_email_loggato,      
    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    })
      setPreSucc('Articolo rimosso con successo!');
      setTimeout(() => {setPreSucc('')},3000)
      //Chiudo il modale
      setTimeout(() => {setOpenModalDettaglio(false)},2000)
    } catch (err) {
      setPreErr('Errore nell\'eliminazione dell\'articolo!')
      setTimeout(() => {setPreErr('')},3000)
    }
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)
          
          
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])



  //Chiamata post per inserimento prodotto
  const onSubmit = data => {
    
    const nProdotto = data.nProdotto.toUpperCase();
    const prezzo = data.prezzo.toUpperCase();
    const aboutProdotto = data.aboutProdotto.toLowerCase().replace(/'/g, "\\'");
  
    if (nomeImmagine) {
    //Immaginre prodotto caricata eseguo l'update dati
    const addM = async () => {
      try {
        
        const add = await axios.post(`${window.$apiEndpoint}/merchant/insertNewProduct`, {
          nProdotto: capitalize(nProdotto),
          prezzo: prezzo,
          aboutProdotto: aboutProdotto,
          email: indirzzi_email_loggato,
          

      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc(true);
        setTimeout(() => {setSucc(false)},3000)

        //Chiudo Modale 
        setTimeout(() => {setOpenModalInserimento(false)},3000)
        //Resetto modale
        reset()

      } catch (err) {
        setErr(true)
        setTimeout(() => {setErr(false)},3000)
      }
    }
    addM()
    } else {
    //Immagine non caricata stampo allert 
    setErr2(true)
    setTimeout(() => {setErr2(false)},4000)
    }
    
    
  }
  
  //Chiamata per aprire il modale di inserimento prodotto
  const CallModaleInserimento = () => {
   setOpenModalInserimento(true) 
  }


  //Caricamento Immagine Prodotto
  const imageHandler = (event) => {

    const file = event.target.files[0];
    const formData = new FormData()
    formData.append('image', file)
    formData.append("email", indirzzi_email_loggato);

    fetch(`${window.$apiEndpoint}/merchant/uploadProdotto`, {
        method: 'POST',
        body: formData,
        headers: {
              'x-access-token' : cookies.token,
              'Accept': 'multipart/form-data'
          },
      })
      .then(res => res.json())
      .then(res => {

        setUploadStatus(res.msg);
        setTimeout(() => {setUploadStatus('')},2500)
        //Setto caricamento a true
        setnomeImmagine(true)
        //Setto nome immagine Preview
        setnomeImmagine2(res.data)
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.finestrapp.it/logo_bianco.svg"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                <Menu attivo={"Prodotti & Servizi"} />

                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.finestrapp.it/logo_bianco.svg"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <Menu attivo={"Prodotti & Servizi"} />

              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">



        {/* Header se admin è loggato come merchant*/}

        { isAdmin ? <div className="bg-green-600">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-green-800">
                              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white truncate">
                              <span className="md:hidden">Attenzione! Stai impersonificando un esercente! Indirizzo Email Esercente: {indirzzi_email_loggato} </span>
                              <span className="hidden md:inline">Attenzione! Stai impersonificando un esercente! Indirizzo Email Esercente: <strong>{indirzzi_email_loggato} </strong> </span>
                            </p>
                          </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <a
                            href="/merchant_list"
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50"
                          >
                            Ritorna al Pannello Amministratore
                          </a>
                        </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            
                          </div>
                        </div>
                      </div>
          </div> : null }
          <div className="py-6 md:mt-16 lg:mt-16 xl:mt-16 sm:mt-1" >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">
              {/* Replace with your content
              
               #Contenuto Merchant Dashboard
              
               */}
              



               <article>

              {/* Profile header */}
            
                <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
                  <div className="mt-2  py-8 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">

                  <div className="bg-white ">
                  <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">I tuoi prodotti & Servizi</h2>
                  <h2 className="mt-2 xl:mb-12 lg:mb-12 md:mb-12 sm:mb-12 mb-16 text-sm text-gray-500 text-left">Per eliminare un prodotto o un servizio clicca sopra l'immagine dell'articolo interessato, mentre per aggiungerne uno nuovo clicca sull'apposito pulsante in fondo a destra.</h2>


                          <div className="max-w-2xl mx-auto px-4 sm:py-4 sm:px-6 lg:max-w-7xl lg:px-8 ">

                            <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8 -mt-10">
                              {AllProdotti.map((product) => (
                                <a key={product.id} onClick={() => CallModaleDettaglio(product.id)} href="#" className="group">
                                  <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                                    <img
                                      src={window.$fileserverEndpointCrop + product.url_immagine_prodotto}
                                      alt={product.nome_prodotto}
                                      className="w-full h-full object-center object-cover group-hover:opacity-75"
                                    />
                                  </div>
                                  <h3 className="mt-4 text-sm text-gray-700">{product.nome_prodotto}</h3>
                                  <h5 className="mt-2 text-xs text-gray-500 text-left">{product.descrizione_prodotto}</h5>
                                  <p className="mt-1 text-lg font-medium text-gray-900">{product.prezzo} €</p>
                                </a>
                              ))}
                            </div>
                          </div>

                          <div className="flex flex-row-reverse -mt-1">
                              <a className="flex"  onClick={() => CallModaleInserimento()} href="#">
                              <ViewGridAddIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Aggiungi Articolo & Servizio</p>
                              </a>
                             </div>


                    
                        
                        
                          </div>
                    </div>
                </div>

              
              </article>




               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

   
    {/* Modale dettaglio */}
    
    <Transition.Root show={openModalDettaglio} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto " onClose={setOpenModalDettaglio}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
              <div className="w-full relative flex rounded-lg items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setOpenModalDettaglio(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
                  <div className="aspect-w-2 aspect-h-4 rounded-lg bg-gray-100 overflow-hidden sm:col-span-4 lg:col-span-5">
                    <img src={window.$fileserverEndpointCrop + Articolo[0].url_immagine_prodotto} alt={product.imageAlt} className="w-full shadow-sm	" />
                  </div>
                  <div className="sm:col-span-8 lg:col-span-7">
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">{Articolo[0].nome_prodotto}</h2>

                    <section aria-labelledby="information-heading" className="mt-2">
  

                      <p className="text-2xl text-gray-900">{Articolo[0].prezzo} €</p>
                      
                      
                    </section>

                    <section aria-labelledby="options-heading" className="mt-2">
                     

                        {/* Descrizione */}
                        <div>
                          <h4 className="text-sm text-gray-900 font-medium">Descrizione</h4>
                          <h5 className="mt-2 text-xs text-gray-500 text-left">{Articolo[0].descrizione_prodotto}</h5>

                          
                        </div>

                        <div class="flex flex-row ">
                        <button
                         onClick={() => cancellaArticolo(Articolo[0].id)}
                          type="button"
                          className="mt-6 w-full bg-red-600 border border-transparent rounded-md mr-2 py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                          Elimina
                        </button>
                        

                       
                       
                      </div>

                      <div className="space-y-1 text-center">
                    
                        <p className="text-sm text-green-600 mt-3" >{showPreSucc}</p>
                        <p className="text-sm text-red-600 mt-3" >{showPreErr}</p>

                       </div>

                        
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    

    {/* Modale inserimento prodotto*/}

                        
    <Transition.Root show={openModalInserimento} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalInserimento}
        onClose={setOpenModalInserimento}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>
                
                <div className="mt-3 sm:mt-5">
                 
                  <div className="mt-2">
                    


                    {/* Corpo del modale */}

                    <div className="space-y-6">
      

      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Inserisci Prodotto & Servizio</h3>
            <p className="mt-1 text-sm text-gray-500">Usa i seguenti campi per inserire un nuovo prodotto o servizio.</p>
            <p className="mt-1 text-sm text-gray-500">Prima di inserire il prodotto o servizio è obbligatorio caricare l'immagine.</p>



            <div className="space-y-1 text-center mt-4">
                    <div className=" text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus-within:outline-none "
                      >
                        <center><CameraIcon className="h-12 w-12 mb-2 text-gray-200" aria-hidden="true" /></center>
                        <span>Carica immagine</span>
                        <input id="file-upload" name="image" accept="image/*" multiple={false} type="file" onChange={imageHandler} className="sr-only" />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG fino a 4MB</p>
                    <p className="text-sm text-green-600 my-2" >{uploadStatus}</p>



                    <center>

                    </center>
                  </div>

            { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nella creazione del prodotto o servizio! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                  { showErr2 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Devi prima caricare una fotografia del prodotto o del servizio! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }
                  
                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Prodotto inserito con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }


                    {nomeImmagine ? <center>
                    <p className="mt-1 text-sm text-gray-600 mt-5">Immagine Caricata</p>

                  <img
                    className="h-32 w-auto mt-1 p-3 shadow-lg border-2 rounded-md		 "
                    src={`https://fileserver.finestrapp.it${nomeImmagine2}`}
                    alt="Logo"
                  />
                  </center> : null}

          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)}>
              

                <div className="col-span-6 sm:col-span-4">
                  <input
                    type="hidden"
                    defaultValue={indirzzi_email_loggato}
                    disabled={true}
                    name="indirizzoEmail"
                    id="indirizzoEmail"
                  />
             
             
                <div className="col-span-6 lg:col-span-4 mt-1">
                  <label htmlFor="nProdotto" className="block text-sm font-medium text-gray-700">
                    Nome Prodotto & Servizio
                  </label>
                  <input
                    type="text"
                    name="nProdotto"
                    id="nProdotto"
                    {...register("nProdotto", { required: true })}
                    autoComplete="nProdotto"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.nProdotto && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2 mt-1">
                  <label htmlFor="prezzo" className="block text-sm font-medium text-gray-700">
                    Prezzo (Inserire solo importo)
                  </label>
                  <input
                    type="text"
                    name="prezzo"
                    {...register("prezzo", { required: true })}
                    id="prezzo"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.prezzo && <p>Il campo è obbligatorio</p>}
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-6 mb-2 mt-1">
                    <label htmlFor="aboutProdotto" className="block text-sm font-medium text-gray-700">
                    Descrizione Prodotto o Servizio
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="aboutProdotto"
                        name="aboutProdotto"
                        rows={7}
                        {...register("aboutProdotto", { required: true })}
                        className="shadow-sm focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                      />
                      {errors.aboutProdotto && <p>Il campo è obbligatorio</p>}
                    </div>
                 </div>


              </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Inserisci Prodotto (Servizio)
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalInserimento(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    


   

              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
