import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  IdentificationIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  EyeIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  SearchCircleIcon,
  SpeakerphoneIcon,
  MapIcon,
  ViewGridAddIcon,
  CogIcon,
  CameraIcon,
  UploadIcon,
  CheckIcon,
  CheckCircleIcon,
  TrashIcon,
  HeartIcon,
  checkCircleIcon,
  InformationCircleIconOut,
  RefreshIcon,
  ExclamationCircleIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon, PlusIcon, } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'

//Importo Menu
import Menu from "./navigation_merchant";
import SidebarMenu from "./sidebar_menu"
import { propTypes } from 'react-bootstrap/esm/Image';

import avatar from "../assets/images/avatar1.png"
import { AllInboxOutlined, SignalCellularNullRounded } from '@material-ui/icons';

import moment from "moment"
import { reset } from 'react-tabs/lib/helpers/uuid';


const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function FornitoriProfilo(props) {
  //Setto gli state

  const [Allinfo, setAllInfo] = useState({});
  const [AllAntipasti, setAntipasti] = useState([{}]);
  const [AllPrimi, setPrimi] = useState([{}]);
  const [AllSecondi, setSecondi] = useState([{}]);
  const [AllFormaggi, setFormaggi] = useState([{}]);
  const [AllDessert, setDessert] = useState([{}]);
  const [AllOrari, setOrari] = useState([{}]);
  const [AllProdotti, setProdotti] = useState([{}]);
  const [AllCoordinate, setCoordinate] = useState([{}]);
  const [loading, setLoading] = useState(false)
  const [immagineCliccata, setImmagineCliccata] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openModalDet, setopenModalDet] = useState(false)
  const [openModalUploadNegozio, setOpenModalUploadNegozio] = useState(false)
  const [openModalUpdateInfo, setOpenModalUpdateInfo] = useState(false)
  const [openModalUpdateInfo2, setOpenModalUpdateInfo2] = useState(false)
  const [openModalUpdateMenu, setOpenModalUpdateMenu] = useState(false)
  const [openModalUpdateOrari, setOpenModalUpdateOrari] = useState(false)

  const [predefinita, setPredefinita] = useState(true);


  const fetch = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/fornitori/profilo`, {
      params: {
        id: props.match.params.id
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    setAllInfo(response.data)
    setLoading(true)
  }





  useEffect(() => {
    let mounted = true
    if(mounted) {
      fetch()
    }
    return(() => {
      mounted = false
    })
  }, [])




  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: registerEdit, handleSubmit: handleSubmitEdit, formState: { errors: errorsEdit } } = useForm();
  const { register: registerArticle, handleSubmit: handleSubmitArticle, formState: { errors: errorsArticle } } = useForm();

  const [tabIndex, setTabIndex] = useState(0);

  const [activeTab, setActiveTab] = useState('Dettagli')
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);

  const [showPreSucc, setPreSucc] = useState('');
  const [showPreErr, setPreErr] = useState('');
  const [show, setShow] = useState(false)

  

   

  
  const tabs = [
    { name: 'Dettagli'},
    { name: 'Articoli comuni'},
  ]
  
  
 const changeTab = (index) => {
  setTabIndex(index)
   if(index === 0) {
     setActiveTab('Dettagli')
   } else if(index === 1) {
     setActiveTab('Articoli comuni')
   }
  }
  

  
  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])

 
  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    
  }


  const CallUpdateInfo = () => {
    setOpenModalUpdateInfo(true);
  }

  const CallUploadNegozio = () => {
    setOpenModalUploadNegozio(true);
  }



  const CallUpdateOrari = () => {
    setOpenModalUpdateOrari(true);
  }

  const CallUpdateMenu = () => {
    setOpenModalUpdateMenu(true);
  }

  const CallUpdateDet = (nome_immagine) => {
  //Apro il modale per cancellare o rendere predefinita
  setopenModalDet(true);
  setImmagineCliccata(nome_immagine);
  }

  //Recupero Controllo completamento del profilo



  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {
      
      return "Chiuso";

    } else {

      return string;

    
    }
  }


  const [viewEdit, setViewEdit] = useState(true)
  const [advModal, setAdvModal] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)


  const onSubmitEdit = data => {
    data.id = Allinfo._id
    const editprofile = async () => {
      const response = await axios.patch(`${window.$apiEndpoint}/fornitori/edit`, 
        data
      , {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setSuccessModal(true)
      setViewEdit(true)
    }
    editprofile()
    fetch()

  }

  const onSubmit = data => {
    console.log(data)
  }




  const deleteArticolo = async (articolo) => {
    if(toBeDeleted) {
      const create = async () => {
        const response = await axios.patch(`${window.$apiEndpoint}/fornitori/remove-articolo`,
          {
            id: Allinfo._id,
            articoloID: articolo.id
          }
          , {
            headers: {
              "x-access-token": cookies.token
            }
          })
        setToBeDeleted(null)
        setAdvModal(!advModal)
        fetch()
      }
      create()
    }
  }


  const [successModal, setSuccessModal] = useState(false)
  const [addArticle, setAddArticle] = useState(false)

  const saveArticle = (data) => {
    setAddArticle(!addArticle)
    data.id = Allinfo._id
    const create = async () => {
      const response = await axios.patch(`${window.$apiEndpoint}/fornitori/insert-articolo`,
        data
        , {
          headers: {
            "x-access-token": cookies.token
          }
        })
      setAddArticle(!addArticle)
      reset()
      fetch()
    }
    create()
  }


  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu attivo={"Fornitori"} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">


          <div className="py-6">
           
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">


               <article>

              {/* Profile header */}
              <div >
                <div className="pt-3 mt-14">
                <ReactTooltip />
                  
                </div>
                <div className="max-w-5xl mx-auto px-4 mt-16 sm:px-6 lg:px-8">
                  <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex">
                      <img
                        className="h-20 w-22 rounded-full ring-4 ring-white sm:h-22 sm:w-22"
                          src={avatar}
                        alt="Immagine di profilo"
                      />
                    <ReactTooltip />

                    </div>
                    <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                      <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                                                  <h1 className="text-2xl mt-3 font-bold text-gray-900 truncate">Ciao</h1>
                          <p className="text-sm	 text-gray-500 capitalize" ><div className="flex"><IdentificationIcon className='h-4 w-4 mr-2 mt-1' />{Allinfo.vat}</div></p>
                      </div>
                      <div className="mt-8 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <a href={`mailto:${Allinfo.email}`}><button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        >
                          <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>Contatta</span>
                        </button></a>
                        <a href={`tel:${Allinfo.tel}`}><button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        >
                          <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span>Chiama</span>
                        </button></a>
                      </div>
                    </div>
                  </div>
                  <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl mt-3 font-bold text-gray-900 truncate">{Allinfo.ragioneSociale}</h1>
                  <p className="text-sm	 text-gray-500 capitalize" >{Allinfo.vat}</p>
                  </div>
                </div>
              </div>

              {/* Tabs */}


              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
                    <TabList className="-mb-px flex cursor-pointer lg:space-x-8 md:space-x-6 border-b border-gray-200 sm:space-x-1" style={{marginBottom: '1px'}} aria-label="Tabs">
                      {tabs.map((tab, index) => (
                        <>
                        <Tab className={classNames(
                            (activeTab === tab.name)
                              ? 'border-pink-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                          )}
                          aria-current={tab.current ? 'page' : undefined}>
                        
                          {tab.name}

                          </Tab>
                        </>
                      ))}
                      </TabList>


                      <TabPanel>
                      {/* DETTAGLI */}
                            <div className="mt-6 max-w-5xl mx-auto px-1 py-6 mb-6">
                            <div className="flex flex-row-reverse my-2">
                              <a className="flex" onClick={() => setViewEdit(!viewEdit)} href="#">
                                <PencilAltIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Modifica Profilo</p>
                              </a>
                            </div>
                              {loading ?  
                              <>{viewEdit ? <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Ragione sociale</dt>
                                  <dd
                                    className="mt-1 max-w-7xl text-justify	 text-sm text-gray-900 space-y-5"
                                    dangerouslySetInnerHTML={{ __html: Allinfo.ragioneSociale }}
                                  />
                                </div>
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">P.IVA / CF</dt>
                                  <dd
                                    className="mt-1 max-w-7xl text-justify	 text-sm text-gray-900 space-y-5"
                                    dangerouslySetInnerHTML={{ __html: Allinfo.vat }}
                                  />
                                </div>
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Indirizzo</dt>
                                  <dd
                                    className="mt-1 max-w-7xl text-justify	 text-sm text-gray-900 space-y-5"
                                    dangerouslySetInnerHTML={{ __html: Allinfo.address + " ," + Allinfo.civic + " - " + Allinfo.postalCode + " " + Allinfo.city + " (" + Allinfo.region + ")" }}
                                  />
                                </div>
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                                  <dd
                                    className="mt-1 max-w-7xl text-justify	 text-sm text-gray-900 space-y-5"
                                    dangerouslySetInnerHTML={{ __html: Allinfo.email }}
                                  />
                                </div>
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Telefono</dt>
                                  <dd
                                    className="mt-1 max-w-7xl text-justify	 text-sm text-gray-900 space-y-5"
                                    dangerouslySetInnerHTML={{ __html: Allinfo.tel ? Allinfo.tel : `<span style={{color:'orange', fontWeight: 'bold'}}>Inserire valore</span>` }}
                                  />
                                </div>
                              </dl> : <form onSubmit={handleSubmitEdit(onSubmitEdit)}>

                                  <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                      <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                      <div className="mt-2">
                                        <div className="grid grid-cols-6 gap-6">
                                          <div className="col-span-6 sm:col-span-6">
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                              Ragione sociale
                                            </label>
                                            <input
                                              type="text"
                                              name="fname"
                                              id="first-name"
                                              defaultValue={Allinfo.ragioneSociale}
                                              {...registerEdit("ragioneSociale", { required: true })}

                                              autoComplete="given-name"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.ragioneSociale && <p>Il campo è obbligatorio</p>}

                                          </div>

                                          <div className="col-span-6 sm:col-span-6">
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                              P.IVA / CF
                                            </label>
                                            <input
                                              type="text"
                                              name="vat"
                                              id="vat"
                                              defaultValue={Allinfo.vat}
                                              {...registerEdit("vat", { required: true })}

                                              autoComplete="given-name"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.vat && <p>Il campo è obbligatorio</p>}

                                          </div>

                                          <div className="col-span-6 sm:col-span-6">
                                            <label htmlFor="indirizzoEmail" className="block text-sm font-medium text-gray-700">
                                              Indirizzo Email
                                            </label>
                                            <input
                                              type="email"
                                              defaultValue={Allinfo.email}
                                              {...registerEdit("email", { required: false })}
                                              name="email"
                                              id="email"
                                              autoComplete="email"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.email && <p>Il campo è obbligatorio</p>}

                                          </div>


                                          <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                                            <label htmlFor="numTel" className="block text-sm font-medium text-gray-700">
                                              Telefono
                                            </label>
                                            <input
                                              type="text"
                                              name="tel"
                                              id="tel"
                                              defaultValue={Allinfo.tel}
                                              {...registerEdit("tel", { required: false })}

                                              autoComplete="given-name"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.tel && <p>Il campo è obbligatorio</p>}

                                          </div>


                                          <div className="col-span-6 lg:col-span-4">
                                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                              Indirizzo
                                            </label>
                                            <input
                                              type="text"
                                              name="street-address"
                                              id="street-address"
                                              defaultValue={Allinfo.address}
                                              {...registerEdit("address", { required: false })}
                                              autoComplete="street-address"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.address && <p>Il campo è obbligatorio</p>}

                                          </div>

                                          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="civico" className="block text-sm font-medium text-gray-700">
                                              Civico
                                            </label>
                                            <input
                                              type="text"
                                              name="civico"
                                              defaultValue={Allinfo.civic}
                                              {...registerEdit("civic", { required: false })}
                                              id="civico"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.civic && <p>Il campo è obbligatorio</p>}
                                          </div>

                                          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="citta" className="block text-sm font-medium text-gray-700">
                                              Città
                                            </label>
                                            <input
                                              type="text"
                                              name="citta"
                                              id="citta"
                                              defaultValue={Allinfo.city}
                                              {...registerEdit("city", { required: false })}
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.city && <p>Il campo è obbligatorio</p>}

                                          </div>

                                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="provincia" className="block text-sm font-medium text-gray-700">
                                              Provincia
                                            </label>
                                            <input
                                              type="text"
                                              name="provincia"
                                              id="provincia"
                                              defaultValue={Allinfo.region}
                                              {...registerEdit("region", { required: false })}
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.region && <p>Il campo è obbligatorio</p>}

                                          </div>

                                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="cap" className="block text-sm font-medium text-gray-700">
                                              CAP
                                            </label>
                                            <input
                                              type="text"
                                              name="postalCode"
                                              id="postalCode"
                                              autoComplete="postalCode"
                                              defaultValue={Allinfo.postalCode}
                                              {...registerEdit("postalCode", { required: false })}
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.postalCode && <p>Il campo è obbligatorio</p>}
                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                      type="submit"
                                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                    >
                                      Modifica
                                    </button>
                                    <button
                                      type="reset"
                                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    >
                                      Annulla
                                    </button>
                                  </div>
                                </form>}</> : null}
                             
                              </div>

                          </TabPanel>

                          <TabPanel>

                          {/* PREVENTIVI*/}

                          <div className="bg-white">
                          <div className="mx-auto py-5 px-0 max-w-7xl sm:px-6 sm:py-10 lg:px-2 lg:py-10">
                            <div className="space-y-10">
                                {!addArticle ? <div className="flex justify-end mt-5 mb-5">
                                  <button
                                    type="button"
                                    onClick={() => setAddArticle(!addArticle)}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    Nuovo articolo
                                  </button>
                                </div> : null}
                                {addArticle ? <div className="mt-5 md:mt-0 md:col-span-2">
                                  <form onSubmit={handleSubmitArticle(saveArticle)}>
                                    <div className="grid grid-cols-6 gap-6">

                                      <div className="col-span-4 sm:col-span-4">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                          Articolo<strong><span style={{ color: 'red' }}>*</span></strong>
                                        </label>
                                        <input
                                          type="text"
                                          name="name"
                                          id="name"
                                          {...registerArticle("name", { required: true })}
                                          autoComplete="name"
                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errorsArticle.name && <p>Il campo è obbligatorio</p>}

                                      </div>


                                      <div className="col-span-2 sm:col-span-2">
                                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                          Prezzo <small><strong>IVA esclusa</strong></small>
                                        </label>
                                        <input
                                          type="number"
                                          name="price"
                                          step="0.01"
                                          {...registerArticle("price", { required: false })}
                                          id="price"
                                          autoComplete="price"
                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errorsArticle.price && <p>Il campo è obbligatorio</p>}
                                      </div>

                                     


                                    </div>

                                    <div className="flex justify-end mt-5">
                                      <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                      >
                                        Aggiungi
                                      </button>
                                    </div>
                                  </form>
                                </div> : null}
                            
                                {loading && Allinfo.articoli.length > 0 ? <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Articolo
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Prezzo €
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Operazioni
                                      </th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? <>{Allinfo.articoli.map((articolo, personIdx) => (
                                      <tr key={articolo.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{articolo.name}</td>
                                        
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {articolo.price}
                                          </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                          <div className="flexxo">
                                            <ReactTooltip />
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setToBeDeleted(articolo)
                                                  setAdvModal(!advModal)
                                                }}
                                                className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                              >
                                                <TrashIcon data-tip="Elimina" className="h-4 w-4" aria-hidden="true" />
                                              </button>
                                          </div>
                                          </td>
                                          
                                      </tr>
                                    ))}</> : null}
                                  </tbody>
                                </table> : 'Non sono presenti articoli'}
                            </div>
                          </div>
                        </div>
                       
                          </TabPanel>
                          
                          
                          
                         

                          
                    </Tabs>
                  </div>
                </div>
              </div>

              

              </article>




               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    {/** Modale per conferma predefinita oppure cancella immagine*/}

    <Transition.Root show={openModalDet} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={openModalDet} onClose={setopenModalDet}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <p className="text-sm items-center justify-center text-gray-800">Che cosa vuoi fare?</p>
              <div>
                <div className="mt-0 text-center sm:mt-5">
                  <div className="mt-0">
                  <button
                    onClick={() => console.log(immagineCliccata)}
                    type="button"
                    className="inline-flex items-center mx-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancellare
                  </button>

                  <button
                    onClick={() => console.log(immagineCliccata)}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Settare predefinita
                  </button>

                  <div className="space-y-1 text-center">
                    
                    <p className="text-sm text-green-600 mt-3" >{showPreSucc}</p>
                    <p className="text-sm text-red-600 mt-3" >{showPreErr}</p>

                  </div>

                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setopenModalDet(false)}
                >
                  Torna al tuo profilo
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


   


    {/* Modale di modifica informazioni azienda */}

    <Transition.Root show={openModalUpdateInfo2} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full  fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalUpdateInfo}
        onClose={setOpenModalUpdateInfo}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>
                
                <div className="mt-3 sm:mt-5">
                 
                  <div className="mt-2">
                    


                    {/* Corpo del modale */}

                    <div className="space-y-6">
      

      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Informazioni Profilo</h3>
            <p className="mt-1 text-sm text-gray-500">Use i seguenti campi per modificare le informazioni del tuo profilo.</p>

            { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel aggiornamento dei dati! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }
                  
                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Dati profilo aggironati con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Ragione sociale
                  </label>
                  <input
                    type="text"
                    name="fname"
                    id="first-name"
                    defaultValue={Allinfo.ragioneSociale}
                    {...register("fname", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.ragioneSociale && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="indirizzoEmail" className="block text-sm font-medium text-gray-700">
                    Indirizzo Email
                  </label>
                  <input
                    type="email"
                    defaultValue={Allinfo.email}
                    disabled={true}
                    name="indirizzoEmail"
                    id="indirizzoEmail"
                    autoComplete="email"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <input
                    type="hidden"
                    defaultValue={Allinfo.email}
                    {...register("email", { required: true })}
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.email && <p>Il campo è obbligatorio</p>}

                </div>


                <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                  <label htmlFor="numTel" className="block text-sm font-medium text-gray-700">
                    Numero di Telefono
                  </label>
                  <input
                    type="text"
                    name="numTel"
                    id="numTel"
                    defaultValue={Allinfo.numero_telefono}
                    {...register("numTel", { required: true })}

                    autoComplete="given-name"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.numTel && <p>Il campo è obbligatorio</p>}

                </div>

                
                <div className="col-span-6 lg:col-span-4">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                    Indirizzo
                  </label>
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    defaultValue={Allinfo.indirizzo}
                    {...register("indirizzo", { required: true })}
                    autoComplete="street-address"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.indirizzo && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="civico" className="block text-sm font-medium text-gray-700">
                    Civico
                  </label>
                  <input
                    type="text"
                    name="civico"
                    defaultValue={Allinfo.civico}
                    {...register("civico", { required: true })}
                    id="civico"
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.civico && <p>Il campo è obbligatorio</p>}
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="citta" className="block text-sm font-medium text-gray-700">
                    Città
                  </label>
                  <input
                    type="text"
                    name="citta"
                    id="citta"
                    defaultValue={Allinfo.citta}
                    {...register("citta", { required: true })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.citta && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="provincia" className="block text-sm font-medium text-gray-700">
                    Provincia
                  </label>
                  <input
                    type="text"
                    name="provincia"
                    id="provincia"
                    defaultValue={Allinfo.provincia}
                    {...register("provincia", { required: true })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.provincia && <p>Il campo è obbligatorio</p>}

                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="cap" className="block text-sm font-medium text-gray-700">
                    Cap
                  </label>
                  <input
                    type="text"
                    name="cap"
                    id="cap"
                    autoComplete="cap"
                    defaultValue={Allinfo.cap}
                    {...register("cap", { required: true })}
                    className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  {errors.cap && <p>Il campo è obbligatorio</p>}
                </div>

                
               

              </div>
              <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="submit"
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Profilo
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalUpdateInfo(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>



              <Transition.Root show={openModalUpdateInfo} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenModalUpdateInfo}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <form onSubmit={handleSubmit(onSubmit)}>

                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <div className="mt-2">
                                  <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-6">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Ragione sociale
                                      </label>
                                      <input
                                        type="text"
                                        name="fname"
                                        id="first-name"
                                        defaultValue={Allinfo.ragioneSociale}
                                        {...register("ragioneSociale", { required: true })}

                                        autoComplete="given-name"
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.ragioneSociale && <p>Il campo è obbligatorio</p>}

                                    </div>

                                    <div className="col-span-6 sm:col-span-6">
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        P.IVA / CF
                                      </label>
                                      <input
                                        type="text"
                                        name="vat"
                                        id="vat"
                                        defaultValue={Allinfo.vat}
                                        {...register("vat", { required: true })}

                                        autoComplete="given-name"
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.vat && <p>Il campo è obbligatorio</p>}

                                    </div>

                                    <div className="col-span-6 sm:col-span-6">
                                      <label htmlFor="indirizzoEmail" className="block text-sm font-medium text-gray-700">
                                        Indirizzo Email
                                      </label>
                                      <input
                                        type="email"
                                        defaultValue={Allinfo.email}
                                        {...register("email", { required: false })}
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.email && <p>Il campo è obbligatorio</p>}

                                    </div>


                                    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                                      <label htmlFor="numTel" className="block text-sm font-medium text-gray-700">
                                        Telefono
                                      </label>
                                      <input
                                        type="text"
                                        name="tel"
                                        id="tel"
                                        defaultValue={Allinfo.tel}
                                        {...register("tel", { required: false })}

                                        autoComplete="given-name"
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.tel && <p>Il campo è obbligatorio</p>}

                                    </div>


                                    <div className="col-span-6 lg:col-span-4">
                                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                        Indirizzo
                                      </label>
                                      <input
                                        type="text"
                                        name="street-address"
                                        id="street-address"
                                        defaultValue={Allinfo.address}
                                        {...register("address", { required: false })}
                                        autoComplete="street-address"
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.address && <p>Il campo è obbligatorio</p>}

                                    </div>

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                      <label htmlFor="civico" className="block text-sm font-medium text-gray-700">
                                        Civico
                                      </label>
                                      <input
                                        type="text"
                                        name="civico"
                                        defaultValue={Allinfo.civic}
                                        {...register("civic", { required: false })}
                                        id="civico"
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.civic && <p>Il campo è obbligatorio</p>}
                                    </div>

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                      <label htmlFor="citta" className="block text-sm font-medium text-gray-700">
                                        Città
                                      </label>
                                      <input
                                        type="text"
                                        name="citta"
                                        id="citta"
                                        defaultValue={Allinfo.city}
                                        {...register("city", { required: false })}
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.city && <p>Il campo è obbligatorio</p>}

                                    </div>

                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                      <label htmlFor="provincia" className="block text-sm font-medium text-gray-700">
                                        Provincia
                                      </label>
                                      <input
                                        type="text"
                                        name="provincia"
                                        id="provincia"
                                        defaultValue={Allinfo.region}
                                        {...register("region", { required: false })}
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.region && <p>Il campo è obbligatorio</p>}

                                    </div>

                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                      <label htmlFor="cap" className="block text-sm font-medium text-gray-700">
                                        CAP
                                      </label>
                                      <input
                                        type="text"
                                        name="postalCode"
                                        id="postalCode"
                                        autoComplete="postalCode"
                                        defaultValue={Allinfo.postalCode}
                                        {...register("postalCode", { required: false })}
                                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                      />
                                      {errors.postalCode && <p>Il campo è obbligatorio</p>}
                                    </div>

                                  </div>
                                
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                            >
                              Modifica
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => setOpenModalUpdateInfo(!openModalUpdateInfo)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
    </Transition.Root>

              <Transition.Root show={successModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Evviva!
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Operazione effettuata con successo.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                              onClick={() => setSuccessModal(!successModal)}
                            >
                              Continua
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
    </Transition.Root>

              <Transition.Root show={advModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Attenzione!
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Stai per eliminare l'articolo, vuoi procedere?
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                deleteArticolo(toBeDeleted)
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setToBeDeleted(null)
                                setAdvModal(!advModal)
                              }}
                            >
                              Annulla
                            </button>
                           
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
    </Transition.Root>


            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
