import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  TruckIcon,
  EyeIcon,
  EyeOffIcon,
  MenuIcon,

  ExclamationIcon,
    BeakerIcon,
  CheckIcon,
  TrashIcon,
  ExclamationCircleIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon, PlusIcon, } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from "react-select";

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'

//Importo Menu
import Menu from "./navigation_merchant";
import SidebarMenu from "./sidebar_menu"
import { propTypes } from 'react-bootstrap/esm/Image';

import avatar from "../assets/images/container.jpeg"
import { AllInboxOutlined, SignalCellularNullRounded } from '@material-ui/icons';

import moment from "moment"
import { reset } from 'react-tabs/lib/helpers/uuid';


const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function ContainerProfilo(props) {
  //Setto gli state
  const [see, setSee] = useState(false)
  const [Allinfo, setAllInfo] = useState({});

  const [loading, setLoading] = useState(false)
  const [immagineCliccata, setImmagineCliccata] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openModalDet, setopenModalDet] = useState(false)
  const [openModalUploadNegozio, setOpenModalUploadNegozio] = useState(false)
  const [openModalUpdateInfo, setOpenModalUpdateInfo] = useState(false)
  const [openModalUpdateInfo2, setOpenModalUpdateInfo2] = useState(false)
  const [openModalUpdateMenu, setOpenModalUpdateMenu] = useState(false)
  const [openModalUpdateOrari, setOpenModalUpdateOrari] = useState(false)
  const [selectedCliente, setSelectedCliente] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [editModalMassive, setEditModalMassive] = useState(false)
  const [editModalRestore, setEditModalRestore] = useState(false)
  const [toBeEdited, setToBeEdited] = useState(null)
  const [leaveModal, setLeaveModal] = useState(false)
  const [leaveModalMassive, setLeaveModalMassive] = useState(false)
  const [toBeLeave, setToBeLeave] = useState(null)
  const [articoli, setArticoli] = useState([])

  const [predefinita, setPredefinita] = useState(true);

  const [clienti, setClienti] = useState([])


  const fetch = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/container/profilo`, {
      params: {
        id: props.match.params.id,
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    const cli = await axios.get(`${window.$apiEndpoint}/container/clienti`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setAllInfo(response.data)
      setArticoli(response.data.articoli)
    setClienti(cli.data)
    setLoading(true)
  }





  useEffect(() => {
    let mounted = true
    if(mounted) {
      fetch()
    }
    return(() => {
      mounted = false
    })
  }, [see])




  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: registerEdit, handleSubmit: handleSubmitEdit, formState: { errors: errorsEdit } } = useForm();
  const { register: registerArticle, handleSubmit: handleSubmitArticle, formState: { errors: errorsArticle } } = useForm();

  const [tabIndex, setTabIndex] = useState(1);

  const [activeTab, setActiveTab] = useState('Articoli')
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [today, setToday] = useState(moment().format("YYYY-MM-DD"))

  const [showPreSucc, setPreSucc] = useState('');
  const [showPreErr, setPreErr] = useState('');
  const [show, setShow] = useState(false)

  const tabs = [
    { name: 'Dettagli'},
    { name: 'Articoli'},
  ]
  
  
 const changeTab = (index) => {
  setTabIndex(index)
   if(index === 0) {
     setActiveTab('Dettagli')
   } else if(index === 1) {
     setActiveTab('Articoli')
   }
  }
  

  
  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])

 
  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    
  }


  const CallUpdateInfo = () => {
    setOpenModalUpdateInfo(true);
  }

  const CallUploadNegozio = () => {
    setOpenModalUploadNegozio(true);
  }



  const CallUpdateOrari = () => {
    setOpenModalUpdateOrari(true);
  }

  const CallUpdateMenu = () => {
    setOpenModalUpdateMenu(true);
  }

  const CallUpdateDet = (nome_immagine) => {
  //Apro il modale per cancellare o rendere predefinita
  setopenModalDet(true);
  setImmagineCliccata(nome_immagine);
  }

  //Recupero Controllo completamento del profilo



  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {
      
      return "Chiuso";

    } else {

      return string;

    
    }
  }


  const [viewEdit, setViewEdit] = useState(true)
  const [advModal, setAdvModal] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)


  const onSubmitEdit = data => {
    data.id = Allinfo._id
    const editprofile = async () => {
      const response = await axios.patch(`${window.$apiEndpoint}/container/edit`, 
        data
      , {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setSuccessModal(true)
      setViewEdit(true)
    }
    editprofile()
    fetch()

  }

  const onSubmit = data => {
    console.log(data)
  }




  const deleteArticolo = async (articolo) => {
    if(toBeDeleted) {
      const create = async () => {
        const response = await axios.patch(`${window.$apiEndpoint}/container/remove-articolo`,
          {
            id: Allinfo._id,
            articoloID: articolo.id
          }
          , {
            headers: {
              "x-access-token": cookies.token
            }
          })
        setToBeDeleted(null)
        setAdvModal(!advModal)
        fetch()
      }
      create()
    }
  }

  const editArticolo = async (data) => {
    if (toBeEdited) {
      const response = await axios.patch(`${window.$apiEndpoint}/container/edit-articolo`, {
        articolo: toBeEdited,
        cliente: Allinfo
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBeEdited(null)
      setEditModal(!editModal)
      fetch()
    }
  }

   const editArticoloMassive = async (data) => {
    if (massive.length > 0 && massiveStart && massiveEnd) {
      const response = await axios.patch(`${window.$apiEndpoint}/container/edit-articolo-massivo`, {
        articoli: massive,
        cliente: Allinfo,
        start: massiveStart,
        end: massiveEnd
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      window.location.reload()
      setEditModalMassive(!editModalMassive)
      fetch()
    }
  }



  const [successModal, setSuccessModal] = useState(false)
  const [addArticle, setAddArticle] = useState(false)

  const checkOccupation =  async (cliente) => {
    const response = await axios.get(`${window.$apiEndpoint}/container/occupazione`, {
      params: {
        id: props.match.params.id,
        cliente: cliente
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    let occupation = response.data.occupation
    return response.data.occupation
  }

  const saveArticle = async (data) => {
    setAddArticle(!addArticle)
    data.id = Allinfo._id
    data.cliente = selectedCliente
    
    let check = await checkOccupation(selectedCliente.value) 
    if (check === false) {
      const create = async () => {
        const response = await axios.patch(`${window.$apiEndpoint}/container/insert-articolo`,
          data
          , {
            headers: {
              "x-access-token": cookies.token
            }
          })
        setAddArticle(!addArticle)
        reset()
        fetch()
      }
    create()
    } else {
      alert("Container occupato, impossibile aggiungere nuovo cliente")
    }

    
  }

  const leaveArticolo = async (data) => {
    if (toBeLeave) {
      const response = await axios.patch(`${window.$apiEndpoint}/container/leave-articolo`, {
        articolo: toBeLeave,
        cliente: Allinfo
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBeLeave(null)
      setLeaveModal(!leaveModal)
      fetch()
      setSuccessModal(true)
    }
  }


  const [massive, setMassive] = useState([])
  const [massiveStart, setMassiveStart] = useState(null)
  const [massiveEnd, setMassiveEnd] = useState(null)
  const [leaveDate, setLeaveDate] = useState(null)




  const addOrRemoveToMassive = articolo => {
    let find = massive.find(art => art.id === articolo.id) 
    if(find) {
       setMassive((current) => current.filter((item) => item.id !== articolo.id))
    } else {
        setMassive(old => [...old, articolo])
    }

  }

  const addOrRemoveToMassiveArray = checked => {
    if(checked) {
        setMassive(articoli.filter(articolo => (see ? true : articolo.awk === false)))
    } else {
        setMassive([])
    }
    

  }

    const leaveArticoloMassive = async () => {
        if (leaveDate) {
            const response = await axios.patch(`${window.$apiEndpoint}/container/leave-articolo-massive`, {
                articoli: massive,
                cliente: Allinfo,
                leave: leaveDate

            }, {
                headers: {
                    "x-access-token": cookies.token
                }
            })
            setMassive([])
            setLeaveModalMassive(!leaveModalMassive)
            fetch()
            setSuccessModal(true)
        }
    }

    const restoreArticoloMassive = async () => {
            const response = await axios.patch(`${window.$apiEndpoint}/container/restore-articolo-massive`, {
                articoli: massive,
                cliente: Allinfo,

            }, {
                headers: {
                    "x-access-token": cookies.token
                }
            })
            setMassive([])
            setEditModalRestore(!editModalRestore)
            fetch()
            setSuccessModal(true)
    }



  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu attivo={"Container"} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">


          <div className="py-6">
           
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">


               <article>

              {/* Profile header */}
              <div >
                <div className="pt-3 mt-14">
                <ReactTooltip />
                  
                </div>
                <div className="max-w-5xl mx-auto px-4 mt-16 sm:px-6 lg:px-8">
                  <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex">
                      <img
                        className="h-20 w-22 rounded-full ring-4 ring-white sm:h-22 sm:w-22"
                          src={avatar}
                        alt="Immagine di profilo"
                      />
                    <ReactTooltip />

                    </div>
                    <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                      <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                                                  <h1 className="text-2xl mt-3 font-bold text-gray-900 truncate">{Allinfo.name}</h1>
                                                  <p className="text-sm	 text-gray-500 capitalize" >UID: {Allinfo._id}</p>
                      </div>
                    </div>
                  </div>
                  <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                <h1 className="text-2xl mt-3 font-bold text-gray-900 truncate">{Allinfo.name}</h1>
                <p className="text-sm	 text-gray-500 capitalize" >UID: {Allinfo._id}</p>
                  </div>
                </div>
              </div>

              {/* Tabs */}


              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
                    <TabList className="-mb-px flex cursor-pointer lg:space-x-8 md:space-x-6 border-b border-gray-200 sm:space-x-1" style={{marginBottom: '1px'}} aria-label="Tabs">
                      {tabs.map((tab, index) => (
                        <>
                        <Tab className={classNames(
                            (activeTab === tab.name)
                              ? 'border-pink-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                          )}
                          aria-current={tab.current ? 'page' : undefined}>
                        
                          {tab.name}

                          </Tab>
                        </>
                      ))}
                      </TabList>

                      <TabPanel>
                      {/* DETTAGLI */}
                            <div className="mt-6 max-w-5xl mx-auto px-1 py-6 mb-6">
                            <div className="flex flex-row-reverse my-2">
                              <a className="flex" onClick={() => setViewEdit(!viewEdit)} href="#">
                                <PencilAltIcon className="flex -ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <p className="flex text-sm font-bold text-gray-600 space-y-5 hover:text-blue-900"> Modifica</p>
                              </a>
                            </div>
                              {loading ?  
                              <>{viewEdit ? <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Nome</dt>
                                  <dd
                                    className="mt-1 max-w-7xl text-justify	 text-sm text-gray-900 space-y-5"
                                    dangerouslySetInnerHTML={{ __html: Allinfo.name }}
                                  />
                                </div>                               
                                
                              </dl> : <form onSubmit={handleSubmitEdit(onSubmitEdit)}>

                                  <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                      <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                      <div className="mt-2">
                                        <div className="grid grid-cols-6 gap-6">
                                          <div className="col-span-6 sm:col-span-6">
                                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                              Nome
                                            </label>
                                            <input
                                              type="text"
                                              name="name"
                                              id="name"
                                              defaultValue={Allinfo.name}
                                              {...registerEdit("name", { required: true })}

                                              autoComplete="given-name"
                                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsEdit.name && <p>Il campo è obbligatorio</p>}

                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                      type="submit"
                                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                    >
                                      Modifica
                                    </button>
                                    <button
                                      type="reset"
                                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    >
                                      Annulla
                                    </button>
                                  </div>
                                </form>}</> : null}
                             
                              </div>

                          </TabPanel>

                          <TabPanel>

                          {/* PREVENTIVI*/}

                          <div className="bg-white">
                          <div className="mx-auto py-5 px-0 max-w-7xl sm:px-6 sm:py-10 lg:px-2 lg:py-10">
                            <div className="space-y-10">
                                {!addArticle ? <div className="flex justify-end mt-5 mb-5">
                                  {see ? <button
                                    type="button"
                                    onClick={() => setSee(false)}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                                                      <EyeOffIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" />
                                                                  </button> : <button
                                                                      type="button"
                                                                      onClick={() => setSee(true)}
                                                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                  >
                                                                      <EyeIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" />
                                                                  </button>}
                                  <button
                                    type="button"
                                    onClick={() => setAddArticle(!addArticle)}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    Nuovo articolo
                                  </button>
                                                                  {massive.length > 0 ? <button
                                                                      type="button"
                                                                      onClick={() => {
                                                                          setEditModalMassive(!editModalMassive)
                                                                      }}
                                                                      className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                  >
                                                                      <PencilAltIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" /> Modifica MAX
                                                                  </button> : null}
                                                                  {massive.length > 0 ? <button
                                                                      type="button"
                                                                      onClick={() => {
                                                                          setLeaveModalMassive(!leaveModalMassive)
                                                                      }}
                                                                      className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                  >
                                                                      <TruckIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" /> Uscita MAX
                                                                  </button> : null}
                                                                  {massive.length > 0 ? <button
                                                                      type="button"
                                                                      onClick={() => {
                                                                          setEditModalRestore(!editModalRestore)
                                                                      }}
                                                                      className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                  >
                                                                      <BeakerIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" /> Restore MAX
                                                                  </button> : null}
                                </div> : null}
                                {addArticle ? <div className="mt-5 md:mt-0 md:col-span-2">
                                  <form onSubmit={handleSubmitArticle(saveArticle)}>
                                    <div className="grid grid-cols-6 gap-6">


                                      <div className="col-span-3 sm:col-span-3">
                                        <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                          Cliente
                                        </label>
                                        <Select
                                          options={clienti}
                                          onChange={e => setSelectedCliente(e)}
                                          placeholder="Seleziona"
                                        />

                                      </div>

                                      <div className="col-span-3 sm:col-span-3">
                                        <div className="grid grid-cols-6 gap-6">
                                          <div className="col-span-3 sm:col-span-3">
                                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                              Dal<strong><span style={{ color: 'red' }}>*</span></strong>
                                            </label>
                                            <input
                                              type="date"
                                              defaultValue={today}
                                              onChange={e => setToday(moment(e.target.value).format("YYYY-MM-DD"))}
                                              name="from"
                                              id="from"
                                              {...registerArticle("from", { required: true })}
                                              autoComplete="from"
                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsArticle.from && <p>Il campo è obbligatorio</p>}

                                          </div>
                                          <div className="col-span-3 sm:col-span-3">
                                            <label htmlFor="to" className="block text-sm font-medium text-gray-700">
                                              Al<strong><span style={{ color: 'red' }}>*</span></strong>
                                            </label>
                                            <input
                                              type="date"
                                              name="to"
                                              defaultValue={moment(today).add(7, 'days').format("YYYY-MM-DD")}
                                              id="to"
                                              {...registerArticle("to", { required: true })}
                                              autoComplete="to"
                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            {errorsArticle.to && <p>Il campo è obbligatorio</p>}

                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                          Articolo<strong><span style={{ color: 'red' }}>*</span></strong>
                                        </label>
                                        <input
                                          type="text"
                                          name="name"
                                          id="name"
                                          {...registerArticle("name", { required: true })}
                                          autoComplete="name"
                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errorsArticle.name && <p>Il campo è obbligatorio</p>}

                                      </div>

                                    </div>

                                    <div className="flex justify-end mt-2 mb-5">
                                      <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                      >
                                        Aggiungi
                                      </button>
                                                                        
                                    </div>
                                  </form>
                                </div> : null}
                            
                                {loading && articoli.length > 0 ? <table className=" divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        <input type="checkbox" onChange={e => addOrRemoveToMassiveArray(e.target.checked)} />
                                      </th>
                                      {see ? <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Uscito
                                      </th> : null}
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Cliente
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Articolo
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Periodo
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Operazioni
                                      </th>

                                    </tr>

                                  </thead>
                                  <tbody>
                                    {loading ? <>{articoli.filter(articolo => (see ? true : articolo.awk === false)).map((articolo, personIdx) => (
                                      <tr key={articolo.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"><input type="checkbox" checked={massive.find(obj => obj.id === articolo.id)} onChange={e => addOrRemoveToMassive(articolo)}/></td>
                                            {see ? <td className="px-6 py-4  text-sm text-gray-500 text-ellipsis">{articolo.awk ? <>{articolo.leave}</> : ""}</td> : null}
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-ellipsis"><Link to={`/clienti/${articolo.clienteID}`}>{articolo.clienteRagioneSociale}</Link></td>
                                            <td className="px-6 py-4  text-sm text-gray-500 text-ellipsis">{articolo.name}</td>
                                            <td className="px-6 py-4  text-sm text-gray-500 text-ellipsis">{moment(articolo.from).format("DD/MM/YYYY") + " - " + moment(articolo.to).format("DD/MM/YYYY") + " (" + moment(articolo.to).diff(moment(new Date()), 'days') + " giorni rimanenti)"}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                          <div className="flexxo">
                                            <ReactTooltip />
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setToBeEdited(articolo)
                                                setEditModal(!editModal)
                                              }}
                                              className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                              <PencilAltIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" />
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setToBeLeave(articolo)
                                                setLeaveModal(!editModal)
                                              }}
                                              className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                              <TruckIcon data-tip="Uscita articolo" className="h-4 w-4" aria-hidden="true" />
                                            </button>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setToBeDeleted(articolo)
                                                  setAdvModal(!advModal)
                                                }}
                                                className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                              >
                                                <TrashIcon data-tip="Elimina" className="h-4 w-4" aria-hidden="true" />
                                              </button>
                                          </div>
                                          </td>
                                          
                                      </tr>
                                    )).sort((a, b) => moment(a.to) - moment(b.to)) }</> : null}
                                  </tbody>
                                </table> : 'Non sono presenti articoli'}
                            </div>
                          </div>
                        </div>
                       
                          </TabPanel>
                          
                          
                          
                         

                          
                    </Tabs>
                  </div>
                </div>
              </div>

              

              </article>




               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


              <Transition.Root show={successModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Evviva!
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Operazione effettuata con successo.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                              onClick={() => setSuccessModal(!successModal)}
                            >
                              Continua
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
    </Transition.Root>

              <Transition.Root show={advModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Attenzione!
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Stai per eliminare l'articolo, vuoi procedere?
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                deleteArticolo(toBeDeleted)
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setToBeDeleted(null)
                                setAdvModal(!advModal)
                              }}
                            >
                              Annulla
                            </button>
                           
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
    </Transition.Root>


              <Transition.Root show={leaveModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setLeaveModal(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <TruckIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Uscita container
                              </Dialog.Title>
                              {toBeLeave ? <div className="mt-2">
                                <div className="grid grid-cols-6 gap-6">

                                  
                                  <div className="col-span-6 sm:col-span-6">
                                    <label htmlFor="to" className="block text-left  text-sm font-medium text-gray-700">
                                      Uscita<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="date"
                                      name="to"
                                      id="to"
                                      onChange={
                                        e => setToBeLeave(params => ({
                                          ...params,
                                          leave: e.target.value,
                                        }))
                                      }
                                      autoComplete="to"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                  </div>




                                </div>
                              </div> : null}
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                leaveArticolo(toBeLeave)
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setToBeLeave(null)
                                setLeaveModal(!leaveModal)
                              }}
                            >
                              Annulla
                            </button>

                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>


              {massive.length > 0 ? <Transition.Root show={leaveModalMassive} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setLeaveModalMassive(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <TruckIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Uscita container massiva
                              </Dialog.Title>
                              {massive.length > 0 ? <div className="mt-2">
                                <div className="grid grid-cols-6 gap-6">

                                  
                                  <div className="col-span-6 sm:col-span-6">
                                    <label htmlFor="to" className="block text-left  text-sm font-medium text-gray-700">
                                      Uscita<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="date"
                                      name="to"
                                      id="to"
                                      onChange={
                                        e => setLeaveDate(e.target.value)
                                      }
                                      autoComplete="to"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                  </div>




                                </div>
                              </div> : null}
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                leaveArticoloMassive()
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setLeaveModalMassive(false)
                                setMassive([])

                              }}
                            >
                              Annulla
                            </button>

                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root> : null}


              <Transition.Root show={editModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Modifica
                              </Dialog.Title>
                              {toBeEdited ? <div className="mt-2">
                                <div className="grid grid-cols-6 gap-6">

                                  

                                  <div className="col-span-6 sm:col-span-6 mt-2">
                                    <label htmlFor="ragioneSociale" className="text-left block text-sm font-medium text-gray-700 mb-1">
                                      Cliente
                                    </label>
                                    <Select
                                      options={clienti}
                                      defaultValue={{
                                        value: toBeEdited.clienteID,
                                        label: toBeEdited.clienteRagioneSociale
                                      }}
                                      onChange={
                                        e => {
                                          setToBeEdited(params => ({
                                          ...params,
                                            clienteID: e.value,
                                        }))
                                          setToBeEdited(params => ({
                                          ...params,
                                            clienteRagioneSociale: e.label,
                                        }))
                                      }
                                      }
                                      placeholder="Seleziona"
                                      className="shadow-sm border-gray-300 rounded-md text-left text-sm"
                                    />

                                  </div>

                                  <div className="col-span-6 sm:col-span-6">
                                    <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">
                                      Articolo<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="text"
                                      defaultValue={toBeEdited.name}
                                      onChange={
                                        e => setToBeEdited(params => ({
                                          ...params,
                                          name: e.target.value,
                                        }))
                                      }
                                      name="email"
                                      id="email"
                                      autoComplete="email"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                  </div>

                                  <div className="col-span-3 sm:col-span-3">
                                    <label htmlFor="name" className="block text-left  text-sm font-medium text-gray-700">
                                      Dal<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="date"
                                      defaultValue={toBeEdited.from}
                                      name="from"
                                      id="from"
                                      onChange={
                                        e => setToBeEdited(params => ({
                                          ...params,
                                          from: e.target.value,
                                        }))
                                      }
                                      autoComplete="from"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    {errorsArticle.from && <p>Il campo è obbligatorio</p>}

                                  </div>
                                  <div className="col-span-3 sm:col-span-3">
                                    <label htmlFor="to" className="block text-left  text-sm font-medium text-gray-700">
                                      Al<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="date"
                                      name="to"
                                      defaultValue={toBeEdited.to}
                                      id="to"
                                      onChange={
                                        e => setToBeEdited(params => ({
                                          ...params,
                                          to: e.target.value,
                                        }))
                                      }
                                      autoComplete="to"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    {errorsArticle.to && <p>Il campo è obbligatorio</p>}

                                  </div>




                                </div>
                              </div> : null}
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                editArticolo(toBeEdited)
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setToBeEdited(null)
                                setEditModal(!editModal)
                              }}
                            >
                              Annulla
                            </button>

                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>

              <Transition.Root show={editModalMassive} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setEditModalMassive(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Modifica
                              </Dialog.Title>
                              {massive.length > 0 ? <div className="mt-2">
                                <div className="grid grid-cols-6 gap-6">


                                  <div className="col-span-3 sm:col-span-3">
                                    <label htmlFor="name" className="block text-left  text-sm font-medium text-gray-700">
                                      Dal<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="date"
                                      name="from"
                                      id="from"
                                                                          onChange={
                                                                              e => setMassiveStart(e.target.value)
                                                                          }
                                      autoComplete="from"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                  </div>
                                  <div className="col-span-3 sm:col-span-3">
                                    <label htmlFor="to" className="block text-left  text-sm font-medium text-gray-700">
                                      Al<strong><span style={{ color: 'red' }}>*</span></strong>
                                    </label>
                                    <input
                                      type="date"
                                      name="to"
                                      id="to"
                                      onChange={
                                        e => setMassiveEnd(e.target.value)
                                      }
                                      autoComplete="to"
                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                  </div>



                                </div>
                              </div> : null}
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                editArticoloMassive()
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setMassive([])
                                setEditModalMassive(!editModalMassive)
                              }}
                            >
                              Annulla
                            </button>

                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>

              <Transition.Root show={editModalRestore} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setEditModalRestore(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                              <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Rientro oggetti 
                              </Dialog.Title>
                              <p>Attenzione stai per far <strong>rientrare</strong> gli oggetti selezionati nel container. Vuoi procedere?</p>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                              onClick={() => {
                                restoreArticoloMassive()
                              }}
                            >
                              Continua
                            </button>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setMassive([])
                                setEditModalRestore(!editModalRestore)
                              }}
                            >
                              Annulla
                            </button>

                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>



            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
