import React from 'react'
import {Switch, Route} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

//Dashboard
import Dashboard from './pages/dashboard' //OK
import DashboardOperatore from './pages/dashboard_operatore' //OK

//Clienti
import Clienti from './pages/clienti' //Ok
import ClientiProfilo from './pages/clienti_profilo' //Ok
import ClientiPreventivo from './pages/clienti_preventivo' //Ok
import ClientiPreventivo2 from './pages/clienti_preventivo_opt' //Ok
import ClientiOdl from './pages/clienti_odl' //Ok
import ClientiPreventivoPrint from './pages/clienti_preventivo_print'
import ClientiOdlOperatore from './pages/clienti_odl_operatore' //Ok

//Fornitori
import Fornitori from './pages/fornitori' //Ok
import FornitoriProfilo from './pages/fornitori_profilo' //Ok

//Listino
import Listino from './pages/listino' //Ok

//Preventivi
import Preventivi from './pages/preventivi' //Ok

//Magazzino
import Magazzino from './pages/magazzino' //Ok

//Container
import Container from './pages/container' //Ok
import ContainerProfilo from './pages/container_profilo' //Ok

//Noleggio scala
import NoleggioScala from './pages/noleggioScala' //Ok

//Permessi
import Permessi from './pages/suoloPubblico' //Ok

//Operatori
import Utenti from './pages/utenti' //Ok

//Auth
import Login from './pages/login' //Ok
import ForgotPw from './pages/forgotpw' //Ok


import DashboardMerchant from './pages/dashboard_merchant'
import ListMerchant from './pages/merchant_list'
import Prodotti from './pages/prodotti'
import Profilo from './pages/profilo'
import ProfiloEvents from './pages/profilo_events'
import Nesletter from './pages/newsletter'
import Offerte from './pages/offerte'
import Coupon from './pages/coupon'
import Messaggi from './pages/messaggi'
import OfferteAdmin from './pages/offerte_admin'
import MessaggiAdmin from './pages/messaggi_admin'
import NewUserEvent from './pages/new_user_event'
import DashboardEvent from './pages/dashboard_events'
import CreateEvent from './pages/create_event'
import EventiAdmin from './pages/eventi_admin'
import NotifichePush from './pages/notifiche_push'
import IntroAdmin from './pages/intro_admin'
import Assistenza from './pages/assistenza'



const Routes = (props) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/"
        component={Dashboard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/operatore"
        component={DashboardOperatore}
      ></PrivateRoute>

      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/notifiche_push"
        component={NotifichePush}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/intro_admin"
        component={IntroAdmin}
      ></PrivateRoute>

      {/* CLIENTI ROUTES*/}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/clienti"
        component={Clienti}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/clienti/:id"
        component={ClientiProfilo}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/clienti/preventivo/:id"
        component={ClientiPreventivo2}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/clienti/preventivo-bis/:id"
        component={ClientiPreventivo2}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/clienti/odl/:id"
        component={ClientiOdl}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/clienti/preventivo/stampa/:id"
        component={ClientiPreventivoPrint}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/operatore/odl/:id"
        component={ClientiOdlOperatore}
      ></PrivateRoute>

      {/*FORNITORI */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/fornitori"
        component={Fornitori}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/fornitori/:id"
        component={FornitoriProfilo}
      ></PrivateRoute>


      {/*LISTINO */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/listino"
        component={Listino}
      ></PrivateRoute>

      {/*MAGAZZINO */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/magazzino"
        component={Magazzino}
      ></PrivateRoute>

      {/*PREVENTIVI */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/preventivi"
        component={Preventivi}
      ></PrivateRoute>

      {/*CONTAINER */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/container"
        component={Container}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/container/:id"
        component={ContainerProfilo}
      ></PrivateRoute>

      {/* NOLEGGIO SCALA */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/noleggio-scala"
        component={NoleggioScala}
      ></PrivateRoute>

      {/* PERMESSI */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/permessi"
        component={Permessi}
      ></PrivateRoute>

      {/*UTENTI */}
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/utenti"
        component={Utenti}
      ></PrivateRoute>


      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/merchant_list"
        component={ListMerchant}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/offerte_admin"
        component={OfferteAdmin}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/messaggi_admin"
        component={MessaggiAdmin}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/new_user_event"
        component={NewUserEvent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/eventi_admin"
        component={EventiAdmin}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/dashboard_merchant"
        component={DashboardMerchant}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/assistenza"
        component={Assistenza}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'events']}
        path="/dashboard_events"
        component={DashboardEvent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'events']}
        path="/create_event"
        component={CreateEvent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/prodotti"
        component={Prodotti}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/profilo"
        component={Profilo}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'events']}
        path="/profilo_events"
        component={ProfiloEvents}
      ></PrivateRoute>
       <PrivateRoute
        exact
        roles={['ADMIN']}
        path="/newsletter"
        component={Nesletter}
      ></PrivateRoute>
       <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/offerte"
        component={Offerte}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/coupon"
        component={Coupon}
      ></PrivateRoute>
      <PrivateRoute
        exact
        roles={['ADMIN', 'merchant']}
        path="/messaggi"
        component={Messaggi}
      ></PrivateRoute>
      <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/recupera-password" component={ForgotPw}></Route>
    </Switch>
  );
}
export default Routes
