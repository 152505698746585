import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import Table from "./Table";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SidebarMenu from "./sidebar_menu"
import moment from "moment"
import * as XLSX from 'xlsx';

import {
  ExclamationCircleIcon,
  TrashIcon,
  EyeIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
  DocumentDuplicateIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

//Importo Menu
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Preventivi() {

  const columns = [
    {
      dataField: 'clienteRagioneSociale',
      text: 'Ragione sociale',
    },
    {
      dataField: 'name',
      text: 'Descrizione',
      formatter: (value, row) => {
        return (
          <>
            <ReactTooltip />
            <div className='flexxo font-bold' >
              {row.name}
            </div>
          </>
        )
      }
    },  
    {
      dataField: 'status',
      text: 'Stato',
      formatter: (value, row) => {
        return (
          <>
            {(() => {
              switch (row.status) {
                case 'BOZZA':
                  return (<span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                    {row.status}
                  </span>);
                case 'INVIATO':
                  return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                    {row.status}
                  </span>);
                case 'COMPLETATO':
                  return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                    {row.status}
                  </span>);
                case 'ACCETTATO':
                  return (<span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                    {row.status}
                  </span>);
                case 'RIFIUTATO':
                  return (<span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                    {row.status}
                  </span>);
                default:
                  return (<span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                    {row.status}
                  </span>);
              }
            })()}
          </>
        )
      }
    },  
    {
      dataField: 'totale',
      text: 'Prezzo €',
      formatter: (value, row) => {
        return (
            <div>
              {row.totale > 0 ? row.totale : 'Mancante'}
            </div>
        )
      }
    },
    {
      dataField: 'created_at',
      text: 'Creato il',
      formatter: (value, row) => {
        return (
            <div>
              {moment(row.created_at).format("DD/MM/YYYY")}
            </div>
        )
      }
    },
    {
      dataField: '',
      text: 'Opzioni',
      formatter: (value, row) => {
        return (
          <div className="flexxo">
            <ReactTooltip />
            <Link to={`/clienti/preventivo/${row.id}`} className="text-gray-600 hover:text-gray-900">
              <button
                type="button"
                className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <EyeIcon data-tip="Vedi" className="h-4 w-4" aria-hidden="true" />
              </button>
            </Link>
              <button
                type="button"
              onClick={() => {
                setToBeDeleted(row)
                setAdvModal(!advModal)
              }}
                className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <TrashIcon data-tip="Elimina" className="h-4 w-4" aria-hidden="true" />
              </button>
          </div>
        )
      }
    }
  ];

  //Setto gli state
  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState({});
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState("");
  const [pagesNavigation, setPagesNavigation] = useState(null)
  const [totalLenght, setTotalLenght] = useState(null)
  const [show, setShow] = useState(100);
    const [allStatus, setStatus] = useState(["BOZZA", "INVIATO", "ACCETTATO", "RIFIUTATO"])

    //Recupero Lista Utenti

  const [advModal, setAdvModal] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)

  const [advModalDuplicate, setAdvModalDuplicate] = useState(false)
  const [toBeDuplicated, setToBeDuplicated] = useState(null)


useEffect(() => {
  const expiration = cookies.expiration
  if(new Date(expiration) < new Date()) {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')
  }
}, [setLogout])


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    getPreventivi()
    
    return () => {
      mounted = false
    }
  }, [show, page, allStatus])


  const [allFornitori, setFornitori] = useState([])


  const getPreventivi = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/preventivi/fetch`, {
        params: {
            page: Number(page),
            limit: Number(show),
            search: search,
            status: allStatus
        },
      headers: {
        "x-access-token": cookies.token
      }
    })
    setFornitori(response.data.data)
    setPages(response.data.pagination.length)
    setPagesNavigation(response.data.pagination)
    setTotalLenght(response.data.tot)
    setLoading(true)
  }


    const searchCustomer = async () => {
        getPreventivi()
    }
  const { SearchBar } = Search;


    //Se lo state isCreated ricarico la pagina
    if (isCreated) {
        //Ricarica Pagina
        return window.location.reload();
    }


  //Chiamata post per eliminare preventivo
  const deleteArticolo = async (data) => {
    if(toBeDeleted) {
      const response = await axios.patch(`${window.$apiEndpoint}/preventivi/delete`, {
        id: data.id,
        cliente: data.clienteID
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBeDeleted(null)
      setAdvModal(!advModal)
      getPreventivi()
    }
  }

  //Chiamata per duplicare il preventivo
    const duplicateDocument = async () => {
        if (toBeDuplicated) {
      const response = await axios.post(`${window.$apiEndpoint}/clienti/duplicate-preventivo`, {
          preventivo: toBeDuplicated,
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
        history.push(`/clienti/preventivo/${response.data.id}`)
    }
  }

    const downloadxls = () => {
        let form = []
        allFornitori.map((item, index) => {
            let obj = {
                'Ragione Sociale': item.clienteRagioneSociale,
                'Nome': item.name,
                'Stato': item.status,
                'Importo': item.totale,
            }
            form.push(obj)

        })
        const ws = XLSX.utils.json_to_sheet(form);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Preventivi");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "Preventivi.xlsx");
    };


    const updateStatus = (value, array) => {
        if (array.some(elemento => elemento === value)) {
            setStatus(prevArray => prevArray.filter(elemento => elemento !== value))
        } else {
            setStatus(prevArray => [...prevArray, value]);
        }
    }



  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu  attivo={"Preventivi"}/>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
              {/* Replace with your content */}

              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">

                  <div className="max-w-12xl mx-auto" >
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className=" overflow-hidden sm:rounded-lg">


                            {/* Tabella Esercenti */}
                                                      <div className="max-w-12xl mx-auto" >
                                                          <div className="flex flex-col">
                                                              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                      <div className=" overflow-hidden sm:rounded-lg">

                                                                          {/* Tabella Esercenti */}
                                                                          <div className="max-w-12xl mx-auto mt-8" >
                                                                              <div className="flex flex-col">
                                                                                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                                          <div className=" overflow-hidden sm:rounded-lg">

                                                                                              <div className="sm:flex-auto">

                                                                                                  <div className="flex justify-between align-center">
                                                                                                      <div className="w-50">
                                                                                                          <div className="mt-1 flex rounded-md shadow-sm">
                                                                                                              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                                  <input
                                                                                                                      type="text"
                                                                                                                      name="search"
                                                                                                                      id="search"
                                                                                                                      className="block w-full rounded-none rounded border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                                                      placeholder="🔍 Cerca per ragione sociale"
                                                                                                                      onChange={(e) => searchCustomer()}
                                                                                                                      onKeyUp={e => setSearch(e.target.value)}
                                                                                                                  />
                                                                                                              </div>
                                                                                                              <label className="checkbox-inline mx-2 font-medium">
                                                                                                                  <input className="rounded border-gray-300 mx-1 focus:border-indigo-500 focus:ring-indigo-500" type="checkbox" value="BOZZA" onChange={() => updateStatus("BOZZA", allStatus)} checked={allStatus.some(elemento => elemento === "BOZZA")} />BOZZA
                                                                                                              </label>
                                                                                                              <label className="checkbox-inline mx-2 font-medium">
                                                                                                                  <input className="rounded border-gray-300 mx-1 focus:border-indigo-500 focus:ring-indigo-500" type="checkbox" value="INVIATO" onChange={() => updateStatus("INVIATO", allStatus)} checked={allStatus.some(elemento => elemento === "INVIATO")} />INVIATO
                                                                                                              </label>
                                                                                                              <label className="checkbox-inline mx-2 font-medium">
                                                                                                                  <input className="rounded border-gray-300 mx-1 focus:border-indigo-500 focus:ring-indigo-500" type="checkbox" value="ACCETTATO" onChange={() => updateStatus("ACCETTATO", allStatus)} checked={allStatus.some(elemento => elemento === "ACCETTATO")} />ACCETTATO
                                                                                                              </label>
                                                                                                              <label className="checkbox-inline mx-2 font-medium">
                                                                                                                  <input className="rounded border-gray-300 mx-1 focus:border-indigo-500 focus:ring-indigo-500" type="checkbox" value="RIFIUTATO" onChange={() => updateStatus("RIFIUTATO", allStatus)} checked={allStatus.some(elemento => elemento === "RIFIUTATO")} />RIFIUTATO
                                                                                                              </label>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <div className="mt-1">

                                                                                                          <button
                                                                                                              type="button"
                                                                                                              className="rounded-md border border-transparent bg-success px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-50"
                                                                                                              onClick={() => downloadxls()}
                                                                                                          >
                                                                                                              Esporta
                                                                                                          </button>
                                                                                                      </div>
                                                                                                      <div>

                                                                                                          <Menu as="div" className="relative inline-block text-left z-50">
                                                                                                              <div className="mt-1">
                                                                                                                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                                                                                      Visualizza: {show}
                                                                                                                      <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                                  </Menu.Button>
                                                                                                              </div>
                                                                                                              <Transition
                                                                                                                  as={Fragment}
                                                                                                                  enter="transition ease-out duration-100"
                                                                                                                  enterFrom="transform opacity-0 scale-95"
                                                                                                                  enterTo="transform opacity-100 scale-100"
                                                                                                                  leave="transition ease-in duration-75"
                                                                                                                  leaveFrom="transform opacity-100 scale-100"
                                                                                                                  leaveTo="transform opacity-0 scale-95"
                                                                                                              >

                                                                                                                  <Menu.Items className="z-50">
                                                                                                                      <div className="py-1">
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => {
                                                                                                                                        setShow(e.currentTarget.innerText)
                                                                                                                                        setPage(1)
                                                                                                                                    }}
                                                                                                                                  >
                                                                                                                                      20
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => {
                                                                                                                                          setShow(e.currentTarget.innerText)
                                                                                                                                          setPage(1)
                                                                                                                                      }}
                                                                                                                                  >
                                                                                                                                      50
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => {
                                                                                                                                          setShow(e.currentTarget.innerText)
                                                                                                                                          setPage(1)
                                                                                                                                      }}
                                                                                                                                  >
                                                                                                                                      100
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => {
                                                                                                                                          setShow(e.currentTarget.innerText)
                                                                                                                                          setPage(1)
                                                                                                                                      }}
                                                                                                                                  >
                                                                                                                                      200
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                      </div>
                                                                                                                  </Menu.Items>
                                                                                                              </Transition>
                                                                                                          </Menu>
                                                                                                          <div className="flex flex-1 justify-between sm:hidden">
                                                                                                              <a
                                                                                                                  href="#"
                                                                                                                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                              >
                                                                                                                  Previous
                                                                                                              </a>
                                                                                                              <a
                                                                                                                  href="#"
                                                                                                                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                              >
                                                                                                                  Next
                                                                                                              </a>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                          {loading ? <div className="mt-8 flex flex-col">
                                                                                              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                                                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                                                                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                                                                          <table className="min-w-full divide-y divide-gray-300">
                                                                                                              <thead className="bg-gray-50">
                                                                                                                  <tr>
                                                                                                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                          Ragione sociale
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Preventivo
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Totale €
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Opzioni
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                                                                      </th>
                                                                                                                  </tr>
                                                                                                              </thead>
                                                                                                              <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                  {loading ? allFornitori.map((cliente) => (
                                                                                                                      <tr key={cliente._id}>
                                                                                                                          <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 text-ellipsis">
                                                                                                                              <div className="flex items-center">
                                                                                                                                  <div className="h-10 w-15 flex-shrink-0">
                                                                                                                                  </div>
                                                                                                                                  <div className="ml-4">
                                                                                                                                      <div className="font-medium text-gray-900"><Link to={`/clienti/${cliente.clienteID}`} className="text-gray-600 hover:text-gray-900">{cliente.clienteRagioneSociale}</Link></div>
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          </td>
                                                                                                                          <td className="px-3 py-4 text-sm text-gray-500 text-ellipsis">
                                                                                                                              <div className="text-gray-500"> {cliente.name}</div>
                                                                                                                              <div className="text-gray-900">{(() => {
                                                                                                                                  switch (cliente.status) {
                                                                                                                                      case 'BOZZA':
                                                                                                                                          return (<span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                                                                                                                              {cliente.status}
                                                                                                                                          </span>);
                                                                                                                                      case 'INVIATO':
                                                                                                                                          return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                                                                                                                                              {cliente.status}
                                                                                                                                          </span>);
                                                                                                                                      case 'COMPLETATO':
                                                                                                                                          return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                                                                                                                                              {cliente.status}
                                                                                                                                          </span>);
                                                                                                                                      case 'ACCETTATO':
                                                                                                                                          return (<span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                                                                                                                              {cliente.status}
                                                                                                                                          </span>);
                                                                                                                                      case 'RIFIUTATO':
                                                                                                                                          return (<span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                                                                                                                              {cliente.status}
                                                                                                                                          </span>);
                                                                                                                                      default:
                                                                                                                                          return (<span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                                                                                                                              {cliente.status}
                                                                                                                                          </span>);
                                                                                                                                  }
                                                                                                                              })()}</div>
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                              <div className="flex items-center">
                                                                                                                                  <div className="h-10 w-15 flex-shrink-0">
                                                                                                                                  </div>
                                                                                                                                  <div className="ml-4">
                                                                                                                                      <div className="font-medium text-gray-900">{cliente.totale > 0 ? cliente.totale : 'Mancante'}</div>
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                                              <div className="flexxo">
                                                                                                                                  <ReactTooltip />
                                                                                                                                  <Link to={`/clienti/preventivo/${cliente.id}`} className="text-gray-600 hover:text-gray-900">
                                                                                                                                      <button
                                                                                                                                          type="button"
                                                                                                                                          className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                                                                                      >
                                                                                                                                          <EyeIcon data-tip="Vedi" className="h-4 w-4" aria-hidden="true" />
                                                                                                                                      </button>
                                                                                                                                  </Link>
                                                                                                                                      <button
                                                                                                                                          type="button"
                                                                                                                                          onClick={() => {
                                                                                                                                            setToBeDuplicated(cliente)
                                                                                                                                            setAdvModalDuplicate(!advModalDuplicate)
                                                                                                                                        }}
                                                                                                                                          className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                                                                                      >
                                                                                                                                          <DocumentDuplicateIcon data-tip="Duplica per questo cliente" className="h-4 w-4" aria-hidden="true" />
                                                                                                                                      </button>
                                                                                                                              </div>
                                                                                                                          </td>
                                                                                                                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-between my-2">
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  )) :
                                                                                                                      <tr>
                                                                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                                                                                              <div className="flex justify-center items-center">
                                                                                                                                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                                                                                      <span className="visually-hidden">Loading...</span>
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  }
                                                                                                              </tbody>
                                                                                                          </table>
                                                                                                          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                                                                                              <div className="flex flex-1 justify-between sm:hidden">
                                                                                                                  <a
                                                                                                                      href="#"
                                                                                                                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                                  >
                                                                                                                      Previous
                                                                                                                  </a>
                                                                                                                  <a
                                                                                                                      href="#"
                                                                                                                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                                  >
                                                                                                                      Next
                                                                                                                  </a>
                                                                                                              </div>
                                                                                                              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                                                                                                  <div>
                                                                                                                      <p className="text-sm text-gray-700">
                                                                                                                          Visualizza <span className="font-medium">{show >= allFornitori.length ? allFornitori.length : show}</span> di{' '}
                                                                                                                          <span className="font-medium">{totalLenght}</span> risultati
                                                                                                                      </p>
                                                                                                                  </div>
                                                                                                                  <div>
                                                                                                                      {loading && pages ? <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                                                                                                          <a
                                                                                                                              href="#"
                                                                                                                              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                                                                                                              onClick={() => {
                                                                                                                                  if (pages.length !== 1 && page !== 1) {
                                                                                                                                    setPage(page - 1)
                                                                                                                                  }
                                                                                                                              }}
                                                                                                                          >
                                                                                                                              <span className="sr-only">Prec</span>
                                                                                                                              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                                          </a>
                                                                                                                          {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                                                                                                                          
                                                                                                                                              {pagesNavigation.map((item, index) => {
                                                                                                                                                  return(
                                                                                                                                                  <a
                                                                                                                                                      href="#"
                                                                                                                                                      key={index}
                                                                                                                                                      aria-current="page"
                                                                                                                                                      className={`relative z-10 inline-flex items-center border border-indigo-500 ${page === item ? "bg-indigo-50" : "bg-white"} px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20`}
                                                                                                                                                      onClick={() => setPage(item)}
                                                                                                                                                  >
                                                                                                                                                      {item}
                                                                                                                                                  </a>
                                                                                                                                                  )
                                                                                                                                              })
                                                                                                                                              }
                                                                                                                          <a
                                                                                                                              href="#"
                                                                                                                              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                                                                                                              onClick={() => {
                                                                                                                                  if (pages.length !== 1 && page !== Math.max(...pagesNavigation)) {
                                                                                                                                      setPage(page + 1)
                                                                                                                                  }
                                                                                                                              }}
                                                                                                                          >
                                                                                                                              <span className="sr-only">Succ</span>
                                                                                                                              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                                          </a>


                                                                                                                      </nav> : null}
                                                                                                                  </div>
                                                                                                              </div>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>



                                                                                          </div> : <tr>
                                                                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                                                                  <div className="flex justify-center items-center">
                                                                                                      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                                                          <span className="visually-hidden">Loading...</span>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </td>
                                                                                          </tr>}
                                                                                      </div>
                                                                                  </div>
                                                                              </div>

                                                                          </div>

                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          </div>


                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

              </div>
            </div>
              </div>

              
              </div>

              

              
              </main>
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>



      <Transition.Root show={advModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Attenzione!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Stai per eliminare il preventivo, vuoi procedere?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        deleteArticolo(toBeDeleted)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeDeleted(null)
                        setAdvModal(!advModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={advModalDuplicate} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModalDuplicate(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Attenzione!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Stai per duplicare il preventivo, vuoi procedere?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        duplicateDocument(toBeDuplicated)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeDuplicated(null)
                        setAdvModalDuplicate(!advModalDuplicate)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

              {/* /End replace */}

            </div>




  )
}
