import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Select, { components } from 'react-select';
import JoditEditor from "jodit-react";


/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { Dialog, Transition, RadioGroup } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  SearchCircleIcon,
  SpeakerphoneIcon,
  MapIcon,
  ViewGridAddIcon,
  CogIcon,
  CameraIcon,
  UploadIcon,
  CheckIcon,
  CheckCircleIcon,
  TrashIcon,
  HeartIcon,
  checkCircleIcon,
  plusCircleIcon,
  EyeIcon,
  PaperAirplaneIcon,

} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import { ChevronLeftIcon, FilterIcon, MailIcon, InformationCircleIcon, PhoneIcon, SearchIcon, LocationMarkerIcon, PencilAltIcon, PlusIconSolid, PencilIcon, ViewGridIcon as ViewGridIconSolid,
  ViewListIcon, StarIcon } from '@heroicons/react/solid'

//Importo componente per le Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Importo demone per il crop delle immagini
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

//Importo Demoni per le tabelle
import { useTable } from "react-table";
import Table from "./Table";


//Importo Menu
import Menu from "./navigation_merchant";

//Importo Notifiche di successo
import SuccNotify from "./successNotify";

//Importo Notifiche di insucesso
import ErrNotify from "./errorNotify";
import { array } from 'prop-types';


const styles = (theme) => ({
  cropContainer: {
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-end',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Offerte() {
  const editor = useRef(null)
  //Setto gli state
  const [Allinfo, setAllInfo] = useState([{}]);
  const [allOffer, setAllOffer] = useState([{}]);



  const [AllProdotti, setProdotti] = useState([{}]);
  const [Articolo, setArticolo] = useState([{}]);
  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openOffer, setOpenOffer] = useState(false)
  const [openIDOffer, setIDOffer] = useState([{}])

  const [openModalDettaglio, setOpenModalDettaglio] = useState(false)
  const [openModalInserimento, setOpenModalInserimento] = useState(false)


  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [showsetAllProfile, setAllProfile] = useState([{}]);
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openConfermaCancellazione, setConfermaCancellazione] = useState(false)



  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;
  const [isAdmin, setisAdmin] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [showSucc1, setSucc1] = useState(false);
  const [showErr1, setErr1] = useState(false);
  const [showSucc2, setSucc2] = useState(false);
  const [showErr2, setErr2] = useState(false);
  const [showPreSucc, setPreSucc] = useState('');
  const [showPreErr, setPreErr] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [nomeImmagine, setnomeImmagine] = useState(false);
  const [nomeImmagine2, setnomeImmagine2] = useState('');
  const [uploadStatus2, setUploadStatus2] = useState('');
  const [id_immagine_offerta, set_id_immagine_offerta] = useState('');
  const [id_offerta, set_id_offerta] = useState('');
  const [imageProfile, setimageProfile] = useState('')
  const [imageProfileUrl, setimageProfileUrl] = useState(null)
  const [imageProfileUrl2, setimageProfileUrl2] = useState(null)
  const [idOfferta2, setIdOfferta2] = useState('');

  const [openModalCropProfile, setOpenModalCropProfile] = useState(false)

  const [isRefresh, setisRefresh] = useState(false);
  const [showSucc10, setSucc10] = useState(false);
  const [showErr10, setErr10] = useState(false);
  const [showPrevImage, setshowPrevImage] = useState(false);
  const [showUploadImage, setshowUploadImage] = useState(false);
  const [UploadMesage, setUploadMesage] = useState('');
  const [showForm1 , setForm1 ] = useState(false);
  const [showForm2 , setForm2 ] = useState(false);
  const [showForm3 , setForm3 ] = useState(false);
  const [showForm4 , setForm4 ] = useState(false);



  const config = {
		readonly: false,
    height: 'auto',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	}




  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);
  const [reload, setReload] = useState(0);


  const ImgProfile = window.$fileserverEndpointCrop + imageProfile;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {

    try {
      const croppedImage = await getCroppedImg({
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
        ImgProfile,
        croppedAreaPixels,
        rotation
      });
      setCroppedImage(croppedImage)
      setimageProfileUrl(croppedImage)
      //Il resize è andato a buon fine procedo al update del campo url_image_profile nel database

      const addBase64 = async () => {
        try {

          const add = await axios.post(`${window.$apiEndpoint}/merchant/updatebase64Bid`, {
            email: indirzzi_email_loggato,
            base64: croppedImage,
            id_immagine: id_immagine_offerta,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          setSucc10(true);
          setTimeout(() => {setSucc10(false)},5000)
          //Chiudo Modale
          setTimeout(() => {setOpenModalCropProfile(false)},3000)

        } catch (err) {
          setErr10(true)
          setTimeout(() => {setErr10(false)},5000)
        }
      }
      addBase64()

    } catch (error) {
      console.error(error)
    }
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])



  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }



  function creaImmagine(dataImmgine){

    //var bufferBase64 = new Buffer(`${dataImmgine}`, 'binary');
    //var buffer = new Buffer(dataImmgine, 'binary');
    const arrayData = []
    arrayData.push(dataImmgine)
    const map = arrayData.map((item,index) => {

    var bufferBase64 = new Buffer(`${item}`, 'binary');
    })

    console.log(`data:data:image/png;base64,${Buffer.from(dataImmgine).toString('base64')}`)


    return (
      <img
      className="h-3/4 rounded-lg ring-4 ring-white sm:h-32 sm:w-32"
      src="https://finestrapp.it/images/iphone.png"
      alt="Immagine Offerta"
      />
  )

  }

  //Aro modale per cancellaxione

  const setModalOpenConfirm = async (id_offerta) => {

  //Apro Modale di conferma
  setOpenConfirm(true)

  //Setto l'id offerta
  setIdOfferta2(id_offerta)


  }

  //Apro modale dettaglio Offerta
  const CALLDettOffer = async (idOfferta) => {

  //Recupero i dati per il dettaglio del modale
     const users = await axios.get(`${window.$apiEndpoint}/merchant/fetchOffertaID/${idOfferta}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setIDOffer(users.data)
        var bufferBase64 = new Buffer(users.data[0].cropped_image.data, 'binary');
        setimageProfileUrl2(`${bufferBase64}`)
        setLoading(true)

    } catch (e) {
      console.log(e)
    }

  //Apro modale
  setOpenOffer(true);


}

useEffect(() => {
  if (reload === 4) {


    const fetch = async () => {
      const offerte  = await axios.get(`${window.$apiEndpoint}/merchant/fetchOfferteEsercente/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
          setAllOffer(offerte.data)
          setisRefresh(true)
          setReload(0)



      } catch (e) {
        console.log(e)
      }
    }
    fetch();



  }
}, [reload]);


//Apro modale dettaglio Offerta
const RichiamaTabella = async () => {

  const fetch = async () => {
    const offerte  = await axios.get(`${window.$apiEndpoint}/merchant/fetchOfferteEsercente/${indirzzi_email_loggato}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
        setAllOffer(offerte.data)



    } catch (e) {
      console.log(e)
    }
  }
  fetch();

}


  //Cancella Esercente
  const cancellaOfferta  = async (id_offerta) => {

      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/cancellaOfferta`, {
          id_offerta: id_offerta,
      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })

        setOpenConfirm(false)
        setisRefresh(false)
        setReload(4)
        setSucc1(true);
            setTimeout(() => {setSucc1(false)},5000)

      } catch (err) {

        setErr1(true);
            setTimeout(() => {setErr1(false)},5000)

      }




  }

  //Funzione per Inviare notifica Push con Expo
  async function sendPushNotification(expoPushToken, titolo_notifica, testo_notofica) {

    const message = {
       to: expoPushToken,
       sound: 'default',
       title: titolo_notifica,
       body: testo_notofica,
       badge: 1,
     };
    const requestOptions = {
        'mode': 'no-cors',
        'method': 'POST',
        'headers': {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
            body: JSON.stringify(message),
        };

        await fetch('https://exp.host/--/api/v2/push/send', requestOptions)
            .then(response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && response.json();

                console.log(response)

                //Stampo Notifica a video e resetto form
                setSucc(true);
                setTimeout(() => {setSucc(false)},6000)


                //Resetto modale
                reset()

            })
            .catch(error => {
                console.error('There was an error!', error);
                //Stampo errore
                setErr(true)
                setTimeout(() => {setErr(false)},4000)
            });


  }

  //Invio notifica push

  const inviaNotifica = async (id_offerta) => {

  //Controllo se la notifica è già stata inviata

  const users = await axios.get(`${window.$apiEndpoint}/merchant/fetchOffertaID/${id_offerta}`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {
      const controllo = (users.data[0].notifica)

      //Controllo

      if (controllo !== 0 ) {

      const TitoloNotifica = "🎁 🎁  Nuova Offerta: " + users.data[0].titolo_offerta + " 🎁 🎁 ";
      const Desc = users.data[0].vincolo_descrizione;

      //Inserire qui il codice per inviare la notifica push all'APP
      //Recupero i token di notfica dal database
      const richiamaDatiNot = async () => {
        const users = await axios.get(`${window.$apiEndpoint}/merchant/fetchTokenPush`, {
          headers: {
            "x-access-token": cookies.token
          }
        });
        try {
            //Mappo la risposta è se il token è pieno invio la notifica
            const map = users.data.map((item,index) => {

            //Controllo se il token è null, vuoto o non definito
            if (item.token_notifiche_push === undefined || item.token_notifiche_push === null || item.token_notifiche_push === '') {

            console.log("Token inesistente, utente non app, procedo con il prossimo")

          } else {
          //Token Esietnte invio Notifica
          //console.log(item.token_notifiche_push)
          //Invio Notifica
          sendPushNotification(item.token_notifiche_push, TitoloNotifica, Desc)

          //Recupero email utente associata a questo Expo Token

          const recuperoEmail = async () => {
            const fetchEmail = await axios.get(`${window.$apiEndpoint}/merchant/fetchEmailFromTokenPush/${item.token_notifiche_push}`, {
              headers: {
                "x-access-token": cookies.token
              }
            });
            try {

            const emailUtente = fetchEmail.data;

            const map = emailUtente.map((item,index) => {
            //Inserisco nel Database tutto il record notifica con email Utente
            addMessaggio(TitoloNotifica, Desc, item.email, id_offerta)

            })//Fine Secondo Map

            } catch (e) {
              console.log(e)
            }
          }
          recuperoEmail();

          }

            })

            //Salvo nel database l'invio della notifica
            const addMessaggio = async (TitoloNotifica, Desc, email_utente, id_offerta) => {
              try {

                const add = await axios.post(`${window.$apiEndpoint}/merchant/addNotifica`, {
                tipologia: 2,
                titolo_notifica: TitoloNotifica,
                testo_notofica: Desc,
                email_utente: email_utente,
                id_url_notifica: id_offerta,

              }, {
                  headers: {
                      'x-access-token' : cookies.token
                  }
              })

              } catch (err) {

              }
            }

        } catch (error) {
          console.log(error)
        }
      }
      richiamaDatiNot();



      setSucc2(true);
      setTimeout(() => {setSucc2(false)},5000)

      //Eseguo update sul DB per la notofica inviata

      const add = axios.post(`${window.$apiEndpoint}/merchant/updateNotifica`, {
        id_offerta: id_offerta,
        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })


      } else {

      setErr2(true);
      setTimeout(() => {setErr2(false)},5000)


      }


  } catch (e) {
    console.log(e)
  }

  }


  const columns = useMemo(
    () => [
      {
        Header: "Titolo Offerta",
        accessor: "titolo_offerta",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{cell.row.values.titolo_offerta}</td>

        )
      },
      {
        Header: "Tipologia Offerta",
        accessor: "tipologia",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.tipologia}</td>

        )
      },
      {
        Header: "Scadenza",
        accessor: "scadenza",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.scadenza}</td>

        )
      },
      {
        Header: "Limiti Pezzi",
        accessor: "limiti_pezzi",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.limiti_pezzi}</td>

        )
      },
      {
        Header: "Azioni",
        accessor: "azioni",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

         <ReactTooltip />
        <a  onClick={() => CALLDettOffer(cell.row.values.azioni)} className="text-indigo-600 hover:text-indigo-900">
        <button
        type="button"
        className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >


        <EyeIcon data-tip="Dettagli Offerta" className="h-4 w-4" aria-hidden="true" />
      </button>
        </a>



        <a  onClick={() => inviaNotifica(cell.row.values.azioni)} className="text-indigo-600 hover:text-indigo-900">
        <button
        type="button"
        className="inline-flex items-center disabled:opacity-50 ml-3 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
        <PaperAirplaneIcon data-tip="Invia Notifica" className="h-4 w-4" aria-hidden="true" />
      </button>
        </a>


        <a  onClick={() => setModalOpenConfirm(cell.row.values.azioni)} className="text-indigo-600 hover:text-indigo-900">
        <button
        type="button"
        className="inline-flex items-center  ml-3 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >


        <TrashIcon data-tip="Cancella Offerta" className="h-4 w-4" aria-hidden="true" />
      </button>
        </a>





          </td>

        )
      },





    ],
    []
  );


  const data = useMemo(() => allOffer, []);

   //Controllo se la pagina è controllata da un Amministrstore oppure solamnete dall'Esercente
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      try {
        if(mounted){

        if (indirzzi_email_loggato === indirzzi_email_account) {

           //Sono Esercente
           setisAdmin(false)

          //Controllo Se sono uno degli amministratori
          if (indirzzi_email_loggato === 'info@finestrapp.it' || indirzzi_email_loggato === 'info@tribusadv.com') {
          //Sono Amministrazione ma nel mio account
          setisAdmin(true)
          } else {
           //Sono Esercente
           setisAdmin(false)
          }


        } else {

          //Sono Amministratore
          setisAdmin(true)

        }
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Funzione che trasforma la stringa in mauscolo
  function capitalizeUpper(s)
  {
    //Trasformo tutto in Maiscolo
    const n = s.toUpperCase();
    //Stampo
    return n;
  }

  //Funzione che controlla se la variabile dell'orario è Null
  function checkNull(string)
  {
    if (string === "") {

      return "Chiuso";

    } else {

      return string;


    }
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Lista Offerte per questo utente
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const offerte  = await axios.get(`${window.$apiEndpoint}/merchant/fetchOfferteEsercente/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllOffer(offerte.data)
          setisRefresh(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Recupero Immagine Croppata
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const image = await axios.get(`${window.$apiEndpoint}/users/fetchImageProfiloBidCrop/${indirzzi_email_loggato}/${id_immagine_offerta}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          var bufferBase64 = new Buffer(image.data[0].base_64_image_profile_cropped.data, 'binary');
          setimageProfileUrl(`${bufferBase64}`)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])



  //Chiamata post per inserimento prodotto
  const onSubmit = data => {



    //Capisco il tipo di scenta per capire in che opzione di offerta sono
    const Opzione = selectedOption;
    const Key_Offerta = id_offerta;
    const IndirizzoEmailMercahnt = indirzzi_email_loggato;
    const Titolo = capitalize(data.titolo);
    const dataInserimentoNew = new Date().toLocaleString().replace(/,/, '');

    console.log(Opzione)

    //If che controlla la scelta è imposta le variabili

    if (Opzione === 1) {

    console.log("Sono nell'opzione 1")

    //Dichiaro le variabili per l'opzione 1
    const PercetualeSconto = data.percentuale;
    const LimitiPezzi = data.limitipezzi;
    const Scandenza = data.scadenza;
    const Desc = data.vincolo_descrizione.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '');
    const VincoloDescrizione = Desc;

    //Controllo de la descrizione è pieno

    if (VincoloDescrizione === undefined || VincoloDescrizione === null || VincoloDescrizione === '') {

    //Vuoto
    setErr(true)
    setTimeout(() => {setErr(false)},6000)

    } else {

    //Procedo nell'insert dell'offerta
    const addOfferta = async () => {
      try {

        const add = await axios.post(`${window.$apiEndpoint}/merchant/addOfferta1`, {
        titolo_offerta: Titolo,
        limiti_pezzi: LimitiPezzi,
        scadenza: Scandenza,
        percentuale_sconto: PercetualeSconto,
        sconto_euro: 0,
        vincolo_descrizione: VincoloDescrizione,
        omaggio_descrizione: null,
        email_merchant: IndirizzoEmailMercahnt,
        data_creazione: dataInserimentoNew,
        tipologia: Opzione,
        id_offerta: Key_Offerta,


      }, {
          headers: {
              'x-access-token' : cookies.token
          }
      })
        setSucc(true);
        setTimeout(() => {setSucc(false)},6000)


        //Resetto modale
        reset()

        //Desetto tutte le scelte precedenti
        setshowUploadImage(false)
        setForm1(false)
        setForm2(false)
        setForm3(false)
        setForm4(false)
        setshowPrevImage(false)
        //Richiamo Funzione Tabella
        //setReload(4);
        setSelectedOption(null)
        setSelectedOptionLabel(null)
        setisRefresh(false)
        setReload(4)




      } catch (err) {
        setErr(true)
        setTimeout(() => {setErr(false)},4000)
      }
    }
    addOfferta()



    }//Fine if di prima opzione di inserimento


    } else if (Opzione === 2) {
    //Dichiaro le variabili per l'opzione 2
    const Omaggio = capitalize(data.omaggio);
    const LimitiPezzi = data.limitipezzi;
    const Scandenza = data.scadenza;
    const Desc2 = data.vincolo_descrizione.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '');
    const VincoloDescrizione2 = Desc2;


     //Controllo de la descrizione è pieno

     if (VincoloDescrizione2 === undefined || VincoloDescrizione2 === null || VincoloDescrizione2 === '') {

      //Vuoto
      setErr(true)
      setTimeout(() => {setErr(false)},6000)

      } else {

      //Procedo nell'insert dell'offerta
      const addOfferta2 = async () => {

        try {

          const add = await axios.post(`${window.$apiEndpoint}/merchant/addOfferta2`, {
          titolo_offerta: Titolo,
          limiti_pezzi: LimitiPezzi,
          scadenza: Scandenza,
          percentuale_sconto: 0,
          sconto_euro: 0,
          vincolo_descrizione: VincoloDescrizione2,
          omaggio_descrizione: Omaggio,
          email_merchant: IndirizzoEmailMercahnt,
          data_creazione: dataInserimentoNew,
          tipologia: Opzione,
          id_offerta: Key_Offerta,


        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          setSucc(true);
          setTimeout(() => {setSucc(false)},6000)


          //Resetto modale
          reset()

          //Desetto tutte le scelte precedenti
          setshowUploadImage(false)
          setForm1(false)
          setForm2(false)
          setForm3(false)
          setForm4(false)
          setshowPrevImage(false)
          setSelectedOption(null)
          setSelectedOptionLabel(null)
          setisRefresh(false)
          setReload(4)



        } catch (err) {

          console.log(err)
          setErr(true)
          setTimeout(() => {setErr(false)},6000)
        }
      }
      addOfferta2()

      }//Fine if di prima opzione di inserimento


    } else if (Opzione === 3) {
     //Dichiaro le variabili per l'opzione 3
    const scontoEuro = data.scontoEuro;
    const LimitiPezzi = data.limitipezzi;
    const Scandenza = data.scadenza;
    const Desc3 = data.vincolo_descrizione.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '');
    const VincoloDescrizione3 = Desc3;



     //Controllo de la descrizione è pieno

     if (VincoloDescrizione3 === undefined || VincoloDescrizione3 === null || VincoloDescrizione3 === '') {

      //Vuoto
      setErr(true)
      setTimeout(() => {setErr(false)},6000)


      } else {

      //Procedo nell'insert dell'offerta
      const addOfferta3 = async () => {

        try {

          const add = await axios.post(`${window.$apiEndpoint}/merchant/addOfferta3`, {
          titolo_offerta: Titolo,
          limiti_pezzi: LimitiPezzi,
          scadenza: Scandenza,
          percentuale_sconto: 0,
          sconto_euro: scontoEuro,
          vincolo_descrizione: VincoloDescrizione3,
          omaggio_descrizione: null,
          email_merchant: IndirizzoEmailMercahnt,
          data_creazione: dataInserimentoNew,
          tipologia: Opzione,
          id_offerta: Key_Offerta,


        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          setSucc(true);
          setTimeout(() => {setSucc(false)},6000)


          //Resetto modale
          reset()

          //Desetto tutte le scelte precedenti
          setshowUploadImage(false)
          setForm1(false)
          setForm2(false)
          setForm3(false)
          setForm4(false)
          setshowPrevImage(false)
          setSelectedOption(null)
          setSelectedOptionLabel(null)
          setisRefresh(false)
          setReload(4)


        } catch (err) {

          console.log(err)
          setErr(true)
          setTimeout(() => {setErr(false)},6000)
        }
      }
      addOfferta3()

      }//Fine if di prima opzione di inserimento


    } else if (Opzione === 4) {
     //Dichiaro le variabili per l'opzione 4
    const prodotto = capitalize(data.prodotto);
    const LimitiPezzi = data.limitipezzi;
    const Scandenza = data.scadenza;
    const Desc4 = data.vincolo_descrizione.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '');
    const VincoloDescrizione4 = Desc4;
    const prezzoProdotto = data.prezzoProdotto;


    //Controllo de la descrizione è pieno

    if (VincoloDescrizione4 === undefined || VincoloDescrizione4 === null || VincoloDescrizione4 === '') {

      //Vuoto
      setErr(true)
      setTimeout(() => {setErr(false)},6000)


      } else {

      //Procedo nell'insert dell'offerta
      const addOfferta4 = async () => {

        try {

          const add = await axios.post(`${window.$apiEndpoint}/merchant/addOfferta4`, {
          titolo_offerta: Titolo,
          limiti_pezzi: LimitiPezzi,
          scadenza: Scandenza,
          percentuale_sconto: 0,
          sconto_euro: 0,
          vincolo_descrizione: VincoloDescrizione4,
          omaggio_descrizione: null,
          email_merchant: IndirizzoEmailMercahnt,
          data_creazione: dataInserimentoNew,
          tipologia: Opzione,
          id_offerta: Key_Offerta,
          prodotto: prodotto,
          prezzoProdotto: prezzoProdotto,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
          setSucc(true);
          setTimeout(() => {setSucc(false)},6000)


          //Resetto modale
          reset()

          //Desetto tutte le scelte precedenti
          setshowUploadImage(false)
          setForm1(false)
          setForm2(false)
          setForm3(false)
          setForm4(false)
          setshowPrevImage(false)
          setSelectedOption(null)
          setSelectedOptionLabel(null)
          setisRefresh(false)
          setReload(4)



        } catch (err) {

          console.log(err)
          setErr(true)
          setTimeout(() => {setErr(false)},6000)
        }
      }
      addOfferta4()

      }//Fine if di prima opzione di inserimento

    }


  }

  //Chiamata per aprire il modale di inserimento offerta
  const CallModaleInserimento = () => {
   setOpenModalInserimento(true)
  }

  //Funzione per generazione randomica di stringhe
  function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
  }

  //Caricamento Immagine Offerta
  const imageHandler2 = (event) => {

    const file = event.target.files[0];
    const formData = new FormData()
    const id_immagine = makeid(10);
    set_id_immagine_offerta(id_immagine);
    const id_offerta = makeid(10);
    set_id_offerta(id_offerta)
    formData.append('image', file)
    formData.append("email", indirzzi_email_loggato);
    formData.append("id_immagine", id_immagine);
    formData.append("id_offerta", id_offerta);



    fetch(`${window.$apiEndpoint}/merchant/uploadBid`, {
        method: 'POST',
        body: formData,
        headers: {
              'x-access-token' : cookies.token,
              'Accept': 'multipart/form-data'
          },
      })
      .then(res => res.json())
      .then(res => {

        //Controllo se il messaggio di errore se no apro il resize

        if (res.msg === 'Tipo di file non supportato per il caricamento!') {
          setUploadStatus2(res.msg);
        } else if (res.msg === 'Il file caricato supera i 4MB di diemnsione!') {
          setUploadStatus2(res.msg);
        } else {
          //Caricata con successo

        //Impopsto e recupero immagine da ritagliare
        const fetch = async () => {
          const imageProfiloS = await axios.get(`${window.$apiEndpoint}/merchant/fetchImageProfiloBid/${indirzzi_email_loggato}/${id_offerta}`, {
            headers: {
              "x-access-token": cookies.token
            }
          });
          try {
                setimageProfile(imageProfiloS.data[0].url_immagine)

          } catch (e) {
            console.log(e)
          }
        }
        fetch();
         //Apro il modale per il resize
         setTimeout(() => {
         setOpenModalCropProfile(true)
         //Apro la Preview dell'immagine
         setshowPrevImage(true)
         }, 1000);

         setTimeout(() => {
          //Apro la Preview dell'immagine
          setshowPrevImage(true)
          //Smonto Upload immagine
          setshowUploadImage(false)

          }, 2000);

           //Immagine caricata stampo allert
          setUploadMesage('Immagine caricata con successo!')
          setTimeout(() => {setUploadMesage('')},6000)

          //Controllo l'opzione scelta e carico il form richiesto
          if (selectedOption === 1) {

          //Apro il form per l'opzione uno
          setTimeout(() => {setForm1(true)},3000)

          } else if (selectedOption === 2) {

          //Apro il form per l'opzione due
          setTimeout(() => {setForm2(true)},3000)

          } else if (selectedOption === 3) {

          //Apro il form per l'opzione tre
          setTimeout(() => {setForm3(true)},3000)

        }  else if (selectedOption === 4) {

          //Apro il form per l'opzione quattro
          setTimeout(() => {setForm4(true)},3000)

        }


        }

      })
      .catch(error => {
        console.log(error)
      })
  }

  //Quando Seleziono

  const handleChange = selectedOption => {

    //Desetto tutte le scelte precedenti
    setshowUploadImage(false)
    setForm1(false)
    setForm2(false)
    setForm3(false)
    setForm4(false)
    setshowPrevImage(false)


    setSelectedOption(selectedOption.value);
    setSelectedOptionLabel(selectedOption.label);


    //Apro la sezione del upload immagine
    setshowUploadImage(true)


  };

  //Tipologie

  const tipologie = [
    {
    value: 1,
    label: 'Sconto % con o senza Vincolo',
    },
    {
    value: 2,
    label: 'Omaggio con o senza Vincolo',
    },
    {
    value: 3,
    label: 'Sconto Euro con o senza Vincolo',
    },
    {
    value: 4,
    label: 'Offerta secca su prodotto',
    },
  ]

  const NoOptionsMessage = (NoticeProps) => {
    return (
      <p className="mt-2 p-2 text-xs text-gray-500"> Nessuna tipologia trovata</p>
    );
  };

  const Input = (props) => {

    return (
          <components.Input {...props} />
    );
  };

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.finestrapp.it/logo_bianco.svg"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                <Menu attivo={"Crea & Gestisci Offerte"} />

                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.finestrapp.it/logo_bianco.svg"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <Menu attivo={"Crea & Gestisci Offerte"} />

              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">



        {/* Header se admin è loggato come merchant*/}

        { isAdmin ? <div className="bg-green-600">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-green-800">
                              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white truncate">
                              <span className="md:hidden">Attenzione! Stai impersonificando un esercente! Indirizzo Email Esercente: {indirzzi_email_loggato} </span>
                              <span className="hidden md:inline">Attenzione! Stai impersonificando un esercente! Indirizzo Email Esercente: <strong>{indirzzi_email_loggato} </strong> </span>
                            </p>
                          </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <a
                            href="/merchant_list"
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50"
                          >
                            Ritorna al Pannello Amministratore
                          </a>
                        </div>
                          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">

                          </div>
                        </div>
                      </div>
          </div> : null }
          <div className="py-6 md:mt-16 lg:mt-16 xl:mt-16 sm:mt-1" >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white rounded-xl	">
              {/* Replace with your content

               #Contenuto
               */}




               <article>

              {/* Profile header */}

                <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
                  <div className="mt-2  py-8 sm:-mt-16 sm:space-x-5">

                  <div className="bg-white ">
                  <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Crea una nuova offerta</h2>
                  <h2 className="mt-2 xl:mb-6 lg:mb-6 md:mb-6 sm:mb-6 mb-16 text-sm text-gray-500 text-left">Usa i seguenti campi per creare una nuova offerta scegliendo tra le tibologie presenti.</h2>

                  {/* Accordion per spiegazione */}

                  <h2 className="text-1xl font-bold tracking-tight text-gray-900">Legenda tipologie offerte</h2>
                  <h2 className="mt-2  text-sm text-gray-500 text-left">Per aprire la tipologia cliccare sopra e ricliccarci per chiuderla.</h2>


                          <Accordion className="my-4" allowMultipleExpanded allowZeroExpanded>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                     Che cos'è la tipologia:  <strong>Sconto percentuale con o senza Vincolo?</strong>
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      La seguente tipologia serve a creare un offerta che preveda uno sconto percentuale sull'acquisto di uno o piu prodotti con o senza vincolo o vincoli che viene/vengono descritti dall'esercente
                                      in fase di creazione offerta, inoltre tale tipologia prevede due modilità di esaurimento offerta cioè una a limitazione dei pezzi oppure una scadenza temporale.
                                      Es Offerta con Vincolo: 10% di sconto con una spesa minima di €50 su articoli Frutta fresca, in caso di nessun vincolo l'offerta potrebbe essere semplicemnte il 5% su tutti gli articoli.
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                  Che cos'è la tipologia:  <strong>Omaggio con o senza Vincolo?</strong>
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                  La seguente tipologia serve a creare un offerta che preveda un omaggio di un prodotto con o senza vincolo che se presente viene descritto dall'essercente in fase di creazione dell'offerta
                                  inoltre tale tipologia prevede due modilità di esaurimento offerta cioè una a limitazione dei pezzi oppure una scadenza temporale.
                                  Es Offerta con Vincolo: Ogni 10 pizze una Pizza Margerita in Omaggio nel caso di assenza vincolo potrebbe essere degustazione formaggio a banco.
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                  Che cos'è la tipologia:  <strong>Sconto euro con o senza Vincolo?</strong>
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      La seguente tipologia serve a creare un offerta che preveda uno sconto in euro sull'acquisto di uno o piu prodotti con o senza vincolo o vincoli che viene/vengono descritti dall'esercente
                                      in fase di creazione offerta, inoltre tale tipologia prevede due modilità di esaurimento offerta cioè una a limitazione dei pezzi oppure una scadenza temporale.
                                      Es Offerta con Vincolo: 15€ di sconto con una spesa minima di €80 su articoli Frutta fresca, in caso di nessun vincolo l'offerta potrebbe essere semplicemnte il 3€ su tutti gli articoli.
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                  Che cos'è la tipologia:  <strong>Offerta secca su prodotto?</strong>
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                  La seguente tipologia serve a creare un offerta che preveda accesso all'acuisto di un specifico prodotto ad un prezzo diverso da quello di listino con o senza vincolo o vincoli che viene/vengono descritti dall'esercente
                                  in fase di creazione offerta inoltre tale tipologia prevede due modilità di esaurimento offerta cioè una a limitazione dei pezzi oppure una scadenza temporale.
                                  Es Offerta con Vincolo: Offerta Piagiami lunghi a € 29.90 se si acquista anche un paio di Slip mentre senza vincolo potrebbe essere Offerta Speciale Trio Futura Peg Perego € 499.00 al posto di €500
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>

                            {/* Contenuto pagina */}

                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-8 sm:col-span-8">
                        <label htmlFor="titolo" className="block text-sm font-medium text-gray-700">
                          Titolo Offerta
                        </label>
                        <input
                          type="text"
                          name="titolo"
                          id="titolo"
                          {...register("titolo", {required: true})}
                          autoComplete="titolo"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <small className="text-xs text-gray-500" >Nome che l'offerta avrà publicamente</small>
                        {errors.titolo && <p>Il campo è obbligatorio</p>}
                      </div>

                      <input
                          type="hidden"
                          name="selectOption"
                          id="selectOption"
                          defaultValue={selectedOption}
                          {...register("selectOption", )}
                        />

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="scelta" className="block text-sm font-medium text-gray-700">
                          Tipologia Offerta
                        </label>
                        <Select value={selectedOption}  components={{ NoOptionsMessage, Input }}  placeholder={'Seleziona la tipologia'}  onChange={handleChange} options={tipologie} className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"  />
                        <p className="mt-2 text-xs text-gray-500"> Tipologia Selezionata:  <strong className="text-green-700">{selectedOptionLabel}</strong> </p>

                      </div>

                      <div className="col-span-full">

                        <div className="space-y-1 text-center">
                        {showUploadImage ? <div className=" text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500"
                        >
                          <span>Carica immagine Offerta</span>
                          <input id="file-upload" name="image" accept="image/*" multiple={false} type="file" onChange={imageHandler2} className="sr-only" />
                        </label>
                        <p className="text-xs text-gray-500">PNG, JPG fino a 4MB (preferibile 512x512px)</p>
                        </div>
                        : null}
                        <p className="text-sm text-gray-600" >{uploadStatus2}</p>
                        {showPrevImage ?
                        <>
                        <p className="text-sm text-gray-500">Immagine Caricata</p>
                        <img
                        className="h-3/4 rounded-lg ring-4 ring-white sm:h-32 sm:w-32"
                        src={imageProfileUrl}
                        alt="Immagine Offerta"
                       />
                       <p className="text-sm text-green-500">{UploadMesage}</p>
                       </>
                       : null}
                        </div>

                      </div>

                      {/* Form che cambia a seconda della scelta */}

                      { showForm1 ?
                      <>
                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="percentuale" className="block text-sm font-medium text-gray-700">
                          Percentuale di sconto
                        </label>
                        <input
                          type="number"
                          name="percentuale"
                          id="percentuale"
                          {...register("percentuale", {required: true} )}
                          autocomplete="percentuale"
                          placeholder="es. 5%"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <small className="text-xs text-gray-500" >Percentuale di sconto, inserire solamente il numero senza simbolo %</small>
                        {errors.percentuale && <p>Il campo è obbligatorio</p>}
                      </div>

                      <input
                          type="hidden"
                          name="selectOption"
                          id="selectOption"
                          defaultValue={selectedOption}
                          {...register("selectOption", )}
                        />

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="limitipezzi" className="block text-sm font-medium text-gray-700">
                          Limite di pezzi
                        </label>
                        <input
                          type="number"
                          name="limitipezzi"
                          id="limitipezzi"
                          {...register("limitipezzi", {required: true})}
                          autocomplete="limitipezzi"
                          defaultValue="0"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.limitipezzi && <p>Il campo è obbligatorio</p>}

                        <small className="text-xs text-gray-500" >Nel caso si trattasse di offerta con limite di pezzi se no lasciare settato a 0</small>
                      </div>

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="scadenza" className="block text-sm font-medium text-gray-700">
                          Scadenza Offerta
                        </label>
                        <input
                          type="date"
                          name="scadenza"
                          id="scadenza"
                          {...register("scadenza", {required: true} )}
                          autocomplete="scadenza"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.scadenza && <p>Il campo è obbligatorio</p>}

                        <small className="text-xs text-gray-500" >Inserire anche nel caso si tratti di offerta a pezzi</small>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="vincolo_descrizione" className="block text-sm font-medium text-gray-700 mb-1">
                        Descrizione Utilizzo
                        </label>
                        <textarea
                        id="about"
                        name="vincolo_descrizione"
                        {...register("vincolo_descrizione", {required: true} )}
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />
                      {errors.vincolo_descrizione && <p>Il campo è obbligatorio</p>}

                        <small className="text-xs text-gray-500 mt-2" > Definire tutti i vincoli a cui è sottoposta questa offerta, nel caso l'offerta non preveda vincoli, spiegare il metodo di utilizzo ugualmente</small>
                      </div>
                      </>
                    : null }


                    {/* Opzione Form 2 Omaggio con Vincolo o senza */}

                    { showForm2 ?
                      <>
                    <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="omaggio" className="block text-sm font-medium text-gray-700">
                          Omaggio
                        </label>
                        <input
                          type="text"
                          name="omaggio"
                          id="omaggio"
                          {...register("omaggio", {required: true})}
                          autocomplete="omaggio"
                          placeholder="es. Confezione 12 Uova Fresche"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <small className="text-xs text-gray-500" >Descrizione omaggio</small>
                        {errors.omaggio && <p>Il campo è obbligatorio</p>}
                      </div>

                      <input
                          type="hidden"
                          name="selectOption"
                          id="selectOption"
                          defaultValue={selectedOption}
                          {...register("selectOption", )}
                        />

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="limite_pezzi" className="block text-sm font-medium text-gray-700">
                          Limite di pezzi
                        </label>
                        <input
                          type="number"
                          name="limitipezzi"
                          id="limitipezzi"
                          {...register("limitipezzi", {required: true})}
                          autocomplete="limitipezzi"
                          defaultValue="0"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                         {errors.limitipezzi && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-500" >Nel caso si trattasse di offerta con limite di pezzi se no lasciare settato a 0</small>
                      </div>

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="scadenza" className="block text-sm font-medium text-gray-700">
                          Scadenza Offerta
                        </label>
                        <input
                          type="date"
                          name="scadenza"
                          id="scadenza"
                          {...register("scadenza", {required: true})}
                          autocomplete="scadenza"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.scadenza && <p>Il campo è obbligatorio</p>}

                        <small className="text-xs text-gray-500" >Inserire anche nel caso si tratti di offerta a pezzi</small>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="vincolo_descrizione" className="block text-sm font-medium text-gray-700 mb-1">
                        Descrizione Utilizzo
                        </label>
                        <textarea
                        id="about"
                        name="vincolo_descrizione"
                        {...register("vincolo_descrizione", {required: true} )}
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />
                      {errors.vincolo_descrizione && <p>Il campo è obbligatorio</p>}                        <small className="text-xs text-gray-500 mt-2" > Definire tutti i vincoli a cui è sottoposta questa offerta, nel caso l'offerta non preveda vincoli, spiegare il metodo di utilizzo ugualmente</small>
                      </div>
                      </>
                    : null }


                    {/* Opzione Form 3 Sconto Euro con o senza Vincolo */}

                    { showForm3 ?
                      <>
                    <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="scontoEuro" className="block text-sm font-medium text-gray-700">
                          Sconto in Euro
                        </label>
                        <input
                          step=".01"
                          type="number"
                          name="scontoEuro"
                          id="scontoEuro"
                          {...register("scontoEuro", {required: true})}
                          autocomplete="scontoEuro"
                          placeholder="es. 15"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <small className="text-xs text-gray-500" >Sconto in euro usare il punto per separare i decimali</small>
                        {errors.scontoEuro && <p>Il campo è obbligatorio</p>}
                      </div>

                      <input
                          type="hidden"
                          name="selectOption"
                          id="selectOption"
                          defaultValue={selectedOption}
                          {...register("prodotto", )}
                        />

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="limitipezzi" className="block text-sm font-medium text-gray-700">
                          Limite di pezzi
                        </label>
                        <input
                          type="number"
                          name="limitipezzi"
                          id="limitipezzi"
                          {...register("limitipezzi", {required: true})}
                          autocomplete="limitipezzi"
                          defaultValue="0"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                         {errors.limitipezzi && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-500" >Nel caso si trattasse di offerta con limite di pezzi se no lasciare settato a 0</small>
                      </div>

                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="scadenza" className="block text-sm font-medium text-gray-700">
                          Scadenza Offerta
                        </label>
                        <input
                          type="date"
                          name="scadenza"
                          id="scadenza"
                          {...register("scadenza", {required: true})}
                          autocomplete="scadenza"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                                                 {errors.scadenza && <p>Il campo è obbligatorio</p>}

                        <small className="text-xs text-gray-500" >Inserire anche nel caso si tratti di offerta a pezzi</small>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="vincolo_descrizione" className="block text-sm font-medium text-gray-700 mb-1">
                        Descrizione Utilizzo
                        </label>
                        <textarea
                        id="about"
                        name="vincolo_descrizione"
                        {...register("vincolo_descrizione", {required: true} )}
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />
                      {errors.vincolo_descrizione && <p>Il campo è obbligatorio</p>}                        <small className="text-xs text-gray-500 mt-2" > Definire tutti i vincoli a cui è sottoposta questa offerta, nel caso l'offerta non preveda vincoli, spiegare il metodo di utilizzo ugualmente</small>
                      </div>
                      </>
                    : null }

                    {/* Opzione Form 4 Offerta secca su prodotto */}

                    { showForm4 ?
                      <>
                    <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="prodotto" className="block text-sm font-medium text-gray-700">
                          Prodotto
                        </label>
                        <input
                          type="text"
                          name="prodotto"
                          id="prodotto"
                          {...register("prodotto", {required: true})}
                          autocomplete="prodotto"
                          placeholder="Passeggino Chicco XR34 - SMONTABILE"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <small className="text-xs text-gray-500" >Prodotto oggetto offerta</small>
                        {errors.prodotto && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="prezzoProdotto" className="block text-sm font-medium text-gray-700">
                          Prezzo Prodotto Scontato
                        </label>
                        <input
                          step=".01"
                          type="number"
                          name="prezzoProdotto"
                          id="prezzoProdotto"
                          {...register("prezzoProdotto", {required: true})}
                          autocomplete="prezzoProdotto"
                          placeholder="Es. 199€"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <small className="text-xs text-gray-500" >Prezzo prodotto oggetto offerta, usare il punto per separare i decimali</small>
                        {errors.prezzoProdotto && <p>Il campo è obbligatorio</p>}
                      </div>


                      <input
                          type="hidden"
                          name="selectOption"
                          id="selectOption"
                          defaultValue={selectedOption}
                          {...register("selectOption", )}
                        />

                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="limitepezzi" className="block text-sm font-medium text-gray-700">
                        Limite Pezzo
                        </label>
                        <input
                          type="number"
                          name="limitipezzi"
                          id="limitipezzi"
                          {...register("limitipezzi", {required: true})}
                          autocomplete="limitipezzi"
                          defaultValue="0"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                                                                         {errors.limitipezzi && <p>Il campo è obbligatorio</p>}

                        <small className="text-xs text-gray-500" >Nel caso si trattasse di offerta con limite di pezzi se no lasciare settato a 0</small>
                      </div>

                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="scadenza" className="block text-sm font-medium text-gray-700">
                          Scadenza Offerta
                        </label>
                        <input
                          type="date"
                          name="scadenza"
                          id="scadenza"
                          {...register("scadenza", {required: true})}
                          autocomplete="scadenza"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                         {errors.scadenza && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-500" >Inserire anche nel caso si tratti di offerta a pezzi</small>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="vincolo_descrizione" className="block text-sm font-medium text-gray-700 mb-1">
                          Descrizione Utilizzo
                        </label>
                        <textarea
                        id="about"
                        name="vincolo_descrizione"
                        {...register("vincolo_descrizione", {required: true} )}
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />
                      {errors.vincolo_descrizione && <p>Il campo è obbligatorio</p>}                        <small className="text-xs text-gray-500 mt-2" > Definire tutti i vincoli a cui è sottoposta questa offerta, nel caso l'offerta non preveda vincoli, spiegare il metodo di utilizzo ugualmente</small>
                      </div>
                      </>
                    : null }


                    </div>



                    <div className="flex justify-end mt-5">
                    <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                    Crea Offerta
                    </button>
                    </div>
                    </form>


                            </div>
                          </div>
                          </div>


              </article>






               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


     {/* Modale per il crop dell' immagine offerta */}

     <Transition.Root show={openModalCropProfile} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModalCropProfile}
        onClose={setOpenModalCropProfile}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">

                  <div style={{
                      height: '28vw',
                      position: 'relative',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: '26vw',
                    }} >
                  <Cropper
                        image={ImgProfile}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={3 / 4}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                    </div>

                    {/* Corpo del modale */}

                  <div className="space-y-6">

                  <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">


                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Modifica Immagine</h3>
                        <p className="mt-1 text-sm text-gray-500">Utilizza i seguenti tools per modificare l'immagine e settarla al meglio all'interno della griglia.</p>


                        { showErr10 ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> Errore nel ritaglio del immagine! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc10 ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Immagine ritagliata e settata correttamente, si prega di chiudere la finestra!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }
                      </div>


                      <div className="mt-5 md:mt-0 md:col-span-2">

                      <div className={styles.controls}>
                            <div className={styles.sliderContainer}>
                              <Typography
                                variant="overline"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Zoom
                              </Typography>
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                classes={{ root: styles.slider }}
                                onChange={(e, zoom) => setZoom(zoom)}
                              />
                            </div>
                            <div className={styles.sliderContainer}>
                              <Typography
                                variant="overline"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Rotazione
                              </Typography>
                              <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                classes={{ root: styles.slider }}
                                onChange={(e, rotation) => setRotation(rotation)}
                              />
                            </div>
                          </div>


                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 flex flex-row-reverse ">
                <button
                  type="button"
                  onClick={showCroppedImage}
                  className="w-max mx-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                >
                  Modifica Immagine
                </button>
                <button
                  type="button"
                  className="mt-3 w-max inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModalCropProfile(false)}
                  ref={cancelButtonRef}
                >
                  Chiudi
                </button>
                </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


    {/* Modale dettagglio offerta */}

    <Transition.Root show={openOffer} as={Fragment}>
      <Dialog
        as="div"
        static
        className="w-full h-full fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openOffer}
        onClose={setOpenOffer}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-8/12 sm:p-6">
              <div>

                <div className="mt-3 sm:mt-5">

                  <div className="mt-2">




                    <div className="space-y-6">


                    {/* Corpo del modale */}



      <div className="bg-white  px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Dettaglio Offerta</h3>
            <p className="mt-1 text-sm text-gray-500">di seguito puoi notare tutti i dettagli dell'offerta in corso. <strong className="mt-3">NB. A seconda della tipologia di offerta non tutti i campi saranno utilizzati.</strong></p>


            <p className="mt-3 text-md text-gray-500">Immagine Offerta:</p>

            <img className="rounded-md shadow-lg w-60 mt-1 p-2" src={imageProfileUrl2} />




          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">

          <p className="mt-1 text-md text-gray-500">Titolo Offerta:</p>
          <p className="mt-1 text-sm font-extrabold text-green-700">{openIDOffer[0].titolo_offerta}</p>

          <p className="mt-3 text-md text-gray-500">Descrizione & Vincolo Offerta:</p>
          <p className="mt-1 text-sm text-gray-700 text-justify">{openIDOffer[0].vincolo_descrizione}</p>

          <div className="flex mt-3">
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Tipologia:</p>
          <p className="mt-1 text-sm  font-bold text-green-700">{openIDOffer[0].tipologia}</p>
          </div>
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Scadenza Temporale:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].scadenza}</p>
          </div>
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Limiti Pezzi:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].limiti_pezzi}</p>
          </div>
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Omaggio:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].omaggio_descrizione}</p>
          </div>
        </div>

        <div className="flex mt-3">
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Percentuale Sconto:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].percentuale_sconto} %</p>
          </div>
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Sconto Euro:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].sconto_euro} €</p>
          </div>
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Prodotto in Offerta:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].prodotto}</p>
          </div>
          <div className="flex-1">
          <p className="mt-1 text-md text-gray-500">Prezzo Prodotto:</p>
          <p className="mt-1 text-sm text-gray-700">{openIDOffer[0].prezzo_prodotto} €</p>
          </div>
        </div>

          <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setOpenOffer(false)}
                >
                  Ritorna alle offerte
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    {/* Modale per ls conferma della cancellazione dell'offerta */}

    <Transition.Root show={openConfirm} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpenConfirm}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Attenzione
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler cancellare l'offerta selezionata?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => cancellaOfferta(idOfferta2)}
                >
                  Cancella
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpenConfirm(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


     {/* Notifche di successo */}

     { showSucc ? <SuccNotify titolo={"Congratulazioni"}  testo={"L'offerta è stata creata con successo"} /> : null }

    {/* Notifche di insucesso */}

    { showErr ? <ErrNotify titolo={"Attenzione"}  testo={"Errore nella creazione dell'offerta, si prega di seguire tutti i passaggi proposti per la creazione dell'offerta. Tutti i campi sono obbligatori compreso il campo descrizione"} /> : null }


 {/* Notifche di successo */}

 { showSucc1 ? <SuccNotify titolo={"Congratulazioni"}  testo={"L'offerta è stata cancellata con successo"} /> : null }

     {/* Notifche di insucesso */}

     { showErr1 ? <ErrNotify titolo={"Attenzione"}  testo={"Errore nella cancellazione dell'offerta, erta. Riprova!"} /> : null }


      {/* Notifche di successo */}

 { showSucc2 ? <SuccNotify titolo={"Congratulazioni"}  testo={"Notifica inviata con successo!"} /> : null }

     {/* Notifche di insucesso */}

     { showErr2 ? <ErrNotify titolo={"Attenzione"}  testo={"La notifica Push per questa offerta è già stata inviata!"} /> : null }



    <div className="max-w-5xl mx-auto mt-4 px-2 sm:px-6 lg:px-8">
    <div className="mt-2  py-8 sm:-mt-16 sm:space-x-5">

    <div className="bg-white ">

                         {/* Tabella Esercenti */}

            {isRefresh ? <Table  columns={columns} data={allOffer} pagSize={3} /> : null}
                  </div>
                  </div>
                  </div>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
