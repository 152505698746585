import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import Table from "./Table";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import SidebarMenu from "./sidebar_menu"
import * as XLSX from 'xlsx';

import {
  TrashIcon,
  EyeIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

//Importo Menu
//import Menu from "./navigation";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Clienti() {

 
  //Setto gli state
  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState({});
  const [pagesNavigation, setPagesNavigation] = useState(1)


  const [allClienti, setClienti] = useState([])
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [search, setSearch] = useState("");

    const [show, setShow] = useState(20);
    //Recupero Lista Utenti
    useEffect(() => {
        let mounted = true
        getClienti()

        return () => {
            mounted = false
        }
    }, [search, page, show])

  const getClienti = async () => {
      await axios.get(`${window.$apiEndpoint}/clienti/fetch`, {
          params: {page: Number(page),
          limit: Number(show),
          search: search
        },
          headers: {
              "x-access-token": cookies.token
          }
      }).then(result => {
          if (result.data) {
              setClienti(result.data.clienti);
              setPages(result.data.pages);
              setPagesNavigation(result.data.pagesNavigation)
              setLoading(true);
          }
      }).catch(e => {
          alert(e);
      });
  }

        //Chiamata post per inserimento utente
        const onSubmit = async (data) => {
          const response = await axios.post(`${window.$apiEndpoint}/clienti/create`, data ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti()
          reset()

      }

  //Funzione per download XLS

  const downloadxls = () => {
     let form = []
     allClienti.map((item, index) => {
         let obj = {
           'Ragione Sociale': item.ragioneSociale,
           'PIVA / CF': item.vat,
           'Telefono': item.tel,
           'PEC': item.pec,
           'SDI': item.sdi,
           'Origine': item.origine,
           'Indirizzo': item.address,
           'Civico': item.civic,
           'Codice postale': item.postalCode,
           'Città': item.city,
           'Provincia': item.region,
           'Numero preventivi': item.preventivi.length
         }
         form.push(obj)

     })
     const ws = XLSX.utils.json_to_sheet(form);
     const wb = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, "Clienti");
     /* generate XLSX file and send to client */
     XLSX.writeFile(wb, "Clienti.xlsx");
 };


    const searchCustomer = async () => {
        getClienti()
    }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
          <SidebarMenu attivo={"Clienti"} open={true} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
              {/* Replace with your content */}
              <h2 className="max-w-full mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Crea un nuovo cliente
              </h2>

              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Completa tutti i campi</h3>
                      <p className="mt-1 text-sm text-gray-400">Crea qui il nuovo cliente al quale associare un preventivo. I campi contrassegnati con <strong><span style={{ color: 'red' }}>*</span></strong> sono obbligatori.</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                          Ragione Sociale<strong><span style={{color: 'red'}}>*</span></strong>
                        </label>
                        <input
                          type="text"
                              name="ragioneSociale"
                              id="ragioneSociale"
                              {...register("ragioneSociale", { required: true })}
                              autoComplete="ragioneSociale"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                            {errors.ragioneSociale && <p>Il campo è obbligatorio</p>}

                      </div>

                      <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="p_iva" className="block text-sm font-medium text-gray-700">
                          PIVA/CF
                        </label>
                        <input
                          type="text"
                          name="vat"
                              {...register("vat", { required: false })}
                              id="vat"
                              autoComplete="vat"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                            {errors.vat && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        {...register("email", { required: false })}
                              id="email"
                              autoComplete="email"
                        className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors.email && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="n_telefono" className="block text-sm font-medium text-gray-700">
                          Telefono
                        </label>
                        <input
                          type="tel"
                              name="tel"
                          {...register("tel", { required: false })}
                              id="tel"
                              autoComplete="tel"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.tel && <p>Il campo è obbligatorio</p>}

                      </div>


                      <div className="col-span-4 sm:col-span-4">
                        <label htmlFor="street_address" className="block text-sm font-medium text-gray-700">
                          Indirizzo
                        </label>
                        <input
                          type="text"
                          name="street_address"
                          {...register("address", { required: false })}
                          id="street_address"
                          autoComplete="street_address"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.street_address && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="civic" className="block text-sm font-medium text-gray-700">
                          Civico
                        </label>
                        <input
                          type="text"
                          name="civic"
                          {...register("civic", { required: false })}
                          id="civic"
                          autoComplete="civic"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.civic && <p>Il campo è obbligatorio</p>}
                      </div>


                          <div className="col-span-1 sm:col-span-1 lg:col-span-1">
                            <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                              CAP
                            </label>
                            <input
                              type="text"
                              {...register("postalCode", { required: false })}
                              name="postalCode"
                              id="postalCode"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.postalCode && <p>Il campo è obbligatorio</p>}
                          </div>

                      <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          Città
                        </label>
                        <input
                          type="text"
                          {...register("city", { required: false })}
                          name="city"
                          id="city"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.city && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-1 sm:col-span-1 lg:col-span-1">
                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                          Provincia
                        </label>
                        <input
                          type="text"
                          maxLength={2}
                          {...register("region", { required: false })}
                              name="region"
                              id="region"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                            {errors.region && <p>Il campo è obbligatorio</p>}

                      </div>


                          <div className="col-span-4 sm:col-span-4">
                            <label htmlFor="pec" className="block text-sm font-medium text-gray-700">
                              PEC
                            </label>
                            <input
                              type="email"
                              name="pec"
                              {...register("pec", { required: false })}
                              id="pec"
                              autoComplete="pec"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.pec && <p>Il campo è obbligatorio</p>}
                          </div>

                          <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="sdi" className="block text-sm font-medium text-gray-700">
                              SDI
                            </label>
                            <input
                              type="text"
                              name="sdi"
                              {...register("sdi", { required: false })}
                              id="sdi"
                              autoComplete="sdi"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.sdi && <p>Il campo è obbligatorio</p>}

                          </div>
                          <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="origine" className="block text-sm font-medium text-gray-700">
                              Origine
                            </label>
                            <input
                              type="text"
                              name="origine"
                              {...register("origine", { required: false })}
                              id="origine"
                              autoComplete="origine"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.origine && <p>Il campo è obbligatorio</p>}

                          </div>




                    </div>

                    <div className="flex justify-end mt-5">
                    <button
                    type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                    Aggiungi
                    </button>
                    </div>
                    </form>
                    </div>



                </div>



                {/* Stampo errore o messaggio di successo*/}

                { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> L'indirizzo email inserito risulta già esistente, ricorda l'email dell'esercente deve essere univoca (Inoltre non deve appartenere anche ad un utente)! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Esercente creato con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-blue-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }


                  <div className="max-w-12xl mx-auto mt-8" >
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className=" overflow-hidden sm:rounded-lg">


                                                      <div className="sm:flex-auto">                                                  
                                                          <div className="flex justify-between align-center">
                                                              <div className="w-50">
                                                                  <div className="mt-1 flex rounded-md shadow-sm">
                                                                      <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                          <input
                                                                              type="text"
                                                                              name="search"
                                                                              id="search"
                                                                              className="block w-full rounded-none rounded border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                              placeholder="🔍 Cerca per ragione sociale, IVA o città"
                                                                              onChange={(e) => searchCustomer()}
                                                                              onKeyUp={e => setSearch(e.target.value)}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              <div className="mt-1">
                                                               
                                                                  <button
                                                                      type="button"
                                                                      className="rounded-md border border-transparent bg-success px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-50"
                                                                      onClick={() => downloadxls()}
                                                                  >
                                                                      Esporta
                                                                  </button>
                                                              </div>
                                                              <div>
                                                                
                                                                  <Menu as="div" className="relative inline-block text-left z-50">
                                                                      <div className="mt-1">
                                                                          <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                                              Visualizza: {show}
                                                                              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                          </Menu.Button>
                                                                      </div> 
                                                                      <Transition
                                                                          as={Fragment}
                                                                          enter="transition ease-out duration-100"
                                                                          enterFrom="transform opacity-0 scale-95"
                                                                          enterTo="transform opacity-100 scale-100"
                                                                          leave="transition ease-in duration-75"
                                                                          leaveFrom="transform opacity-100 scale-100"
                                                                          leaveTo="transform opacity-0 scale-95"
                                                                      >

                                                                          <Menu.Items className="z-50">
                                                                              <div className="py-1">
                                                                              <Menu.Item as={Fragment}>
                                                                                      {({ active }) => (
                                                                                          <label
                                                                                              className={classNames(
                                                                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                  'block w-full px-4 py-2 text-center text-sm'
                                                                                              )}
                                                                                              onClick={e => setShow(e.currentTarget.innerText)}
                                                                                          >
                                                                                              10
                                                                                          </label>
                                                                                      )}
                                                                                  </Menu.Item>
                                                                                  <Menu.Item>
                                                                                      {({ active }) => (
                                                                                          <label
                                                                                              className={classNames(
                                                                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                  'block w-full px-4 py-2 text-center text-sm'
                                                                                              )}
                                                                                              onClick={e => setShow(e.currentTarget.innerText)}
                                                                                          >
                                                                                              20
                                                                                          </label>
                                                                                      )}
                                                                                  </Menu.Item>
                                                                                  <Menu.Item>
                                                                                      {({ active }) => (
                                                                                          <label
                                                                                              className={classNames(
                                                                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                  'block w-full px-4 py-2 text-center text-sm'
                                                                                              )}
                                                                                              onClick={e => setShow(e.currentTarget.innerText)}
                                                                                          >
                                                                                              50
                                                                                          </label>
                                                                                      )}
                                                                                  </Menu.Item>
                                                                                  <Menu.Item>
                                                                                      {({ active }) => (
                                                                                          <label
                                                                                              className={classNames(
                                                                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                  'block w-full px-4 py-2 text-center text-sm'
                                                                                              )}
                                                                                              onClick={e => setShow(e.currentTarget.innerText)}
                                                                                          >
                                                                                              100
                                                                                          </label>
                                                                                      )}
                                                                                  </Menu.Item>
                                                                              </div>
                                                                          </Menu.Items>
                                                                      </Transition>
                                                                  </Menu>
                                                                  <div className="flex flex-1 justify-between sm:hidden">
                                                                      <a
                                                                          href="#"
                                                                          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                      >
                                                                          Previous
                                                                      </a>
                                                                      <a
                                                                          href="#"
                                                                          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                      >
                                                                          Next
                                                                      </a>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                 {loading ? <div className="mt-8 flex flex-col">
                                                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                                  <table className="min-w-full divide-y divide-gray-300">
                                                                      <thead className="bg-gray-50">
                                                                          <tr>
                                                                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                  Ragione sociale
                                                                              </th>
                                                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                  Indirizzo
                                                                              </th>
                                                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                  Preventivi
                                                                              </th>
                                                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                  Opzioni
                                                                              </th>
                                                                              {/*<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Role
                                    </th>*/}
                                                                              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                              </th>
                                                                          </tr>
                                                                      </thead>
                                                                      <tbody className="divide-y divide-gray-200 bg-white">
                                                                          {loading ? allClienti.map((cliente) => (
                                                                              <tr key={cliente._id}>
                                                                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                      <div className="flex items-center">
                                                                                          <div className="h-10 w-15 flex-shrink-0">
                                                                                          </div>
                                                                                          <div className="ml-4">
                                                                                              <div className="font-medium text-gray-900">{cliente.ragioneSociale}</div>
                                                                                              <div className="text-gray-500">{cliente.vat}</div>
                                                                                          </div>
                                                                                      </div>
                                                                                  </td>
                                                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                      <div className="text-gray-500"> {cliente.address}, {cliente.civic}</div>
                                                                                      <div className="text-gray-900">{cliente.city} ({cliente.region}), {cliente.postalCode}</div>
                                                                                  </td>
                                                                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-between my-2">
                                                                                    {cliente.preventivi.length}
                                                                                  </td>
                                                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                      <div className="flexxo">
                                                                                          <ReactTooltip />
                                                                                          <Link to={`/clienti/${cliente._id}`} className="text-gray-600 hover:text-gray-900">
                                                                                              <button
                                                                                                  type="button"
                                                                                                  className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                                              >
                                                                                                  <EyeIcon data-tip="Vedi" className="h-4 w-4" aria-hidden="true" />
                                                                                              </button>
                                                                                          </Link>
                                                                                      </div>
                                                                                  </td>
                                                                                  
                                                                              </tr>
                                                                          )) :
                                                                              <tr>
                                                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                                                      <div className="flex justify-center items-center">
                                                                                          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                                              <span className="visually-hidden">Loading...</span>
                                                                                          </div>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          }
                                                                      </tbody>
                                                                  </table>
                                                                  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                                                      <div className="flex flex-1 justify-between sm:hidden">
                                                                          <a
                                                                              href="#"
                                                                              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                          >
                                                                              Previous
                                                                          </a>
                                                                          <a
                                                                              href="#"
                                                                              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                          >
                                                                              Next
                                                                          </a>
                                                                      </div>
                                                                      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                                                          <div>
                                                                              <p className="text-sm text-gray-700">
                                                                                  Visualizza <span className="font-medium">{show >= allClienti.length ? allClienti.length : show}</span> di{' '}
                                                                                  <span className="font-medium">{allClienti.length}</span> risultati
                                                                              </p>
                                                                          </div>
                                                                          <div>
                                                                              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                                                                  <a
                                                                                      href="#"
                                                                                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                                                                  >
                                                                                      <span className="sr-only">Prec</span>
                                                                                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                  </a>
                                                                                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                                                                                  {(() => {
                                                                                      switch (pages) {
                                                                                          case 1:
                                                                                              return (<a
                                                                                                  href="#"
                                                                                                  aria-current="page"
                                                                                                  className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                                                                              >
                                                                                                  1
                                                                                              </a>);
                                                                                          case (pages > 1):
                                                                                              return (
                                                                                              <>
                                                                                                      {pagesNavigation.map(item => {
                                                                                                          <a
                                                                                                              href="#"
                                                                                                              aria-current="page"
                                                                                                              className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                                                                                          >
                                                                                                              {item}
                                                                                                          </a>
                                                                                                      })

                                                                                                }
                                                                                              </>
                                                                                              );
                                                                                          default:
                                                                                              return (<a
                                                                                                  href="#"
                                                                                                  aria-current="page"
                                                                                                  className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                                                                              >
                                                                                                  1
                                                                                              </a>);
                                                                                      }
                                                                                  })()}
                                                                                  <a
                                                                                      href="#"
                                                                                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                                                                  >
                                                                                      <span className="sr-only">Succ</span>
                                                                                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                  </a>
                                                                                  
                                                                                  
                                                                              </nav>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              


                                                  </div> : <tr>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                          <div className="flex justify-center items-center">
                                                              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                  <span className="visually-hidden">Loading...</span>
                                                              </div>
                                                          </div>
                                                      </td>
                                                  </tr> }
                        </div>
                      </div>
                    </div>

                  </div>

              </div>
            </div>
              </div>


              </div>




              </main>
               </div>

             

            </div>




  )
}
