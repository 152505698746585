import { useHistory} from 'react-router-dom'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { Switch } from '@headlessui/react'


import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SidebarMenu from "./sidebar_menu"

import {
  TrashIcon,
  ExclamationCircleIcon,
  PencilAltIcon,
  MenuIcon,
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Utenti() {

  const [isAdmin, setAdmin] = useState(false)

  const columns = [
    {
      dataField: 'fname',
      text: 'Utente',
      formatter: (value, row) => {
        return (
          <>
            <ReactTooltip />
            <div className='flexxo font-bold' >
              {row.fname + " " + row.lname} 
            </div>
          </>

        )
      }
    },
    {
      dataField: 'email',
      text: 'Email',
      formatter: (value, row) => {
        return (
          <>
            <ReactTooltip />
            <div className='flexxo font-bold' >
              {row.email} 
            </div>
          </>

        )
      }
    },
    {
      dataField: 'role',
      text: 'Ruolo',
      formatter: (value, row) => {
        return (
          <>
            {(() => {
              switch (row.role) {
                case 'ADMIN':
                  return (<span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                    {row.role}
                  </span>);
                case 'USER':
                  return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                    {row.role}
                  </span>);
                default:
                  return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                    {row.role}
                  </span>);
              }
            })()}
          </>

        )
      }
    },
    {
      dataField: '',
      text: 'Opzioni',
      formatter: (value, row) => {
        return (
          <div className="flexxo">
            <ReactTooltip />
            <button
              type="button"
              onClick={() => {
                setSwitchEdited(row.role === 'ADMIN' ? true : false)
                setToBeEdited(row)
                setEditModal(!editModal)
              }}
              className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon data-tip="Modifica" className="h-4 w-4" aria-hidden="true" />
            </button>
            <button
              type="button"
              disabled={cookies.email === row.email ? true : false}
              onClick={() => {
                setToBeDeleted(row)
                setAdvModal(!advModal)
              }}
              className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <TrashIcon data-tip="Elimina" className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        )
      }
    }
  ];

  //Setto gli state
  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  const [editModal, setEditModal] = useState(false)
  const [toBeEdited, setToBeEdited] = useState(null)
  const [switchEdited, setSwitchEdited] = useState(false)


  const [advModal, setAdvModal] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    getUtenti()
    
    return () => {
      mounted = false
    }
  }, [])


  const [allUtenti, setUtenti] = useState([])


  const getUtenti = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/user/fetch`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setUtenti(response.data)
    setLoading(true)
  }

  const getDup = async (email) => {
    const response = await axios.get(`${window.$apiEndpoint}/user/fetchDup`, {
      params: {
        email: email
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    if(response.data.found) {
      return true
    } else {
      return false
    }
  }

        //Chiamata post per inserimento utente
        const onSubmit = async (data) => {

          let found = await getDup(data.email)

          console.log(found)

          if (!found) {
              setDup(false)
              const response = await axios.post(`${window.$apiEndpoint}/user/register`, {
              fname: data.fname.toUpperCase(),
              lname: data.lname.toUpperCase(),
              email: data.email.toLowerCase().trim(),
              role: isAdmin ? 'ADMIN' : 'USER'
            } ,{
              headers: {
                "x-access-token": cookies.token
              }
            })
            getUtenti()
            reset()
          } else {
            setDup(true)
          }

          
          
      }

      const [isDup, setDup] = useState(false)

  //Chiamata post per eliminare articolo dal listino
  const deleteArticolo = async (data) => {
    if (toBeDeleted) {
      const response = await axios.post(`${window.$apiEndpoint}/user/delete`, {
        id: data._id,
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBeDeleted(null)
      setAdvModal(!advModal)
      getUtenti()
    }

  }


  const editArticolo = async (data) => {
    if (toBeEdited) {
      const response = await axios.patch(`${window.$apiEndpoint}/user/edit`, {
        servizio: data,
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBeEdited(null)
      setEditModal(!editModal)
      getUtenti()
    }
  }



  const { SearchBar } = Search;



    //Se lo state isCreated ricarico la pagina
    if (isCreated) {
        //Ricarica Pagina
        return window.location.reload();
    }


  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu  attivo={"Utenti"} open={true}/>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
              {/* Replace with your content */}
              <h2 className="max-w-full mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Crea un nuovo utente
              </h2>

              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Completa tutti i campi</h3>
                      <p className="mt-1 text-sm text-gray-400">Crea qui il nuovo utente al quale dare accesso al gestionale. I campi contrassegnati con <strong><span style={{ color: 'red' }}>*</span></strong> sono obbligatori.</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">

                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                              Nome<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="text"
                              name="fname"
                              {...register("fname", { required: true })}
                              id="fname"
                              autoComplete="fname"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.fname && <p>Il campo è obbligatorio</p>}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="n_telefono" className="block text-sm font-medium text-gray-700">
                              Cognome<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="text"
                              name="lname"
                              {...register("lname", { required: true })}
                              id="lname"
                              autoComplete="lname"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.lname && <p>Il campo è obbligatorio</p>}
                          </div>


                      <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email<strong><span style={{ color: 'red' }}>*</span></strong>
                        </label>
                        <input
                          type="email"
                              name="email"
                              id="email"
                              {...register("email", { required: true })}
                              autoComplete="email"
                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                            {errors.email && <p>Il campo è obbligatorio</p>}
                            {isDup && <p className="text-red-500 font-bold text-sm	mt-2">L'email risulta associata ad un utente esistente, scegli una nuova email.</p>}

                      </div>

                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <Switch
                              checked={isAdmin}
                              onChange={() => setAdmin(!isAdmin)}
                              className={classNames(
                                isAdmin ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                              )}
                            >
                              <span className="sr-only">Agree to policies</span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  isAdmin ? 'translate-x-5' : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                              />
                            </Switch>
                             
                          </div>
                            <div className="ml-3">
                              <p className="text-base text-gray-500">
                                Amministratore
                              </p>
                            </div>
                    </div>
                



                    </div>

                    <div className="flex justify-end mt-5">
                    <button
                    type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                    Aggiungi
                    </button>
                    </div>
                    </form>
                    </div>



                </div>

                

                {/* Stampo errore o messaggio di successo*/}

                { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> L'indirizzo email inserito risulta già esistente, ricorda l'email dell'esercente deve essere univoca (Inoltre non deve appartenere anche ad un utente)! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Esercente creato con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-blue-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }


                  <div className="max-w-12xl mx-auto mt-8" >
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className=" overflow-hidden sm:rounded-lg">


                            {/* Tabella Esercenti */}

                            {loading ? <ToolkitProvider
                              keyField='vat'
                              data={allUtenti}
                              columns={columns}
                              search
                            >
                              {
                                props => (
                                  <div>
                                    <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                      {...props.searchProps}
                                      placeholder="Cerca"
                                      className="search-input text-left"
                                    />
                                    </div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      striped
                                      hover
                                      condensed
                                      keyField="vat"
                                      pagination={paginationFactory({
                                        showTotal: false,
                                        firstPageText: 'Inizio', // the text of first page button
                                        prePageText: 'Prec', // the text of previous page button
                                        nextPageText: 'Succ', // the text of next page button
                                        lastPageText: 'Fine', // the text of last page button
                                        nextPageTitle: 'Vai al successivo', // the title of next page button
                                        prePageTitle: 'Vai al precedente', // the title of previous page button
                                        firstPageTitle: 'Vai al primo', // the title of first page button
                                        lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                      })}
                                    />
                                  </div>
                                )
                              }
                            </ToolkitProvider> : null}

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

              </div>
            </div>
              </div>

              
              </div>

              

              
              </main>
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

      <Transition.Root show={advModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Attenzione!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Stai per eliminare il fornitore <strong>e tutti gli articoli</strong> ad esso associati, vuoi procedere?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        deleteArticolo(toBeDeleted)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeDeleted(null)
                        setAdvModal(!advModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={advModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Attenzione!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Stai per eliminare l'utente, vuoi procedere?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        deleteArticolo(toBeDeleted)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeDeleted(null)
                        setAdvModal(!advModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Modifica
                      </Dialog.Title>
                      {toBeEdited ? <div className="mt-2">
                        <div className="grid grid-cols-6 gap-6">

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="fname" className="block text-left text-sm font-medium text-gray-700">
                              Nome<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="text"
                              defaultValue={toBeEdited.fname}
                              onChange={
                                e => setToBeEdited(params => ({
                                  ...params,
                                  fname: e.target.value,
                                }))
                              }
                              name="fname"
                              id="fname"
                              autoComplete="fname"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />

                          </div>


                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="lname" className="block text-left text-sm font-medium text-gray-700">
                              Cognome<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="text"
                              defaultValue={toBeEdited.lname}
                              onChange={
                                e => setToBeEdited(params => ({
                                  ...params,
                                  lname: e.target.value,
                                }))
                              }
                              name="lname"
                              id="lname"
                              autoComplete="lname"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>


                          <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">
                              Email<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="email"
                              disabled={cookies.email === toBeEdited.email}
                              defaultValue={toBeEdited.email}
                              onChange={
                                e => setToBeEdited(params => ({
                                  ...params,
                                  email: e.target.value,
                                }))
                              }
                              name="email"
                              id="email"
                              autoComplete="email"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="flex items-start">
                            <div className="flex-shrink-0">
                              <Switch
                                checked={toBeEdited.role === 'ADMIN' ? true : false}
                                onChange={
                                  e => setToBeEdited(params => ({
                                    ...params,
                                    role: e ? 'ADMIN' : 'USER',
                                  }))
                                }
                                className={classNames(
                                  toBeEdited.role === 'ADMIN' ? 'bg-indigo-600' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    toBeEdited.role === 'ADMIN' ? 'translate-x-5' : 'translate-x-0',
                                    'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                  )}
                                />
                              </Switch>

                            </div>
                            <div className="ml-3">
                              <p className="text-base text-gray-500">
                                Amministratore
                              </p>
                            </div>
                          </div>




                        </div>
                      </div> : null}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        editArticolo(toBeEdited)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeEdited(null)
                        setEditModal(!editModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

              {/* /End replace */}

            </div>




  )
}
