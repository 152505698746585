/* Variabili di Navigazione per Menu   */
import { Fragment, useState, useRef, useEffect, useCallback, useMemo } from 'react'
import axios from 'axios'

import {
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    MenuIcon,
    UsersIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
    Logout,
    GiftIcon,
    UserGroup,
    ExclamationIcon,
    CogIcon,
    EyeOffIcon,
    EyeIcon,
    InformationCircleIcon,
    TrashIcon,
    MailIcon,
    ViewGridAddIcon,
    PlusCircleIcon,
    TicketIcon,
    ChatIcon,


  } from '@heroicons/react/outline'

const { parseCookies, destroyCookie } = require('nookies');
const cookies = parseCookies()


export default function Menu(attivo){

const [AllMessaggi, setAllMessaggi] = useState([{}]);
const indirzzi_email_loggato = cookies.email_events;
const [loading, setLoading] = useState(false)
const [isCreated, setCreated] = useState(false)

//Controllo che i cookie siano settati correttamente

    if (indirzzi_email_loggato == null) {
        // do cookie doesn't exist stuff;
        setCreated(true)
        //Ricarica Pagina
    }
    else {
        // do cookie exists stuff

    }


//Recupero Lista Messaggi
useEffect(() => {
  let mounted = true
  const fetch = async () => {
    const messaggi = await axios.get(`${window.$apiEndpoint}/merchant/fetchMessageID/${indirzzi_email_loggato}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        setAllMessaggi(messaggi.data)
        setLoading(true)


      }
    } catch (e) {
      console.log(e)
    }
  }
  fetch();
  return () => {
    mounted = false
  }
}, [])


//Recupero Lista Messaggi
useEffect(() => {
  let mounted = true
  const fetch = async () => {
    const messaggi = await axios.get(`${window.$apiEndpoint}/merchant/fetchMessageID/${indirzzi_email_loggato}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        setTimeout(() => {
          setAllMessaggi(messaggi.data)
          setLoading(true)
        }, 2000);


      }
    } catch (e) {
      console.log(e)
    }
  }
  fetch();
  return () => {
    mounted = false
  }
}, [AllMessaggi])



//Se lo state isCreated ricarico la pagina
if (isCreated) {
    //Ricarica Pagina
    return window.location.reload();

}

//Dichiaro Voci del menu
const navigation = [
  { name: 'Riepilogo & Messaggi', href: '/dashboard_events', icon: UsersIcon, current: true , notifiche: 0, not: true},
  { name: 'Crea & Gestisci Evento', href: '/create_event', icon: CalendarIcon, current: true , notifiche: 0, not: false},



  ]


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

//Creo la costante con le voci

const arrayDiNavigazione = []

const map = navigation.map((item,index) => {

       if (attivo.attivo === item.name) {

       let schedaAttiva = true;

       //Controllo quale sheet è attiva
       const menu = {
        name: item.name,
        href: item.href,
        icon: item.icon,
        current: schedaAttiva,
	    not: item.not
	   }
        arrayDiNavigazione.push(menu)


      } else if (item.name === 'Riepilogo & Messaggi'){

        let schedaAttiva = false;
        //Controllo quale sheet è attiva
        const menu = {
        name: item.name,
        href: item.href,
        icon: item.icon,
        current: schedaAttiva,
		notifiche:item.notifiche,
		not: item.not }
        arrayDiNavigazione.push(menu)

       } else {

        let schedaAttiva = false;
        //Controllo quale sheet è attiva
        const menu = {
        name: item.name,
        href: item.href,
        icon: item.icon,
        current: schedaAttiva,
		not: item.not }
        arrayDiNavigazione.push(menu)

       }

      })

  return (
    <>
    {arrayDiNavigazione.map((item) => (
    <a
        key={item.name}
        href={item.href}
        className={classNames(
          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
        )}
      >
        <item.icon
          className={classNames(
            item.current2 ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
            'mr-3 flex-shrink-0 h-6 w-6'
          )}
          aria-hidden="true"
        />
        {item.name}{item.not ? <div style={{ backgroundColor: 'green', marginTop: '-1vh', marginLeft: '3px', fontSize: '0.65rem' , borderRadius: '35px', width: '18px', height:'18px', fontWeight: 'bolder'}}><center style={{marginTop: '-2px'}}>{ loading ? AllMessaggi[0].conto : 0}</center></div> : null}
      </a>
    ))}
    </>

  )
}
