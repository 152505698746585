import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  CogIcon,
  MailIcon,
} from '@heroicons/react/outline'
import { BsPeople } from 'react-icons/bs';
import { GoPackage } from 'react-icons/go';
import { TbFileInvoice } from 'react-icons/tb';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import ReactTooltip from 'react-tooltip';
import navigation from "./navigation";
import moment from "moment"

import logo_main from "../assets/images/falco_white.png"
import SidebarMenu from "./sidebar_menu"
import * as XLSX from 'xlsx';


//Importo Menu
import Menu from "./navigation";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Dashboard() {
  //Setto gli state

  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  let history = useHistory()

    const [search, setSearch] = useState("");

    const [show, setShow] = useState(20);

    const [clienti, setClienti] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);



  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])

  useEffect(() => {
    getStatistics()
  }, [show, page, search])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }

  const [statistics, setStatistics] = useState(null)

  const getStatistics = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/statistics/dashboard`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setStatistics(response.data)
    setLoading(true)
  }


  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;


  const columns = [
    {
      dataField: 'clienteRagioneSociale',
      text: 'Ragione sociale',
      formatter: (value, row) => {
        return (
          <>
            <ReactTooltip />
            <Link to={`/clienti/${row.clienteID}`}>
              {row.clienteRagioneSociale}
            </Link>
          </>

        )
      }
    },
    {
      dataField: 'name',
      text: 'Descrizione',
      formatter: (value, row) => {
        return (
          <>
            <ReactTooltip />
            <Link to={`/clienti/preventivo/${row.id}`}>
              {row.name}
            </Link>
          </>

        )
      }
    },
    {
      dataField: 'totale',
      text: 'Totale',
      formatter: (value, row) => {
        return (
          <>{row.totale > 0 ? row.totale + " €" : 0}</>
        )
      }
    },
    {
      dataField: 'status',
      text: 'Stato',
      formatter: (value, row) => {
        return (
          <>{(() => {
            switch (row.status) {
              case 'BOZZA':
                return (<span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                  {row.status}
                </span>);
              case 'INVIATO':
                return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                  {row.status}
                </span>);
              case 'ACCETTATO':
                return (<span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                  {row.status}
                </span>);
              case 'RIFIUTATO':
                return (<span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                  {row.status}
                </span>);
              default:
                return (<span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                  {row.status}
                </span>);
            }
          })()}</>
        )
      }
    },
    {
      dataField: 'created_at',
      text: 'Creato il',
      formatter: (value, row) => {
        return (
          <>{moment(row.created_at).format("DD/MM/YYYY")}</>
        )
      }
    },
  ];


    const downloadxls = () => {
       let form = []
       statistics.lastPreventivi.map((item, index) => {
          console.log(item)
           let obj = {
             'Ragione Sociale': item.clienteRagioneSociale,
             'Descrizione': item.name,
             'Totale': item.totale > 0 ? item.totale + " €" : 0,
             'Stato': item.status,
             'Creato il': moment(item.created_at).format("DD/MM/YYYY")
           }
           form.push(obj)
       })
       if(form.length > 0) {
         const ws = XLSX.utils.json_to_sheet(form);
         const wb = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(wb, ws, "Clienti");
         /* generate XLSX file and send to client */
         XLSX.writeFile(wb, "Clienti.xlsx");
       }

   };




  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">

      <SidebarMenu attivo={"Riepilogo"}/>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
              {/* Replace with your content */}
              <div className="mt-8">
                <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">Riepilogo</h2>
                  <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {/* Card */}
                       <div key="Numero Offerte" className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                            <BsPeople className="h-6 w-6 text-gray-400" aria-hidden="true" />
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="text-sm font-medium text-gray-500 truncate">Clienti</dt>
                                <dd>
                                 {loading ? <div className="text-lg font-medium text-gray-900">{statistics.clienti}</div> : null }
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href="clienti" className="font-medium text-cyan-700 hover:text-cyan-900">
                              Vedi tutti
                            </a>
                          </div>
                        </div>
                      </div>


                      <div key="Numero Esercenti" className="bg-white overflow-hidden shadow rounded-lg">
                       <div className="p-5">
                         <div className="flex items-center">
                           <div className="flex-shrink-0">
                            <TbFileInvoice className="h-6 w-6 text-gray-400" aria-hidden="true" />
                           </div>
                           <div className="ml-5 w-0 flex-1">
                             <dl>
                               <dt className="text-sm font-medium text-gray-500 truncate">Preventivi accettati</dt>
                               <dd>
                                {loading ? <div className="text-lg font-medium text-gray-900">{statistics.preventiviAccettati}</div> : null}

                               </dd>
                             </dl>
                           </div>
                         </div>
                       </div>
                       <div className="bg-gray-50 px-5 py-3">
                         <div className="text-sm">
                           <a href="preventivi" className="font-medium text-cyan-700 hover:text-cyan-900">
                             Vedi Tutti
                           </a>
                         </div>
                       </div>
                     </div>

                      <div key="Numero Esercenti" className="bg-white overflow-hidden shadow rounded-lg">
                       <div className="p-5">
                         <div className="flex items-center">
                           <div className="flex-shrink-0">
                            <TbFileInvoice className="h-6 w-6 text-gray-400" aria-hidden="true" />
                           </div>
                           <div className="ml-5 w-0 flex-1">
                             <dl>
                               <dt className="text-sm font-medium text-gray-500 truncate">Preventivi in corso</dt>
                               <dd>
                                {loading ? <div className="text-lg font-medium text-gray-900">{statistics.preventiviAperti}</div> : null}

                               </dd>
                             </dl>
                           </div>
                         </div>
                       </div>
                       <div className="bg-gray-50 px-5 py-3">
                         <div className="text-sm">
                           <a href="preventivi" className="font-medium text-cyan-700 hover:text-cyan-900">
                             Vedi Tutti
                           </a>
                         </div>
                       </div>
                     </div>

                     <div key="Numero Eventi" className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <TbFileInvoice className="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Preventivi rifiutati</dt>
                              <dd>
                                {loading ? <div className="text-lg font-medium text-gray-900">{statistics.preventiviRifiutati}</div> : null}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a href="preventivi" className="font-medium text-cyan-700 hover:text-cyan-900">
                            Vedi Tutti
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <h2 className="max-w-full mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Ultimi preventivi
              </h2>

              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >
              <div className="flex flex-col">


            {loading ? <>
                                      <table className="min-w-full divide-y divide-gray-300">
                                          <thead className="bg-gray-50">
                                              <tr>
                                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                      Ragione sociale
                                                  </th>
                                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                      Descrizione
                                                  </th>
                                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                      Totale
                                                  </th>
                                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                      Stato
                                                  </th>
                                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                      Creato il
                                                  </th>
                                              </tr>
                                          </thead>
                                          <tbody className="divide-y divide-gray-200 bg-white">
                                              {loading ? statistics.lastPreventivi.map((row) => (
                                                  <tr key={row.id}>
                                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                          <>
                                                              <ReactTooltip />
                                                              <Link to={`/clienti/${row.clienteID}`}>
                                                                  {row.clienteRagioneSociale}
                                                              </Link>
                                                          </>
                                                      </td>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                          <>
                                                              <ReactTooltip />
                                                              <Link to={`/clienti/preventivo/${row.id}`}>
                                                                  {row.name}
                                                              </Link>
                                                          </>
                                                      </td>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                          <>{row.totale > 0 ? row.totale + " €" : 0}</>
                                                      </td>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                          {(() => {
                                                              switch (row.status) {
                                                                  case 'BOZZA':
                                                                      return (<span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                                                          {row.status}
                                                                      </span>);
                                                                  case 'INVIATO':
                                                                      return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                                                                          {row.status}
                                                                      </span>);
                                                                  case 'ACCETTATO':
                                                                      return (<span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                                                          {row.status}
                                                                      </span>);
                                                                  case 'RIFIUTATO':
                                                                      return (<span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                                                          {row.status}
                                                                      </span>);
                                                                  default:
                                                                      return (<span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                                                          {row.status}
                                                                      </span>);
                                                              }
                                                          })()}
                                                      </td>
                                                      <td>
                                                          <>{moment(row.created_at).format("DD/MM/YYYY")}</>
                                                      </td>
                                                      {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          Active
                                        </span>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>*/}
                                                      
                                                  </tr>
                                              )).slice(0, 30) :
                                                  <tr>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                          <div class="flex justify-center items-center">
                                                              <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                  <span class="visually-hidden">Loading...</span>
                                                              </div>
                                                          </div>
                                                      </td>
                                                  </tr>
                                              }
                                          </tbody>
                                      </table>
            </> : null}





    </div>





               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
