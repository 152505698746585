import { useHistory, Redirect } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

//Importo Menu
import Menu from "./navigation";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function NewMarket() {
  //Setto gli state
  const [allUsers, viewUsers] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [allCostumers, viewCostumers] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState([{}]);


  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;







useEffect(() => {
  const expiration = cookies.expiration
  if(new Date(expiration) < new Date()) {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')
  }
}, [setLogout])


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  const cards = [
    { name: 'Numero Offerte', href: '#', icon: GiftIcon, amount: '15' },
    { name: 'Numero Esercenti', href: '#', icon: UserGroupIcon, amount: '30' },
    { name: 'Numero Eventi', href: '#', icon: CalendarIcon, amount: '10' }
    // More items...
  ]

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])

        //Chiamata post per inserimento utente
        const onSubmit = data => {
        const fname = data.fname.toUpperCase().trim();
        const lname = data.lname.toUpperCase().trim();
        const email = data.email_address.toLowerCase().trim( );
        const password = makeid(10);

        /*const addM = async () => {
          try {

            const add = await axios.post(`${window.$apiEndpoint}/events/create`, {
              fname: fname,
              lname: lname,
              email: email,

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
          } catch (err) {
            setErr(true)
            setTimeout(() => {setErr(false)},3000)
          }
        }
        addM()*/


        //Creo credenziali di accesso per l'utente eventi

        const createUserMerchant = async () => {
          try {

            const add = await axios.post(`${window.$apiEndpoint}/auth/register`, {
              fname: fname,
              lname: lname,
              email: email,
              password: password,
              role: 'events',

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
            //Nessuna Risposta

            setSucc(true);
            setTimeout(() => {setSucc(false)},3000)
            setTimeout(() => {setCreated(true)},3000)

          } catch (err) {
            setErr(true)
            setTimeout(() => {setErr(false)},3000)
          }
        }
        createUserMerchant()


        //Invio email con i dati del cliente
        const sendUserEmail = async () => {
          try {

            const add = await axios.post(`${window.$apiEndpoint}/events/sendEmail`, {
              fname: fname,
              lname: lname,
              email: email,
              password: password,

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
            //Nessuna Risposta
          } catch (err) {
            setErr(true)
            setTimeout(() => {setErr(false)},3000)
          }
        }
        sendUserEmail()

      }


    //Se lo state isCreated ricarico la pagina
    if (isCreated) {
        //Ricarica Pagina
        return window.location.reload();
    }




  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-12 w-auto"
                    src="https://fileserver.finestrapp.it/logo_bianco.svg"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                <Menu attivo={"Crea Utente Eventi"} />
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci"  className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-full"
                  src="https://fileserver.finestrapp.it/logo_bianco.svg"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <Menu attivo={"Crea Utente Eventi"} />
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={window.$fileserverEndpoint + showsetAllProfile[0].url_imagine_profilo_dash}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci"  className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-6">
              {/* Replace with your content */}
              <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Crea un nuovo utente eventi
              </h2>

              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Completa tutti i campi</h3>
                  <p className="mt-1 text-sm text-gray-400">E' importante inserire un indirizzo email esiste in quanto verrà utilizzato come username. L'utente che gestirà e creerà l'evento ricevera vià email i dati di accesso al portale.</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          Nome
                        </label>
                        <input
                          type="text"
                          name="fname"
                          id="fname"
                          {...register("fname", { required: true })}
                          autoComplete="fname"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.fname && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="lname" className="block text-sm font-medium text-gray-700">
                          Cognome
                        </label>
                        <input
                          type="text"
                          name="lname"
                          id="lname"
                          {...register("lname", { required: true })}
                          autoComplete="lname"
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.lname && <p>Il campo è obbligatorio</p>}

                      </div>




                      <div className="col-span-12 sm:col-span-12">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                        Indrizzo Email
                      </label>
                      <input
                        type="email"
                        name="email_address"
                        {...register("email_address", { required: true })}
                        id="email_address"
                        autoComplete="email_address"
                        className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors.email_address && <p>Il campo è obbligatorio</p>}
                      </div>






                    </div>

                    <div className="flex justify-end mt-5">
                    <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                    Crea Esercente
                    </button>
                    </div>
                    </form>
                    </div>



                </div>

                {/* Stampo errore o messaggio di successo*/}

                { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> L'indirizzo email inserito risulta già esistente! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Utente Eventi creato con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }

              </div>
            </div>
              </div>
              </div>
              </main>
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

              {/* /End replace */}

            </div>




  )
}
