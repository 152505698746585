import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import Table from "./Table";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SidebarMenu from "./sidebar_menu"
import * as XLSX from 'xlsx';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import moment from "moment"

import {
  TrashIcon,
  EyeIcon,
  CalendarIcon,
  BadgeCheckIcon,
  PencilAltIcon,
  ExclamationCircleIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';
import Select from "react-select";

//Importo Menu

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Permessi() {


  //Setto gli state
  const [loading, setLoading] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState({});


  const [advModal, setAdvModal] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)

  const [permessiModal, setPermessiModal] = useState(false)
  const [toBePermessi, setToBePermessi] = useState(null)

  const [allPreventivi, setPreventivi] = useState(null)
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [search, setSearch] = useState("");
    const [pagesNavigation, setPagesNavigation] = useState(null)

    const [show, setShow] = useState(20);

useEffect(() => {
  const expiration = cookies.expiration
  if(new Date(expiration) < new Date()) {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')
  }
}, [setLogout])


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    getScala()
    getClienti()

    return () => {
      mounted = false
    }
  }, [])


  const [allScala, setScala] = useState([])


  const getScala = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/permessi/fetch`, {
      params: {
        search: search
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    setScala(response.data)
    setLoading(true)
  }


  const [allClienti, setClienti] = useState([])


  const getClienti = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/permessi/clienti`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setClienti(response.data)
    setLoading(true)
  }

  const [errReq, setErrReq] = useState(false)

        //Chiamata post per inserimento utente
        const onSubmit = async (data) => {


          if(selectedCliente && selectedPreventivo) {
            data.cliente = selectedCliente
            data.preventivo = selectedPreventivo
            data.name = type
            const response = await axios.post(`${window.$apiEndpoint}/permessi/create`, data, {
              headers: {
                "x-access-token": cookies.token
              }
            })
            getScala()
            getClienti()
            reset()
            setSelectedCliente(null)
            setSelectedPreventivo(null)
            setErrReq(false)
          } else {
            setErrReq(true)
          }


      }

        //Chiamata post per eliminare fornitore
      const deleteArticolo = async (data) => {
        const response = await axios.post(`${window.$apiEndpoint}/permessi/delete`, {
            id: data._id
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
        setToBeDeleted(null)
        setAdvModal(!advModal)
        getScala()
        getClienti()

          reset()


      }



  const [editModal, setEditModal] = useState(false)
  const [toBeEdited, setToBeEdited] = useState(null)


  const [type, setType] = useState([])



  const editArticolo = async (data) => {
    if (toBeEdited) {
      const response = await axios.patch(`${window.$apiEndpoint}/permessi/edit`, data, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBeEdited(null)
      setEditModal(!editModal)
      getScala()
      getClienti()

    }
  }

  const permessiArticolo = async (data) => {
    if (toBePermessi) {
      const response = await axios.patch(`${window.$apiEndpoint}/permessi/permessi`, {
        permessi: !toBePermessi.permessi,
        utente: cookies.email,
        id: data._id
      }, {
        headers: {
          "x-access-token": cookies.token
        }
      })
      setToBePermessi(null)
      setPermessiModal(!permessiModal)
      getScala()
      getClienti()

    }
  }

  const [selectedCliente, setSelectedCliente] = useState(null)
  const [selectedPreventivo, setSelectedPreventivo] = useState(null)

    const searchCustomer = async () => {
        getScala()
    }

  const { SearchBar } = Search;

    const downloadxls = () => {
        let form = []
        allScala.map((item, index) => {
            let obj = {
                'Ragione Sociale': item.clienteRagioneSociale,
                'Nome': item.name,
                'Stato': item.status,
                'Importo': item.totale,
            }
            form.push(obj)

        })
        const ws = XLSX.utils.json_to_sheet(form);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Preventivi");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "Preventivi.xlsx");
    };



    //Se lo state isCreated ricarico la pagina
    if (isCreated) {
        //Ricarica Pagina
        return window.location.reload();
    }


  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu  attivo={"Permessi"} open={true}/>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            </div>
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
              {/* Replace with your content */}
              <h2 className="max-w-full mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Crea nuovo permesso o transennamento
              </h2>

              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Completa tutti i campi</h3>
                      <p className="mt-1 text-sm text-gray-400">Crea qui il nuovo noleggio scala per i clienti. I campi contrassegnati con <strong><span style={{ color: 'red' }}>*</span></strong> sono obbligatori.</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Cliente<strong><span style={{ color: 'red' }}>*</span></strong>
                        </label>
                            <Select
                              options={allClienti}
                              onChange={e => {
                                setSelectedCliente(e)
                                let array = []
                                e.preventivi.map(item => {
                                  let obj = {
                                    value: item.id,
                                    label: item.name
                                  }
                                  array.push(obj)
                                })
                                setPreventivi(array)
                              }}
                              placeholder="Seleziona"
                            />
                            {errReq && <p>Il campo è obbligatorio</p>}


                      </div>

                      <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Preventivi<strong><span style={{ color: 'red' }}>*</span></strong>
                        </label>
                            <Select
                              options={allPreventivi}
                              onChange={e => {
                                setSelectedPreventivo(e)
                              }}
                              placeholder="Seleziona"
                            />
                            {errReq && <p>Il campo è obbligatorio</p>}



                      </div>


                          <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="location" className="block text-left  text-sm font-medium text-gray-700">
                              Location<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="text"
                              name="location"
                              id="location"
                              {...register("location", { required: true })}
                              autoComplete="location"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.location && <p>Il campo è obbligatorio</p>}

                          </div>

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="date" className="block text-left  text-sm font-medium text-gray-700">
                              Data inzio<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="date"
                              name="date"
                              id="date"
                              {...register("date", { required: true })}
                              autoComplete="date"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.date && <p>Il campo è obbligatorio</p>}

                          </div>

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="from" className="block text-left  text-sm font-medium text-gray-700">
                              Ora inizio<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="time"
                              name="from"
                              id="from"
                              {...register("from", { required: true })}
                              autoComplete="from"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.from && <p>Il campo è obbligatorio</p>}

                          </div>

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="dataFine" className="block text-left  text-sm font-medium text-gray-700">
                              Data fine<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="date"
                              name="dataFine"
                              id="dataFine"
                              {...register("dataFine", { required: true })}
                              autoComplete="dataFine"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.dataFine && <p>Il campo è obbligatorio</p>}

                          </div>

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="to" className="block text-left  text-sm font-medium text-gray-700">
                              Ora fine<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="time"
                              name="to"
                              id="to"
                              {...register("to", { required: true })}
                              autoComplete="to"
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.to && <p>Il campo è obbligatorio</p>}

                          </div>

                          <div className="col-span-3 sm:col-span-3">

                            <div class="flex items-center mb-4">
                              <input id="default-checkbox" type="checkbox" value="OCCUPAZIONE SUOLO PUBBLICO" onChange={e => {
                                  if(type.includes(e.target.value)) {
                                    setType(current =>
                                      current.filter(t => {
                                        return t !== e.target.value;
                                      })
                                    )
                                  } else {
                                    setType(old => [...old, e.target.value])
                                  }
                                }} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                              <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">OCCUPAZIONE SUOLO PUBBLICO</label>
                            </div>
                            <div class="flex items-center">
                              <input id="checked-checkbox" type="checkbox" value="TRANSENNAMENTO" onChange={e => {
                                if (type.includes(e.target.value)) {
                                  setType(current =>
                                    current.filter(t => {
                                      return t !== e.target.value;
                                    })
                                  )
                                } else {
                                  setType(old => [...old, e.target.value])
                                }
                              }} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                              <label for="checked-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">TRANSENNAMENTO</label>
                            </div>


                          </div>



                    </div>

                    <div className="flex justify-end mt-5">
                    <button
                    type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                    Aggiungi
                    </button>
                    </div>
                    </form>
                    </div>



                </div>



                {/* Stampo errore o messaggio di successo*/}

                { showErr ? <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          <center><strong>Attenzione:</strong> L'indirizzo email inserito risulta già esistente, ricorda l'email dell'esercente deve essere univoca (Inoltre non deve appartenere anche ad un utente)! </center>
                        </p>
                      </div>
                    </div>
                  </div> : null }

                {showSucc ? <div className="rounded-md bg-green-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800"><center>Esercente creato con successo!</center></p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-blue-600"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : null }


                  <div className="max-w-12xl mx-auto mt-8" >
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className=" overflow-hidden sm:rounded-lg">


                            {/* Tabella Esercenti */}

                                                      {/* Tabella Esercenti */}

                                                      <div className="max-w-12xl mx-auto" >
                                                          <div className="flex flex-col">
                                                              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                      <div className=" overflow-hidden sm:rounded-lg">

                                                                          {/* Tabella Esercenti */}
                                                                          <div className="max-w-12xl mx-auto mt-8" >
                                                                              <div className="flex flex-col">
                                                                                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                                          <div className=" overflow-hidden sm:rounded-lg">

                                                                                              <div className="sm:flex-auto">

                                                                                                  <div className="flex justify-between align-center">
                                                                                                      <div className="w-50">
                                                                                                          <div className="mt-1 flex rounded-md shadow-sm">
                                                                                                              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                                  <input
                                                                                                                      type="text"
                                                                                                                      name="search"
                                                                                                                      id="search"
                                                                                                                      className="block w-full rounded-none rounded border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                                                      placeholder="🔍 Cerca per nome"
                                                                                                                      onChange={(e) => searchCustomer()}
                                                                                                                      onKeyUp={e => setSearch(e.target.value)}
                                                                                                                  />
                                                                                                              </div>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <div className="mt-1">

                                                                                                          <button
                                                                                                              type="button"
                                                                                                              className="rounded-md border border-transparent bg-success px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-50"
                                                                                                              onClick={() => downloadxls()}
                                                                                                          >
                                                                                                              Esporta
                                                                                                          </button>
                                                                                                      </div>
                                                                                                      <div>

                                                                                                          <Menu as="div" className="relative inline-block text-left z-50">
                                                                                                              <div className="mt-1">
                                                                                                                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                                                                                      Visualizza: {show}
                                                                                                                      <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                                  </Menu.Button>
                                                                                                              </div>
                                                                                                              <Transition
                                                                                                                  as={Fragment}
                                                                                                                  enter="transition ease-out duration-100"
                                                                                                                  enterFrom="transform opacity-0 scale-95"
                                                                                                                  enterTo="transform opacity-100 scale-100"
                                                                                                                  leave="transition ease-in duration-75"
                                                                                                                  leaveFrom="transform opacity-100 scale-100"
                                                                                                                  leaveTo="transform opacity-0 scale-95"
                                                                                                              >

                                                                                                                  <Menu.Items className="z-50">
                                                                                                                      <div className="py-1">
                                                                                                                          <Menu.Item as={Fragment}>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => setShow(e.currentTarget.innerText)}
                                                                                                                                  >
                                                                                                                                      10
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => setShow(e.currentTarget.innerText)}
                                                                                                                                  >
                                                                                                                                      20
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => setShow(e.currentTarget.innerText)}
                                                                                                                                  >
                                                                                                                                      50
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                          <Menu.Item>
                                                                                                                              {({ active }) => (
                                                                                                                                  <label
                                                                                                                                      className={classNames(
                                                                                                                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                          'block w-full px-4 py-2 text-center text-sm'
                                                                                                                                      )}
                                                                                                                                      onClick={e => setShow(e.currentTarget.innerText)}
                                                                                                                                  >
                                                                                                                                      100
                                                                                                                                  </label>
                                                                                                                              )}
                                                                                                                          </Menu.Item>
                                                                                                                      </div>
                                                                                                                  </Menu.Items>
                                                                                                              </Transition>
                                                                                                          </Menu>
                                                                                                          <div className="flex flex-1 justify-between sm:hidden">
                                                                                                              <a
                                                                                                                  href="#"
                                                                                                                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                              >
                                                                                                                  Previous
                                                                                                              </a>
                                                                                                              <a
                                                                                                                  href="#"
                                                                                                                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                              >
                                                                                                                  Next
                                                                                                              </a>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                          {loading ? <div className="mt-8 flex flex-col">
                                                                                              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                                                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                                                                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                                                                          <table className="min-w-full divide-y divide-gray-300">
                                                                                                              <thead className="bg-gray-50">
                                                                                                                  <tr>
                                                                                                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                          Cliente
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Preventivo
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Location
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Data
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Permessi
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                          Opzioni
                                                                                                                      </th>
                                                                                                                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                                                                      </th>
                                                                                                                  </tr>
                                                                                                              </thead>
                                                                                                              <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                  {loading ? allScala.map((cliente) => (
                                                                                                                      <tr key={cliente._id}>
                                                                                                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                              <>
                                                                                                                                  <ReactTooltip />
                                                                                                                                  <Link to={`/clienti/${cliente.clienteID}`} data-tip="Vai a cliente">{cliente.clienteRagioneSociale}</Link>
                                                                                                                              </>
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                                              <>
                                                                                                                                  <ReactTooltip />
                                                                                                                                  <Link to={`/clienti/preventivo/${cliente.preventivoID}`} data-tip="Vai a preventivo">{cliente.preventivoName}</Link>

                                                                                                                              </>
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                              {cliente.location}
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                                              <>
                                                                                                                                  <ReactTooltip />
                                                                                                                                  <span className="mr-4">
                                                                                                                                      {moment(cliente.data).format("DD/MM/YYYY") + " " + moment(moment().format("YYYY-MM-DD") + " " + cliente.from).format("HH:mm") + " - " + moment(cliente.dataFine).format("DD/MM/YYYY") + " " + moment(moment().format("YYYY-MM-DD") + " " + cliente.to).format("HH:mm")}
                                                                                                                                  </span>
                                                                                                                                  <br />
                                                                                                                                  {(() => {
                                                                                                                                      switch (cliente.name) {
                                                                                                                                          case 'OCCUPAZIONE SUOLO PUBBLICO':
                                                                                                                                              return (<span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                                                                                                                                                  {cliente.name}
                                                                                                                                              </span>);
                                                                                                                                          case 'TRANSENNAMENTO':
                                                                                                                                              return (<span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                                                                                                                                                  {cliente.name}
                                                                                                                                              </span>);
                                                                                                                                          default:
                                                                                                                                              return (<span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                                                                                                                                  {cliente.name}
                                                                                                                                              </span>);
                                                                                                                                      }
                                                                                                                                  })()}
                                                                                                                              </>
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                              <>
                                                                                                                                  <ReactTooltip />
                                                                                                                                  <BadgeCheckIcon className="h-6 w-6" data-tip={cliente.permessi ? cliente.permessiInfo : "Permessi non ancora richiesti"} style={{ color: cliente.permessi ? 'green' : 'lightgrey' }} />
                                                                                                                              </>
                                                                                                                          </td>
                                                                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                                              <div className="flexxo">
                                                                                                                                  <ReactTooltip />
                                                                                                                                  <button
                                                                                                                                      type="button"
                                                                                                                                      onClick={() => {
                                                                                                                                          setToBeEdited(cliente)
                                                                                                                                          setEditModal(!editModal)
                                                                                                                                      }}
                                                                                                                                      className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                                                                                  >
                                                                                                                                      <PencilAltIcon data-tip="Vedi" className="h-4 w-4" aria-hidden="true" />
                                                                                                                                  </button>
                                                                                                                                  <button
                                                                                                                                      type="button"
                                                                                                                                      onClick={() => {
                                                                                                                                          setToBePermessi(cliente)
                                                                                                                                          setPermessiModal(!permessiModal)
                                                                                                                                      }}
                                                                                                                                      className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                                                                                  >
                                                                                                                                      <BadgeCheckIcon data-tip="Permessi" className="h-4 w-4" aria-hidden="true" />
                                                                                                                                  </button>
                                                                                                                                  <button
                                                                                                                                      type="button"
                                                                                                                                      onClick={() => {
                                                                                                                                          setToBeDeleted(cliente)
                                                                                                                                          setAdvModal(!advModal)
                                                                                                                                      }}
                                                                                                                                      className="inline-flex items-center ml-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                                                                                  >
                                                                                                                                      <TrashIcon data-tip="Elimina" className="h-4 w-4" aria-hidden="true" />
                                                                                                                                  </button>
                                                                                                                              </div>
                                                                                                                          </td>
                                                                                                                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-between my-2">
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  )) :
                                                                                                                      <tr>
                                                                                                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                                                                                              <div className="flex justify-center items-center">
                                                                                                                                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                                                                                      <span className="visually-hidden">Loading...</span>
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  }
                                                                                                              </tbody>
                                                                                                          </table>
                                                                                                          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                                                                                              <div className="flex flex-1 justify-between sm:hidden">
                                                                                                                  <a
                                                                                                                      href="#"
                                                                                                                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                                  >
                                                                                                                      Previous
                                                                                                                  </a>
                                                                                                                  <a
                                                                                                                      href="#"
                                                                                                                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                                                                                  >
                                                                                                                      Next
                                                                                                                  </a>
                                                                                                              </div>
                                                                                                              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                                                                                                  <div>
                                                                                                                      <p className="text-sm text-gray-700">
                                                                                                                          Visualizza <span className="font-medium">{show >= allScala.length ? allScala.length : show}</span> di{' '}
                                                                                                                          <span className="font-medium">{allScala.length}</span> risultati
                                                                                                                      </p>
                                                                                                                  </div>
                                                                                                                  <div>
                                                                                                                      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                                                                                                          <a
                                                                                                                              href="#"
                                                                                                                              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                                                                                                          >
                                                                                                                              <span className="sr-only">Prec</span>
                                                                                                                              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                                          </a>
                                                                                                                          {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                                                                                                                          {(() => {
                                                                                                                              switch (pages) {
                                                                                                                                  case 1:
                                                                                                                                      return (<a
                                                                                                                                          href="#"
                                                                                                                                          aria-current="page"
                                                                                                                                          className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                                                                                                                      >
                                                                                                                                          1
                                                                                                                                      </a>);
                                                                                                                                  case (pages > 1):
                                                                                                                                      return (
                                                                                                                                          <>
                                                                                                                                              {pagesNavigation.map(item => {
                                                                                                                                                  <a
                                                                                                                                                      href="#"
                                                                                                                                                      aria-current="page"
                                                                                                                                                      className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                                                                                                                                  >
                                                                                                                                                      {item}
                                                                                                                                                  </a>
                                                                                                                                              })

                                                                                                                                              }
                                                                                                                                          </>
                                                                                                                                      );
                                                                                                                                  default:
                                                                                                                                      return (<a
                                                                                                                                          href="#"
                                                                                                                                          aria-current="page"
                                                                                                                                          className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                                                                                                                      >
                                                                                                                                          1
                                                                                                                                      </a>);
                                                                                                                              }
                                                                                                                          })()}
                                                                                                                          <a
                                                                                                                              href="#"
                                                                                                                              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                                                                                                          >
                                                                                                                              <span className="sr-only">Succ</span>
                                                                                                                              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                                          </a>


                                                                                                                      </nav>
                                                                                                                  </div>
                                                                                                              </div>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>



                                                                                          </div> : <tr>
                                                                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500" colSpan={4}>
                                                                                                  <div className="flex justify-center items-center">
                                                                                                      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                                                                                          <span className="visually-hidden">Loading...</span>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </td>
                                                                                          </tr>}
                                                                                      </div>
                                                                                  </div>
                                                                              </div>

                                                                          </div>

                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

              </div>
            </div>
              </div>


              </div>




              </main>
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

      <Transition.Root show={permessiModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setPermessiModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <BadgeCheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Permessi
                      </Dialog.Title>
                      <div className="mt-2">
                       {toBePermessi ? <p className="text-sm text-gray-500">
                          Stai per {toBePermessi.permessi ? ' rimuovere ' : ' confermare '} i permessi, vuoi procedere?
                        </p> : null}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        permessiArticolo(toBePermessi)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBePermessi(null)
                        setPermessiModal(!permessiModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={advModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Attenzione!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Stai per eliminare il container <strong>e tutti gli articoli</strong> ad esso associati, vuoi procedere?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        deleteArticolo(toBeDeleted)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeDeleted(null)
                        setAdvModal(!advModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={editModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setAdvModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <PencilAltIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Modifica
                      </Dialog.Title>
                      {toBeEdited ? <div className="mt-2">
                        <div className="grid grid-cols-6 gap-6">

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="name" className="block text-left text-sm font-medium text-gray-700 mt-1">
                              Cliente<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <Select
                              options={allClienti}
                              defaultValue={{
                                value: toBeEdited.clienteID,
                                label: toBeEdited.clienteRagioneSociale
                              }}
                              onChange={e => {
                                setToBeEdited(params => ({
                                  ...params,
                                  clienteID: e.value,
                                }))
                                setToBeEdited(params => ({
                                  ...params,
                                  clienteRagioneSociale: e.label,
                                }))
                                let array = []
                                e.preventivi.map(item => {
                                  let obj = {
                                    value: item.id,
                                    label: item.name
                                  }
                                  array.push(obj)
                                })
                                setPreventivi(array)
                              }}
                              placeholder="Seleziona"
                              className="text-left text-sm shadow-sm"
                            />

                          </div>

                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="name" className="block text-left text-sm font-medium text-gray-700 mt-1">
                              Preventivo<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <Select
                              options={allPreventivi}
                              defaultValue={{
                                value: toBeEdited.preventivoID,
                                label: toBeEdited.preventivoName
                              }}
                              onChange={e => {
                                setSelectedCliente(e)
                                let array = []
                                e.preventivi.map(item => {
                                  let obj = {
                                    value: item.id,
                                    label: item.name
                                  }
                                  array.push(obj)
                                })
                                setPreventivi(array)
                              }}
                              placeholder="Seleziona"
                              className="text-left text-sm shadow-sm"
                            />

                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="location" className="block text-left  text-sm font-medium text-gray-700">
                              Location<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="text"
                              name="location"
                              id="location"
                              defaultValue={toBeEdited.location}
                              autoComplete="location"
                              onChange={e => setToBeEdited(params => ({
                                ...params,
                                location: e.target.value,
                              }))}
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.location && <p>Il campo è obbligatorio</p>}

                          </div>


                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="date" className="block text-left  text-sm font-medium text-gray-700">
                              Data inizio<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="date"
                              name="date"
                              id="date"
                              defaultValue={moment(toBeEdited.data).format("YYYY-MM-DD")}
                              autoComplete="date"
                              onChange={e => setToBeEdited(params => ({
                                ...params,
                                data: moment(e.target.value),
                              }))}
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.date && <p>Il campo è obbligatorio</p>}

                          </div>


                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="from" className="block text-left  text-sm font-medium text-gray-700">
                              Ora inizio<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="time"
                              name="from"
                              id="from"
                              defaultValue={moment(moment().format("YYYY-MM-DD") + " " + toBeEdited.from).format("HH:mm")}
                              autoComplete="from"
                              onChange={e => setToBeEdited(params => ({
                                ...params,
                                from: e.target.value,
                              }))}
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.from && <p>Il campo è obbligatorio</p>}

                          </div>


                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="dataFine" className="block text-left  text-sm font-medium text-gray-700">
                              Data fine<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="date"
                              name="dataFine"
                              id="dataFine"
                              defaultValue={moment(toBeEdited.dataFine).format("YYYY-MM-DD")}
                              autoComplete="dataFine"
                              onChange={e => setToBeEdited(params => ({
                                ...params,
                                dataFine: moment(e.target.value),
                              }))}
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.dataFine && <p>Il campo è obbligatorio</p>}

                          </div>


                          <div className="col-span-3 sm:col-span-3">
                            <label htmlFor="to" className="block text-left  text-sm font-medium text-gray-700">
                              Ora fine<strong><span style={{ color: 'red' }}>*</span></strong>
                            </label>
                            <input
                              type="time"
                              name="to"
                              id="to"
                              defaultValue={moment(moment().format("YYYY-MM-DD") + " " + toBeEdited.to).format("HH:mm")}
                              autoComplete="to"
                              onChange={e => setToBeEdited(params => ({
                                ...params,
                                to: e.target.value,
                              }))}
                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.to && <p>Il campo è obbligatorio</p>}

                          </div>

                        </div>
                      </div> : null}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"

                      onClick={() => {
                        editArticolo(toBeEdited)
                      }}
                    >
                      Continua
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setToBeEdited(null)
                        setEditModal(!editModal)
                      }}
                    >
                      Annulla
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

              {/* /End replace */}

            </div>




  )
}
