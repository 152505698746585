import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition, Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import Table from "./Table";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CirclePicker } from 'react-color'
import SidebarMenu from "./sidebar_menu"
import Select from "react-select";
import moment from "moment"
import CKEditors from "react-ckeditor-component";

import {
  ArrowLeftIcon,
  TrashIcon,
  ArrowRightIcon,
  EyeIcon,
  CalendarIcon,
  CheckIcon,
  ChartBarIcon,
  PrinterIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  PencilIcon,
  MailIcon,
  BookmarkIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
    OfficeBuildingIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

//Importo Menu
import Menu from "./navigation";
import { propTypes } from 'react-bootstrap/esm/Image'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function ClientiPreventivo(props) {


  //Setto gli state
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState({});
    const [enabled, setEnabled] = useState(false)


    const editorConfig = {
        // Altre opzioni di configurazione...
        contentsCss: 'body { font-size: 10px; }' // Imposta la dimensione del testo a 10px
    };

    const editorConfig2 = {
        // Altre opzioni di configurazione...
        contentsCss: 'body { font-size: 12px; }' // Imposta la dimensione del testo a 10px
    };

useEffect(() => {
  const expiration = cookies.expiration
  if(new Date(expiration) < new Date()) {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')
  }
}, [setLogout])


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  let styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "white",
      backgroundColor: state.data.color,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
    })
  };

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }
    const [servizi, setServizi] = useState([])

  //Recupero Lista Utenti
  useEffect(() => {
    getClienti()
    getListino()
    getContainer()
  }, [])



  //Gestione listino

  const [isListino, setListino] = useState(null)

  const getListino = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/listino/fetch4Preventivo`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setListino(response.data)
    setLoading2(true)
  }

  const [isContainer, setContainer] = useState(null)

    const getContainer = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/container/fetch4Preventivo`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setContainer(response.data)
    setLoading2(true)
  }


  const [isPreventivo, setPreventivo] = useState([])
  const [isCliente, setCliente] = useState([])
  const [legenda, setLegenda] = useState(null)
  const [carico, setCarico] = useState(null)
  const [scatole, setScatole] = useState(null)
  const [info, setInfo] = useState(null)
  const [clausoleEditabili, setClausoleEditabili] = useState(
        "- <u>Eventuale</u> <strong>rimontaggio cucina in modo diverso: SOSTITUZIONE TOP h 4 cm x 4.20 mt x 60</strong> profondità con: bordi, finizioni – alzatina e zoccolo a parte – comprensivo di ritiro materiale presso nostro fornitore, trasporto – accurata progettazione in base alle misurazioni effettuate sul momento: tagli per lavandino e piano cottura, bordatura finale con utilizzo di macchinari altamente professionali, montaggio. Il TOP è disponibile anche in altre misurazioni.<br/>- <strong>Eventuale servizio DEPOSITO MOBILI</strong>: accatastamento mobili ed imballo, <strong>in sicuri CONTAINER CUSTODITI all’interno</strong> del nostro magazzino, chiusi con chiavi e lucchetti, <strong>assicurati</strong> furto ed incendio.Successivo lavoro di svuotamento container e ricarco del camion, consegna e rimontaggio.<br/>-Servizio <strong>SGOMBERO</strong> presso <strong>area di smaltimento di competenza</strong> con <strong>emissione formulario</strong>: <br/>dati i nuovi regolamenti per i rifiuti, non possiamo ritirare: <br/>1.<u>RIFIUTI NON SEPARATI CORRETTAMENTE</u>: carta, plastica, vetro, ceramica, legno, rifiuti ingombranti grandi, rifiuti ingombranti fini: vanno suddivisi in sacchi(trasparenti, <u>NON sono ammessi SACCHI NERI</u>) o meglio ancora cartoni(che possiamo fornirvi) - <u>SUDDIVISI</u> con sopra indicato il tipo di materiale. <br/>2.BARATTOLI PIENI(marmellate, conserve, vino, ecc) <br/>3.MATERIALI PERICOLOSI: batterie, bombole del gas, olio, armi, vernici, taniche, medicinali, guano di piccione."
  )
  const [postAltri, setPostAltri] = useState("")
  const [aggiuntivi, setAggiuntivi] = useState([
        {
            label: "Sopralluogo tecnico preventivo per raccolta informazioni: analisi di ogni variabile ed eventuali servizi particolari da preventivare a parte. Progettazione e programmazione del trasloco in ogni sua fase; assistenza e supporto prima durante e dopo il trasloco.",
            value: false
        },
        {
            label: "Smontaggio mobili, stivaggio del carico, trasporto, scarico a destinazione, rimontaggio <u>previsto unicamente per il materiale elencato nel file excel allegato</u>.",
            value: false
        },
        {
            label: "Tutte le operazioni vengono eseguite da <strong>personale tecnico formato e specializzato, in regola</strong> con le leggi contributive, assicurative ed antinfortunistiche come da leggi vigenti in merito.",
            value: false
        },
        {
            label: "Utilizzo di <strong>mezzi performanti</strong>: carico e scarico – consegna al piano con ausilio di autoscala, trasporto con autocarro furgonato con allestimento specifico per trasporto mobili.",
            value: false
        },
        {
            label: "<strong>Protezione ed imballaggio dei mobili smontati con apposite coperte e polistirolo</strong> antigraffio – e dei <strong>materassi</strong> con appositi sacchi in polietilene (l’imballaggio è effettuato con specifiche coperte da trasloco, più ecologiche del pluriball, consentendo meno sprechi di plastica!)",
            value: false
        },
        {
            label: "Imballaggio <strong>quadri</strong> o <strong>oggetti delicati – fragili</strong> / disimballo e ricollocazione a parte",
            value: false
        },
        {
            label: "Eventuale servizio <strong>imballaggio mobili completo</strong> con <em>pluriball</em>  – da conteggiare a parte",
            value: false
        },
        {
            label: "Eventuale <strong>servizio imballaggio contenuto dei mobili</strong> completo (tutta casa) ",
            value: false
        },
        {
            label: "<strong>Fornitura di imballaggi</strong> di 2 diverse dimensioni per inscatolare gli effetti personali con ritiro presso nostra sede",
            value: false
        },
        {
            label: "<strong>Pacchetto kit fragili</strong>: fornitura <strong>rotoli scotch + kit con 100 sacchetti</strong> <em>pluriball</em> + carta imballaggio fragili",
            value: false
        },
        {
            label: "<strong>Scatoloni appendiabiti</strong>: a scelta se comodato d’uso <u>gratuito consegna e ritiro in giornata</u> (si raccomanda di appendere gli abiti imbustati nei sacchetti di nylon per una maggior igiene) – oppure a <u>pagamento con consegna nei giorni precedenti</u>, eventuale vendita oppure ritiro nei giorni successivi al trasloco",
            value: false
        },
        {
            label: "<strong>LAMPADARI</strong>: smontaggio ed imballaggio – disimballo e ricollocazione a parte",
            value: false
        },
        {
            label: "<strong>PIANOFORTE, CASSEFORTI, oggetti fino a 600 kg</strong>: movimentazione con pianoplan, apposito macchinario cingolato",
            value: false
        },
        {
            label: "Spese di <strong>viaggio</strong>",
            value: false
        },
        {
            label: "Servizio svolgimento <strong>pratiche occupazione suolo pubblico e posa segnaletica</strong>",
            value: false
        },
        {
            label: "Polizza di <strong>RESPONSABILITA’ CIVILE con massimale 2.000.000 €</strong>",
            value: false
        },
        {
            label: "<strong>POLIZZA MERCI TRASPORTATE</strong> (copertura fino a 30.000 € valore trasportato) (<u>diversa da POLIZZA VETTORIALE</u> obbligatoria per legge che hanno alcune aziende, che in caso di incidente <u>paga per 1€ /kg</u>)",
            value: false
        },
        {
            label: "<u>Eventuali smontaggi / rimontaggi NON DISCUSSI IN PRECEDENZA e <strong>non elencati nel file excel allegato</strong></u>",
            value: false
        },
        {
            label: "<strong>Eventuali smontaggi / rimontaggi DA RIFARE PER ERRATA VALUTAZIONE DEGLI SPAZI</strong> (se un mobile viene montato in un posto e poi si deve smontare e rimontare in altra stanza / su altra parete / in garage), verrà conteggiato un supplemento per lavoro extra che non era stato preventivato)",
            value: false
        },
        {
            label: "PREAVVISO VICINATO / CONDOMINI / AMMINISTRATORE per utilizzo cortile con <strong>affissione di AVVISI</strong>",
            value: false
        }, {
            label: "<strong>PARTI IDRAULICHE</strong>, allacciamento GAS E lavastoviglie (possiamo fornire contatto idraulico di fiducia)",
            value: false
        }, {
            label: "Smontaggio e rimontaggio lampadari, barre tende",
            value: false
        },
    ])
  const [stanze, setStanze] = useState([
    {
      value: 'waewauG8Tahs2yeich',
      label: 'INGRESSO'
    },
    {
      value: 'ice8mohdaeNgoo1moo',
      label: 'CUCINA'
    },
    {
      value: 'ahf0iem5iixahJ4aru',
      label: 'SALA PRANZO'
    },
    {
      value: 'dieHieM0yei9saithe',
      label: 'BAGNO'
    },
    {
      value: 'ohshaeh8lini4cheeH',
      label: 'CAMERA 1'
    },
    {
      value: 'eph5uePeelu5ulae0i',
      label: 'CAMERA 2'
    },
    {
        value: 'AeRaesh0peiving0qu',
      label: 'SOGGIORNO'
    },
    {
        value: 'ighae6yoolohDoo3oh',
      label: 'STUDIO'
    },
    {
        value: 'phaerah6Ukoo0vengi',
      label: 'VARIE'
    },
    {
        value: 'jup0tho1veLaixaP3a',
      label: 'CANTINA'
    },
    {
        value: 'OGhothae0tie2waile',
      label: 'GARAGE'
    },
  ])
  const [altriServizi, setAltriServizi] = useState([])
  const [isSaving, setSaving] = useState(false)
  const [serviceChooser, setServiceChooser] = useState(false)
  const [altriServiceChooser, setAltriServiceChooser] = useState(false)
  const [currentStep, setCurrentStep] = useState(null)


  const onClick = (id) => {
    setServiceChooser(prev => Boolean(!prev[id]) ? { ...prev, [id]: true } : { ...prev, [id]: false });
  }

  const onClickAltri = (id) => {
    setAltriServiceChooser(prev => Boolean(!prev[id]) ? { ...prev, [id]: true } : { ...prev, [id]: false });
  }


  const generate_random = (length) => {
    var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}



  // ✅ Update one or more objects in a state array
  const updateColoreLegenda = (item, color) => {
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, color: color };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateColoreCarico = (item, color) => {
    setCarico(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, color: color };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateDestinazioneLegenda = (item, dest) => {
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateCaricoLegenda = (item, dest) => {
    setCarico(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateStanza = (item, dest) => {
    setStanze(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePermessiLegenda = (item, dest) => {
    console.log(dest)
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, permessi: dest };
        }
        return obj;
      }),
    );
  };



  // ✅ Update one or more objects in a state array
  const updatePermessiCarico = (item, dest) => {
    console.log(dest)
    setCarico(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, permessi: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateNomeServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_name: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePrezzoServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_prezzo: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePrezzoServizioSconto = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_sconto: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateQtyServizio = (item, value) => {
    item.qty = value
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_qty: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateFromChangeServizioPrice = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_prezzo: value.price };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeAltroServizioPrice = (item, value) => {
    setAltriServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, costo: value.price };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeServizioName = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_name: value.label };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeAltroServizioName = (item, value) => {
    setAltriServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, label: value.label };
        }
        return obj;
      }),
    );
}
  // ✅ Update one or more objects in a state array
  const updateFromChangeAltroServizioValue = (item, value) => {
    setAltriServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, value: value.value };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeServizioID = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_ID: value.value };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeAltroServizioID = (item, value) => {
    setAltriServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, value: value.value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateDestinazioneServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, legenda: value };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateCaricoServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, carico: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateStanzaServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, stanza: value };
        }
        return obj;
      }),
    );
  };

    // ✅ Update one or more objects in a state array
    const updateGoToContainer = (item, value) => {

        let newVal = {
            value: item.legenda.value,
            label: item.legenda.label,
            isContainer: value,
            color: item.legenda.color
        }

        setServizi(current =>
            current.map(obj => {
                if (obj.id === item.id) {
                    return { ...obj, legenda: newVal };
                }
                return obj;
            }),
        );
    };

    // ✅ Update one or more objects in a state array
    const updateGoFromContainer = (item, value) => {
        let newVal = {
            value: item.carico.value,
            label: item.carico.label,
            isContainer: value
        }

        setServizi(current =>
            current.map(obj => {
                if (obj.id === item.id) {
                    return { ...obj, carico: newVal };
                }
                return obj;
            }),
        );
    }



    // ✅ Update one or more objects in a state array
    const updateAltriServiziNome = (item, dest) => {
        setAltriServizi(current =>
            current.map(obj => {
                if (obj.id === item.id) {
                    return { ...obj, label: dest };
                }
                return obj;
            }),
        );
    };

    // ✅ Update one or more objects in a state array
    const updateAltriServiziCosto = (item, dest) => {
        setAltriServizi(current =>
            current.map(obj => {
                if (obj.id === item.id) {
                    return { ...obj, costo: dest };
                }
                return obj;
            }),
        );
    };

    // ✅ Update one or more objects in a state array
    const updateAltriServiziSconto = (item, dest) => {
        setAltriServizi(current =>
            current.map(obj => {
                if (obj.id === item.id) {
                    return { ...obj, sconto: dest };
                }
                return obj;
            }),
        );
    };

  const [numerazione, setNumerazione] = useState(null)

  const getClienti = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/clienti/fetchPreventivo`, {
      params: {
        id: props.match.params.id
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    setPreventivo(response.data.preventivo)
    setNumerazione(response.data.numerazione)
    setLegenda(response.data.preventivo.legenda)
    setCarico(response.data.preventivo.carico)
    if (response.data.preventivo.stanze && response.data.preventivo.stanze.length > 0) {
      setStanze(response.data.preventivo.stanze)
    }
    setCliente(response.data.cliente)
    setServizi(response.data.preventivo.servizi)
    setScatole(response.data.preventivo.scatole)
      if (response.data.preventivo.oggetto) {
          setOggetto(response.data.preventivo.oggetto)
      }
      if (response.data.preventivo.postAltri) {
          setPostAltri(response.data.preventivo.postAltri)
      }
      if (response.data.preventivo.clausoleEditabili) {
          setClausoleEditabili(response.data.preventivo.clausoleEditabili)
      }
      if( response.data.preventivo.note) {
          setNote(response.data.preventivo.note)
      }
    setAltriServizi(response.data.preventivo.altriServizi ? response.data.preventivo.altriServizi : [])
    setInfo(response.data.preventivo.info)
      if (response.data.preventivo.aggiuntivi) {
          setAggiuntivi(response.data.preventivo.aggiuntivi)

      }
    let steps = response.data.preventivo.steps
    if(steps) {
      let i = 1
      steps.map(step => {
        if(step.status === 'complete') {
         if(i !== 3) {
             i = i + 1
         }
          
        }
      })
      setCurrentStep(i)
    }

    setLoading(true)
  }


  const calcoloImponibile = () => {
    let tot = 0
    servizi.map(item => {
        tot = tot + (item.servizio_prezzo ? item.servizio_prezzo : 0) * (item.servizio_qty ? item.servizio_qty : 1)
    })
    altriServizi.map(item => {
        tot = tot + (item.costo ? parseFloat(item.costo) : 0)
    })
    return tot
  }

  const calcoloSconto = () => {
    let tot1 = 0
    servizi.map(item => {
        tot1 = tot1 + (item.servizio_prezzo ? (item.servizio_prezzo - (item.servizio_prezzo - parseFloat(item.servizio_sconto ? item.servizio_sconto : item.servizio_prezzo))) : 0) * (item.servizio_qty ? item.servizio_qty : 1)
    })
    let tot2 = 0
    altriServizi.map(item => {
        tot2 = tot2 + (item.costo ? parseFloat(item.costo) - (item.costo - parseFloat(item.sconto ? parseFloat(item.sconto) : parseFloat(item.costo))) : 0)
    })
    let tot99 = parseFloat(tot1) + parseFloat(tot2)
    return tot99
  }

  const calcoloValoreSconto = () => {
      let imponibile = calcoloImponibile()
      let sconto = calcoloSconto()
      let tot = parseFloat(imponibile) - parseFloat(sconto)
      return tot
  }

  const calcoloIVA = () => {
      let tot1 = 0
      servizi.map(item => {
          tot1 = tot1 + (item.servizio_prezzo ? (item.servizio_prezzo - (item.servizio_prezzo - parseFloat(item.servizio_sconto ? item.servizio_sconto : item.servizio_prezzo))) : 0) * (item.servizio_qty ? item.servizio_qty : 1)
      })
      let tot2 = 0
      altriServizi.map(item => {
          tot2 = tot2 + (item.costo ? item.costo - (item.costo - parseFloat(item.sconto ? item.sconto : item.costo)) : 0)
      })
      let tot99 = tot1 + tot2
      let iva = (tot99 / 100 * 22).toFixed(2)
    return iva
  }

  const calcoloTotale = () => {
    let imponibile = calcoloSconto()
    let iva = calcoloIVA()
    let tot = parseFloat(imponibile) + parseFloat(iva)
    return tot
  }

  const calcoloTotaleAltriConSconto = () => {
      let tot = 0
      altriServizi.map(item => {
          tot = tot + (item.costo ? parseFloat(item.costo) - (item.costo - parseFloat(item.sconto ? parseFloat(item.sconto) : parseFloat(item.costo))) : 0)
      })
      return tot
  }

  const calcoloTotaleAltri = () => {
      let tot = 0
      altriServizi.map(item => {
          tot = tot + (item.costo ? parseFloat(item.costo) : 0)
      })
      return tot
  }

  const calcoloTotaleSuddivisoPerCarichi =  () => {
    let array = []
    let containerCarico = []
    let containerScarico = []
    isContainer.map(container => {
        servizi.map(i => {
            if(i.carico.isContainer === true && i.carico.value === container.value) {
                if(containerCarico.indexOf(container) === -1) {
                    containerCarico.push(container)
                }
            }
            if (i.legenda.isContainer === true && i.legenda.value === container.value) {
                if (containerScarico.indexOf(container) === -1) {
                    containerScarico.push(container)
                }
            }
        })
    })
    let carichi = carico.concat(containerCarico)
    let scarichi = legenda.concat(containerScarico)
    carichi.map(c => {
        let obj = {
            carico: c.label,
            destinazioni: [],
        }
        scarichi.map(l => {
            let tot = 0
            let tot1 = 0
            servizi.map(item => {
                if (item.carico.value === c.value && item.legenda.value === l.value) {
                    console.log(item)
                    tot = tot + (item.servizio_prezzo ? item.servizio_prezzo : 0) * (item.servizio_qty ? item.servizio_qty : 1)
                    tot1 = tot1 + (item.servizio_prezzo ? (item.servizio_prezzo - (item.servizio_prezzo - parseFloat(item.servizio_sconto ? item.servizio_sconto : item.servizio_prezzo))) : 0) * (item.servizio_qty ? item.servizio_qty : 1)
                }
            })
            let obj2 = {
                destinazione: l.label,
                imponibile: tot,
                sconto: tot1
            }
            obj.destinazioni.push(obj2)
        })
        array.push(obj)
    })
    
    return array
  }


  const removeServizio = async (item) => {
    let array = []
    servizi.map((s, index, tutto) => {
        if (s.id !== item.id && item.servizio_name !== s.servizio_name) {
            array.push(item)
        }
        if(index === (tutto.length -1)) {
            setServizi(array)
        }
    })
  }

    //Chiamata post per inserimento utente
    const removeServizi =  (item) => {
        
    }


    //Chiamata post per inserimento utente
    const removeServiziAltri = async (item) => {
        setLoading(false)
        let array = altriServizi.filter(s => s.id !== item.id)
        const response = await axios.patch(`${window.$apiEndpoint}/clienti/remove-servizi-preventivo-altri`, {
            cliente: isCliente,
            preventivo: isPreventivo,
            altriServizi: array,
            id: props.match.params.id,
            imponibile: calcoloImponibile(),
            iva: calcoloIVA(),
            totale: calcoloTotale(),
            scontato: calcoloSconto(),
            totaleAltri: calcoloTotaleAltri(),
            totaleAltriConSconto: calcoloTotaleAltriConSconto(),
            calcoloTotaleSuddivisoPerCarichi: calcoloTotaleSuddivisoPerCarichi()
        }, {
            headers: {
                "x-access-token": cookies.token
            }
        })
        getClienti()
        getListino()
        getContainer()
        setLoading(true)
    }

  

    

        //Chiamata post per inserimento utente
        const onSubmit = async (data) => {
          setSaving(true)

          const response = await axios.patch(`${window.$apiEndpoint}/clienti/edit-preventivo`, {
            cliente: isCliente,
            preventivo: data,
            organizzazione: isPreventivo.organizzazione,
            name: data.name.length > 0 ? data.name : isPreventivo.name,
            legenda: legenda,
            servizi: servizi,
            stanze: stanze,
            carico: carico,
            scatole: scatole,
            altriServizi: altriServizi,
            oggetto: oggetto,
            postAltri: postAltri,
            info: info,
            aggiuntivi: aggiuntivi,
            oggetto: oggetto,
            postAltri: postAltri,
            note: note,
            clausoleEditabili: clausoleEditabili,
            id: props.match.params.id,
              imponibile: calcoloImponibile(),
              iva: calcoloIVA(),
              totale: calcoloTotale(),
              scontato: calcoloSconto(),
              totaleAltri: calcoloTotaleAltri(),
              totaleAltriConSconto: calcoloTotaleAltriConSconto(),
              calcoloTotaleSuddivisoPerCarichi: calcoloTotaleSuddivisoPerCarichi()
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti()
          setTimeout(() => {
            setSaving(false)
          }, 1000)
      }


      const [stepLoader, setStepLoader] = useState(true)
      const [note, setNote] = useState("")
        //Chiamata post per inserimento utente
        const completeStep = async (data) => {
          setStepLoader(false)
          const response = await axios.patch(`${window.$apiEndpoint}/clienti/complete-step`, {
            cliente: isCliente,
            id: props.match.params.id,
            step: data,
            preventivo: isPreventivo
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          const response2 = await axios.post(`${window.$apiEndpoint}/clienti/pdf-download-odl`,
            {
              preventivo: isPreventivo,
              cliente: isCliente
            }
            , {
              headers: {
                "x-access-token": cookies.token
              }
            })
          const response3 = await axios.post(`${window.$apiEndpoint}/clienti/pdf-download-prev`,
            {
              preventivo: isPreventivo,
              cliente: isCliente
            }
            , {
              headers: {
                "x-access-token": cookies.token
              }
            })
          getClienti()
          setTimeout(() => {
            setStepLoader(true)
            if(parseInt(data) >= 3) {
              history.push(`/clienti/${isCliente._id}`)
            }
          }, 1000)
      }


    const { SearchBar } = Search;

    const completeAggiuntivi = async () => {
        setSaving(true)
        const response = await axios.patch(`${window.$apiEndpoint}/clienti/complete-aggiuntivi`, {
            cliente: isCliente,
            aggiuntivi: aggiuntivi,
            clausoleEditabili: clausoleEditabili,
            id: props.match.params.id,
        }, {
            headers: {
                "x-access-token": cookies.token
            }
        })
        
        setTimeout(() => {
            getClienti()
            setSaving(false)
        }, 1000)
    }


    const eliminaServizio = async (item) => {
        let confirm = window.confirm("Sei sicuro di voler eliminare il servizio?")
        if(confirm) {
            let array = servizi.filter(s => s.id !== item.id)
            console.log(array)
            const response = await axios.post(`${window.$apiEndpoint}/clienti/edit-array-servizi`, {
                cliente: isCliente,
                servizi: array,
                id: props.match.params.id
            }, {
                headers: {
                    "x-access-token": cookies.token
                }
            })
            if (response) {
                window.location.reload()
            }
        }
        
    }

    const eliminaServizioAltri = async (item) => {
        let confirm = window.confirm("Sei sicuro di voler eliminare il servizio?")
        if(confirm) {
            let array = altriServizi.filter(s => s.id !== item.id)
            const response = await axios.post(`${window.$apiEndpoint}/clienti/edit-array-servizi-altri`, {
                cliente: isCliente,
                altriServizi: array,
                id: props.match.params.id
            }, {
                headers: {
                    "x-access-token": cookies.token
                }
            })
            if (response) {
                window.location.reload()
            }
        }
        
    }

    const [oggetto, setOggetto] = useState(`<p style="font-size: 12px;">- SMONTAGGIO, TRASPORTO E RIMONTAGGIO MOBILI ELENCATI NEL FILE ALLEGATO</p>`)

    const onChangeDescription = (evt) => {
        const newContent = evt.editor.getData();

        console.log(newContent)

        setPreventivo(params => ({
            ...params,
            organizzazione: newContent,
        }))
    }



    const onChangeClausoleEditabili = (evt) => {
        const newContent = evt.editor.getData();
        setClausoleEditabili(newContent)
    }

    const onChangeNote = (evt) => {
        const newContent = evt.editor.getData();
        setNote(newContent)
    }

    const onChangeOggetto = (evt) => {
        const newContent = evt.editor.getData();
        setOggetto(newContent)
    }


    const onChangePostAltri = (evt) => {
        const newContent = evt.editor.getData();
        setPostAltri(newContent)
    }

    const onChangeKitGenerici = (evt) => {
        const newContent = evt.editor.getData();

        setScatole(params => ({
            ...params,
            kitGenerici: newContent,
        }))
    }



  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SidebarMenu attivo={"Clienti"} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {loading && loading2 && stepLoader && currentStep ? <>

                  {(() => {
                      switch (currentStep) {
                          case 1:
                              return (
                                  <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none min-h-full">

                                      {/* STEP 1 */}
                                      <div className="py-6">
                                          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                                          </div>
                                          <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
                                              {/* Replace with your content */}
                                              {isPreventivo && isCliente && loading && loading2 ? <nav aria-label="Progress">
                                                  <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                                      {isPreventivo.steps.map((step) => (
                                                          <li key={step.name} className="md:flex-1">
                                                              {step.status === 'complete' ? (
                                                                  <a
                                                                      href={step.href}
                                                                      className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                  >
                                                                      <span className="text-sm font-medium text-green-600 group-hover:text-green-800">{step.id}</span>
                                                                      <span className="text-sm font-medium text-green-600">{step.name}</span>
                                                                  </a>
                                                              ) : step.status === 'current' ? (
                                                                  <a
                                                                      href={step.href}
                                                                      className="flex flex-col border-l-4 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                      aria-current="step"
                                                                  >
                                                                      <span className="text-sm font-medium text-blue-600">{step.id}</span>
                                                                      <span className="text-sm font-medium text-blue-600">{step.name}</span>
                                                                  </a>
                                                              ) : (
                                                                  <a
                                                                      href={step.href}
                                                                      className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                  >
                                                                      <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                                                      <span className="text-sm font-medium">{step.name}</span>
                                                                  </a>
                                                              )}
                                                          </li>
                                                      ))}
                                                  </ol>
                                              </nav> : null}

                                              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

                                                  <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                                      <div className=" md:mt-0 md:col-span-2">
                                                          <div className="flex justify-end mb-5">
                                                              <p>
                                                                  Gent. {isCliente.ragioneSociale}<br />
                                                                  {isCliente.address + ', ' + isCliente.civic}<br />
                                                                  {isCliente.postalCode + ' - ' + isCliente.city + ' (' + isCliente.region + ')'}<br />
                                                                  piva/cf {isCliente.vat}
                                                              </p>
                                                          </div>
                                                          {isPreventivo && isCliente && loading && loading2 ? <form onSubmit={handleSubmit(onSubmit)}>
                                                              <div className="grid grid-cols-6 gap-6 mb-4 ">
                                                                  <div className="col-span-1 sm:col-span-1 ">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero preventivo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <div className="flex">
                                                                          <input
                                                                              type="number"
                                                                              name="number"
                                                                              id="number"
                                                                              defaultValue={isPreventivo.number ? isPreventivo.number : numerazione}
                                                                              {...register("number")}
                                                                              className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                          />
                                                                          <p>/{moment(isPreventivo.created_at).format("YYYY")}</p>

                                                                      </div>

                                                                      {errors.number && <p>Il campo è obbligatorio</p>}
                                                                  </div>
                                                                  <div className="col-span-2 sm:col-span-2">
                                                                      <label htmlFor="sopralluogo" className="block text-sm font-medium text-gray-700">
                                                                          Data sopralluogo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <input
                                                                          type="date"
                                                                          name="sopralluogo"
                                                                          id="sopralluogo"
                                                                          defaultValue={isPreventivo.sopralluogo ? moment(isPreventivo.sopralluogo).format("YYYY-MM-DD") : null}
                                                                          {...register("sopralluogo")}
                                                                          className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />

                                                                      {errors.sopralluogo && <p>Il campo è obbligatorio</p>}
                                                                  </div>
                                                                  <div className="col-span-3 sm:col-span-3">
                                                                      <label htmlFor="traslocoIpotetico" className="block text-sm font-medium text-gray-700">
                                                                          Data trasloco ipotetico<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <input
                                                                          type="text"
                                                                          name="traslocoIpotetico"
                                                                          id="traslocoIpotetico"
                                                                          defaultValue={isPreventivo.traslocoIpotetico}
                                                                          {...register("traslocoIpotetico")}
                                                                          className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />

                                                                      {errors.traslocoIpotetico && <p>Il campo è obbligatorio</p>}
                                                                  </div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Nome preventivo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <input
                                                                          type="text"
                                                                          name="name"
                                                                          id="name"
                                                                          defaultValue={isPreventivo.name}
                                                                          {...register("name")}
                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />

                                                                      {errors.name && <p>Il campo è obbligatorio</p>}
                                                                  </div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Oggetto del preventivo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <CKEditors
                                                                          activeclassName="p10"
                                                                          content={oggetto}
                                                                          config={editorConfig}
                                                                          events={{
                                                                              "change": onChangeOggetto
                                                                          }}
                                                                      />

                                                                      {errors.oggetto && <p>Il campo è obbligatorio</p>}
                                                                  </div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Organizzazione lavori<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <CKEditors
                                                                          activeclassName="p10"
                                                                          content={isPreventivo.organizzazione}
                                                                          config={editorConfig}
                                                                          events={{
                                                                              "change": onChangeDescription
                                                                          }}
                                                                      />

                                                                      {errors.organizzazione && <p>Il campo è obbligatorio</p>}
                                                                  </div>

                                                              </div>
                                                              <hr className='my-16' />

                                                              {/*CARICO  */}
                                                              <div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Carico <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setCarico(old => [...old, {
                                                                              label: "",
                                                                              value: generate_random(10),
                                                                              permessi: false
                                                                          }])}>Aggiungi</small>
                                                                      </label>
                                                                  </div>
                                                                  {carico ? <div className="mt-2">
                                                                      {carico.map((item, index) => {
                                                                          return (
                                                                              <div key={index} className="grid grid-cols-2 gap-2 mb-4 mt-4">

                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                  }}>
                                                                                      <div style={{
                                                                                          display: 'flex',
                                                                                          flexDirection: 'row',
                                                                                          marginRight: '2vw',
                                                                                          paddingTop: '2vh'
                                                                                      }}>
                                                                                          <ReactTooltip />
                                                                                          <MinusCircleIcon
                                                                                              onClick={() => setCarico(current =>
                                                                                                  current.filter(dest => {
                                                                                                      return dest.value !== item.value;
                                                                                                  }),
                                                                                              )}
                                                                                              className="h-6 w-6 text-red-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Rimuovi"
                                                                                          />
                                                                                      </div>
                                                                                      <div style={{ width: '100vw' }}>
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                              Carico

                                                                                          </label>
                                                                                          <input
                                                                                              type="text"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.label}
                                                                                              onChange={e => updateCaricoLegenda(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>

                                                                                  </div>

                                                                                  <div className='flex-1 justify-start'>
                                                                                      <div className="flex items-center">
                                                                                          <input id="checked-checkbox" checked={item.permessi} onChange={e => updatePermessiCarico(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Permessi</label>
                                                                                      </div>
                                                                                  </div>

                                                                              </div>
                                                                          )
                                                                      })}
                                                                  </div> : null}

                                                              </div>
                                                              <hr className='my-16' />

                                                              {/*DESTINAZIONI  */}
                                                              <div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Destinazioni <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setLegenda(old => [...old, {
                                                                              label: "",
                                                                              color: "",
                                                                              value: generate_random(10),
                                                                              permessi: false
                                                                          }])}>Aggiungi</small>
                                                                      </label>
                                                                  </div>
                                                                  {legenda ? <div className="mt-2">
                                                                      {legenda.map((item, index) => {
                                                                          return (
                                                                              <div key={index} className="grid grid-cols-2 gap-2 mb-4 mt-4">

                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                  }}>
                                                                                      <div style={{
                                                                                          display: 'flex',
                                                                                          flexDirection: 'row',
                                                                                          marginRight: '2vw',
                                                                                          paddingTop: '2vh'
                                                                                      }}>
                                                                                          <ReactTooltip />
                                                                                          <MinusCircleIcon
                                                                                              onClick={() => setLegenda(current =>
                                                                                                  current.filter(dest => {
                                                                                                      return dest.value !== item.value;
                                                                                                  }),
                                                                                              )}
                                                                                              className="h-6 w-6 text-red-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Rimuovi"
                                                                                          />
                                                                                      </div>
                                                                                      <div style={{ width: '100vw' }}>
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                              Destinazione
                                                                                          </label>
                                                                                          <input
                                                                                              type="text"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.label}
                                                                                              onChange={e => updateDestinazioneLegenda(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>

                                                                                  </div>
                                                                                  <div className="ml-4">

                                                                                      <CirclePicker
                                                                                          triangle='hide'
                                                                                          color={item.color}
                                                                                          colors={[
                                                                                              '#FF0000', '#00FFFF', '#0000FF', '#FFFF00', '#008000', '#FFA500', '#FF00FF', '#800000', '#0000A0', '#800080', '#C0C0C0'
                                                                                          ]}
                                                                                          onChangeComplete={(color, event) => {
                                                                                              updateColoreLegenda(item, color.hex)
                                                                                          }}
                                                                                      />
                                                                                  </div>

                                                                                  <div className='flex-1 justify-start'>
                                                                                      <div className="flex items-center">
                                                                                          <input id="checked-checkbox" checked={item.permessi} onChange={e => updatePermessiLegenda(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Permessi</label>
                                                                                      </div>
                                                                                  </div>

                                                                              </div>
                                                                          )
                                                                      })}
                                                                  </div> : null}

                                                              </div>
                                                              <hr className='my-16' />
                                                              {/*STANZE */}
                                                              <div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Stanze <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setStanze(old => [...old, {
                                                                              label: "",
                                                                              value: generate_random(10),
                                                                          }])}>Aggiungi</small>
                                                                      </label>
                                                                  </div>
                                                                  {legenda ? <div className="mt-2">
                                                                      {stanze.map((item, index) => {
                                                                          return (
                                                                              <div key={index} className="grid grid-cols-2 gap-2 mb-4 mt-4">

                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                  }}>
                                                                                      <div style={{
                                                                                          display: 'flex',
                                                                                          flexDirection: 'row',
                                                                                          marginRight: '2vw',
                                                                                          paddingTop: '2vh'
                                                                                      }}>
                                                                                          <ReactTooltip />
                                                                                          <MinusCircleIcon
                                                                                              onClick={() => setStanze(current =>
                                                                                                  current.filter(dest => {
                                                                                                      return dest.value !== item.value;
                                                                                                  }),
                                                                                              )}
                                                                                              className="h-6 w-6 text-red-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Rimuovi"
                                                                                          />
                                                                                      </div>
                                                                                      <div style={{ width: '100vw' }}>
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                              Nome stanza
                                                                                          </label>
                                                                                          <input
                                                                                              type="text"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.label}
                                                                                              onChange={e => updateStanza(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>

                                                                                  </div>

                                                                              </div>
                                                                          )
                                                                      })}
                                                                  </div> : null}

                                                              </div>
                                                              <hr className='my-16' />
                                                              {/*SERVIZI*/}
                                                              <div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Servizi <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setServizi(old => [...old, {
                                                                              servizio_name: "",
                                                                              servizio_qty: 1,
                                                                              servizio_prezzo: "",
                                                                              servizio_sconto: null,
                                                                              servizio_ID: "",
                                                                              stanza: {
                                                                                  value: "",
                                                                                  label: ""
                                                                              },
                                                                              legenda: {
                                                                                  value: legenda[0] ? legenda[0].value : "",
                                                                                  color: legenda[0] ? legenda[0].color : "",
                                                                                  label: legenda[0] ? legenda[0].label : "",
                                                                                  isContainer: false
                                                                              },
                                                                              carico: {
                                                                                  value: carico[0] ? carico[0].value : "",
                                                                                  label: carico[0] ? carico[0].label : "",
                                                                                  isContainer: false
                                                                              },
                                                                              id: generate_random(10)
                                                                          }])}>Aggiungi</small>
                                                                      </label>
                                                                  </div>
                                                                  <div className="mt-2">
                                                                      {servizi.map((item, i) => {
                                                                          return (
                                                                              <div key={i} className="grid grid-cols-3 gap-3 mb-4 mt-4">

                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                  }}>
                                                                                      <div style={{
                                                                                          display: 'flex',
                                                                                          flexDirection: 'row',
                                                                                          marginRight: '2vw',
                                                                                          paddingTop: '2vh'
                                                                                      }}>
                                                                                          <ReactTooltip />
                                                                                          <MinusCircleIcon
                                                                                              onClick={() => eliminaServizio(item)}
                                                                                              className="h-6 w-6 text-red-600 mr-4"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Rimuovi"
                                                                                          />
                                                                                          {serviceChooser[i] ? <PencilIcon
                                                                                              onClick={() => onClick(i)}
                                                                                              className="h-6 w-6 text-blue-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Nuovo servizio"
                                                                                          /> : <BookmarkIcon
                                                                                              onClick={() => onClick(i)}
                                                                                              className="h-6 w-6 text-blue-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Seleziona da listino"
                                                                                          />}
                                                                                      </div>

                                                                                      <div style={{ width: '100vw' }}>
                                                                                          <label htmlFor="ragioneSociale" className={`block text-sm font-medium text-gray-700 ${!serviceChooser[i] ? ' mb-1' : null}`}>
                                                                                              Servizio
                                                                                          </label>
                                                                                          {serviceChooser[i] ? <input
                                                                                              type="text"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.servizio_name}
                                                                                              onChange={e => updateNomeServizio(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          /> : <Select
                                                                                              options={isListino}
                                                                                              defaultValue={{
                                                                                                  value: item.servizio_name,
                                                                                                  label: item.servizio_name
                                                                                              }}
                                                                                              onChange={e => {
                                                                                                  updateFromChangeServizioPrice(item, e)
                                                                                                  updateFromChangeServizioName(item, e)
                                                                                                  updateFromChangeServizioID(item, e)
                                                                                              }}
                                                                                              placeholder="Seleziona servizio"
                                                                                          />}
                                                                                      </div>

                                                                                  </div>
                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                  }}>
                                                                                      <div className="mr-4">
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                              Qtà
                                                                                          </label>
                                                                                          <input
                                                                                              type="number"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              step="1"
                                                                                              defaultValue={item.servizio_qty}
                                                                                              onChange={e => updateQtyServizio(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>
                                                                                      <div className="mr-4">
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                              Prezzo <span className="text-gray-400"><small>Totale: <strong>{item.servizio_qty * item.servizio_prezzo}* €</strong></small></span>
                                                                                          </label>
                                                                                          <input
                                                                                              type="number"
                                                                                              step="0.01"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.servizio_prezzo}
                                                                                              onChange={e => updatePrezzoServizio(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>
                                                                                      <div>
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                              Scontato <span className="text-gray-400"><small>Totale: <strong>{item.servizio_qty * item.servizio_sconto}* €</strong></small></span>
                                                                                          </label>
                                                                                          <input
                                                                                              type="number"
                                                                                              step="0.01"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.servizio_sconto}
                                                                                              onChange={e => updatePrezzoServizioSconto(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>


                                                                                  </div>
                                                                                  <div className="flex">
                                                                                      <div className="w-full mr-2">
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                              Stanza
                                                                                          </label>
                                                                                          <Select
                                                                                              options={stanze}
                                                                                              defaultValue={item.stanza}
                                                                                              placeholder="Seleziona stanza"
                                                                                              onChange={e => updateStanzaServizio(item, e)}
                                                                                          />

                                                                                      </div>
                                                                                      <div className="w-full mr-2">
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1 flex">
                                                                                              Carico    {" "}<div className="flex items-center">
                                                                                                  <input id="checked-checkbox" checked={item.carico.isContainer} onChange={e => updateGoFromContainer(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 ml-4 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                                  <label htmlFor="checked-checkbox" className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300"><OfficeBuildingIcon className="h-4 w-4" /></label>
                                                                                              </div>
                                                                                          </label>


                                                                                          {item.carico.isContainer ? <Select
                                                                                              options={isContainer}
                                                                                              defaultValue={item.carico}
                                                                                              placeholder="Seleziona carico"
                                                                                              onChange={e => updateCaricoServizio(item, e)}
                                                                                          /> : <Select
                                                                                              options={carico}
                                                                                              defaultValue={item.carico}
                                                                                              placeholder="Seleziona carico"
                                                                                              onChange={e => updateCaricoServizio(item, e)} />
                                                                                          }
                                                                                      </div>
                                                                                      <div className="w-full">
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1 flex">
                                                                                              Destinazione {" "}<div className="flex items-center">
                                                                                                  <input id="checked-checkbox" checked={item.legenda.isContainer} onChange={e => updateGoToContainer(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 ml-2 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                                  <label htmlFor="checked-checkbox" className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300"><OfficeBuildingIcon className="h-4 w-4" /></label>
                                                                                              </div>
                                                                                          </label>
                                                                                          {item.legenda.isContainer ? <Select
                                                                                              options={isContainer}
                                                                                              defaultValue={item.legenda}
                                                                                              placeholder="Seleziona destinazione"
                                                                                              onChange={e => updateDestinazioneServizio(item, e)} /> : <Select
                                                                                              options={legenda}
                                                                                              defaultValue={item.legenda}
                                                                                              placeholder="Seleziona destinazione"
                                                                                              onChange={e => updateDestinazioneServizio(item, e)}
                                                                                              styles={styles} />
                                                                                          }
                                                                                      </div>
                                                                                  </div>


                                                                              </div>
                                                                          )
                                                                      })}
                                                                  </div>

                                                              </div>

                                                              <hr className='my-16' />
                                                              {/*STANZE */}
                                                              <div>
                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Servizi scorporati <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setAltriServizi(old => [...old, {
                                                                              label: "",
                                                                              value: "",
                                                                              id: generate_random(10),
                                                                              costo: "",
                                                                              sconto: null
                                                                          }])}>Aggiungi</small>
                                                                      </label>
                                                                  </div>
                                                                  {legenda ? 
                                                                  <div className="mt-2">
                                                                      {altriServizi.map((item, index) => {
                                                                          return (
                                                                            

                                                                                  <div 
                                                                                  className="grid grid-cols-2 gap-2 mb-4 mt-4"
                                                                                  key={index}
                                                                              >
                                                                                <div style={{display: 'flex'}}>
                                                                                      <div style={{
                                                                                          display: 'flex',
                                                                                          flexDirection: 'row',
                                                                                          marginRight: '2vw',
                                                                                          paddingTop: '2vh'
                                                                                      }}>
                                                                                          <ReactTooltip />
                                                                                          <MinusCircleIcon
                                                                                              onClick={() => eliminaServizioAltri(item)}
                                                                                              className="h-6 w-6 text-red-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Rimuovi"
                                                                                          />
                                                                                          {altriServiceChooser[index] ? <PencilIcon
                                                                                              onClick={() => onClickAltri(index)}
                                                                                              className="h-6 w-6 text-blue-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Nuovo servizio"
                                                                                          /> : <BookmarkIcon
                                                                                                  onClick={() => onClickAltri(index)}
                                                                                              className="h-6 w-6 text-blue-600"
                                                                                              aria-hidden="true"
                                                                                              data-tip="Seleziona da listino"
                                                                                          />}
                                                                                      </div>
                                                                                      <div className='w-full'>
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                              Servizio
                                                                                          </label>
                                                                                          
                                                                                          {altriServiceChooser[index] ? <input
                                                                                              type="text"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.label}
                                                                                              onChange={e => updateAltriServiziNome(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          /> : <Select
                                                                                              options={isListino}
                                                                                              defaultValue={{
                                                                                                  value: item.value,
                                                                                                  label: item.label
                                                                                              }}
                                                                                              onChange={e => {
                                                                                                  updateFromChangeAltroServizioPrice(item, e)
                                                                                                  updateFromChangeAltroServizioName(item, e)
                                                                                                  updateFromChangeAltroServizioID(item, e)
                                                                                                  updateFromChangeAltroServizioValue(item,e)
                                                                                              }}
                                                                                              className="mt-1"
                                                                                              placeholder="Seleziona servizio"
                                                                                          />}
                                                                                      </div>
                                                                                      </div>
                                                                                  <div style={{ display: 'flex' }}>
                                                                                      <div className='mr-4'>
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                              Costo 
                                                                                          </label>
                                                                                          <input
                                                                                              type="text"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              defaultValue={item.costo}
                                                                                              onChange={e => updateAltriServiziCosto(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>
                                                                                      <div >
                                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                              Sconto
                                                                                          </label>
                                                                                          <input
                                                                                              type="number"
                                                                                              name="name"
                                                                                              id="name"
                                                                                              step="0.01"
                                                                                              defaultValue={item.sconto}
                                                                                              onChange={e => updateAltriServiziSconto(item, e.target.value)}
                                                                                              autoComplete="name"
                                                                                              className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                          />
                                                                                      </div>
                                                                                  </div>
                                                                                      


                                                                              </div>
                                                                          )
                                                                      })}
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Campo da inserire dopo i servizi scorporati<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                      </label>
                                                                      <CKEditors
                                                                          activeclassName="p10"
                                                                          content={postAltri}
                                                                          events={{
                                                                              "change": onChangePostAltri
                                                                          }}
                                                                      />

                                                                      {errors.oggetto && <p>Il campo è obbligatorio</p>}
                                                                  </div>

                                                              </div>


                                                              {isPreventivo && isCliente && loading && loading2 ? <><hr className='my-16' />

                                                                  {/* SCATOLE */}

                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Fornitura e trasporto scatole
                                                                      </label>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.fornituraNoTrasporto : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      fornituraNoTrasporto: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Solo fornitura senza trasporto</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.noScatole : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      noScatole: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Niente scatole</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.conOsenzaScatole : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      conOsenzaScatole: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Preventivare con o senza scatole</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-6 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.appendiabiti : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      appendiabiti: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Appendiabiti</label>
                                                                          </div>
                                                                          {/**INPUT NUMERO SE CHECKED */}
                                                                      </div>
                                                                  </div>

                                                                  {scatole && scatole.appendiabiti ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero appendiabiti
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={scatole ? scatole.numeroAppendiabiti : null}
                                                                          onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  numeroAppendiabiti: e.target.value,
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.imballaggioCompleto : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      imballaggioCompleto: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Servizio imballaggio completo</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.imballaggioParziale : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      imballaggioParziale: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Servizio imballaggio parziale</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-6 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.scatolePiccole : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      scatolePiccole: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scatole piccole</label>
                                                                          </div>
                                                                          {/**INPUT NUMERO SE CHECKED */}
                                                                      </div>
                                                                  </div>

                                                                  {scatole && scatole.scatolePiccole ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero scatole piccole
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={scatole ? scatole.numeroScatolePiccole : null}
                                                                          onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  numeroScatolePiccole: e.target.value,
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-6 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.scatoleGrandi : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      scatoleGrandi: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scatole grandi</label>
                                                                          </div>
                                                                          {/**INPUT NUMERO SE CHECKED */}
                                                                      </div>
                                                                  </div>

                                                                  {scatole && scatole.scatoleGrandi ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero scatole grandi
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={scatole ? scatole.numeroScatoleGrandi : null}
                                                                          onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  numeroScatoleGrandi: e.target.value,
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}


                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.faldoni : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      faldoni: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scatole faldoni</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {scatole && scatole.faldoni ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero faldoni
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={scatole ? scatole.numeroFaldoni : null}
                                                                          onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  numeroFaldoni: e.target.value,
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.soloTrasporto : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      soloTrasporto: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Solo trasporto senza fornitura</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                            


                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={scatole ? scatole.kitFragili : false} onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      kitFragili: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Kit fragili</label>
                                                                          </div>
                                                                      </div>
                                                                      {scatole.kitFragili ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                          <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                              Numero kit fragili
                                                                          </label>
                                                                          <input
                                                                              type="number"
                                                                              name="name"
                                                                              id="name"
                                                                              step="1"
                                                                              defaultValue={scatole ? scatole.kitGenerici : null}
                                                                              onChange={
                                                                                  e => setScatole(params => ({
                                                                                      ...params,
                                                                                      kitGenerici: e.target.value,
                                                                                  }))
                                                                              }
                                                                              autoComplete="name"
                                                                              className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                          />
                                                                      </div> : null}
                                                                  </div>





                                                                  <hr className='my-16' />

                                                                  {/* INFO FINALI */}

                                                                  <div className="col-span-12 sm:col-span-6">
                                                                      <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                          Varie
                                                                      </label>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.bacchetteTende : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      bacchetteTende: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Bacc tende</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {info && info.bacchetteTende ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero bacc tende
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={info ? info.numeroBacchetteTende : null}
                                                                          onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  numeroBacchetteTende: parseInt(e.target.value),
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                           

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.borseTV : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      borseTV: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Borse TV</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-6 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.quadri : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      quadri: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Quadri</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {info && info.quadri ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero quadri
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={info ? info.numeroQuadri : null}
                                                                          onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  numeroQuadri: parseInt(e.target.value),
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.lampadari : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      lampadari: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lampadari</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {info && info.lampadari ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero lampadari
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={info ? info.numeroLampadari : null}
                                                                          onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  numeroLampadari: parseInt(e.target.value),
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.faretti : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      faretti: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Faretti</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {info && info.faretti ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero faretti
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={info ? info.numeroFaretti : null}
                                                                          onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  numeroFaretti: parseInt(e.target.value),
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.plafoniere : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      plafoniere: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Plafoniere</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {info && info.plafoniere ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero plafoniere
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={info ? info.numeroPlafoniere : null}
                                                                          onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  numeroPlafoniere: parseInt(e.target.value),
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.aplique : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      aplique: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Aplique</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  {info && info.aplique ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                          Numero aplique
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="name"
                                                                          id="name"
                                                                          step="1"
                                                                          defaultValue={info ? info.numeroAplique : null}
                                                                          onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  numeroAplique: parseInt(e.target.value),
                                                                              }))
                                                                          }
                                                                          autoComplete="name"
                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                  </div> : null}

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.carrelloPianoforte : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      carrelloPianoforte: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Carrello pianoforte</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.autoscala31mt : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      autoscala31mt: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Autoscala 31 mt doppia velocità</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.falegnameria : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      falegnameria: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lavori di falegnameria</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  
                                                                  </> : null}





                                                              <hr className='my-16' />

                                                              {/* COSTI TOTALI */}

                                                              {servizi ? <div className="flex justify-end mt-5">
                                                                  <p>
                                                                      <strong>Imponibile:</strong> {calcoloImponibile()} €<br />
                                                                       <strong>Con sconto:</strong> {calcoloSconto()} €
                                                                       <span className="text-gray-500 ml-2"><small>{" -" + calcoloValoreSconto() + " €"}</small></span><br />
                                                                      
                                                                      <strong>IVA:</strong> {calcoloIVA()} €<br />

                                                                      <strong>Totale:</strong> {calcoloTotale()} €<br />
                                                                  </p>
                                                              </div> : null}

                                                             

                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Note<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                  </label>
                                                                  <CKEditors
                                                                      activeclassName="p10"
                                                                      content={note}
                                                                      config={editorConfig}
                                                                      events={{
                                                                          "change": onChangeNote
                                                                      }}
                                                                  />

                                                                  {errors.oggetto && <p>Il campo è obbligatorio</p>}
                                                              </div>

                                                              <br />

                                                              <div className="grid grid-cols-2 gap-4">
                                                                  <div className="col-span-1 sm:col-span-1">
                                                                      <label htmlFor="acconto" className="block text-sm font-medium text-gray-700">
                                                                          Importo acconto<strong><span style={{ color: 'red' }}>*</span><small className='ml-2 font-light'>IVA esclusa</small> (suggerito 50% : {calcoloSconto()/2} €) </strong>
                                                                      </label>
                                                                      <input
                                                                          type="number"
                                                                          name="acconto"
                                                                          id="acconto"
                                                                          defaultValue={isPreventivo.acconto}
                                                                          {...register("acconto")}
                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />

                                                                      {errors.acconto && <p>Il campo è obbligatorio</p>}
                                                                  </div>

                                                                  <div className="col-span-1 sm:col-span-1">

                                                                  </div>
                                                                  <div className="col-span-12 sm:col-span-6 mt-6">
                                                                      <div className='flex-1 justify-start'>
                                                                          <div className="flex items-center">
                                                                              <input id="checked-checkbox" checked={info ? info.scorporo : false} onChange={
                                                                                  e => setInfo(params => ({
                                                                                      ...params,
                                                                                      scorporo: e.target.checked,
                                                                                  }))
                                                                              } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                              <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scorporo prezzo</label>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                              </div>



                                                              <div className="flex justify-between">
                                                                  <div className="flex justify-start mt-5">
                                                                      <button
                                                                          type="button"
                                                                          onClick={() => history.goBack()}
                                                                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                      >
                                                                          <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                                                                      </button>
                                                                  </div>
                                                                  <div className="flex justify-end mt-5">
                                                                      {/*<button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MailIcon className="mr-2 h-4 w-4" />Invia
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <PrinterIcon className="mr-2 h-4 w-4" />Stampa
                        </button>*/}
                                                                      <button
                                                                          type="submit"
                                                                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                                      >
                                                                          {isSaving ? 'Salvo...' : 'Aggiorna'}
                                                                      </button>
                                                                      <button
                                                                          type="submit"
                                                                          onClick={() => completeStep(1)}
                                                                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                      >
                                                                          <ArrowRightIcon className="mr-2 h-4 w-4" />Avanti
                                                                      </button>
                                                                  </div>
                                                              </div>


                                                          </form> : null}
                                                      </div>





                                                  </div>
                                              </div>
                                          </div>


                                      </div>




                                  </main>
                              );
                        case 2: 
                              return (<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none min-h-full">

                                  {/* STEP 2 */}
                                  <div className="py-6">
                                      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                                      </div>
                                      <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
                                          {/* Replace with your content */}
                                          {isPreventivo && isCliente && loading && loading2 ? <nav aria-label="Progress">
                                              <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                                  {isPreventivo.steps.map((step) => (
                                                      <li key={step.name} className="md:flex-1">
                                                          {step.status === 'complete' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-green-600 group-hover:text-green-800">{step.id}</span>
                                                                  <span className="text-sm font-medium text-green-600">{step.name}</span>
                                                              </a>
                                                          ) : step.status === 'current' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="flex flex-col border-l-4 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                  aria-current="step"
                                                              >
                                                                  <span className="text-sm font-medium text-blue-600">{step.id}</span>
                                                                  <span className="text-sm font-medium text-blue-600">{step.name}</span>
                                                              </a>
                                                          ) : (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                                                  <span className="text-sm font-medium">{step.name}</span>
                                                              </a>
                                                          )}
                                                      </li>
                                                  ))}
                                              </ol>
                                          </nav> : null}

                                          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

                                              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                                  {isPreventivo && isCliente && loading && loading2 ? <div className="mt-5 md:mt-0 md:col-span-2">
                                                      <div className="flex justify-between mt-5 mb-5">
                                                          <p>
                                                              Gent. {isCliente.ragioneSociale}<br />
                                                              {isCliente.address + ', ' + isCliente.civic}<br />
                                                              {isCliente.postalCode + ' - ' + isCliente.city + ' (' + isCliente.region + ')'}<br />
                                                              piva/cf {isCliente.vat}
                                                          </p>
                                                          <div className="text-right">
                                                              <h5>Carico</h5>
                                                              <ul className="list-none">
                                                                  {carico.map((destinazione, index) => {
                                                                      return (
                                                                          <li key={index}><span>{destinazione.label} {destinazione.permessi ? "(Permessi richiesti)" : null}</span></li>
                                                                      )
                                                                  })}
                                                              </ul>
                                                              <h5>Destinazioni</h5>
                                                              <ul className="list-none">
                                                                  {legenda.map((destinazione, index) => {
                                                                      return (
                                                                          <li key={index}><span style={{ color: destinazione.color }}>{destinazione.label} {destinazione.permessi ? "(Permessi richiesti)" : null}</span></li>
                                                                      )
                                                                  })}
                                                              </ul>
                                                          </div>
                                                      </div>
                                                      <div className="flex justify-start mt-5">
                                                          <p>
                                                              Preventivo n {isPreventivo.number}/{moment(isPreventivo.created_at).format("YY")} del {moment(isPreventivo.created_at).format("DD/MM/YYYY")}<br />
                                                              <br />
                                                              {isPreventivo.sopralluogo ? <span>A seguito di sopralluogo effettuato in data {moment(isPreventivo.sopralluogo).format("DD/MM/YYYY")} trova</span> : <span>Trova</span>} di seguito la nostra migliore offerta
                                                          </p>
                                                      </div>

                                                  </div> : null}



                                                  <br />
                                                  {stanze.map((stanza, index) => {
                                                      return (
                                                          <>{servizi.filter(item => item.stanza.value === stanza.value).length > 0 ?
                                                              <div className="border rounded-sm p-4 border-black">
                                                                  <h5>{stanza.label}</h5>
                                                                  <ul className="list-disc">
                                                                      {servizi.filter(item => item.stanza.value === stanza.value).map((servizio, index) => {
                                                                          return (
                                                                              <li key={index} className="flex justify-between">
                                                                                  <div style={{ color: servizio.legenda.color }}>nº{servizio.servizio_qty} {servizio.servizio_name}</div>
                                                                                  <div>{info.scorporo ? <span>{servizio.servizio_prezzo * servizio.servizio_qty}* € </span> : ""}</div>
                                                                              </li>
                                                                          )
                                                                      })}
                                                                  </ul>
                                                              </div>
                                                              : null}</>
                                                      )
                                                  })}

                                                  <div className="border rounded-sm p-4 border-black">
                                                      <h5>SCATOLE</h5>
                                                      <br />
                                                      {scatole.completo ? <p>✔️ Completo</p> : null}
                                                      {scatole.fornituraNoTrasporto ? <p>✔️ Solo fornitura senza trasporto</p> : null}
                                                      {scatole.noScatole ? <p>✔️ Niente scatole</p> : null}
                                                      {scatole.conOsenzaScatole ? <p>✔️ Preventivare con o senza scatole</p> : null}
                                                      {scatole.appendiabiti ? <p>✔️ Appendiabiti nº {scatole.numeroAppendiabiti}</p> : null}
                                                      {scatole.imballaggioCompleto ? <p>✔️ Servizio imballaggio completo</p> : null}
                                                      {scatole.imballaggioParziale ? <p>✔️ Servizio imballaggio parziale</p> : null}
                                                      {scatole.scatolePiccole ? <p>✔️ Scatole piccole nº {scatole.numeroScatolePiccole}</p> : null}
                                                      {scatole.scatoleGrandi ? <p>✔️ Scatole grandi nº {scatole.numeroScatoleGrandi}</p> : null}
                                                      {scatole.faldoni ? <p>✔️ Scatole faldoni nº {scatole.numeroFaldoni}</p> : null}
                                                      {scatole.kitFragili ? <p>✔️ Kit fragili</p> : null}


                                                  </div>
                                                  <div className="border rounded-sm p-4 border-black">
                                                      <h5>VARIE</h5>
                                                      <br />
                                                      {info.bacchetteTende ? <p>✔️ Bacc tende</p> : null}
                                                      {info.capiAppesi ? <p>✔️ Capi appesi</p> : null}
                                                      {info.borseTV ? <p>✔️ Borse TV</p> : null}
                                                      {info.quadri ? <p>✔️ Quadri nº {info.numeroQuadri}</p> : null}
                                                      {info.plafoniere ? <p>✔️ Plafoniere nº {info.numeroPlafoniere}</p> : null}
                                                      {info.aplique ? <p>✔️ Aplique nº {info.numeroAplique}</p> : null}
                                                      {info.faretti ? <p>✔️ Faretti nº {info.numeroFaretti}</p> : null}
                                                      {info.lampadari ? <p>✔️ Lampadari nº {info.numeroLampadari}</p> : null}
                                                      {info.carrelloPianoforte ? <p>✔️ Carrello pianoforte</p> : null}
                                                      {info.autoscala31mt ? <p>✔️ Autoscala 31mt</p> : null}
                                                      {info.falegnameria ? <p>✔️ Lavori falegnameria</p> : null}
                                                  </div>

                                                  <div className="flex justify-between">
                                                      <div className="border rounded-sm w-full p-4 border-black">
                                                          <h5>PREZZO: {isPreventivo.imponibile} € + IVA 22%</h5>
                                                      </div>
                                                      <div className="border rounded-sm w-full p-4 border-black">
                                                          <h5>ACCONTO: {isPreventivo.acconto + " € + IVA 22% "}<small>da versare a conferma lavori</small></h5>
                                                      </div>
                                                  </div>
                                                  <div className="border rounded-sm w-full p-4 border-black">
                                                      <h5>NOTE</h5>
                                                      <p>{isPreventivo.note}</p>
                                                  </div>
                                                  <br></br>
                                                  <div className="flex justify-between">
                                                      <div className="flex justify-start mt-5">
                                                          <button
                                                              type="button"
                                                              onClick={() => completeStep(0)}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                          >
                                                              <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                                                          </button>
                                                      </div>
                                                      <div className="flex justify-end mt-5">
                                                          {/*<button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MailIcon className="mr-2 h-4 w-4" />Invia
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <PrinterIcon className="mr-2 h-4 w-4" />Stampa
                        </button>*/}
                                                          <button
                                                              type="submit"
                                                              onClick={() => completeStep(2)}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                          >
                                                              <ArrowRightIcon className="mr-2 h-4 w-4" />Avanti
                                                          </button>
                                                      </div>
                                                  </div>






                                              </div>
                                          </div>
                                      </div>


                                  </div>




                              </main>);
                        case  (currentStep > 2):
                              return (<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none min-h-full">

                                  {/* STEP 2 */}
                                  <div className="py-6">
                                      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                                      </div>
                                      <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
                                          {/* Replace with your content */}
                                          {isPreventivo && isCliente && loading && loading2 ? <nav aria-label="Progress">
                                              <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                                  {isPreventivo.steps.map((step) => (
                                                      <li key={step.name} className="md:flex-1">
                                                          {step.status === 'complete' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-green-600 group-hover:text-green-800">{step.id}</span>
                                                                  <span className="text-sm font-medium text-green-600">{step.name}</span>
                                                              </a>
                                                          ) : step.status === 'current' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="flex flex-col border-l-4 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                  aria-current="step"
                                                              >
                                                                  <span className="text-sm font-medium text-blue-600">{step.id}</span>
                                                                  <span className="text-sm font-medium text-blue-600">{step.name}</span>
                                                              </a>
                                                          ) : (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                                                  <span className="text-sm font-medium">{step.name}</span>
                                                              </a>
                                                          )}
                                                      </li>
                                                  ))}
                                              </ol>
                                          </nav> : null}

                                          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

                                              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                                  {isPreventivo && isCliente && loading && loading2 ? <div className="mt-5 md:mt-0 md:col-span-2">
                                                      <div className="flex justify-between mt-5 mb-5">
                                                          <p>
                                                              Gent. {isCliente.ragioneSociale}<br />
                                                              {isCliente.address + ', ' + isCliente.civic}<br />
                                                              {isCliente.postalCode + ' - ' + isCliente.city + ' (' + isCliente.region + ')'}<br />
                                                              piva/cf {isCliente.vat}
                                                          </p>
                                                        
                                                      </div>
                                                      <div className="flex justify-start mt-5">
                                                          <p>
                                                              Preventivo n {isPreventivo.number}/{moment(isPreventivo.created_at).format("YY")} del {moment(isPreventivo.created_at).format("DD/MM/YYYY")}<br />
                                                              <br />
                                                          </p>
                                                      </div>

                                                  </div> : null}



                                                  <br />
                                                  {aggiuntivi.map((aggiuntivo, index) => {
                                                      return (
                                                          <>
                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={aggiuntivo.value} onChange={
                                                                              e => setAggiuntivi(current =>
                                                                                  current.map(obj => {
                                                                                      if (obj.label === aggiuntivo.label) {
                                                                                          return { ...obj, value: e.target.checked};
                                                                                      }

                                                                                      return obj;
                                                                                  }),
                                                                              )
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm text-gray-900 dark:text-gray-300">{parse(aggiuntivo.label)}</label>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </>
                                                      )
                                                  })}

                                                  <div className="col-span-12 sm:col-span-6 mt-4">
                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                          Clausole editabili<strong><span style={{ color: 'red' }}>*</span></strong>
                                                      </label>
                                                      <CKEditors
                                                          activeclassName="p10"
                                                          config={editorConfig2}
                                                          content={clausoleEditabili}
                                                          events={{
                                                              "change": onChangeClausoleEditabili
                                                          }}
                                                      />

                                                      {errors.clausoleEditabili && <p>Il campo è obbligatorio</p>}
                                                  </div>

                                                  
                                                  <br></br>
                                                  <div className="flex justify-between">
                                                      <div className="flex justify-start mt-5">
                                                          <button
                                                              type="button"
                                                              onClick={() => completeStep(1)}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                          >
                                                              <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                                                          </button>
                                                      </div>
                                                      <div className="flex justify-end mt-5">
                                                          {/*<button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MailIcon className="mr-2 h-4 w-4" />Invia
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <PrinterIcon className="mr-2 h-4 w-4" />Stampa
                        </button>*/}
                                                          <button
                                                              type="submit"
                                                              onClick={() => {
                                                                onSubmit()
                                                                completeStep(3)
                                                            }}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                          >
                                                              Completa
                                                          </button>
                                                      </div>
                                                  </div>






                                              </div>
                                          </div>
                                      </div>


                                  </div>




                              </main>)
                        case  3:
                              return (<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none min-h-full">

                                  {/* STEP 2 */}
                                  <div className="py-6">
                                      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                                      </div>
                                      <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
                                          {/* Replace with your content */}
                                          {isPreventivo && isCliente && loading && loading2 ? <nav aria-label="Progress">
                                              <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                                  {isPreventivo.steps.map((step) => (
                                                      <li key={step.name} className="md:flex-1">
                                                          {step.status === 'complete' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-green-600 group-hover:text-green-800">{step.id}</span>
                                                                  <span className="text-sm font-medium text-green-600">{step.name}</span>
                                                              </a>
                                                          ) : step.status === 'current' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="flex flex-col border-l-4 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                  aria-current="step"
                                                              >
                                                                  <span className="text-sm font-medium text-blue-600">{step.id}</span>
                                                                  <span className="text-sm font-medium text-blue-600">{step.name}</span>
                                                              </a>
                                                          ) : (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                                                  <span className="text-sm font-medium">{step.name}</span>
                                                              </a>
                                                          )}
                                                      </li>
                                                  ))}
                                              </ol>
                                          </nav> : null}

                                          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

                                              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                                  {isPreventivo && isCliente && loading && loading2 ? <div className="mt-5 md:mt-0 md:col-span-2">
                                                      <div className="flex justify-between mt-5 mb-5">
                                                          <p>
                                                              Gent. {isCliente.ragioneSociale}<br />
                                                              {isCliente.address + ', ' + isCliente.civic}<br />
                                                              {isCliente.postalCode + ' - ' + isCliente.city + ' (' + isCliente.region + ')'}<br />
                                                              piva/cf {isCliente.vat}
                                                          </p>
                                                        
                                                      </div>
                                                      <div className="flex justify-start mt-5">
                                                          <p>
                                                              Preventivo n {isPreventivo.number}/{moment(isPreventivo.created_at).format("YY")} del {moment(isPreventivo.created_at).format("DD/MM/YYYY")}<br />
                                                              <br />
                                                          </p>
                                                      </div>

                                                  </div> : null}



                                                  <br />
                                                  {aggiuntivi.map((aggiuntivo, index) => {
                                                      return (
                                                          <>
                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={aggiuntivo.value} onChange={
                                                                              e => setAggiuntivi(current =>
                                                                                  current.map(obj => {
                                                                                      if (obj.label === aggiuntivo.label) {
                                                                                          return { ...obj, value: e.target.checked};
                                                                                      }

                                                                                      return obj;
                                                                                  }),
                                                                              )
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm text-gray-900 dark:text-gray-300">{parse(aggiuntivo.label)}</label>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </>
                                                      )
                                                  })}

                                                  <div className="col-span-12 sm:col-span-6 mt-4">
                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                          Clausole editabili<strong><span style={{ color: 'red' }}>*</span></strong>
                                                      </label>
                                                      <CKEditors
                                                          activeclassName="p10"
                                                          config={editorConfig}
                                                          content={clausoleEditabili}
                                                          events={{
                                                              "change": onChangeClausoleEditabili
                                                          }}
                                                      />

                                                      {errors.clausoleEditabili && <p>Il campo è obbligatorio</p>}
                                                  </div>


                                                  
                                                  <br></br>
                                                  <div className="flex justify-between">
                                                      <div className="flex justify-start mt-5">
                                                          <button
                                                              type="button"
                                                              onClick={() => completeStep(1)}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                          >
                                                              <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                                                          </button>
                                                      </div>
                                                      <div className="flex justify-end mt-5">
                                                          {/*<button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MailIcon className="mr-2 h-4 w-4" />Invia
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <PrinterIcon className="mr-2 h-4 w-4" />Stampa
                        </button>*/}
                                                          <button
                                                              type="button"
                                                              onClick={() => completeAggiuntivi()}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                          >
                                                              {isSaving ? 'Salvo...' : 'Aggiorna'}
                                                          </button>
                                                          <button
                                                              type="submit"
                                                              onClick={() => completeStep(3)}
                                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                          >
                                                              Completa
                                                          </button>
                                                      </div>
                                                  </div>






                                              </div>
                                          </div>
                                      </div>


                                  </div>




                              </main>)
                          default:
                              return (<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none min-h-full">

                                  {/* STEP 1 */}
                                  <div className="py-6">
                                      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                                      </div>
                                      <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
                                          {/* Replace with your content */}
                                          {isPreventivo && isCliente && loading && loading2 ? <nav aria-label="Progress">
                                              <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                                  {isPreventivo.steps.map((step) => (
                                                      <li key={step.name} className="md:flex-1">
                                                          {step.status === 'complete' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-green-600 group-hover:text-green-800">{step.id}</span>
                                                                  <span className="text-sm font-medium text-green-600">{step.name}</span>
                                                              </a>
                                                          ) : step.status === 'current' ? (
                                                              <a
                                                                  href={step.href}
                                                                  className="flex flex-col border-l-4 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                                  aria-current="step"
                                                              >
                                                                  <span className="text-sm font-medium text-blue-600">{step.id}</span>
                                                                  <span className="text-sm font-medium text-blue-600">{step.name}</span>
                                                              </a>
                                                          ) : (
                                                              <a
                                                                  href={step.href}
                                                                  className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                                                              >
                                                                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                                                  <span className="text-sm font-medium">{step.name}</span>
                                                              </a>
                                                          )}
                                                      </li>
                                                  ))}
                                              </ol>
                                          </nav> : null}

                                          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

                                              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                                  <div className=" md:mt-0 md:col-span-2">
                                                      <div className="flex justify-end mb-5">
                                                          <p>
                                                              Gent. {isCliente.ragioneSociale}<br />
                                                              {isCliente.address + ', ' + isCliente.civic}<br />
                                                              {isCliente.postalCode + ' - ' + isCliente.city + ' (' + isCliente.region + ')'}<br />
                                                              piva/cf {isCliente.vat}
                                                          </p>
                                                      </div>
                                                      {isPreventivo && isCliente && loading && loading2 ? <form onSubmit={handleSubmit(onSubmit)}>
                                                          <div className="grid grid-cols-6 gap-6 mb-4 ">
                                                              <div className="col-span-1 sm:col-span-1 ">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero preventivo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                  </label>
                                                                  <div className="flex">
                                                                      <input
                                                                          type="number"
                                                                          name="number"
                                                                          id="number"
                                                                          defaultValue={isPreventivo.number ? isPreventivo.number : isCliente.preventivi.length + 1}
                                                                          {...register("number")}
                                                                          className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                      />
                                                                      <p>/{moment(isPreventivo.created_at).format("YYYY")}</p>

                                                                  </div>

                                                                  {errors.number && <p>Il campo è obbligatorio</p>}
                                                              </div>
                                                              <div className="col-span-2 sm:col-span-2">
                                                                  <label htmlFor="sopralluogo" className="block text-sm font-medium text-gray-700">
                                                                      Data sopralluogo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                  </label>
                                                                  <input
                                                                      type="date"
                                                                      name="sopralluogo"
                                                                      id="sopralluogo"
                                                                      defaultValue={isPreventivo.sopralluogo ? moment(isPreventivo.sopralluogo).format("YYYY-MM-DD") : null}
                                                                      {...register("sopralluogo")}
                                                                      className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />

                                                                  {errors.sopralluogo && <p>Il campo è obbligatorio</p>}
                                                              </div>
                                                              <div className="col-span-3 sm:col-span-3">
                                                                  <label htmlFor="traslocoIpotetico" className="block text-sm font-medium text-gray-700">
                                                                      Data trasloco ipotetico<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                  </label>
                                                                  <input
                                                                      type="text"
                                                                      name="traslocoIpotetico"
                                                                      id="traslocoIpotetico"
                                                                      defaultValue={isPreventivo.traslocoIpotetico}
                                                                      {...register("traslocoIpotetico")}
                                                                      className="mt-1 mr-2 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />

                                                                  {errors.traslocoIpotetico && <p>Il campo è obbligatorio</p>}
                                                              </div>
                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Nome preventivo<strong><span style={{ color: 'red' }}>*</span></strong>
                                                                  </label>
                                                                  <input
                                                                      type="text"
                                                                      name="name"
                                                                      id="name"
                                                                      defaultValue={isPreventivo.name}
                                                                      {...register("name")}
                                                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />

                                                                  {errors.name && <p>Il campo è obbligatorio</p>}
                                                              </div>

                                                          </div>
                                                          <hr className='my-16' />

                                                          {/*CARICO  */}
                                                          <div>
                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                      Carico <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setCarico(old => [...old, {
                                                                          label: "",
                                                                          value: generate_random(10),
                                                                          permessi: false
                                                                      }])}>Aggiungi</small>
                                                                  </label>
                                                              </div>
                                                              {carico ? <div className="mt-2">
                                                                  {carico.map((item, index) => {
                                                                      return (
                                                                          <div key={index} className="grid grid-cols-2 gap-2 mb-4 mt-4">

                                                                              <div style={{
                                                                                  display: 'flex',
                                                                                  flexDirection: 'row',
                                                                              }}>
                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                      marginRight: '2vw',
                                                                                      paddingTop: '2vh'
                                                                                  }}>
                                                                                      <ReactTooltip />
                                                                                      <MinusCircleIcon
                                                                                          onClick={() => setCarico(current =>
                                                                                              current.filter(dest => {
                                                                                                  return dest.value !== item.value;
                                                                                              }),
                                                                                          )}
                                                                                          className="h-6 w-6 text-red-600"
                                                                                          aria-hidden="true"
                                                                                          data-tip="Rimuovi"
                                                                                      />
                                                                                  </div>
                                                                                  <div style={{ width: '100vw' }}>
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                          Carico

                                                                                      </label>
                                                                                      <input
                                                                                          type="text"
                                                                                          name="name"
                                                                                          id="name"
                                                                                          defaultValue={item.label}
                                                                                          onChange={e => updateCaricoLegenda(item, e.target.value)}
                                                                                          autoComplete="name"
                                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                      />
                                                                                  </div>

                                                                              </div>

                                                                              <div className='flex-1 justify-start'>
                                                                                  <div className="flex items-center">
                                                                                      <input id="checked-checkbox" checked={item.permessi} onChange={e => updatePermessiCarico(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                      <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Permessi</label>
                                                                                  </div>
                                                                              </div>

                                                                          </div>
                                                                      )
                                                                  })}
                                                              </div> : null}

                                                          </div>
                                                          <hr className='my-16' />

                                                          {/*DESTINAZIONI  */}
                                                          <div>
                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                      Destinazioni <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setLegenda(old => [...old, {
                                                                          label: "",
                                                                          color: "",
                                                                          value: generate_random(10),
                                                                          permessi: false
                                                                      }])}>Aggiungi</small>
                                                                  </label>
                                                              </div>
                                                              {legenda ? <div className="mt-2">
                                                                  {legenda.map((item, index) => {
                                                                      return (
                                                                          <div key={index} className="grid grid-cols-2 gap-2 mb-4 mt-4">

                                                                              <div style={{
                                                                                  display: 'flex',
                                                                                  flexDirection: 'row',
                                                                              }}>
                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                      marginRight: '2vw',
                                                                                      paddingTop: '2vh'
                                                                                  }}>
                                                                                      <ReactTooltip />
                                                                                      <MinusCircleIcon
                                                                                          onClick={() => setLegenda(current =>
                                                                                              current.filter(dest => {
                                                                                                  return dest.value !== item.value;
                                                                                              }),
                                                                                          )}
                                                                                          className="h-6 w-6 text-red-600"
                                                                                          aria-hidden="true"
                                                                                          data-tip="Rimuovi"
                                                                                      />
                                                                                  </div>
                                                                                  <div style={{ width: '100vw' }}>
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                          Destinazione
                                                                                      </label>
                                                                                      <input
                                                                                          type="text"
                                                                                          name="name"
                                                                                          id="name"
                                                                                          defaultValue={item.label}
                                                                                          onChange={e => updateDestinazioneLegenda(item, e.target.value)}
                                                                                          autoComplete="name"
                                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                      />
                                                                                  </div>

                                                                              </div>
                                                                              <div className="ml-4">

                                                                                  <CirclePicker
                                                                                      triangle='hide'
                                                                                      color={item.color}
                                                                                      colors={[
                                                                                          '#FF0000', '#00FFFF', '#0000FF', '#FFFF00', '#008000', '#FFA500', '#FF00FF', '#800000', '#0000A0', '#800080', '#C0C0C0'
                                                                                      ]}
                                                                                      onChangeComplete={(color, event) => {
                                                                                          updateColoreLegenda(item, color.hex)
                                                                                      }}
                                                                                  />
                                                                              </div>

                                                                              <div className='flex-1 justify-start'>
                                                                                  <div className="flex items-center">
                                                                                      <input id="checked-checkbox" checked={item.permessi} onChange={e => updatePermessiLegenda(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                      <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Permessi</label>
                                                                                  </div>
                                                                              </div>

                                                                          </div>
                                                                      )
                                                                  })}
                                                              </div> : null}

                                                          </div>
                                                          <hr className='my-16' />
                                                          {/*STANZE */}
                                                          <div>
                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                      Stanze <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setStanze(old => [...old, {
                                                                          label: "",
                                                                          value: generate_random(10),
                                                                      }])}>Aggiungi</small>
                                                                  </label>
                                                              </div>
                                                              {legenda ? <div className="mt-2">
                                                                  {stanze.map((item, index) => {
                                                                      return (
                                                                          <div key={index} className="grid grid-cols-2 gap-2 mb-4 mt-4">

                                                                              <div style={{
                                                                                  display: 'flex',
                                                                                  flexDirection: 'row',
                                                                              }}>
                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                      marginRight: '2vw',
                                                                                      paddingTop: '2vh'
                                                                                  }}>
                                                                                      <ReactTooltip />
                                                                                      <MinusCircleIcon
                                                                                          onClick={() => setStanze(current =>
                                                                                              current.filter(dest => {
                                                                                                  return dest.value !== item.value;
                                                                                              }),
                                                                                          )}
                                                                                          className="h-6 w-6 text-red-600"
                                                                                          aria-hidden="true"
                                                                                          data-tip="Rimuovi"
                                                                                      />
                                                                                  </div>
                                                                                  <div style={{ width: '100vw' }}>
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                                          Nome stanza
                                                                                      </label>
                                                                                      <input
                                                                                          type="text"
                                                                                          name="name"
                                                                                          id="name"
                                                                                          defaultValue={item.label}
                                                                                          onChange={e => updateStanza(item, e.target.value)}
                                                                                          autoComplete="name"
                                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                      />
                                                                                  </div>

                                                                              </div>

                                                                          </div>
                                                                      )
                                                                  })}
                                                              </div> : null}

                                                          </div>
                                                          <hr className='my-16' />
                                                          {/*SERVIZI*/}
                                                          <div>
                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                      Servizi <small className="text-blue-600 cursor-pointer font-bold" onClick={() => setServizi(old => [...old, {
                                                                          servizio_name: "",
                                                                          servizio_qty: 1,
                                                                          servizio_prezzo: "",
                                                                          servizio_ID: "",
                                                                          stanza: {
                                                                              value: "",
                                                                              label: ""
                                                                          },
                                                                          legenda: {
                                                                              value: "",
                                                                              color: "",
                                                                              label: "",
                                                                              isContainer: false
                                                                          },
                                                                          carico: {
                                                                              value: "",
                                                                              label: "",
                                                                              isContainer: false
                                                                          },
                                                                          id: generate_random(10)
                                                                      }])}>Aggiungi</small>
                                                                  </label>
                                                              </div>
                                                              {servizi ? <div className="mt-2">
                                                                  {servizi.map((item, index) => {
                                                                      return (
                                                                          <div key={index} className="grid grid-cols-3 gap-3 mb-4 mt-4">

                                                                              <div style={{
                                                                                  display: 'flex',
                                                                                  flexDirection: 'row',
                                                                              }}>
                                                                                  <div style={{
                                                                                      display: 'flex',
                                                                                      flexDirection: 'row',
                                                                                      marginRight: '2vw',
                                                                                      paddingTop: '2vh'
                                                                                  }}>
                                                                                      <ReactTooltip />
                                                                                      <MinusCircleIcon
                                                                                          onClick={() => setServizi(current =>
                                                                                              current.filter(dest => {
                                                                                                  return dest.id !== item.id;
                                                                                              }),
                                                                                          )}
                                                                                          className="h-6 w-6 text-red-600 mr-4"
                                                                                          aria-hidden="true"
                                                                                          data-tip="Rimuovi"
                                                                                      />
                                                                                      {serviceChooser[index] ? <PencilIcon
                                                                                          onClick={() => onClick(index)}
                                                                                          className="h-6 w-6 text-blue-600"
                                                                                          aria-hidden="true"
                                                                                          data-tip="Nuovo servizio"
                                                                                      /> : <BookmarkIcon
                                                                                          onClick={() => onClick(index)}
                                                                                          className="h-6 w-6 text-blue-600"
                                                                                          aria-hidden="true"
                                                                                          data-tip="Seleziona da listino"
                                                                                      />}
                                                                                  </div>

                                                                                  <div style={{ width: '100vw' }}>
                                                                                      <label htmlFor="ragioneSociale" className={`block text-sm font-medium text-gray-700 ${!serviceChooser[index] ? ' mb-1' : null}`}>
                                                                                          Servizio
                                                                                      </label>
                                                                                      {serviceChooser[index] ? <input
                                                                                          type="text"
                                                                                          name="name"
                                                                                          id="name"
                                                                                          defaultValue={item.servizio_name}
                                                                                          onChange={e => updateNomeServizio(item, e.target.value)}
                                                                                          autoComplete="name"
                                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                      /> : <Select
                                                                                          options={isListino}
                                                                                          defaultValue={{
                                                                                              value: item.servizio_name,
                                                                                              label: item.servizio_name
                                                                                          }}
                                                                                          onChange={e => {
                                                                                              updateFromChangeServizioPrice(item, e)
                                                                                              updateFromChangeServizioName(item, e)
                                                                                              updateFromChangeServizioID(item, e)
                                                                                          }}
                                                                                          placeholder="Seleziona servizio"
                                                                                      />}
                                                                                  </div>

                                                                              </div>
                                                                              <div style={{
                                                                                  display: 'flex',
                                                                                  flexDirection: 'row',
                                                                              }}>
                                                                                  <div className="mr-4">
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                          Qtà
                                                                                      </label>
                                                                                      <input
                                                                                          type="number"
                                                                                          name="name"
                                                                                          id="name"
                                                                                          step="1"
                                                                                          defaultValue={item.servizio_qty}
                                                                                          onChange={e => updateQtyServizio(item, e.target.value)}
                                                                                          autoComplete="name"
                                                                                          className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                      />
                                                                                  </div>
                                                                                  <div>
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                          Prezzo
                                                                                      </label>
                                                                                      <input
                                                                                          type="number"
                                                                                          step="0.01"
                                                                                          name="name"
                                                                                          id="name"
                                                                                          defaultValue={item.servizio_prezzo}
                                                                                          onChange={e => updatePrezzoServizio(item, e.target.value)}
                                                                                          autoComplete="name"
                                                                                          className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                                      />
                                                                                  </div>


                                                                              </div>
                                                                              <div className="flex">
                                                                                  <div className="w-full mr-2">
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1">
                                                                                          Stanza
                                                                                      </label>
                                                                                      <Select
                                                                                          options={stanze}
                                                                                          defaultValue={item.stanza}
                                                                                          placeholder="Seleziona stanza"
                                                                                          onChange={e => updateStanzaServizio(item, e)}
                                                                                      />

                                                                                  </div>
                                                                                  <div className="w-full mr-2">
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1 flex">
                                                                                          Carico    {" "}<div className="flex items-center">
                                                                                              <input id="checked-checkbox" checked={item.carico.isContainer} onChange={e => updateGoFromContainer(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 ml-4 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                              <label htmlFor="checked-checkbox" className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300"><OfficeBuildingIcon className="h-4 w-4" /></label>
                                                                                          </div>
                                                                                      </label>


                                                                                      {item.carico.isContainer ? <Select
                                                                                          options={isContainer}
                                                                                          defaultValue={item.carico}
                                                                                          placeholder="Seleziona carico"
                                                                                          onChange={e => updateCaricoServizio(item, e)}
                                                                                      /> : <Select
                                                                                          options={carico}
                                                                                          defaultValue={item.carico}
                                                                                          placeholder="Seleziona carico"
                                                                                          onChange={e => updateCaricoServizio(item, e)} />
                                                                                      }
                                                                                  </div>
                                                                                  <div className="w-full">
                                                                                      <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700 mb-1 flex">
                                                                                          Destinazione {" "}<div className="flex items-center">
                                                                                              <input id="checked-checkbox" checked={item.legenda.isContainer} onChange={e => updateGoToContainer(item, e.target.checked)} type="checkbox" className="w-4 h-4 text-blue-600 ml-2 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                              <label htmlFor="checked-checkbox" className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300"><OfficeBuildingIcon className="h-4 w-4" /></label>
                                                                                          </div>
                                                                                      </label>
                                                                                      {item.legenda.isContainer ? <Select
                                                                                          options={isContainer}
                                                                                          defaultValue={item.legenda}
                                                                                          placeholder="Seleziona destinazione"
                                                                                          onChange={e => updateDestinazioneServizio(item, e)} /> : <Select
                                                                                          options={legenda}
                                                                                          defaultValue={item.legenda}
                                                                                          placeholder="Seleziona destinazione"
                                                                                          onChange={e => updateDestinazioneServizio(item, e)}
                                                                                          styles={styles} />
                                                                                      }
                                                                                  </div>
                                                                              </div>


                                                                          </div>
                                                                      )
                                                                  })}
                                                              </div> : null}

                                                          </div>


                                                          {isPreventivo && isCliente && loading && loading2 ? <><hr className='my-16' />

                                                              {/* SCATOLE */}

                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                      Fornitura e trasporto scatole
                                                                  </label>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.completo : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  completo: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Completo</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.fornituraNoTrasporto : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  fornituraNoTrasporto: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Solo fornitura senza trasporto</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.noScatole : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  noScatole: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Niente scatole</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.conOsenzaScatole : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  conOsenzaScatole: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Preventivare con o senza scatole</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-6 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.appendiabiti : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  appendiabiti: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Appendiabiti</label>
                                                                      </div>
                                                                      {/**INPUT NUMERO SE CHECKED */}
                                                                  </div>
                                                              </div>

                                                              {scatole && scatole.appendiabiti ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero appendiabiti
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={scatole ? scatole.numeroAppendiabiti : null}
                                                                      onChange={
                                                                          e => setScatole(params => ({
                                                                              ...params,
                                                                              numeroAppendiabiti: e.target.value,
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.imballaggioCompleto : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  imballaggioCompleto: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Servizio imballaggio completo</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.imballaggioParziale : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  imballaggioParziale: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Servizio imballaggio parziale</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-6 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.scatolePiccole : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  scatolePiccole: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scatole piccole</label>
                                                                      </div>
                                                                      {/**INPUT NUMERO SE CHECKED */}
                                                                  </div>
                                                              </div>

                                                              {scatole && scatole.scatolePiccole ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero scatole piccole
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={scatole ? scatole.numeroScatolePiccole : null}
                                                                      onChange={
                                                                          e => setScatole(params => ({
                                                                              ...params,
                                                                              numeroScatolePiccole: e.target.value,
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}

                                                              <div className="col-span-6 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.scatoleGrandi : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  scatoleGrandi: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scatole grandi</label>
                                                                      </div>
                                                                      {/**INPUT NUMERO SE CHECKED */}
                                                                  </div>
                                                              </div>

                                                              {scatole && scatole.scatoleGrandi ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero scatole grandi
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={scatole ? scatole.numeroScatoleGrandi : null}
                                                                      onChange={
                                                                          e => setScatole(params => ({
                                                                              ...params,
                                                                              numeroScatoleGrandi: e.target.value,
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}


                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={scatole ? scatole.faldoni : false} onChange={
                                                                              e => setScatole(params => ({
                                                                                  ...params,
                                                                                  faldoni: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scatole faldoni</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              {scatole && scatole.faldoni ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero faldoni
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={scatole ? scatole.numeroFaldoni : null}
                                                                      onChange={
                                                                          e => setScatole(params => ({
                                                                              ...params,
                                                                              numeroFaldoni: e.target.value,
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}





                                                              <hr className='my-16' />

                                                              {/* INFO FINALI */}

                                                              <div className="col-span-12 sm:col-span-6">
                                                                  <label htmlFor="ragioneSociale" className="block font-medium text-gray-700">
                                                                      Varie
                                                                  </label>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.bacchetteTende : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  bacchetteTende: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Bacc tende</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.capiAppesi : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  capiAppesi: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Capi appesi</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.borseTV : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  borseTV: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Borse TV</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-span-6 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.quadri : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  quadri: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Quadri</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              {info && info.quadri ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero quadri
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={info ? info.numeroQuadri : null}
                                                                      onChange={
                                                                          e => setInfo(params => ({
                                                                              ...params,
                                                                              numeroQuadri: parseInt(e.target.value),
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.lampadari : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  lampadari: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lampadari</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              {info && info.lampadari ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero lampadari
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={info ? info.numeroLampadari : null}
                                                                      onChange={
                                                                          e => setInfo(params => ({
                                                                              ...params,
                                                                              numeroLampadari: parseInt(e.target.value),
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.plafoniere : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  plafoniere: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Plafoniere</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              {info && info.plafoniere ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero plafoniere
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={info ? info.numeroPlafoniere : null}
                                                                      onChange={
                                                                          e => setInfo(params => ({
                                                                              ...params,
                                                                              numeroPlafoniere: parseInt(e.target.value),
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.aplique : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  aplique: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Aplique</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              {info && info.aplique ? <div className="col-span-3 sm:col-span-3 mt-6">
                                                                  <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                      Numero aplique
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="name"
                                                                      id="name"
                                                                      step="1"
                                                                      defaultValue={info ? info.numeroAplique : null}
                                                                      onChange={
                                                                          e => setInfo(params => ({
                                                                              ...params,
                                                                              numeroAplique: parseInt(e.target.value),
                                                                          }))
                                                                      }
                                                                      autoComplete="name"
                                                                      className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />
                                                              </div> : null}

                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.carrelloPianoforte : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  carrelloPianoforte: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Carrello pianoforte</label>
                                                                      </div>
                                                                  </div>
                                                              </div></> : null}





                                                          <hr className='my-16' />

                                                          {/* COSTI TOTALI */}

                                                          {servizi ? <div className="flex justify-end mt-5">
                                                              <p>
                                                                  <strong>Imponibile:</strong> {servizi.reduce((accumulator, object) => {
                                                                      return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
                                                                  }, 0) ? servizi.reduce((accumulator, object) => {
                                                                      return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
                                                                  }, 0) + " €" : 0 + " €"}<br />
                                                                  <strong>IVA:</strong> {+(Math.round(servizi.reduce((accumulator, object) => {
                                                                      return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
                                                                  }, 0) / 100 * 22 + "e+2") + "e-2") + " €"}<br />
                                                                  <strong>Totale:</strong> {servizi.reduce((accumulator, object) => {
                                                                      return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
                                                                  }, 0) + (+(Math.round(servizi.reduce((accumulator, object) => {
                                                                      return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
                                                                  }, 0) / 100 * 22 + "e+2") + "e-2")) + " €"}<br />
                                                              </p>
                                                          </div> : null}

                                                          <div className="col-span-3 sm:col-span-3 mt-6">
                                                              <label htmlFor="ragioneSociale" className="block text-sm font-medium text-gray-700">
                                                                  Note
                                                              </label>
                                                              <textarea
                                                                  rows="5"
                                                                  id="name"
                                                                  step="1"
                                                                  defaultValue={isPreventivo.note}
                                                                  {...register("note")}
                                                                  className="mt-1 mr-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                              />
                                                          </div>

                                                          <br />

                                                          <div className="grid grid-cols-2 gap-4">
                                                              <div className="col-span-1 sm:col-span-1">
                                                                  <label htmlFor="acconto" className="block text-sm font-medium text-gray-700">
                                                                      Importo acconto<strong><span style={{ color: 'red' }}>*</span><small className='ml-2 font-light'>IVA esclusa</small></strong>
                                                                  </label>
                                                                  <input
                                                                      type="number"
                                                                      name="acconto"
                                                                      id="acconto"
                                                                      defaultValue={isPreventivo.acconto}
                                                                      {...register("acconto")}
                                                                      className="mt-1 focus:ring-blue-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                  />

                                                                  {errors.acconto && <p>Il campo è obbligatorio</p>}
                                                              </div>

                                                              <div className="col-span-1 sm:col-span-1">

                                                              </div>
                                                              <div className="col-span-12 sm:col-span-6 mt-6">
                                                                  <div className='flex-1 justify-start'>
                                                                      <div className="flex items-center">
                                                                          <input id="checked-checkbox" checked={info ? info.scorporo : false} onChange={
                                                                              e => setInfo(params => ({
                                                                                  ...params,
                                                                                  scorporo: e.target.checked,
                                                                              }))
                                                                          } type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                          <label htmlFor="checked-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scorporo prezzo</label>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                          </div>



                                                          <div className="flex justify-between">
                                                              <div className="flex justify-start mt-5">
                                                                  <button
                                                                      type="button"
                                                                      onClick={() => history.goBack()}
                                                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                  >
                                                                      <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                                                                  </button>
                                                              </div>
                                                              <div className="flex justify-end mt-5">
                 
                                                                  <button
                                                                      type="submit"
                                                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                                  >
                                                                      {isSaving ? 'Salvo...' : 'Aggiorna'}
                                                                  </button>
                                                                  <button
                                                                      type="submit"
                                                                      onClick={() => completeStep(1)}
                                                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                  >
                                                                      <ArrowRightIcon className="mr-2 h-4 w-4" />Avanti
                                                                  </button>
                                                              </div>
                                                          </div>


                                                      </form> : null}
                                                  </div>





                                              </div>
                                          </div>
                                      </div>


                                  </div>




                              </main>);
                      }
                  })()}
        { currentStep <= 1 ?
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">


          </main> : <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

          </main>}</> :
          <div className="flex justify-center items-center align-middle h-full w-full">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>


            </div>




  )
}
