import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser';
import Table from "./Table";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CirclePicker } from 'react-color'
import SidebarMenu from "./sidebar_menu"
import Select from "react-select";
import moment from "moment"

import {
  ArrowLeftIcon,
  TrashIcon,
  ArrowRightIcon,
  EyeIcon,
  CalendarIcon,
  CheckIcon,
  ChartBarIcon,
  PrinterIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  PencilIcon,
  MailIcon,
  BookmarkIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  CheckCircleIcon,
  ExclamationIcon,
  CogIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

//Importo Menu
import Menu from "./navigation";
import { propTypes } from 'react-bootstrap/esm/Image'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function ClientiPreventivoPrint(props) {


  //Setto gli state
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const cookies = parseCookies()
  const history = useHistory()
  const [isLoggedOut, setLogout] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [isCreated, setCreated] = useState(false)
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [showsetAllProfile, setAllProfile] = useState({});


useEffect(() => {
  const expiration = cookies.expiration
  if(new Date(expiration) < new Date()) {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')
  }
}, [setLogout])


const logout = () => {
    localStorage.clear();
    destroyCookie()
    setLogout(true)
    history.push('/login')

}

const CALLlogout = () => {
  setOpen(true);
}

  let styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "white",
      backgroundColor: state.data.color,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
    })
  };

  //Generazione randomica di stringhe
  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    getClienti()
    getListino()
  }, [])



  //Gestione listino

  const [isListino, setListino] = useState(null)

  const getListino = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/listino/fetch4Preventivo`, {
      headers: {
        "x-access-token": cookies.token
      }
    })
    setListino(response.data)
    setLoading2(true)
  }


  const [isPreventivo, setPreventivo] = useState([])
  const [isCliente, setCliente] = useState([])
  const [servizi, setServizi] = useState(null)
  const [legenda, setLegenda] = useState(null)
  const [carico, setCarico] = useState(null)
  const [scatole, setScatole] = useState(null)
  const [info, setInfo] = useState(null)
  const [stanze, setStanze] = useState([
    {
      value: 'waewauG8Tahs2yeich',
      label: 'INGRESSO'
    },
    {
      value: 'ice8mohdaeNgoo1moo',
      label: 'CUCINA'
    },
    {
      value: 'ahf0iem5iixahJ4aru',
      label: 'SALOTTO'
    },
    {
      value: 'dieHieM0yei9saithe',
      label: 'BAGNO'
    },
    {
      value: 'ohshaeh8lini4cheeH',
      label: 'CAMERA DA LETTO 1'
    },
    {
      value: 'eph5uePeelu5ulae0i',
      label: 'CAMERA DA LETTO 2'
    },
  ])
  const [isSaving, setSaving] = useState(false)
  const [serviceChooser, setServiceChooser] = useState(false)
  const [currentStep, setCurrentStep] = useState(null)


  const onClick = (id) => {
    setServiceChooser(prev => Boolean(!prev[id]) ? { ...prev, [id]: true } : { ...prev, [id]: false });
  }


  const generate_random = (length) => {
    var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}



  // ✅ Update one or more objects in a state array
  const updateColoreLegenda = (item, color) => {
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, color: color };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateColoreCarico = (item, color) => {
    setCarico(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, color: color };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateDestinazioneLegenda = (item, dest) => {
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateCaricoLegenda = (item, dest) => {
    setCarico(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateStanza = (item, dest) => {
    setStanze(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, label: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePermessiLegenda = (item, dest) => {
    console.log(dest)
    setLegenda(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, permessi: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePermessiCarico = (item, dest) => {
    console.log(dest)
    setCarico(current =>
      current.map(obj => {
        if (obj.value === item.value) {
          return { ...obj, permessi: dest };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateNomeServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_name: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updatePrezzoServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_prezzo: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateQtyServizio = (item, value) => {
    item.qty = value
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_qty: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateFromChangeServizioPrice = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_prezzo: value.price };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeServizioName = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_name: value.label };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateFromChangeServizioID = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, servizio_ID: value.value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateDestinazioneServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, legenda: value };
        }
        return obj;
      }),
    );
  };
  // ✅ Update one or more objects in a state array
  const updateCaricoServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, carico: value };
        }
        return obj;
      }),
    );
  };

  // ✅ Update one or more objects in a state array
  const updateStanzaServizio = (item, value) => {
    setServizi(current =>
      current.map(obj => {
        if (obj.id === item.id) {
          return { ...obj, stanza: value };
        }
        return obj;
      }),
    );
  };



  const getClienti = async () => {
    const response = await axios.get(`${window.$apiEndpoint}/clienti/fetchPreventivo`, {
      params: {
        id: props.match.params.id
      },
      headers: {
        "x-access-token": cookies.token
      }
    })
    setPreventivo(response.data.preventivo)
    setLegenda(response.data.preventivo.legenda)
    setCarico(response.data.preventivo.carico)
    if (response.data.preventivo.stanze && response.data.preventivo.stanze.length > 0) {
      setStanze(response.data.preventivo.stanze)
    }
    setCliente(response.data.cliente)
    setServizi(response.data.preventivo.servizi)
    setScatole(response.data.preventivo.scatole)
    setInfo(response.data.preventivo.info)
    let steps = response.data.preventivo.steps
    if(steps) {
      let i = 1
      steps.map(step => {
        if(step.status === 'complete') {
          i = i + 1
        }
      })
      setCurrentStep(i)
       console.log(i)
    }
    setLoading(true)
  }

        //Chiamata post per inserimento utente
        const onSubmit = async (data) => {
          setSaving(true)
          const response = await axios.patch(`${window.$apiEndpoint}/clienti/edit-preventivo`, {
            cliente: isCliente,
            preventivo: data,
            name: data.name.length > 0 ? data.name : isPreventivo.name,
            legenda: legenda,
            servizi: servizi,
            stanze: stanze,
            carico: carico,
            scatole: scatole,
            info: info,
            id: props.match.params.id,
            imponibile: servizi.reduce((accumulator, object) => {
              return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
            }, 0),
            iva: +(Math.round(servizi.reduce((accumulator, object) => {
              return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
            }, 0) / 100 * 22 + "e+2") + "e-2"),
            totale: servizi.reduce((accumulator, object) => {
              return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
            }, 0) + (+(Math.round(servizi.reduce((accumulator, object) => {
              return accumulator + (parseFloat(object.servizio_prezzo) * parseInt(object.servizio_qty));
            }, 0) / 100 * 22 + "e+2") + "e-2"))
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti()  
          setTimeout(() => {
            setSaving(false)
          }, 1000)
      }


      const [stepLoader, setStepLoader] = useState(true)
        //Chiamata post per inserimento utente
        const completeStep = async (data) => {
          setStepLoader(false)
          const response = await axios.patch(`${window.$apiEndpoint}/clienti/complete-step`, {
            cliente: isCliente,
            id: props.match.params.id,
            step: data
          } ,{
            headers: {
              "x-access-token": cookies.token
            }
          })
          getClienti() 
          setTimeout(() => {
            setStepLoader(true)
            if(parseInt(data) === 2) {
              history.push(`/clienti/${isCliente._id}`)
            }
          }, 1000) 
      }


    const { SearchBar } = Search;

  const printPrev = () => {
    const box = document.getElementById('not-print');

    // 👇️ hides element (still takes up space on page)
    box.style.visibility = 'hidden';


    window.print()
  }


    //Se lo state isCreated ricarico la pagina
    if (isCreated) {
        //Ricarica Pagina
        return window.location.reload();
    }




  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">

        <button id="not-print" onClick={() => printPrev()}>Qui il tasto per stampare</button>

        
        {isPreventivo && isCliente && loading && loading2 ?<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

            {/* STEP 2 */}
            <div >
              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              </div>
              <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 mt-6">
                {/* Replace with your content */}


                <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 mt-3" >

                  <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    {isPreventivo && isCliente && loading && loading2 ? <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="flex justify-between mt-5 mb-5">
                        <p>
                          Gent.mo {isCliente.ragioneSociale}<br />
                          {isCliente.address + ', ' + isCliente.civic}<br />
                          {isCliente.postalCode + ' - ' + isCliente.city + ' (' + isCliente.region + ')'}<br />
                          piva/cf {isCliente.vat}
                        </p>
                        <div className="text-right">
                          <h5>Carico</h5>
                          <ul className="list-none">
                            {carico.map((destinazione, index) => {
                              return (
                                <li key={index}><span>{destinazione.label} {destinazione.permessi ? "(Permessi richiesti)" : null}</span></li>
                              )
                            })}
                          </ul>
                          <h5>Destinazioni</h5>
                          <ul className="list-none">
                            {legenda.map((destinazione, index) => {
                              return (
                                <li key={index}><span style={{ color: destinazione.color }}>{destinazione.label} {destinazione.permessi ? "(Permessi richiesti)" : null}</span></li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="flex justify-start mt-5">
                        <p>
                          Preventivo n {isPreventivo.number}/{moment(isPreventivo.created_at).format("YY")} del {moment().format("DD/MM/YYYY")}<br/>
                          <br /> 
                          {isPreventivo.sopralluogo ? <span>A seguito di sopralluogo effettuato in data {moment(isPreventivo.sopralluogo).format("DD/MM/YYYY")} trova</span> : <span>Trova</span>} di seguito la nostra migliore offerta
                        </p>
                      </div>
                      
                    </div>: null}

                    

                     <br />
                     {stanze.map((stanza, index) => {
                      return (
                        <>{servizi.filter(item => item.stanza.value === stanza.value).length > 0 ? 
                        <div className="border rounded-sm p-4 border-black">
                          <h5>{stanza.label}</h5>
                          <ul className="list-disc">
                            {servizi.filter(item => item.stanza.value === stanza.value).map((servizio, index) => {
                              return (
                                <li key={index} className="flex justify-between">
                                  <div style={{ color: servizio.legenda.color }}>nº{servizio.servizio_qty} {servizio.servizio_name}</div>
                                  <div>{info.scorporo ? <span>{servizio.servizio_prezzo}* € </span> : ""}</div>
                                  </li>
                              )
                            })}
                          </ul>
                        </div> 
                        : null}</>
                      )
                     })}

                    <div className="border rounded-sm p-4 border-black">
                      <h5>SCATOLE</h5>
                      <br />
                      {scatole.completo ? <p>✔️ Completo</p> : null}
                      {scatole.fornituraNoTrasporto ? <p>✔️ Solo fornitura senza trasporto</p> : null}
                      {scatole.noScatole ? <p>✔️ Niente scatole</p> : null}
                      {scatole.conOsenzaScatole ? <p>✔️ Preventivare con o senza scatole</p> : null}
                      {scatole.appendiabiti ? <p>✔️ Appendiabiti nº {scatole.numeroAppendiabiti}</p> : null}
                      {scatole.imballaggioCompleto ? <p>✔️ Servizio imballaggio completo</p> : null}
                      {scatole.imballaggioParziale ? <p>✔️ Servizio imballaggio parziale</p> : null}
                      {scatole.faldoni ? <p>✔️ Scatole piccole nº {scatole.numeroScatolePiccole}</p> : null}
                      {scatole.faldoni ? <p>✔️ Scatole grandi nº {scatole.numeroScatoleGrandi}</p> : null}
                      {scatole.faldoni ? <p>✔️ Scatole faldoni nº {scatole.numeroFaldoni}</p> : null}

                      
                    </div> 
                    <div className="border rounded-sm p-4 border-black">
                      <h5>VARIE</h5>
                      <br />
                      {info.bacchetteTende ? <p>✔️ Bacc tende</p> : null}
                      {info.capiAppesi ? <p>✔️ Capi appesi</p> : null}
                      {info.borseTV ? <p>✔️ Borse TV</p> : null}
                      {info.quadri ? <p>✔️ Quadri nº {info.numeroQuadri}</p> : null}
                      {info.plafoniere ? <p>✔️ Plafoniere nº {info.numeroPlafoniere}</p> : null}
                      {info.aplique ? <p>✔️ Aplique nº {info.numeroAplique}</p> : null}
                      {info.lampadari ? <p>✔️ Lampadari nº {info.numeroLampadari}</p> : null}
                      {info.carrelloPianoforte ? <p>✔️ Carrello pianoforte</p> : null}

                    </div> 

                    <div className="flex justify-between">
                      <div className="border rounded-sm w-full p-4 border-black">
                        <h5>PREZZO: {isPreventivo.imponibile}* €</h5>
                      </div> 
                      <div className="border rounded-sm w-full p-4 border-black">
                        <h5>ACCONTO: {isPreventivo.acconto + " € "}<small>da versare in data {moment(isPreventivo.dataAcconto).format("DD-MM-YYYY")}</small></h5>
                      </div> 
                    </div>
                    <div className="border rounded-sm w-full p-4 border-black">
                      <h5>NOTE</h5>
                      <p>{isPreventivo.note}</p>
                    </div> 
                    <br></br>
                    <small><em>* prezzo da intendersi IVA esclusa</em></small>
                    <div className="flex justify-between">
                      <div className="flex justify-start mt-5">
                        <button
                          type="button"
                          onClick={() => completeStep(0)}
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <ArrowLeftIcon className="mr-2 h-4 w-4" />Indietro
                        </button>
                      </div>
                      <div className="flex justify-end mt-5">
                        {/*<button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <MailIcon className="mr-2 h-4 w-4" />Invia
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <PrinterIcon className="mr-2 h-4 w-4" />Stampa
                        </button>*/}
                        <button
                          type="submit"
                          onClick={() => completeStep(2)}
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                          Completa 
                        </button>
                      </div>
                    </div>
                    





                  </div>
                </div>
              </div>


            </div>




        </main> :
          <div className="flex justify-center items-center align-middle h-full w-full">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>} 
               </div>

               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

 
            </div>




  )
}
